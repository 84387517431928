<template>
    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
</template>

<script>
export default {
  computed: {
    perPage: {
      get: function () { return this.$store.state.pagerStd.cursor.range },
      set: function (newValue) { this.$store.dispatch('pagerStd/perPageCountChanged', newValue, { root: true }) }
    },
    pageOptions () {
      return this.$store.state.pagerStd.pageOptions
    }
  }
}
</script>