<template>
  <b-nav tabs class="tables-top-nav">
    <b-nav-item class="desktop-layer" exact to="/invoices">Новый счет</b-nav-item>
    <b-nav-item class="desktop-layer" exact to="/invoices/all">История счетов</b-nav-item>
    <b-nav-item class="desktop-layer" exact to="/invoices/settings">Настройки</b-nav-item>
    <b-nav-item class="desktop-layer" exact :href="`${VUE_APP_MERCHANT_VUE3_URL}invoices/links`">Ссылки</b-nav-item>
    <b-nav-item class="mobile-layer" exact to="/invoices">Счет</b-nav-item>
    <b-nav-item class="mobile-layer" exact to="/invoices/all">История</b-nav-item>
    <b-nav-item class="mobile-layer" exact to="/invoices/settings">Настройки</b-nav-item>
    <b-nav-item class="mobile-layer" exact :href="`${VUE_APP_MERCHANT_VUE3_URL}invoices/links`">Ссылки</b-nav-item>
  </b-nav>
</template>

<script>
export default {
  computed: {
    VUE_APP_MERCHANT_VUE3_URL() {
      return VUE_APP_MERCHANT_VUE3_URL
    }
  },
  methods: {

  },
}

</script>
<style scoped>

</style>
