<template>
  <div class="cell-wrapper">
    {{phone}}&nbsp;<clipboard :data="phone"/> <br />
    {{email}}&nbsp;<clipboard :data="email"/>
  </div>
</template>

<script>
export default {
  computed: {

    email: function () {
      let email = ''
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      if (activeColumnRow === 'loansFinalized'){
          email = this.item.customer.email
      }
      if (activeColumnRow === 'loansPending'){
          email = this.item.lead.email
      }
      return email
    },
    phone: function () {
      let phone = ''
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      if (activeColumnRow === 'loansFinalized'){
          phone = this.item.customer.phone
      }
      if (activeColumnRow === 'loansPending'){
          phone = this.item.lead.phone
      }
      return phone
    }
  },
  props: ['item']
}
</script>
