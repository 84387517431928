<template>
  <div>
    <div v-if="showLimitTab">
      <b-row>
        <b-col><h3>Настройка лимита для выплат</h3></b-col>
        <b-col class="text-right"><a href="https://mndr.in/payout_limits" target="_blank">Инструкция</a></b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <label for="transaction_limit">Лимит на одну операцию (рублей)</label>
          <div class="input-group">
            <b-form-input id="transaction_limit" name="transaction_limit" v-model="projectSingleOperationLimit" :state="projectSingleOperationLimitValid"></b-form-input>
            <div class="input-group-append">
              <b-button v-on:click="eraseField('transaction_limit')" variant="outline-secondary" style="padding: 0px 10px 0px 10px;"><mdicon width="30" height="30" name="delete-circle-outline" /></b-button>
            </div>
            <b-form-invalid-feedback id="transaction_limit-feedback">
              {{errorMessageTransaction}}
            </b-form-invalid-feedback>
          </div>
          <div class="remove-info" v-if="showElement('transaction_limit_removed')">При сохранении лимит транзакции будет удалён</div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <label for="daily_limit">Общий дневной лимит по проекту (рублей)</label>
          <div class="input-group">
            <b-form-input id="daily_limit" name="daily_limit" v-model="projectTotalDailyLimit" :state="projectTotalDailyLimitValid"></b-form-input>
            <div class="input-group-append">
              <b-button v-on:click="eraseField('daily_limit')" variant="outline-secondary" style="padding: 0px 10px 0px 10px;"><mdicon width="30" height="30" name="delete-circle-outline" /></b-button>
            </div>
            <b-form-invalid-feedback id="daily_limit-feedback">
              {{errorMessageDaily}}
            </b-form-invalid-feedback>
          </div>
          <div class="remove-info" v-if="showElement('daily_limit_removed')">При сохранении дневной лимит будет удалён</div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <label for="monthly_limit">Общий месячный лимит по проекту (рублей)</label>
          <div class="input-group">
            <b-form-input id="monthly_limit" name="monthly_limit" v-model="projectTotalMonthlyLimit" :state="projectTotalMonthlyLimitValid"></b-form-input>
            <div class="input-group-append">
              <b-button v-on:click="eraseField('monthly_limit')" variant="outline-secondary" style="padding: 0px 10px 0px 10px;"><mdicon width="30" height="30" name="delete-circle-outline" /></b-button>
            </div>
            <b-form-invalid-feedback id="monthly_limit-feedback">
              {{errorMessageMonthly}}
            </b-form-invalid-feedback>
          </div>
          <div class="remove-info" v-if="showElement('monthly_limit_removed')">При сохранении месячный лимит будет удалён</div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-button v-if="checkScopePresence('antifraud:client_limits_self.write')" v-on:click="saveLimit" :disabled="!allowedLimitSubmit()" variant="outline-primary">Сохранить</b-button>
        </b-col>
      </b-row>
    </div>

    <div v-else style="min-height: 200px">
      <Loader :size="3" />
    </div>

  </div>
</template>

<script>
export default {
  props: ['mid'],
  data: function () {
    return {
      errorMessageDaily: '',
      errorMessageTransaction: '',
      errorMessageMonthly: '',
      dailyLimitRemovedShow: false,
      transactionLimitRemovedShow: false,
      monthlyLimitRemovedShow: false,
    }
  },
  computed:{
    showLimitTab(){
      if (this.$store.state.projectLimits.busy){return false}
      return true
    },
    projectTotalDailyLimit:{
      get: function () {
        return this.$store.state.projectLimits.limits.current.daily
      },
      set: function (newValue) { this.updateLimitsValue('daily', newValue) }
    },
    projectSingleOperationLimit:{
      get: function () {
        return this.$store.state.projectLimits.limits.current.transaction
      },
      set: function (newValue) { this.updateLimitsValue('transaction', newValue) }
    },
    projectTotalMonthlyLimit:{
      get: function () {
        return this.$store.state.projectLimits.limits.current.monthly
      },
      set: function (newValue) { this.updateLimitsValue('monthly', newValue) }
    },
    projectTotalDailyLimitValid(){
      this.errorMessageDaily = ''
      this.dailyLimitRemovedShow = false
      if (this.projectTotalDailyLimit === null){
        return null
      }
      if (this.projectTotalDailyLimit === ''){
        if (Object.prototype.hasOwnProperty.call(this.$store.state.projectLimits.limits.daily.byMid, this.mid)){
          this.dailyLimitRemovedShow = true
          return null
        }else{
          return null
        }
      }
      let isnum = /^\d+$/.test(this.projectTotalDailyLimit)
      if (!isnum){
        this.errorMessageDaily = 'В поле лимита можно вводить только цифры.'
        return false
      }
      return null
    },
    projectSingleOperationLimitValid(){
      this.errorMessageTransaction = ''
      this.transactionLimitRemovedShow = false
      if (this.projectSingleOperationLimit === null){
        return null
      }
      if (this.projectSingleOperationLimit === ''){
        if (Object.prototype.hasOwnProperty.call(this.$store.state.projectLimits.limits.transaction.byMid, this.mid)){
          this.transactionLimitRemovedShow = true
          return null
        }else{
          return null
        }
      }
      let isnum = /^\d+$/.test(this.projectSingleOperationLimit)
      if (!isnum){
        this.errorMessageTransaction = 'В поле лимита можно вводить только цифры.'
        return false
      }
      return null
    },
    projectTotalMonthlyLimitValid(){
      this.errorMessageMonthly = ''
      this.monthlyLimitRemovedShow = false
      if (this.projectTotalMonthlyLimit === null){
        return null
      }
      if (this.projectTotalMonthlyLimit === ''){
        if (Object.prototype.hasOwnProperty.call(this.$store.state.projectLimits.limits.monthly.byMid, this.mid)){
          this.monthlyLimitRemovedShow = true
          return null
        }else{
          return null
        }
      }
      let isnum = /^\d+$/.test(this.projectTotalMonthlyLimit)
      if (!isnum){
        this.errorMessageMonthly = 'В поле лимита можно вводить только цифры.'
        return false
      }
      return null
    },
  },
  methods:{
    updateLimitsValue(type, value){
      let limits = {}
      limits[type] = value
      this.$store.commit('projectLimits/updateCurrentLimitValues', limits)
    },
    allowedLimitSubmit(){
      if (this.projectTotalDailyLimitValid === false || this.projectSingleOperationLimitValid === false || this.projectTotalMonthlyLimitValid === false){
        return false
      }
      if (this.$store.state.projectLimits.busy){
        return false
      }
      return true
    },
    saveLimit(){
      // обработка Transaction limit
      if (Object.prototype.hasOwnProperty.call(this.$store.state.projectLimits.limits.transaction.byMid, this.mid)){
        let existingLimit = this.$store.state.projectLimits.limits.transaction.byMid[this.mid]
        if (existingLimit.amount != this.projectSingleOperationLimit){
          if (this.projectSingleOperationLimit !== '' && this.projectSingleOperationLimit !== null){
            let data = {}
            data.id = existingLimit.id
            data.payload = {
              "amount": this.projectSingleOperationLimit,
              "limit_type": existingLimit.limit_type,
              "name": existingLimit.name,
              "opcode": existingLimit.opcode
            }
            this.$store.dispatch('projectLimits/changeLimit', data)
          }else{
            let data = {}
            data.id = existingLimit.id
            this.$store.dispatch('projectLimits/deleteLimit', data)
          }
        }
      }else{
        if (this.projectSingleOperationLimit != '' && this.projectSingleOperationLimit !== null){
          let payload = {
            "name": "CID: "+this.$store.state.account.mandarin.client_id+" MID: "+this.mid+" payouts/transaction",
            "mw_merchant_id": this.mid,
            "client_id": this.$store.state.account.mandarin.client_id,
            "opcode": [472],
            // "instrument_type_id": 4747,
            "limit_type": "t",
            "amount": this.projectSingleOperationLimit,
          }
          this.$store.dispatch('projectLimits/createLimit', payload)
        }
      }
      // Обработка daily limit
      if (Object.prototype.hasOwnProperty.call(this.$store.state.projectLimits.limits.daily.byMid, this.mid)){
        let existingLimit = this.$store.state.projectLimits.limits.daily.byMid[this.mid]
        if (existingLimit.amount != this.projectTotalDailyLimit){
          if (this.projectTotalDailyLimit !== '' && this.projectTotalDailyLimit !== null){
            let data = {}
            data.id = existingLimit.id
            data.payload = {
              "amount": this.projectTotalDailyLimit,
              "limit_type": existingLimit.limit_type,
              "name": existingLimit.name,
              "opcode": existingLimit.opcode,
              "quantity": existingLimit.quantity,
            }
            this.$store.dispatch('projectLimits/changeLimit', data)
          }else{
            let data = {}
            data.id = existingLimit.id
            this.$store.dispatch('projectLimits/deleteLimit', data)
          }
        }
      }else{
        if (this.projectTotalDailyLimit !== '' && this.projectTotalDailyLimit !== null){
          let payload = {
            "name": "CID: " + this.$store.state.account.mandarin.client_id + " MID: " + this.mid + " payouts/daily",
            "mw_merchant_id": this.mid,
            "client_id": this.$store.state.account.mandarin.client_id,
            "opcode": [472],
            // "instrument_type_id": 4747,
            "limit_type": "d",
            "amount": this.projectTotalDailyLimit,
            "quantity": 10000000,
          }
          this.$store.dispatch('projectLimits/createLimit', payload)
        }
      }
      // Обработка monthly limit
      if (Object.prototype.hasOwnProperty.call(this.$store.state.projectLimits.limits.monthly.byMid, this.mid)) {
        let existingLimit = this.$store.state.projectLimits.limits.monthly.byMid[this.mid]
        if (existingLimit.amount != this.projectTotalMonthlyLimit) {
          if (this.projectTotalMonthlyLimit !== '' && this.projectTotalMonthlyLimit !== null) {
            let data = {}
            data.id = existingLimit.id
            data.payload = {
              "amount": this.projectTotalMonthlyLimit,
              "limit_type": existingLimit.limit_type,
              "name": existingLimit.name,
              "opcode": existingLimit.opcode,
              "quantity": existingLimit.quantity,
            }
            this.$store.dispatch('projectLimits/changeLimit', data)
          } else {
            let data = {}
            data.id = existingLimit.id
            this.$store.dispatch('projectLimits/deleteLimit', data)
          }
        }
      } else {
        if (this.projectTotalMonthlyLimit !== '' && this.projectTotalMonthlyLimit !== null) {
          let payload = {
            "name": "CID: " + this.$store.state.account.mandarin.client_id + " MID: " + this.mid + " payouts/monthly",
            "mw_merchant_id": this.mid,
            "client_id": this.$store.state.account.mandarin.client_id,
            "opcode": [472],
            // "instrument_type_id": 4747,
            "limit_type": "m",
            "amount": this.projectTotalMonthlyLimit,
            "quantity": 1000000000,
          }
          this.$store.dispatch('projectLimits/createLimit', payload)
        }
      }
    },
    eraseField(field) {
      if (field === 'transaction_limit') {
        this.projectSingleOperationLimit = ''
      }
      if (field === 'daily_limit') {
        this.projectTotalDailyLimit = ''
      }
      if (field === 'monthly_limit') {
        this.projectTotalMonthlyLimit = ''
      }
    },
    showElement(el) {
      if (el === 'daily_limit_removed') {
        return this.dailyLimitRemovedShow
      }
      if (el === 'transaction_limit_removed') {
        return this.transactionLimitRemovedShow
      }
      if (el === 'monthly_limit_removed') {
        return this.monthlyLimitRemovedShow
      }
      return false
    }
  },
  watch: {
    mid: function (newMid) {
      this.$store.commit('projectLimits/setCurrentMid', newMid)
      this.$store.dispatch('projectLimits/loadLimits')
    }
  },
  mounted() {
    this.$store.commit('projectLimits/setCurrentMid', this.mid)
    this.$store.dispatch('projectLimits/loadLimits')
  }
}
</script>

<style scoped>
h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #3988FF;
  margin-bottom: 0;
}

.remove-info {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #c4183c;
}
</style>
