<template>
  <d-navbar-nav class="py-2 pr-2 flex-row align-items-center justify-content-between">
    <div class="px-2">
      <button class="menu-toggle" @click.stop="toggle_sidebar" >☰</button>
    </div>
    <div class="mobile-navbar-nav navbar-nav mt-4">
      <d-navbar type="light" class="align-items-stretch p-0"  style="flex-wrap: nowrap">
        <navbar-nav />
      </d-navbar>
    </div>
  </d-navbar-nav>
</template>

<script>
import NavbarNav from "./NavbarNav.vue";
export default {
  components: {
    NavbarNav,
  },
  data () {
    return {

    }
  },
  computed: {
    currentUser: function () {
      if (Object.prototype.hasOwnProperty.call(this.$store.state.account.mandarin, 'email')){
        return this.$store.state.account.mandarin.email
      }else{
        return ""
      }
    },
  },
  methods: {
    toggle_sidebar() {
      this.$emit('toggleSidebar');
      console.log('Click Hamburger')
    },
  },
}
</script>

<style scoped>
.menu-toggle {
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 101;
}
.name {
  color: rgba(0, 0, 0, 0.6);
  font-size: 20px;
  vertical-align: middle;
}
@media (max-width: 1440px) {
  .menu-toggle {
    display: block;
  }
}
</style>
