
export default {
  namespaced: true,
  state: {
    transactionsPriceInfoLoading: false,
    transactionsPriceInfo: {},
    lastDatePriceInfoGroup: [],
    priceInfoLoading: false,
    urls: {
      //transactionsPriceInfoUrl: '/payment-gateway/v3/billing/transaction_price_info'
      transactionsPriceInfoUrl: '/payment-gateway/v3/billing/transaction_price_info'
    },
  },
  mutations:{

  },
  actions:{
    loadPriceInfo(context){
      let url = context.state.urls.transactionsPriceInfoUrl
      this.$axios.get(url)
        .then(response => {
          // Обрезаем все лишнее что пришло в ответе
          console.log(response)
        })
        .catch(error => {
          console.log('error in loadPriceInfo', error)
        })
    }
  }
}