<template>
  <div id="invoicesWrapper" class="row">
    <div v-if="!error403" class="w-100 d-flex flex-row">
      <div class="col-12 min-w-355 px-0 px-sm-2" :class="{'col-5': currentComponent==='newInvoice', 'col-xl-5': currentComponent==='newInvoice'}">
        <NavTabs class="tab-mobile"/>
        <b-card class="card-small with-nav-tabs desktop-layer mobile-active">
          <Loader v-if="isLoading" :height="600" :size="3"/>
          <div v-else class="">
            <component :is="currentComponent"/>
          </div>
        </b-card>
      </div>
      <div v-if="currentComponent==='newInvoice'" class="col-7 desktop-layer invoices-history">
        <tableInvoices :isLoading="loadingData"/>
      </div>
    </div>
    <div class="w-100 m-5" v-else>
      <div class="col-12 col-md-3">
        <h5 class="text-center">Доступ запрещен: У вас не хватает прав для просмотра раздела</h5>
      </div>
    </div>
  </div>
</template>

<script>
import ExtendInvoice from './extendInvoice.vue'
import newInvoice from './tableInvoiceNew.vue'
import all from './tableInvocesAll.vue'
import tableInvoices from './tableInvoces.vue'
import settings from './tableInvoiceSettings.vue'
import NavTabs from './navTabs'

export default {
  components: {
    NavTabs,
    ExtendInvoice,
    newInvoice,
    all,
    tableInvoices,
    settings
  },
  data() {
    return {
      isLoading: false,
      currentStep: 1,
      mobileTab: 'new',
    }
  },
  computed: {
    error403() {
      return this.$store.state.invoices.error403;
    },
    currentComponent() {
      return (this.$route.params.page) ? this.$route.params.page : 'newInvoice'
    },
    loadingData() {
      return !this.$store.state.pager.apiResponseReceived;
    }
  },
  methods: {
    setMobileTab(tab) {
      this.mobileTab = tab;
    },
    changeStep(step) {
      if (step === 'back') {
        this.$router.push({name: 'PayoutsForm', params: {mid: this.$route.params.mid}});
      } else {
        this.currentStep = step;
        this.$router.push({
          name: 'PayoutsForm',
          params: {mid: this.$route.params.mid, tid: this.$route.params.tid, step: step}
        });
      }
    },
    async updateHistory() {
      await this.$store.dispatch('pager/updateCurrentTableState', 'default');
    },
  },
  async created() {
    await this.$store.dispatch('invoices/fetchAccountSettings', this.$store.state.account.mandarin.client_id);
    await this.$store.dispatch('loadingHLMerchants');
  },
  async mounted() {

  },
  watch: {
    '$route'(to, from) {
      // console.log(this.$route.params.page)
    }
  },
}

</script>

<style scoped>
.fixed-width {
  margin-top: 43px;
}

.min-w-355 {
  min-width: 355px;
}

.invoices-history {
  margin-top: 42px;
}
@media (max-width: 768px) {
  .tab-mobile {
    padding-left: 15px;
  }
}
</style>

<style>
div#invoicesWrapper button#exportButton {
  display: none;
}
</style>
