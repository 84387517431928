<template>
  <b-card class="card-small extended-information">
    <h4 class="desktop-layer">История операций через форму</h4>
    <Loader v-if="isLoading" :height="600" :size="3" />
    <div v-else>
      <pager/>
      <b-table small :fields="fields" :items="dataTable" responsive="lg" class="my-custom-table" @row-clicked="selectItem">
        <template v-slot:head()="data">
          <div v-html="data.field.label" :class="{'desktop-layer': data.field.key === 'customer'}"></div>
        </template>
        <template #cell(created_at)="data">
          <div class="container-td text-nowrap">
            <div class="order-id-container"></div>
            <span>{{ properDT(data.item.created_at) }}</span>
            <br>
            <clipboard @click.native="onCopy" v-if="data.item.order_id" :data="data.item.order_id"/><span class="order-id-text">{{ data.item.order_id }}</span>
          </div>
        </template>
        <template v-slot:cell(id)="data">
          <div class="container-td text-nowrap">
            <div class="order-id-container"></div>
            <clipboard @click.native="onCopy" v-if="data.item.secure_id" :data="data.item.secure_id"/><span class="order-id-text">{{ data.item.secure_id }}</span>
            <br>
            <span class="order-id-text">{{ printNumber(data.item) }}</span>
          </div>
        </template>
        <template v-slot:cell(customer)="data">
          <div class="container-td text-nowrap desktop-layer">
            <div class="order-id-container"></div>
            <span>{{ data.item.phone }}</span>
            <br>
            <clipboard @click.native="onCopy" v-if="data.item.email" :data="data.item.email"/><span class="order-id-text">{{ data.item.email }}</span>
          </div>
        </template>
        <template #cell(amount)="data">
          <div class="text-center w-100 container-td">
            <div class="order-id-container"></div>
            <span>{{ properMRub(parseFloat(data.item.amount)) }}</span>
            <br>
            <span v-html="getStatusBadge(data.item.state)"></span>
          </div>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import pager from './pager.vue'
import { getLabel, getStatusBadge } from '@/configs/filtersTables/labels'
export default {
  props: [
    'isLoading',
  ],
  components: {
    pager,
    getStatusBadge,
  },
  data() {
    return {
      fields: [
      {
        key: 'created_at',
        label: 'Дата</br>Номер заказа',
        class: 'width-250px'
      },
      {
        key: 'id',
        label: 'ID транзакции</br>Получатель',
        class: 'width-180px'
      },
      {
        key: 'customer',
        label: 'Телефон</br>Пользователь',
        class: 'width-115px'
      },
      {
        key: 'amount',
        label: 'Сумма</br>Статус',
        class: 'min-width-60px'
      },
    ]
    }
  },
  computed:{
    dataTable(){
      return this.$store.state.payLender.apiData.transactions
    },
  },
  methods:{
    printNumber(item) {
      return (item.payouts_method === 'sbp') ? 'СБП: ' + item.phone : item.n_card;
    },
    onCopy(event) {
      event.stopPropagation();
    },
    selectItem(item){
      // console.log(item);
      this.$emit('goTransaction', {
        mid: item.client_merchant,
        tid: item.id,
        step: (item.n_card == null && item.state === 'bindings' && item.secure_js_operation_id !== null) ? null : '2',
      });
    },
    displayLabel(filter_name, current_value){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getLabel(filters_config, filter_name, current_value)
    },
    getStatusBadge(status){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getStatusBadge(filters_config, status)
    },
  }
}
</script>

<style scoped>
.order-id-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  vertical-align: middle;
  padding-left: 5px;
}
.container-td {
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
.my-custom-table {
  position: relative;
  overflow: hidden;
}
.my-custom-table .table {
  table-layout: fixed;
  width: 100%;
}

.my-custom-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 25%;
  max-width: 25%;
}

.order-id-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 10%;
  height: 100%;
  background: linear-gradient(to left, white, transparent);
  z-index: 1;
}
@media (max-width: 953px) {
  .extended-information {
    border-top-left-radius: 0;
  }
}
</style>
<style>
@media (max-width: 953px) {
  .desktop-layer, .container-td.desktop-layer {display: none;}
  .extended-information {font-size: 10px;}
  .order-id-text {font-size: 10px;}
}
@media (max-width: 539px) {
  .width-60px { max-width: 59px; }
  .width-180px { max-width: 100px; }
  .width-250px { max-width: 100px; }
  .width-60px .order-id-text { max-width: 54px; }
  .width-180px .order-id-text { max-width: 80px; }
  .width-250px .order-id-text { max-width: 80px; }
}
@media (min-width: 540px) and (max-width: 953px) {
  .width-60px { max-width: 59px; }
  .width-180px { max-width: 170px; }
  .width-250px { max-width: 180px; }
  .width-60px .order-id-text { min-width: 54px; }
  .width-180px .order-id-text { min-width: 80px; }
  .width-250px .order-id-text { min-width: 80px; }
}
@media (min-width: 954px) and (max-width: 1679px) {
  .order-id-text {font-size: 12px;}
  .width-60px { max-width: 59px; }
  .width-115px { max-width: 183px; }
  .width-180px { max-width: 157px; }
  .width-250px { max-width: 199px; }
  .width-60px .order-id-text { max-width: 54px; min-width: 54px; }
  .width-115px .order-id-text { max-width: 171px; min-width: 171px; }
  .width-180px .order-id-text { max-width: 145px; min-width: 145px; }
  .width-250px .order-id-text { max-width: 187px; min-width: 187px; }
}

@media (min-width: 1680px) and (max-width: 1919px) {
  .width-60px { max-width: 89px; }
  .width-115px { max-width: 209px; }
  .width-180px { max-width: 275px; }
  .width-250px { max-width: 317px; }
  .width-60px .order-id-text { max-width: 84px; min-width: 84px; }
  .width-115px .order-id-text { max-width: 197px; min-width: 197px; }
  .width-180px .order-id-text { max-width: 263px; min-width: 263px; }
  .width-250px .order-id-text { max-width: 305px; min-width: 305px; }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .width-60px { max-width: 111px; }
  .width-115px { max-width: 261px; }
  .width-180px { max-width: 331px; }
  .width-250px { max-width: 407px; }
  .width-60px .order-id-text { max-width: 106px; min-width: 106px; }
  .width-115px .order-id-text { max-width: 249px; min-width: 249px; }
  .width-180px .order-id-text { max-width: 319px; min-width: 319px; }
  .width-250px .order-id-text { max-width: 395px; min-width: 395px; }
}

@media (min-width: 2561px) {
  .width-60px { max-width: 127px; }
  .width-115px { max-width: 282px; }
  .width-180px { max-width: 400px; }
  .width-250px { max-width: 471px; }
  .width-60px .order-id-text { max-width: 122px; min-width: 122px; }
  .width-115px .order-id-text { max-width: 270px; min-width: 270px; }
  .width-180px .order-id-text { max-width: 388px; min-width: 388px; }
  .width-250px .order-id-text { max-width: 459px; min-width: 459px; }
}
</style>
