import { render, staticRenderFns } from "./rewardsInnSuggestions.vue?vue&type=template&id=4bdab968&scoped=true"
import script from "./rewardsInnSuggestions.vue?vue&type=script&lang=js"
export * from "./rewardsInnSuggestions.vue?vue&type=script&lang=js"
import style0 from "suggestions-jquery/dist/css/suggestions.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./rewardsInnSuggestions.vue?vue&type=style&index=1&id=4bdab968&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bdab968",
  null
  
)

export default component.exports