<template>
  <div class="accTable">
    <div v-if="haveRows" class="table-wrapper">
        <b-pagination align="center" v-model="currentPage" :total-rows="rows" :per-page="perPage" :aria-controls="'tab_'+data_property_name"></b-pagination>
        <b-table :id="'tab_'+data_property_name" striped hover :fields="fields" :items="items" :per-page="perPage" :current-page="currentPage"></b-table>
        <b-pagination align="center" v-model="currentPage" :total-rows="rows" :per-page="perPage" :aria-controls="'tab_'+data_property_name"></b-pagination>
    </div>
    <div v-else>
      Нет данных по этому разделу
    </div>
  </div>
</template>

<script>
export default {
  props: ['data_property_name'],
  data () {
    return {
      perPage: 10,
      currentPage: 1
    }
  },
  computed: {
    fields: function () {
      let settlementAmountLabel = 'К перечислению'
      if (this.data_property_name === 'reward_to_be_refunded') { settlementAmountLabel = 'К удержанию' }
      if (this.data_property_name === 'no_documents') { settlementAmountLabel = 'К перечислению' }
      let fields = [
        {
          key: 'signedAt',
          label: 'Дата подписания',
          sortable: true,
          formatter: 'properD'
        },
        {
          key: 'customerName',
          label: 'ФИО заёмщика',
          sortable: true
        },
        {
          key: 'customerPhone',
          label: 'Телефон заёмщика',
          sortable: false
        },
        {
          key: 'contractId',
          label: 'Договор займа',
          sortable: true
        },
        {
          key: 'purchaseAmount',
          label: 'Сумма покупки',
          sortable: true
        },
        {
          key: 'reward',
          label: 'Вознаграждение',
          sortable: true
        },
        {
          key: 'settlementAmount',
          label: settlementAmountLabel,
          sortable: true,
          tdClass: 'defTdClass'
        }
      ]
      return fields
    },
    items: function () {
      let items = []
      let dataTable = this.$store.state.acts.actCertificate[this.data_property_name].loans
      dataTable.forEach(element => {
        let item = {}
        item.customerName = element.customer_name
        item.customerPhone = element.customer_phone
        item.contractId = element.contract_id
        item.purchaseAmount = this.properMRub(element.purchase_amount)
        item.reward = this.properMRub(element.reward_calculation_amount) + ' (' + element.reward_rate + '%)'
        item.settlementAmount = this.properMRub(element.current_settlement_amount)
        item.signedAt = element.signed_at
        items.push(item)
      })
      return items
    },
    rows () { return this.items.length },
    haveRows: function () {
      if (this.rows > 0) { return true }
      return false
    }
  },
  methods: {
    defTdClass (value, key, item) {
      if (this.data_property_name === 'reward_to_be_paid') { return 'text-success' }
      if (this.data_property_name === 'reward_to_be_refunded') { return 'text-danger' }
      if (this.data_property_name === 'no_documents') { return 'text-danger' }
      return ''
    }
  }
}
</script>
