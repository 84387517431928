<template>
  <div class="cell-wrapper">
    <span :class="getAmountClass(item.opcode)">{{signFromOpcode(item.opcode)}} {{this.properM(item.amount)}}</span>
    <span v-if="mandarin_total_price">&nbsp;({{mandarin_total_price}})</span>
    <br /><span v-if="this.getOperationType(item)">{{getOperationType(item)}} </span>
  </div>
</template>

<script>
export default {
  props: ['item'],
  computed: {
    sign: function () { return this.signFromOpcode(this.item.opcode) },
    mandarin_total_price: function () {
      if (this.item.price === null) {
        return false
      }
      if (this.item.price.hasOwnProperty('mandarin_total_price') && this.item.price.mandarin_total_price !== null) {
        return this.properM(this.item.price.mandarin_total_price)
      }
      return false
    }
  },
  methods: {
    getOperationType (item) {
      if (item.instrument_type_id === 1) {
        if (item.mw_type === 'binding'){
          if (item.opcode === 1){ return 'Оплата с привязкой' }
          if (item.opcode === 2){ return 'Блокировка средств' }
          if (item.opcode === 4){ return 'Разблокировка средств' }
          if (item.opcode === 5){ return 'Возврат оплаты' } 
        }else{
          if (item.opcode === 6 && item.cs2 === 'downpayment') { return 'Предоплата MandarinPOS - Оплата картой (2-стадийная)' }
          if (item.opcode === 6 && item.cs2 !== 'downpayment') { return 'Оплата картой (2-стадийная)' }
          if (item.opcode === 1) { return 'Оплата картой' }
          if (item.opcode === 3) { return 'Оплата картой (Rec.)' }
          if (item.opcode === 2 && item.cs2 === 'downpayment') { return 'Возврат предоплаты' }
          if (item.opcode === 2 && item.cs2 !== 'downpayment') { return 'Возврат оплаты картой' }
          if (item.opcode === 4 && item.cs2 === 'downpayment') { return 'Блокировка предоплаты' }
          if (item.opcode === 5 && item.cs2 === 'downpayment') { return 'Разблокировка предоплаты' }
        }
        
      }
      if (item.instrument_type_id === 801) {
        if (item.opcode === 1 && item.mw_type === null) { return 'Покупка MandarinPOS' }
        if (item.opcode === 451 && item.mw_type === null) { return 'Возврат покупки MandarinPOS' }
      }
      if (item.instrument_type_id === 800) {
        if (item.opcode === 1) { return 'Заявка MandarinPOS' }
      }
      if ([4747,474,482,483,484].includes(item.instrument_type_id) && item.opcode === 472){ return 'Выплата на карту'}
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.positive{
  color: #4dbd74;
}
.negative{
  color: #f86c6b;
}
</style>

