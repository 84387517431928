<template>
  <d-navbar-nav class="flex-row">
    <li v-if="balanceAvailable()" id="balance-wrapper" class="nav-item dropdown mr-2 pointer" v-closable="{handler: 'clickedOutsideDropdownElementBalance'}">
      <a class="nav-link dropdown-toggle text-nowrap text-center" style="padding-top: 9px;" v-d-toggle.balance>
        <i style="color: rgba(0, 0, 0, 0.6); font-size: 24px; position: relative; top: 5px;" class="material-icons right">account_balance_wallet</i>

        <span v-if="balance_loaded" class="hf" style="position: relative; top: -1px;">Баланс: {{properM(balance_total)}} <span v-html="currency(currency_id)"></span></span>
        <span style="position: relative; top: 7px; display: inline-block; min-width: 40px;" v-else><Loader :size="1" /></span>
      </a>
      <d-collapse is-nav id="balance" v-model="collapseBalanceShown" class="dropdown-menu dropdown-menu-small">
        <div v-if="balance_loaded">
        <b-dropdown-item v-for="(item, index) in balance_details" v-bind:key="index">
          <span>{{item.label}}:</span> <span>{{properM(item.amount)}} <span v-html="currency(currency_id)"></span></span>
        </b-dropdown-item>
        </div>
        <b-dropdown-item v-else>
          <div style="min-height: 23px; min-width: 40px; text-align: center; position: relative; top: 14px;"><Loader :size="2" /></div>
        </b-dropdown-item>
        <b-dropdown-item>
          <b-button :disabled="refreshBalanceDisabled" size="sm" style="line-height: 1;" variant="light" block id="refresh-balance" v-on:click="loadBalance">Обновить</b-button>
        </b-dropdown-item>
      </d-collapse>
    </li>
    <li v-if="checkItemForAccess('sla')" id="sla-wrapper" class="nav-item dropdown mr-2 pointer" v-closable="{handler: 'clickedOutsideDropdownElementManager'}">
      <a class="nav-link dropdown-toggle text-nowrap text-center" style="padding-top: 15px;" v-d-toggle.manager>
        <i style="color: rgba(0, 0, 0, 0.6); font-size: 20px;" class="fa fa-phone-square"></i>{{$t("navigation.sla")}} {{currentSLA}}</a>
      <d-collapse style="overflow-x: hidden; width: 200px;" is-nav id="manager" v-model="collapseManagerShown" class="dropdown-menu dropdown-menu-small">
        <d-dropdown-item >
          <div v-if="!clientDataEmpty">
            <span style="font-weight: 400;">Ваш менеджер:</span><br>
            {{currentManager.name}}<br>
            <a v-if="this.currentManager.phone" :href="'tel:'+this.currentManager.phone">{{ currentManager.phoneDisp }}</a><br>
            <a :href="'mailto:'+this.currentManager.email">{{ currentManager.email }}</a>
          </div>
        </d-dropdown-item>
      </d-collapse>
    </li>
    <li class="nav-item dropdown mx-2 pointer" v-closable="{handler: 'clickedOutsideDropdownElementSettings'}">
      <a class="nav-link dropdown-toggle text-nowrap text-center" style="padding-top: 15px;" v-d-toggle.settings>
        <i style="color: rgba(0, 0, 0, 0.6); font-size: 20px;" class="fa fa-cogs"></i>
        <span class="ml-1">{{ $t("navigation.settings") }}</span>
      </a>
      <d-collapse is-nav id="settings" v-model="collapseSettingsShown" class="dropdown-menu projects-menu dropdown-menu-small overflow-hidden">
        <b-dropdown-item v-if="checkItemForAccess('integration.ru')" target="_blank" href="https://docs.mandarinbank.com">
          <i class="fa fa-external-link-alt"></i>
          {{ $t("navigation.integration") }}
        </b-dropdown-item>
        <b-dropdown-item v-if="checkItemForAccess('integration.en')" target="_blank" href="http://docs.mandarinbank.com/eng.html">
          <i class="fa fa-external-link-alt"></i>
          {{ $t("navigation.integration") }}
        </b-dropdown-item>
        <b-dropdown-item v-if="checkItemForAccess('monitoring.ru')"  target="_blank" :href="extUrlsMonitoring">
          <i class="fa fa-external-link-alt"></i>
          {{ $t("navigation.monitoring") }}
        </b-dropdown-item>
        <b-dropdown-item to="/project/add">
          Добавить проект
        </b-dropdown-item>
        <b-dropdown-item v-if="checkItemForAccess('monitoring.en')"  target="_blank" :href="extUrlsMonitoringEn">
          <i class="fa fa-external-link-alt"></i>
          {{ $t("navigation.monitoring") }}
        </b-dropdown-item>
        <template v-if="!clientDataEmpty">
          <b-dropdown-item v-for='(site, index) in sitesArray' :to="'/project/'+site.merchant_id" :key="index">
            {{ site.merchant_id }}&nbsp;{{site.name}}
          </b-dropdown-item>
        </template>


      </d-collapse>
    </li>
    <li class="nav-item dropdown pointer" v-closable="{handler: 'clickedOutsideDropdownElementUserActions'}">
      <a class="nav-link dropdown-toggle text-nowrap text-right" style="min-width: 100px; padding-top: 15px;" v-d-toggle.user-actions>
        <span class="d-sm-inline-block"><i style="color: rgba(0, 0, 0, 0.6); font-size: 20px;" class="fa fa-user-circle"></i> {{ $store.state.account.mandarin.email }}</span>
      </a>
      <d-collapse id="user-actions" v-model="collapseUserActionShown" is-nav class="dropdown-menu dropdown-menu-small">
        <!--<d-dropdown-item v-if="checkItemForAccess('settings#profile')" class="pointer" to="/settings/profile"><i class="fa fa-file"></i> Анкета</d-dropdown-item>-->
        <d-dropdown-item v-if="checkItemForAccess('settings#profile')" class="pointer" :href="myaccountUrl+'/change_password'"><i class="fa fa-shield-alt"></i> Безопасность</d-dropdown-item>
        <d-dropdown-item v-if="checkItemForAccess('settings#profile')" class="pointer" :href="myaccountUrl+'/userlist'"><i class="fa fa-user"></i> Пользователи</d-dropdown-item>
        <d-dropdown-item v-if="checkItemForAccess('settings#profile')" class="pointer" to="/settings/requisites"><i class="fa fa-file"></i> Реквизиты</d-dropdown-item>
        <d-dropdown-item v-if="checkItemForAccess('settings#profile')" class="pointer" to="/settings/notifications"><i class="fa fa-envelope-open"></i> Оповещения</d-dropdown-item>
        <d-dropdown-item v-if="checkScopePresence('lead_reward_certificates.read')" class="pointer" to="/rewards"><i class="fa fa-envelope"></i> {{ $t("navigation.reward") }}</d-dropdown-item>

        <d-dropdown-divider />
        <d-dropdown-item v-if="checkItemForAccess('logout')" @click.native="logout" class="pointer text-danger">
          <i class="text-danger fa fa-sign-out-alt"></i> {{ $t("navigation.logout") }}
        </d-dropdown-item>
      </d-collapse>
    </li>
  </d-navbar-nav>
</template>

<script>
export default {
  data () {
    return {
      currency_id: 643,
      extUrlsMonitoring: VUE_APP_EXT_URLS_MONITORING,
      extUrlsMonitoringEn: VUE_APP_EXT_URLS_MONITORING,
      collapseBalanceShown: false,
      collapseManagerShown: false,
      collapseSettingsShown: false,
      collapseUserActionShown: false,
      collapseLanguageShown: false,
      switcher: {
        flag: 'ru',
        title: ''
      }
    }
  },
  computed: {
    balance_total () { return this.$store.state.balance.balance_total},
    balance_loaded () { return this.$store.state.balance.balance_loaded },
    balance_details () { return this.$store.state.balance.balance_details },
    refreshBalanceDisabled() { return !this.$store.state.balance.balance_loaded },
    myaccountUrl () { return this.removeTrailingSlash(VUE_APP_MYACCOUNT_URL) },
    currentSLA: function () {
      if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData, 'sla')){
        return this.$store.state.account.clientData.sla
      }else{
        return ""
      }
    },
    currentManager(){
      let manager = {
        name: 'Нет данных',
        phone: false,
        phoneDisp: 'Нет данных',
        email: 'Нет данных'
      }
      if (this.clientDataEmpty){return manager}
      if (!Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData, 'account_manager') || this.$store.state.account.clientData.account_manager === null){
        return manager
      }
      if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.account_manager, 'name')){
        manager.name = this.$store.state.account.clientData.account_manager.name + ' ' + this.$store.state.account.clientData.account_manager.family
      }
      if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.account_manager, 'cell_phone') && this.$store.state.account.clientData.account_manager.cell_phone){
        manager.phoneDisp = this.phoneProperDisplay(this.$store.state.account.clientData.account_manager.cell_phone)
        manager.phone = this.normalizePhone(this.$store.state.account.clientData.account_manager.cell_phone)
      }
      if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.account_manager, 'email') && this.$store.state.account.clientData.account_manager.email){
        manager.email = this.$store.state.account.clientData.account_manager.email
      }
      return manager
    },
    sitesArray: function () { return this.$store.state.account.clientData.processedSites },
    clientDataEmpty () { return this.$store.state.account.clientDataEmpty }
  },
  mounted () {
    switch (this.$i18n.locale) {
      case 'en':
        this.switcher.flag = 'gb'
        this.switcher.title = 'English'
        break
      case 'ru':
      default:
        this.switcher.flag = 'ru'
        this.switcher.title = 'Русский'
    }
    this.loadBalance()
  },
  methods: {
    balanceAvailable(){
      return this.checkScopePresence('pay_bank_account_balance.read')
    },
    clickedOutsideDropdownElementManager () {
      if (this.collapseManagerShown) { this.$root.$emit('collapse-toggle', 'manager') }
    },
    clickedOutsideDropdownElementSettings () {
      if (this.collapseSettingsShown) { this.$root.$emit('collapse-toggle', 'settings') }
    },
    clickedOutsideDropdownElementUserActions () {
      if (this.collapseUserActionShown) { this.$root.$emit('collapse-toggle', 'user-actions') }
    },
    clickedOutsideDropdownElementLanguage () {
      if (this.collapseLanguageShown) { this.$root.$emit('collapse-toggle', 'language') }
    },
    clickedOutsideDropdownElementBalance (){
      if (this.collapseBalanceShown) { this.$root.$emit('collapse-toggle', 'balance') }
    },
    switchLanguage (locale, flag, title) {
      this.$i18n.locale = locale
      this.switcher.flag = flag
      this.switcher.title = title
      let domain = this.getCurrentDomain()
      document.cookie = 'merchant_locale=' + locale + ';domain=' + domain + ';path=/'
    },
    logout () {
      let domain = this.getCurrentDomain()
      document.cookie = 'merchant_access_token=;domain=' + domain + ';path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie = 'merchant_access_token=;domain=' + domain + ';path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie = "qualification_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
      document.cookie = "qualification_user_id= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
      this.$store.commit('updateAccessToken', '')
      return window.location = `${VUE_APP_SECURE_APP}logout/`
    },
    checkItemForAccess (item) {
      // Пока у нас нет англоязычных пользователей, поэтому показываем всем все кроме английских доков.
      let allowed = true
      switch (item) {
        case 'integration.en':
        case 'monitoring.en':
          allowed = false
          break
      }
      return allowed
    },
    loadBalance(){
      if (this.balanceAvailable()){
        this.$store.dispatch('balance/loadBalance')
      }
    }
  },
}
</script>

<style scoped>
  #user-actions .fa{
    width: 12px;
    text-align: center;
  }
  .mobile-navbar-nav #balance-wrapper,
  .mobile-navbar-nav #sla-wrapper {
    display: none;
  }
  .dropdown-menu::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 100%);
    pointer-events: none;
  }
</style>
