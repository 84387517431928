<template>
  <d-container fluid class="mb-4">
    <h5 class="pt-2">Размер вознаграждения</h5>

    <div class="header mt-3">Вознаграждение, получаемое ПРОДАВЦОМ/ПРЕДСТАВИТЕЛЕМ ПРОДАВЦА</div>
    <d-row v-if="showMerchantLeadReward">
      <d-col :xl="1" :sm="2" class="text-nowrap">
        с <span>{{properD(this.$store.state.prices.prices.actualMERCHANT_LEAD_REWARD.active_from)}}</span>
      </d-col>
      <d-col :sm="1" class="text-nowrap">
        {{this.$store.state.prices.prices.actualMERCHANT_LEAD_REWARD.fee_rate}}%
      </d-col>
      <d-col :sm="9" :xl="10">
        Вознаграждение Продавца или его Представителя (п. 3.5 Договора) от суммы финансирования покупки
      </d-col>
    </d-row>

    <div class="header mt-3">Вознаграждение, получаемое MANDARIN.LIFE</div>
    <d-row v-if="showMandarinLeadReward">
      <d-col :xl="1" :sm="2" class="text-nowrap">
        с <span>{{properD(this.$store.state.prices.prices.actualMANDARIN_LEAD_REWARD.active_from)}}</span>
      </d-col>
      <d-col :sm="1" class="text-nowrap">
        {{this.$store.state.prices.prices.actualMANDARIN_LEAD_REWARD.fee_rate}}%
      </d-col>
      <d-col :sm="9" :xl="10">
        Вознаграждение MANDARIN.LIFE (п. 4.6 Договора) от суммы до финансирования покупки
      </d-col>
    </d-row>

    <d-row v-if="showAcquiringFee">
      <d-col :xl="1" :sm="2" class="text-nowrap">
        с <span>{{properD(this.$store.state.prices.prices.actualACQUIRING_FEE.active_from)}}</span>
      </d-col>
      <d-col :sm="1" class="text-nowrap">
        {{this.$store.state.prices.prices.actualACQUIRING_FEE.fee_rate}}%
      </d-col>
      <d-col :sm="9" :xl="10">
        Прием платежей. Банковские карты VISA International, MasterCard Worldwide, МИР
      </d-col>
    </d-row>

    <d-row v-if="showPaybyloanFee">
      <d-col :xl="1" :sm="2" class="text-nowrap">
        с <span>{{properD(this.$store.state.prices.prices.actualPAYBYLOAN_FEE.active_from)}}</span>
      </d-col>
      <d-col :sm="1" class="text-nowrap">
        {{this.$store.state.prices.prices.actualPAYBYLOAN_FEE.fee_rate}}%
      </d-col>
      <d-col :sm="9" :xl="10">
        Оплата Заемными средствами без Поставки лидов
      </d-col>
    </d-row>

  </d-container>
</template>

<script>
export default {
  computed: {
    showMerchantLeadReward () {
      if (this.$store.state.prices.prices.actualMERCHANT_LEAD_REWARD.received) {
        return true
      }
      return false
    },
    showAcquiringFee () {
      if (this.$store.state.prices.prices.actualACQUIRING_FEE.received) {
        return true
      }
      return false
    },
    showPaybyloanFee () {
      if (this.$store.state.prices.prices.actualPAYBYLOAN_FEE.received) {
        return true
      }
      return false
    },
    showMandarinLeadReward () {
      if (this.$store.state.prices.prices.actualMANDARIN_LEAD_REWARD.received) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.$store.dispatch('loadPrices')
  }
}
</script>

<style lang="scss" scoped>
.header{
  font-size: 1rem;
  color: #3D5170;
  font-weight: 500;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfe1e3;
}
</style>
