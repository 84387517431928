<template>
  <div :style="`height: ${height}px`">
    <div class="load">
      <i :class="`fa fa-spinner fa-spin fa-${size}x`"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ['height', 'size']
}
</script>

<style scoped>

.load {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
}
</style>
