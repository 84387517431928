<template>
  <div class="cell-wrapper">
    <div v-if="activeColumnRow === 'loansFinalized'">
      {{amount}} <br />
      {{downpaymentAmount}}
    </div>
    <div v-if="activeColumnRow === 'loansPending'">
      {{amount}}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeColumnRow: function () { return this.$store.state.pager.activeColumnRow },
    amount: function () {
      let amount = 'undefined field'
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      if (activeColumnRow === 'loansFinalized') {
        amount = this.item.purchase_amount
      }
      if (activeColumnRow === 'loansPending') {
        amount = this.item.amount
      }
      amount = this.properMRub(amount)
      return amount
    },
    downpaymentAmount: function () {
      if (this.item.downpayment_amount === 0) {
        return '—'
      }
      return this.properMRub(this.item.downpayment_amount)
    },
    id: function () {
      return this.item.id
    }
  },
  props: ['item']
}
</script>
