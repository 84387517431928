<template>
  <div class="cell-wrapper">
    {{translateStatus(item.status)}}
  </div>
</template>

<script>
export default {
  props: ['item'],
  methods:{
    translateStatus(status){
      let statuses = {
        success: 'Успех',
        error: 'Ошибка',
        initial: "Обработка"
      }
      if (Object.prototype.hasOwnProperty.call(statuses, status)){
        return statuses[status]
      }
      return status
    }
  }
}
</script>
