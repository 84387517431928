export default {
    namespaced: true,
    state: {
      busy: false,
    },
    mutations: {
      updateBusy(state, value){
        state.busy = value
      },
    },
    actions: {
      addNewBankAccount(context, data){
        let url = VUE_APP_API_CLIENTS + '/clients/v1/clients/' + context.rootState.account.cid + '/bank_accounts';
        context.commit('updateBusy', true)
        this.$axios.post(url, data.payload)
          .then(response => {
            context.commit('updateBusy', false)
            if (response.status === 201){
              this._vm.$toast.success('Новый банковский счет добавлен.')
              context.dispatch('loadV1ClientObject', context.rootState.account.cid, { root: true })
              // редиректнуть на страницу реквизитов
              data.router.push('/settings/requisites')
              return false
            }
            if (response.status === 400){
              if (Object.prototype.hasOwnProperty.call(response.data, 'non_field_errors')){
                if (response.data.non_field_errors[0].includes("Company account already exists")){
                  this._vm.$toast.error('Этот банковский счет ранее добавлялся для вашей компании.')
                  return false
                }
              }
            }
            this._vm.$toast.error('К сожалению, нам не удалось добавить счёт.')
            console.log(response, 'response in addNewBankAccount')
          })
          .catch(error => {
            console.log('error in promise addNewBankAccount', error)
            let error_displayed = false
            if (Object.prototype.hasOwnProperty.call(error, 'response')){
              if (Object.prototype.hasOwnProperty.call(error.response, 'status') && error.response.status === 400){
                if (Object.prototype.hasOwnProperty.call(error.response, 'data') &&
                  Object.prototype.hasOwnProperty.call(error.response.data, 'non_field_errors') && 
                  error.response.data.non_field_errors[0].startsWith("Company account already exists")
                ){
                  error_displayed = true
                  this._vm.$toast.error('Этот банковский счет ранее добавлялся для вашей компании.')
                }
              }
            }
            if (!error_displayed){
              this._vm.$toast.error('Произошла ошибка при попытке добавить номер счета.')
            }
          })
      },
      patchProperties(context, data){
        let url = VUE_APP_API_CLIENTS + '/clients/v1/clients/' + context.rootState.account.cid + '/bank_accounts/'+data.id;
        context.commit('updateBusy', true)
        this.$axios.patch(url, data.payload)
          .then(response => {
            context.commit('updateBusy', false)
            if (response.status === 200){
              this._vm.$toast.success('Настройки банковского счета успешно изменены.')
              context.dispatch('loadV1ClientObject', context.rootState.account.cid, { root: true })
              return false
            }
            this._vm.$toast.error('К сожалению, нам не удалось изменить настройки банковского счёта.')
            console.log(response, 'response in patchProperties')
            context.dispatch('loadV1ClientObject', context.rootState.account.cid, { root: true })
          })
          .catch(error => {
            this._vm.$toast.error('К сожалению, нам не удалось изменить настройки банковского счёта. Ошибка: '+error.message)
            console.log('error in promise patchProperties', error)
          })
      }
    }
  }