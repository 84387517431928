// Этот модуль описывает структуру показа всего для роли LifeMerchant.
// Массив argLeft отвечает за ссылки по левой стороне таблицы,
// argTop - по верхней навигации над таблицей. names позволяют идентифицировать роут,
// а classes - уникализировать верстку каждого таба или пункта меню при нужде.
//
import router from '@/router'
export default {
  namespaced: true,
  state: {
    argLeft: [
      /* {
        arg: 'loans',
        label: 'Заявки',
        classes: 'loans'
      },
      {
        arg: 'receipts',
        label: 'Продажи',
        classes: 'receipts'
      }, */
      {
        arg: 'analytics',
        label: 'LifeMerchant.left.sales',
        classes: 'analytics'
      },
      {
        arg: 'rewards',
        label: 'LifeMerchant.left.leads',
        classes: 'rewards'
      }
    ],
    argTop: [
      [
        {
          name: 'loansPending',
          arg: 'pending',
          label: 'LifeMerchant.argTop.loansPending',
          classes: 'pending'
        },
        {
          name: 'loansFinalized',
          arg: 'finalized',
          label: 'LifeMerchant.argTop.loansFinalized',
          classes: 'finalized'
        },
        {
          name: 'receiptsPayments',
          arg: 'payments',
          label: 'LifeMerchant.argTop.receiptsPayments',
          classes: 'receipts-payments'
        },
        {
          name: 'receiptsReturns',
          arg: 'returns',
          label: 'LifeMerchant.argTop.receiptsReturns',
          classes: 'receipts-returns'
        },
        {
          name: 'receiptsAll',
          arg: 'all',
          label: 'LifeMerchant.argTop.receiptsAll',
          classes: 'receipts-all'
        }
      ],
      [
        {
          name: 'rewardsActs',
          arg: 'acts',
          label: 'LifeMerchant.argTop.rewardsActs',
          classes: 'acts'
        },
        {
          name: 'rewardsRecipients',
          arg: 'recipients',
          label: 'LifeMerchant.argTop.rewardsRecipients',
          classes: 'recipients'
        }
      ]
    ],
    displayTable: {
      columnsControl: {
        receiptsPayments: {
          fields: [
            { key: 'updated', label: 'LifeMerchant.tableColumns.updated', templateName: 'CellUpdated' },
            { key: 'amount', label: 'LifeMerchant.tableColumns.amount', templateName: 'CellAmount' },
            { key: 'contract', label: 'LifeMerchant.tableColumns.contract', templateName: 'CellContract' },
            { key: 'customer', label: 'LifeMerchant.tableColumns.customer', templateName: 'CellCustomer' },
            { key: 'action', label: 'LifeMerchant.tableColumns.action', templateName: 'CellTransactionsActions' }
          ]
        },
        receiptsReturns: {
          fields: [
            { key: 'updated', label: 'LifeMerchant.tableColumns.updated', templateName: 'CellUpdated' },
            { key: 'amount', label: 'LifeMerchant.tableColumns.amount', templateName: 'CellAmount' },
            { key: 'contract', label: 'LifeMerchant.tableColumns.contract', templateName: 'CellContract' },
            { key: 'customer', label: 'LifeMerchant.tableColumns.customer', templateName: 'CellCustomer' },
            { key: 'action', label: 'LifeMerchant.tableColumns.action', templateName: 'CellTransactionsActions' }
          ]
        },
        receiptsAll: {
          fields: [
            { key: 'updated', label: 'LifeMerchant.tableColumns.updated', templateName: 'CellUpdated' },
            { key: 'amount', label: 'LifeMerchant.tableColumns.amount', templateName: 'CellAmount' },
            { key: 'contract', label: 'LifeMerchant.tableColumns.contract', templateName: 'CellContract' },
            { key: 'customer', label: 'LifeMerchant.tableColumns.customer', templateName: 'CellCustomer' },
            { key: 'action', label: 'LifeMerchant.tableColumns.action', templateName: 'CellTransactionsActions' }
          ]
        },
        loansPending: {
          fields: [
            { key: 'created_at', label: 'LifeMerchant.tableColumns.updated', templateName: 'CellLoanDate' },
            { key: 'amount', label: 'LifeMerchant.tableColumns.amount', templateName: 'CellLoanAmount' },
            { key: 'person', label: 'LifeMerchant.tableColumns.person', templateName: 'CellLoanPerson' },
            { key: 'person_contact', label: 'LifeMerchant.tableColumns.person_contact', templateName: 'CellLoanPersonContact' }

          ]
        },
        loansFinalized: {
          fields: [
            { key: 'signed_at', label: 'LifeMerchant.tableColumns.updated', templateName: 'CellLoanDate' },
            { key: 'person', label: 'LifeMerchant.tableColumns.person', templateName: 'CellLoanPerson' },
            { key: 'amount', label: 'LifeMerchant.tableColumns.amount', templateName: 'CellLoanAmount' },
            { key: 'agreement', label: 'LifeMerchant.tableColumns.agreement', templateName: 'CellLoanAgreement' }

          ]
        },
        rewardsActs: {
          fields: [
            { key: 'signed_at', label: 'LifeMerchant.tableColumns.signed_at', templateName: 'CellActsDate' },
            { key: 'accounting_period', label: 'LifeMerchant.tableColumns.accounting_period', templateName: 'CellActsPeriod' },
            { key: 'recipient_info', label: 'LifeMerchant.tableColumns.recipient_info', templateName: 'CellActsRecipient' },
            { key: 'total_settlement_account', label: 'LifeMerchant.tableColumns.total_settlement_account', templateName: 'CellActsAccount' },
            { key: 'is_accepted', label: 'LifeMerchant.tableColumns.is_accepted', templateName: 'CellActsAccepted' },
            { key: 'action', label: '', templateName: 'CellActsActions' }
          ]
        }
      },
      tableApiUrls: {
        receiptsPayments: {
          url: '/payment-gateway/v3/transactions',
          responseObject: 'transactions'
        },
        receiptsReturns: {
          url: '/payment-gateway/v3/transactions',
          responseObject: 'transactions'
        },
        receiptsAll: {
          url: '/payment-gateway/v3/transactions',
          responseObject: 'transactions'
        },
        loansPending: {
          url: '/life-backend/api/v3/purchase_requests',
          responseObject: 'purchase_requests'
        },
        loansFinalized: {
          url: '/life-backend/api/v3/purchases',
          responseObject: 'purchases'
        },
        rewardsActs: {
          url: '/life-backend/api/v3/lead_reward/certificates',
          responseObject: 'certificates'
        }
      },
      tableFilters: {
        datePicker: {
          receiptsPayments: {
            field: 'created'
          },
          receiptsReturns: {
            field: 'created'
          },
          receiptsAll: {
            field: 'created'
          },
          loansPending: {
            field: 'created_at'
          },
          loansFinalized: {
            field: 'signed_at'
          },
          rewardsActs: {
            // field: 'created_at'
          }
        },
        operationTypes: {
          receiptsPayments: {
            1: {
              label: 'LifeMerchant.ops.rp1',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['6'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            /* 2: {
              label: 'Покупка MandarinPOS',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['801'] },
                { argument: 'opcode', operator: 'in', value: ['1'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            }, */
            2: {
              label: 'LifeMerchant.ops.rp2',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['1'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            3: {
              label: 'LifeMerchant.ops.rp3',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['3'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            }
          },
          receiptsReturns: {
            1: {
              label: 'LifeMerchant.ops.rr1',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['2', '22'] }
              ]
            },
            2: {
              label: 'LifeMerchant.ops.rr2',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['801'] },
                { argument: 'opcode', operator: 'in', value: ['451'] }
              ]
            }/*,
            3: {
              label: 'Возврат оплаты картой',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['2'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            } */
          },
          receiptsAll: {
            /*
            1: {
              label: 'Предоплата MandarinPOS - Оплата картой (2-стадийная)',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['6'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            2: {
              label: 'Покупка MandarinPOS',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['801'] },
                { argument: 'opcode', operator: 'in', value: ['1'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            3: {
              label: 'Оплата картой',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['1'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            4: {
              label: 'Оплата картой (Rec.)',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['3'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            5: {
              label: 'Возврат предоплаты',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['2'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            6: {
              label: 'Возврат покупки MandarinPOS - Возврат покупки MandarinPOS',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['801'] },
                { argument: 'opcode', operator: 'in', value: ['451'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            7: {
              label: 'Возврат оплаты картой',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['2'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            8: {
              label: 'Предоплата (блокировка)',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['4'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            },
            9: {
              label: 'Предоплата (разблокировка)',
              filter: [
                { argument: 'instrument_type_id', operator: 'in', value: ['1'] },
                { argument: 'opcode', operator: 'in', value: ['5'] },
                { argument: 'mw_type', operator: 'in', value: ['transaction'] }
              ]
            }
            */
          }
        },
        status: {
          receiptsPayments: {
            1: {
              label: 'LifeMerchant.ops.rps1',
              filter: [
                { argument: 'status', operator: 'in', value: ['success'] }
              ],
              default: true
            },
            2: {
              label: 'LifeMerchant.ops.rps2',
              filter: [
                { argument: 'status', operator: 'in', value: ['fail', 'timeout'] }
              ],
              default: false
            },
            3: {
              label: 'LifeMerchant.ops.rps3',
              filter: [
                { argument: 'status', operator: 'in', value: ['wait', 'wait_internal', 'wait_3ds', 'wait_external'] }
              ],
              default: false
            }
          },
          receiptsReturns: {
            1: {
              label: 'LifeMerchant.ops.rps1',
              filter: [
                { argument: 'status', operator: 'in', value: ['success'] }
              ],
              default: true
            },
            2: {
              label: 'LifeMerchant.ops.rps2',
              filter: [
                { argument: 'status', operator: 'in', value: ['fail', 'timeout'] }
              ],
              default: false
            },
            3: {
              label: 'LifeMerchant.ops.rps3',
              filter: [
                { argument: 'status', operator: 'in', value: ['wait', 'wait_internal', 'wait_3ds', 'wait_external'] }
              ],
              default: false
            }
          },
          receiptsAll: {
            1: {
              label: 'LifeMerchant.ops.rps1',
              filter: [
                { argument: 'status', operator: 'in', value: ['success'] }
              ],
              default: true
            },
            2: {
              label: 'LifeMerchant.ops.rps2',
              filter: [
                { argument: 'status', operator: 'in', value: ['fail', 'timeout'] }
              ],
              default: false
            },
            3: {
              label: 'LifeMerchant.ops.rps3',
              filter: [
                { argument: 'status', operator: 'in', value: ['wait', 'wait_internal', 'wait_3ds', 'wait_external'] }
              ],
              default: false
            }
          },
          loansPending: {
            1: {
              label: 'LifeMerchant.ops.lp1',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Initial', 'null'] }
              ],
              default: false,
              theme: 'primary'
            },
            2: {
              label: 'LifeMerchant.ops.lp2',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Collect'] }
              ],
              default: false,
              theme: 'warning'
            },
            3: {
              label: 'LifeMerchant.ops.lp3',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Timeout'] }
              ],
              default: false,
              theme: 'secondary'
            },
            4: {
              label: 'LifeMerchant.ops.lp4',
              filter: [
                { argument: 'stage', operator: 'in', value: ['SmsSent'] }
              ],
              default: false,
              theme: 'warning'
            },
            5: {
              label: 'LifeMerchant.ops.lp5',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Rejected'] }
              ],
              default: false,
              theme: 'danger'
            },
            6: {
              label: 'LifeMerchant.ops.lp6',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Cancel'] }
              ],
              default: false,
              theme: 'danger'
            },
            7: {
              label: 'LifeMerchant.ops.lp7',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Processing', 'PreAuth'] }
              ],
              default: false,
              theme: 'warning'
            }
          },
          loansFinalized: {
            1: {
              label: 'LifeMerchant.ops.lf1',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Confirm'] }
              ],
              default: false,
              theme: 'success'
            },
            2: {
              label: 'LifeMerchant.ops.lf2',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Rejected'] }
              ],
              default: false,
              theme: 'danger'
            },
            3: {
              label: 'LifeMerchant.ops.lf3',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Paidout'] }
              ],
              default: false,
              theme: 'success'
            },
            4: {
              label: 'LifeMerchant.ops.lf4',
              filter: [
                { argument: 'stage', operator: 'in', value: ['Cancel'] }
              ],
              default: false,
              theme: 'dark'
            }
          },
          rewardsActs: {
            1: {
              label: 'LifeMerchant.ops.ra1',
              filter: [
                { argument: 'is_accepted', operator: 'in', value: [true] }
              ],
              default: false,
              theme: 'success'
            },
            2: {
              label: 'LifeMerchant.ops.ra2',
              filter: [
                { argument: 'is_accepted', operator: 'in', value: [false] }
              ],
              default: false,
              theme: 'warning'
            }
          }
        },
        periods: {
          rewardsActs: {
            1: {
              label: 'LifeMerchant.ops.ra1',
              filter: [
                { argument: 'is_accepted', operator: '=', value: [true] }
              ],
              default: false,
              theme: 'success'
            },
            2: {
              label: 'LifeMerchant.ops.ra2',
              filter: [
                { argument: 'is_accepted', operator: 'in', value: ['false'] }
              ],
              default: false,
              theme: 'warning'
            }
          }
        }
      }
    },
    // НЕ ЗАБЫВАЕМ что обновление таблиц возможно только если мы заранее прописываем возможные респонсы
    apiData: {
      purchase_requests: [],
      loans: [],
      purchases: [],
      transactions: [],
      certificates: []
    }
  },
  mutations: {
    updateResponse (state, data) {
      let dataname = data.dataname
      // deep copy
      let dataCopy = JSON.parse(JSON.stringify(data[dataname]))
      // проводим дополнительные проверки и форматируем данные в структуру
      // которая не будет валить нам весь апп.
      if (dataname === 'loan') {
        dataCopy.forEach((loan, index) => {
          if (!loan.hasOwnProperty('customer') || !loan.customer) {
            loan.customer = {}
          }
          dataCopy[index] = loan
        })
      }
      state.apiData[dataname] = dataCopy
    }
  },
  actions: {
    refreshTableData (context, queryObject) {
      // Ну вот собственно тут мы и определяем что мы будем обновлять.
      // Первым делом берем url
      let activeColumnRow = context.rootState.pager.activeColumnRow
      let url = context.state.displayTable.tableApiUrls[activeColumnRow].url
      let responseObject = context.state.displayTable.tableApiUrls[activeColumnRow].responseObject
      // Параметры нам передает запрос на обновление (pager на данный момент)
      // type я тут не использую, а остальное юзаю
      // Перед началом запроса сбрасываю управляющие переменные.
      context.commit('pager/updateApiResponseReceived', false, { root: true })
      context.commit('pager/updateApiDataEmpty', true, { root: true })
      this.$axios.get(url, {
        params: queryObject.params
      }).then(response => {
        // Обрезаем все лишнее что пришло в ответе
        response = response.data
        context.commit('pager/updateCursor', response.cursor, { root: true })
        context.commit('pager/updateCurrentOffset', queryObject.currentOffset, { root: true })
        context.commit('pager/updateApiResponseReceived', true, { root: true })
        if (response[responseObject].length === 0) {
          context.commit('pager/updateApiDataEmpty', true, { root: true })
        } else {
          // Очищаем респонс от лишних данных
          let object = {
            [responseObject]: response[responseObject],
            dataname: responseObject
          }
          context.commit('updateResponse', object)
          context.commit('pager/updateApiDataEmpty', false, { root: true })
        }
      }).catch(console.error)
    },
    createLeadRewardCertificate (context) {
      // Отправляем в пейджер сообщение о том что мы грузим страницу.
      context.commit('pager/updateApiResponseReceived', false, { root: true })
      let url = '/life-backend/api/v3/lead_reward/certificates'
      this.$axios.post(url)
        .then(response => {
          if (response && typeof response === 'object' && response.hasOwnProperty('status') && response.status === 201) {
            // перегружаем содержимое страницы после перегенерации актов - удаляется старый, создается новый, иначе нет способа
            context.dispatch('pager/updateCurrentTableState', 'default', { root: true })
            router.go()
          } else {
            console.log(response, 'response without status 201')
            alert('К сожалению, мы не смогли создать акт. Пожалуйста, обратитесь в техподдержку.')
          }
        })
        .catch(error => {
          console.log('error in promise', error)
          console.log('error.response', error.response)
          console.log('error.request', error.request)
          console.log('Error', error.message)
        })
    }
  }
}
