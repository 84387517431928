<template>
  <div class="cell-wrapper">
    {{recipient}}
  </div>
</template>

<script>
export default {
  props: ['item'],
  computed: {
    recipient () {
      if (this.item.recipient_info && typeof (this.item.recipient_info) === 'object' && this.item.recipient_info.hasOwnProperty('short_name')) {
        return this.item.recipient_info.short_name
      }
      return '—'
    }
  }
}
</script>
