<template>
  <div>
    <p class="mb-0">{{ $t("filters.ot1") }}</p>
    <multiselect v-model="selectedValues" :options="options" :multiple="true"
    :disabled="disableSelect" track-by="label" label="label" @input="selectChanged"
    :deselect-label="$i18n.t('service.remove')" :select-label="$i18n.t('service.add')" :selected-label="$i18n.t('service.optionSelected')"
    :placeholder="multiselectPlaceholder()"></multiselect>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      selectedValues: null,
      settingProcess: true
    }
  },
  computed: {
    disableSelect () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      if (activeTableNamespace === 'lifeMerchant') {
        return true
      }
      return false
    },
    options: function () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let optionsObject = this.$store.state[activeTableNamespace].displayTable.tableFilters.status[activeColumnRow]
      let options = []
      Object.entries(optionsObject).forEach(entry => {
        let option = {
          label: this.$i18n.t(entry[1].label),
          default: entry[1].default,
          id: entry[0]
        }
        options.push(option)
      })
      return options
    }
  },
  methods: {
    selectChanged () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let optionsObject = this.$store.state[activeTableNamespace].displayTable.tableFilters.status[activeColumnRow]
      let values = []
      this.selectedValues.forEach(item => {
        values.push(optionsObject[item.id])
      })
      this.$store.commit('filters/updateStatusFilter', values)
      this.$store.dispatch('filters/filterChanged', values)
    }
  },
  mounted () {
    // Ситуация такова - если у нас ни один фильтр не выбран из OperationTypes, то нам
    // надо использовать все возможные фильтры для этого контрола. Вот мы их и посылаем на вычисления тут.
    // подбираем источник фильтров в зависимости от роли
    let source = {}
    let activeTableNamespace = this.$store.state.pager.activeTableNamespace
    source = this.$store.state[activeTableNamespace]
    let activeColumnRow = this.$store.state.pager.activeColumnRow
    let optionsObject = source.displayTable.tableFilters.operationTypes[activeColumnRow]
    let optionsArray = []
    Object.entries(optionsObject).forEach(entry => {
      optionsArray.push(entry[1])
    })
    this.$store.dispatch('filters/calculateDefaultFilters', optionsArray)

    // и, натурально, надо сбрасывать фильтры в сторе, если они уже установлены предыдущей страницей.
    // тут по умолчанию фильтров нет, поэтому ставим пустой массив
    let values = []
    if (!this.settingProcess) {
      // нам не нужно запускать запрос апи если мы только устанавливаем значение при сетапе фильтра
      values.skipApiQuery = false
    } else {
      values.skipApiQuery = true
    }
    this.$store.commit('filters/updateOperationTypesFilter', values)
    this.$store.dispatch('filters/filterChanged', values)
  }
}
</script>
