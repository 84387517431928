export default {
  namespaced: true,
  state: {
    filtersValues: {
      operationTypes: [],
      status: [],
      selectedDates: {},
      selectedPeriods: {},
      action: null,
      search: ''
    },
    filtersForApi: {
      created: [],
      periods: [],
      standardObject: {}, // Это набор фильтров выбранных на данный момент
      defaultObject: {}, // Это набор фильтров добавляемых если instrument_type_id и opcode в standardObject отсутствуют.
      filterByString: null
    }
  },
  mutations: {
    clearAllFilters (state) {
      state.filtersValues.operationTypes = []
      state.filtersValues.status = []
      state.filtersValues.selectedDates = {}
      state.filtersValues.selectedPeriods = {}
      state.filtersValues.action = null
      state.filtersValues.search = ''

      state.filtersForApi.created = []
      state.filtersForApi.periods = []
      state.filtersForApi.standardObject = {}
      state.filtersForApi.defaultObject = {}
      state.filtersForApi.filterByString = null
    },
    updateActionFilter (state, values) {
      state.filtersValues.action = values
    },
    updateSearchFilter (state, values) {
      state.filtersValues.search = values
    },   
    updateOperationTypesFilter (state, values) {
      state.filtersValues.operationTypes = values
    },
    updateStatusFilter (state, values) {
      state.filtersValues.status = values
    },
    updateDateFilter (state, values) {
      state.filtersValues.selectedDates = values
    },
    updatePeriodsFilter (state, values) {
      // Мне не очень нравится эта конструкция, но пока ничего умнее не придумал.
      // Возможно в процессе эксплуатации станут яснее требования к фильтрам

      if (!values) { return false }
      if (values.hasOwnProperty('fromPeriod')) {
        state.filtersValues.selectedPeriods.fromPeriod = values.fromPeriod
      }
      if (values.hasOwnProperty('toPeriod')) {
        state.filtersValues.selectedPeriods.toPeriod = values.toPeriod
      }
    },
    updateFiltersForApi (state, values) {
      state.filtersForApi.created = values.created

      if (values.hasOwnProperty('periods')) { state.filtersForApi.periods = values.periods }
      state.filtersForApi.standardObject = values.standardObject
    },
    updateDefaultFiltersForApi (state, defaultObject) {
      state.filtersForApi.defaultObject = defaultObject
    },
    prepareFilterByParameter (state, value){
      state.filtersForApi.filterByString = value
    },
    prepareFilterByParameterRemove (state) {
      console.log('param',state)
      let filterByString = ''
      let alreadyHaveParameter = false
      // проверяем на created
      if (state.filtersForApi.hasOwnProperty('created') &&
      typeof state.filtersForApi.created !== 'undefined' &&
      state.filtersForApi.created.length > 0) {
        filterByString += state.filtersForApi.created[0].field + state.filtersForApi.created[0].separator + state.filtersForApi.created[0].value +
        ' ' + state.filtersForApi.created[1].field + state.filtersForApi.created[1].separator + state.filtersForApi.created[1].value
        // filterByString += 'created_at' + state.filtersForApi.created[0].separator + state.filtersForApi.created[0].value + '&created_at' + state.filtersForApi.created[1].separator + state.filtersForApi.created[1].value
        alreadyHaveParameter = true
      }
      // Генерим строку фильтров из standardObject
      let operationTypesFiltersAssigned = false
      Object.entries(state.filtersForApi.standardObject).forEach(entry => {
        // Проверяем, есть ли у нас в standardObject opcode или instrument_type_id
        if (entry[0] === 'opcode' || entry[0] === 'instrument_type_id') {
          operationTypesFiltersAssigned = true
        }
        // Если до этого не было параметров - не добавляем &, если были - добавляем.
        if (!alreadyHaveParameter) {
          filterByString += entry[0]
          alreadyHaveParameter = true
        } else {
          filterByString += ' ' + entry[0]
        }

        if (entry[1].operator === 'in') {
          filterByString += ' in '
          filterByString += entry[1].value.join(',')
        }
        // Недоработан, это плейсхолдер. Продумать на будущее.
        if (entry[1].operator === '=') {
          filterByString += '='
          filterByString += entry[1].value.join(',')
        }
      })
      // Если не установлены фильтры operationTypes - генерим их из defaultObject
      if (!operationTypesFiltersAssigned) {
        Object.entries(state.filtersForApi.defaultObject).forEach(entry => {
          if (!alreadyHaveParameter) {
            filterByString += entry[0]
            alreadyHaveParameter = true
          } else {
            filterByString += ' ' + entry[0]
          }
          if (entry[1].operator === 'in') {
            filterByString += ' in '
            filterByString += entry[1].value.join(',')
          }
        })
      }

      // Добавляем фильтры на periods

      if (state.filtersForApi.periods.length > 0) {
        state.filtersForApi.periods.forEach(entry => {
          if (!alreadyHaveParameter) {
            filterByString += entry.field + entry.separator + entry.value
            alreadyHaveParameter = true
          } else {
            filterByString += ' ' + entry.field + entry.separator + entry.value
          }
        })
      }

      state.filtersForApi.filterByString = encodeURIComponent(filterByString)
    }
  },
  actions: {
    filterChanged (context, values) {
      context.dispatch('recalculateFilters')
      if (!values.skipApiQuery) {
        context.dispatch('pager/updateCurrentTableState', 'default', { root: true })
      }
    },
    calculateDefaultFilters (context, optionsObject) {
      let defaultObject = {}
      // Ситуация такова - если у нас ни один фильтр не выбран из OperationTypes, то нам
      // надо использовать все возможные фильтры для этого контрола. Вот мы их и вычисляем тут.
      // делаем глубокую копию фильтров из которых будем делать дефолтные фильтры
      let allOperationTypes = JSON.parse(JSON.stringify(optionsObject))

      allOperationTypes.forEach((item) => {
        item.filter.forEach((filt) => {
          if (!defaultObject.hasOwnProperty(filt.argument)) {
            // Такого фильтра пока не было - создаем его.
            defaultObject[filt.argument] = {}
            defaultObject[filt.argument].operator = filt.operator
            defaultObject[filt.argument].value = []
            Object.assign(defaultObject[filt.argument].value, filt.value)
          } else {
            // Такой фильтр уже есть - добавляем значения, если этих значений еще не было
            filt.value.forEach((val) => {
              if (defaultObject[filt.argument].value.indexOf(val) === -1) {
                defaultObject[filt.argument].value.push(val)
              }
            })
          }
        })
      })
      context.commit('updateDefaultFiltersForApi', defaultObject)
    },
    recalculateFilters (context) {
      // Рекалькулировать фильтры мы будем через получение данных об активной вкладке из модулей.
      let activeTableNamespace = context.rootState.pager.activeTableNamespace
      let activeColumnRow = context.rootState.pager.activeColumnRow
      // datePicker
      // в каких-то версиях датепикера может и не быть. Потому проверяем на undefined
      let created = false
      if (context.state.filtersValues.selectedDates.hasOwnProperty('start') &&
      context.state.filtersValues.selectedDates.hasOwnProperty('end') &&
      context.state.filtersValues.selectedDates.start !== 'undefined' &&
      context.state.filtersValues.selectedDates.end !== 'undefined' &&
      context.rootState[activeTableNamespace].displayTable.tableFilters.datePicker[activeColumnRow] !== 'undefined') {
        created = [
          {
            field: context.rootState[activeTableNamespace].displayTable.tableFilters.datePicker[activeColumnRow].field,
            separator: '>=',
            value: context.state.filtersValues.selectedDates.start
          },
          {
            field: context.rootState[activeTableNamespace].displayTable.tableFilters.datePicker[activeColumnRow].field,
            separator: '<',
            value: context.state.filtersValues.selectedDates.end
          }
        ]
      }

      // Добавляем Периоды для ситуаций когда нам нужны периоды
      let periods = []
      if (context.state.filtersValues.selectedPeriods.hasOwnProperty('fromPeriod') &&
      context.state.filtersValues.selectedPeriods.fromPeriod) {
        let period = {
          field: 'accounting_period',
          separator: '>',
          value: context.state.filtersValues.selectedPeriods.fromPeriod
        }
        periods.push(period)
      }
      if (context.state.filtersValues.selectedPeriods.hasOwnProperty('toPeriod') &&
      context.state.filtersValues.selectedPeriods.toPeriod) {
        let period = {
          field: 'accounting_period',
          separator: '<=',
          value: context.state.filtersValues.selectedPeriods.toPeriod
        }
        periods.push(period)
      }
      // Очищаем стандартный набор фильтров - мы сгенерим их все заново. Единственный способ
      // справиться с удалением фильтров

      let standardObject = {}

      // делаем глубокую копию
      let currentOperationTypes = JSON.parse(JSON.stringify(context.state.filtersValues.operationTypes))

      currentOperationTypes.forEach((item, itemkey) => {
        item.filter.forEach((filt, filterkey) => {
          if (!standardObject.hasOwnProperty(filt.argument)) {
            // Такого фильтра пока не было - создаем его.
            standardObject[filt.argument] = {}
            standardObject[filt.argument].operator = filt.operator
            standardObject[filt.argument].value = []
            Object.assign(standardObject[filt.argument].value, filt.value)
          } else {
            // Такой фильтр уже есть - добавляем значения, если этих значений еще не было
            filt.value.forEach((val) => {
              if (standardObject[filt.argument].value.indexOf(val) === -1) {
                standardObject[filt.argument].value.push(val)
              }
            })
          }
        })
      })

      // делаем глубокую копию Статусов
      let currentStatus = JSON.parse(JSON.stringify(context.state.filtersValues.status))
      // Здесь код повторяется с предыдущим, впоследствии надо придумать как его объединить.
      currentStatus.forEach((item, itemkey) => {
        item.filter.forEach((filt, filterkey) => {
          if (!standardObject.hasOwnProperty(filt.argument)) {
            // Такого фильтра пока не было - создаем его.
            standardObject[filt.argument] = {}
            standardObject[filt.argument].operator = filt.operator
            standardObject[filt.argument].value = []
            Object.assign(standardObject[filt.argument].value, filt.value)
          } else {
            // Такой фильтр уже есть - добавляем значения, если этих значений еще не было
            filt.value.forEach((val) => {
              if (standardObject[filt.argument].value.indexOf(val) === -1) {
                standardObject[filt.argument].value.push(val)
              }
            })
          }
        })
      })
      
      if (context.state.filtersValues.action) {
          standardObject['action'] = {
              operator: '=',
              value: [context.state.filtersValues.action]
          }
      }

      let values = {
        standardObject: standardObject
      }
      if (created) {
        values.created = created
      }
      if (periods.length > 0) {
        values.periods = periods
      }

      context.commit('updateFiltersForApi', values)
    },
    prepareFilterByParameterAction (context) {
      let activeTableNamespace = context.rootState.pager.activeTableNamespace
      let parametersSeparator = ' '
      // Для старых апи сеперетор между параметрами внутри параметра filter_by - '&', для новых - ' ', поэтому разделяем.
      if (activeTableNamespace === 'lifeMerchant'){
        parametersSeparator = '&'
      }
      let filterByString = ''
      let alreadyHaveParameter = false
      // проверяем на created
      if (context.state.filtersForApi.hasOwnProperty('created') &&
      typeof context.state.filtersForApi.created !== 'undefined' &&
      context.state.filtersForApi.created.length > 0) {
        filterByString += context.state.filtersForApi.created[0].field + context.state.filtersForApi.created[0].separator + context.state.filtersForApi.created[0].value +
        parametersSeparator + context.state.filtersForApi.created[1].field + context.state.filtersForApi.created[1].separator + context.state.filtersForApi.created[1].value
        // filterByString += 'created_at' + state.filtersForApi.created[0].separator + state.filtersForApi.created[0].value + '&created_at' + state.filtersForApi.created[1].separator + state.filtersForApi.created[1].value
        alreadyHaveParameter = true
      }
      // Генерим строку фильтров из standardObject
      let operationTypesFiltersAssigned = false
      Object.entries(context.state.filtersForApi.standardObject).forEach(entry => {
        // Проверяем, есть ли у нас в standardObject opcode или instrument_type_id
        if (entry[0] === 'opcode' || entry[0] === 'instrument_type_id') {
          operationTypesFiltersAssigned = true
        }
        // Если до этого не было параметров - не добавляем &, если были - добавляем.
        if (!alreadyHaveParameter) {
          filterByString += entry[0]
          alreadyHaveParameter = true
        } else {
          filterByString += parametersSeparator + entry[0]
        }

        if (entry[1].operator === 'in') {
          filterByString += ' in '
          filterByString += entry[1].value.join(',')
        }
        // Недоработан, это плейсхолдер. Продумать на будущее.
        if (entry[1].operator === '=') {
          filterByString += '='
          filterByString += entry[1].value.join(',')
        }
      })
      // Если не установлены фильтры operationTypes - генерим их из defaultObject
      if (!operationTypesFiltersAssigned) {
        Object.entries(context.state.filtersForApi.defaultObject).forEach(entry => {
          if (!alreadyHaveParameter) {
            filterByString += entry[0]
            alreadyHaveParameter = true
          } else {
            filterByString += parametersSeparator + entry[0]
          }
          if (entry[1].operator === 'in') {
            filterByString += ' in '
            filterByString += entry[1].value.join(',')
          }
        })
      }

      // Добавляем фильтры на periods

      if (context.state.filtersForApi.periods.length > 0) {
        context.state.filtersForApi.periods.forEach(entry => {
          if (!alreadyHaveParameter) {
            filterByString += entry.field + entry.separator + entry.value
            alreadyHaveParameter = true
          } else {
            filterByString += parametersSeparator + entry.field + entry.separator + entry.value
          }
        })
      }
      context.commit('prepareFilterByParameter', encodeURIComponent(filterByString))
      
    }


  }
}
