// Работа с пейджером
export default {
  namespaced: true,
  state: {
    cursor: {
      range: 10,
      currentOffset: 0,
      count: 0,
      next: null,
      prev: null,
      total: null,
      search: ''
    },

    activeTableNamespace: false,
    activeColumnRow: false,
    activeApi: false,

    pageOptions: [10, 15, 25, 50, 100],

    currentApiUrl: '/payment-gateway/v3/transactions',
    apiResponseReceived: false,
    apiDataEmpty: true

  },
  mutations: {
    updateCursor (state, cursor) {
      state.cursor.count = cursor.count
      state.cursor.next = cursor.next
      state.cursor.prev = cursor.prev
      state.cursor.total = cursor.total
    },
    updateCurrentOffset (state, currentOffset) {
      state.cursor.currentOffset = currentOffset
    },
    updateRange (state, range) {
      state.cursor.range = range
    },
    updateActiveTableNamespace (state, namespace) {
      state.activeTableNamespace = namespace
    },
    updateActiveColumnRow (state, columnRow) {
      state.activeColumnRow = columnRow
    },
    updateApiResponseReceived (state, value) {
      state.apiResponseReceived = value
    },
    updateApiDataEmpty (state, value) {
      state.apiDataEmpty = value
    }
  },
  actions: {
    perPageCountChanged (context, value) {
      context.commit('updateRange', value)
      context.dispatch('updateCurrentTableState', 'default')
    },
    updateCurrentTableState (context, value) {
      // Нам надо получить неймспейс активного тейбла и стриггерить обновление апи в этом модуле.
      // В зависимости от того что послужило источником запроса - генерим массив запроса - параметры
      let queryObject = {
        type: '',
        params: {},
        currentOffset: 0
      }
      switch (value) {
        case 'prev':
          queryObject.type = 'cursor'
          // если мы переходим по курсору - то мы передаем в него меняющийся currentOffset, остальное все берется из токена прев-некст
          queryObject.params.cursor = context.state.cursor.prev
          queryObject.currentOffset = context.state.cursor.currentOffset - 1
          break
        case 'next':
          queryObject.type = 'cursor'
          queryObject.params.cursor = context.state.cursor.next
          queryObject.currentOffset = context.state.cursor.currentOffset + 1
          break
        default:
          // Для ситуации когда триггером запроса послужил не курсор - мы запрашиваем фильтры
          queryObject.type = 'filters'
          queryObject.params.limit_to = context.state.cursor.range
          context.dispatch('filters/prepareFilterByParameterAction', null, { root: true })
          queryObject.params.filter_by = context.rootState.filters.filtersForApi.filterByString
          break
      }
      if (context.state.activeTableNamespace) {
        context.dispatch(context.state.activeTableNamespace + '/refreshTableData', queryObject, { root: true })
      } else {
        alert('К сожалению, не найден activeTableNamespace, возможно прав пользователя недостаточно для этой операции, обратитесь к администрации')
      }
    }
  }
}
