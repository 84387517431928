<template>
  <d-container fluid class="pt-5">

    <div class="common-navbar">
      <CommonNavBar />
    </div>

    <div class="d-flex wrappers">
      <div class="wrapper">
        <div v-if="showTableName === 'loans'">
          <LoansTable :key="tableKey"/>
          </div>
        <div v-if="showTableName === 'transactions'">
          <TransactionsTable :key="tableKey"/>
        </div>
        <div v-if="showTableName === 'rewardsActs'">
          <TableActs :key="tableKey" />
        </div>
        <div v-if="showTableName === 'singleAct'">
          <SingleAct :key="tableKey" />
        </div>
        <div v-if="showTableName === 'rewardsRecipients'">
          <RewardsWrapper :key="tableKey" />
        </div>
        <div v-if="showTableName === 'modules'">
            <div v-if="!showMerchant">
                <ModulesTable :key="tableKey" />
            </div>
            <div v-else>
                <div v-if="showTypeModule === 'pay'">
                    <PayMerchant :key="tableKey" />
                </div>
                <div v-if="showTypeModule === 'payout'">
                    <PayoutMerchant :key="tableKey" />
                </div>
                <div v-if="showTypeModule === 'life'">
                    <LifeMerchant :key="tableKey" />
                </div>
            </div>
        </div>
        <div v-if="showTableName === 'modulestransactions'">
          <ModulesTransactions :key="tableKey" />
        </div>
        <div v-if="showTableName === 'modulesregistries'">
          <ModulesRegistries :key="tableKey" />
        </div>
      </div>
      <div v-if="showTableName === 'transactions'" class="extend">
        <TransactionsExtend />
      </div>
      <div v-if="showTableName === 'modulestransactions'" class="extend">
        <ModulesTransactionsExtend />
      </div>
    </div>
  </d-container>
</template>

<script>
import CommonNavBar from '@/components/NavBar'
// import PayLenderTable from '@/components/PayLender/Table'
import LoansTable from '@/components/Loans/Table'
import TransactionsTable from '@/components/Transactions/Table'
import TransactionsExtend from '@/components/Transactions/Extend'
import ModulesTable from '@/components/Payout/Modules'
import PayMerchant from '@/components/Payout/PayMerchant'
import PayoutMerchant from '@/components/Payout/PayoutMerchant'
import LifeMerchant from '@/components/Payout/LifeMerchant'
import ModulesTransactions from '@/components/Payout/Transactions'
import ModulesRegistries from '@/components/Payout/Registries'
import ModulesTransactionsExtend from '@/components/Payout/Extend'
// import LoansExtend from '@/components/Loans/Extend'
import TableActs from '@/components/Acts/tableActs'
import SingleAct from '@/components/Acts/singleAct'
import RewardsWrapper from '@/pages/rewards/wrapper'

export default {
  components: {
    CommonNavBar,
    // PayLenderTable,
    LoansTable,
    TableActs,
    SingleAct,
    TransactionsTable,
    TransactionsExtend,
    ModulesTable,
    PayMerchant,
    PayoutMerchant,
    LifeMerchant,
    ModulesTransactions,
    ModulesTransactionsExtend,
    ModulesRegistries,
    // LoansExtend,
    RewardsWrapper,
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    tableKey () {
      return this.getKeyForComponents()
    },
    showMerchant () {
        return this.$route.params.mid
    },
    showTypeModule () {
        return this.$route.params.arg3
    },
    showTableName () {
      if (this.$route.params.arg1 === 'analytics') {
        if (this.$route.params.arg2 === 'pending' || this.$route.params.arg2 === 'finalized') {
          return 'loans'
        }
        if (this.$route.params.arg2 === 'payments' || this.$route.params.arg2 === 'returns' || this.$route.params.arg2 === 'all') {
          return 'transactions'
        }
      }
      if (this.$route.params.arg1 === 'rewards' && this.$route.params.arg2 === 'acts') {
        return 'rewardsActs'
      }
      if (this.$route.params.arg1 === 'rewards' && this.$route.params.arg2 === 'act') {
        return 'singleAct'
      }
      if (this.$route.params.arg1 === 'rewards' && this.$route.params.arg2 === 'recipients') {
        return 'rewardsRecipients'
      }
      if (this.$route.params.arg1 === 'nocode' && this.$route.params.arg2 === 'modules') {
          this.defineActiveRowColumnAndModule()
          return 'modules'
      }
      if (this.$route.params.arg1 === 'nocode' && this.$route.params.arg2 === 'modulestransactions') {
          this.defineActiveRowColumnAndModule()
          return 'modulestransactions'
      }
      if (this.$route.params.arg1 === 'nocode' && this.$route.params.arg2 === 'modulesregistries') {
          this.defineActiveRowColumnAndModule()
          return 'modulesregistries'
      }
      if (this.$route.params.arg1 === 'nocode' && this.$route.params.arg3 === 'payout') {
          this.defineActiveRowColumnAndModule()
          return 'payout'
      }
      if (this.$route.params.arg1 === 'nocode' && this.$route.params.arg3 === 'pay') {
          this.defineActiveRowColumnAndModule()
          return 'pay'
      }
      return 'transactions'
    }
  },
  created () {
    // Уже при создании нам надо понимать какие активные модули у нас используются
    // И что у нас за собственно раздел в этом активном модуле.
    this.defineActiveRowColumnAndModule()
    this.$store.dispatch('loadV1ClientObject', this.$store.state.account.cid)

    
  },
  mounted () {

  },
  methods: {
    tableSetting ({ field, select, i }) {
      if (select) {
        this.fields.splice(i, 0, field)
      } else {
        this.fields = this.fields.filter(item => item.key !== field.key)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .extend{
    padding-left: 30px;
    width: 270px;
  }
  .wrappers {
      min-width: 1250px;
      width: calc(100% - 30px);
  }
  .wrapper {
      width: 100%;
      min-width: calc(100% - 300px)
  }
</style>
