<template>
  <div class="mb-3">
    <b-row class="mb-3">
      <b-col cols="4">
        <DatePicker/>
      </b-col>
      <b-col cols="4">
        <Status/>
      </b-col>
      <b-col cols="4">
        <Opcodes/>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <InstrumentTypes/>
      </b-col>
      <b-col cols="4">
        <Mids/>
      </b-col>
      <b-col cols="4">
        <b-button block @click="runFilters" class="search-button" variant="primary">Поиск с фильтрами</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import DatePicker from './datePicker'
import Status from './status'
import Opcodes from './opcodes'
import InstrumentTypes from './instrumentTypes'
import Mids from './mids'

export default {
  components:{
      DatePicker,
      Status,
      Opcodes,
      InstrumentTypes,
      Mids
  },
  data() {
      return {

      }
  },
  methods:{
    runFilters(){
      this.$store.dispatch('pagerStd/updateCurrentTableState', null, { root: true })
    }
  }
}
</script>

<style scoped>
.search-button{
  line-height: 18px;
  font-size: 14px;
  border-radius: 5px;
}
div >>> .form-control{
  height: 38px;
}
</style>
