<template>
  <div id="navbar-search" v-if="visible_search" style="margin-top: 16px; width: 100%;">
    <div>
      <b-input-group seamless style="width: 350px; margin-right: 30px;">
        <b-input-group-text slot="prepend">
          <i class="material-icons">search</i>
        </b-input-group-text>
        <b-input
          style="height: 35px;"
          ref="search"
          v-model="search"
          placeholder="Поиск, 3+ знаков"
          @keydown.native="checkEnterKey"
        ></b-input>
      </b-input-group>
    </div>
    <div style="margin-right: 20px;">
      <b-button variant="outline-primary" @click="goSearch" :disabled="!allowedSubmit">Искать</b-button>
    </div>
  </div>
</template>

<script>
export default{
  data (){
    return{
      
    }
  },
  computed:{
    search:{
      get: function () { return this.$store.state.search.query },
      set: function (newValue) { this.$store.commit('search/updateQuery', newValue) }
    },
    busy(){
      return !this.$store.state.pagerStd.apiResponseReceived
    },
    allowedSubmit(){
      let valid = true
      if (this.search.length < 3){ valid = false }
      if (this.busy){ valid = false }
      return valid
    },
    visible_search() {
        // Убираем гловальный поиск на странице lifeBroker
        return this.$store.state.pagerStd.activeTableNamespace !== "lifeBroker"
    }
  },
  methods:{
    goSearch () {
      if (this.$router.history.current.fullPath === '/search'){
        this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
      }else{
        return this.$router.push('/search')
      }
    },
    checkEnterKey(event){
      if (!this.allowedSubmit){
        return false
      }
      if (event.which === 13) {
        this.goSearch()
      }
    }
  }


}

</script>
<style scoped>
  .input-group-text{
    border: transparent;
  }
  .form-control{
    border: transparent;
  }
  .input-group{
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  }
</style>