<template>
    <d-form-select v-model="perPage" :options="pageOptions"></d-form-select>
</template>

<script>
export default {
  computed: {
    perPage: {
      get: function () { return this.$store.state.pager.cursor.range },
      set: function (newValue) { this.$store.dispatch('pager/perPageCountChanged', newValue, { root: true }) }
    },
    pageOptions () {
      return this.$store.state.pager.pageOptions
    }
  }
}
</script>
