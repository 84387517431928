<template>
  <b-row>
    <b-col cols="12" lg="9">
      <b-card class="card-small">
      <Filters/>
      <PagerComponent class="projectsPage"/>
      <Loader v-if="loadingData" :height="600" :size="3" />
      <div class="main-table-wrapper" v-else>
        <b-table striped hover selectable select-mode="single" class="text-nowrap" :fields="fields" :items="dataTable" @row-clicked="selectItem">
          <template v-slot:head()="data">
            <span v-html="data.field.label"></span>
          </template>
          <template v-slot:cell(is_sandbox)="data">
            <d-badge class="sandbox-badge" :theme="getBadgeTheme('is_sandbox', data.item.is_sandbox)">
              {{ getLabel('is_sandbox', data.item.is_sandbox) }}
            </d-badge>
          </template>
          <template v-slot:cell(onboarding_status)="data">
            <d-badge class="sandbox-badge" outline :theme="getBadgeTheme('onboarding_status', data.item.onboarding_status)">
              {{ getLabel('onboarding_status', data.item.onboarding_status) }}
            </d-badge>
          </template>
          <template v-slot:cell(actions)="data">
            <b-dropdown text="Действия" variant="primary" right ref="dropdown">
              <b-dropdown-item @click.prevent="getEdit(data.item.merchant_id)">Настроить</b-dropdown-item>
<!--              <b-dropdown-item disabled @clickюпр.prevent="getDelete(data.item.merchant_id)">Удалить</b-dropdown-item>-->
            </b-dropdown>
          </template>
        </b-table>
      </div>
      </b-card>
    </b-col>
    <b-col cols="12" lg="3" class="fixed-extend d-lg-block d-none">
      <ExtendProject :item="extendItem"/>
    </b-col>
  </b-row>
</template>

<script>
import PagerComponent from '@/components/Pager/pagerComponent'
import Filters from './Filters/Filters'
import moment from 'moment'
import ExtendInvoice from "../Invoices/extendInvoice.vue";
import ExtendProject from './extendProject.vue'

export default {
  name: 'ProjectsTable',
  components: {
    ExtendInvoice,
    PagerComponent,
    Filters,
    ExtendProject,
    moment
  },
  data(){
    return{
      extendItem: null,
      isLoading: false,
      fields: [
        {
          key: 'merchant_id',
          label: 'MID',
        },
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'is_sandbox',
          label: 'Режим',
        },
        {
          key: 'onboarding_status',
          label: 'Статус одобрения',
        },
        {
          key: 'actions',
          label: 'Действия',
        },
      ]
    }
  },
  computed:{
    dataTable(){
      return this.$store.state.projects.projects
      // return []
    },
    loadingData(){
      return !this.$store.state.pagerStd.apiResponseReceived
      // return false
    }
  },
  watch: {
    loadingData: function (newValue) {
      if (newValue)
        this.extendItem = false;
    }
  },
  methods:{
    selectItem(item, index){
      this.extendItem = item
    },
    getDelete(id){
      // this.$store.dispatch('projects/deleteProject', id)
    },
    getEdit(id){
      this.$router.push({ name: 'ProjectEdit', params: { arg1: id } })
    },
    getLabel(field, value) {
      const config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig][field];
      const option = config.options.find(option => option.value === value);
      return option ? option.label : 'Неизвестно';
    },
    getBadgeTheme(field, value) {
      const config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig][field];
      const option = config.options.find(option => option.value === value);
      return option ? option.badge : 'default';
    },
  },
  created(){
    // Чтобы остатки фильтров не оставались после предыдущих страниц, очищаем фильтры. Делать это надо до установки новых.
    this.$store.commit('filtersStd/clearFilters')
    // Устанавливаем, какой из конфигов фильтров из config/filtersTables используется в этой таблице.
    this.$store.commit('filtersStd/setActiveFilterConfig', 'filtersProjects', { root: true })
  },
  mounted () {
    // Устанавливаем нейспейс для пейджера в этой таблице.
    this.$store.commit('pagerStd/updateActiveTableNamespace', 'projects', { root: true })
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
  },
}
</script>

<style scoped>
.badge-warning {
  color: white;
}
.table >>> td {
  vertical-align: middle;
}
</style>

<style>
.projectsPage button#exportButton {
  display: none;
}
</style>
