// Этот модуль описывает структуру набора таблиц Prices

export default {
  state: {
    prices: {
      all: {},
      actualMERCHANT_LEAD_REWARD: {
        active_from: '',
        fee_rate: '',
        received: false
      },
      actualACQUIRING_FEE: {
        received: false
      },
      actualPAYBYLOAN_FEE: {
        received: false
      },
      actualMANDARIN_LEAD_REWARD: {
        active_from: '',
        fee_rate: '',
        received: false
      }
    },
    pricesEmpty: true,
    pricesReceived: false
  },
  mutations: {
    togglePricesEmpty (state, value) {
      state.pricesEmpty = value
    },
    togglePricesReceived (state, value) {
      state.pricesReceived = value
    },
    updatePrices (state, { type, value }) {
      state.prices[type] = value
    }
  },
  actions: {
    loadActualPriceByFeeType (context) {
      let url = '/life-backend/api/v3/prices/actual'
      this.$axios.get(url, { })
        .then(response => {
          // Обрезаем все лишнее что пришло в ответе
          response = response.data
          if (response.hasOwnProperty('prices')) {
            response.prices.forEach(element => {
              let type = 'actual' + element.fee_type
              element.received = true
              context.commit('updatePrices', {
                type: type,
                value: element
              })
            })
          }
        })
        .catch(error => {
          console.log('loadActualPriceByFeeType', error)
        })
    },
    loadPrices (context) {
      context.commit('togglePricesEmpty', true)
      context.commit('togglePricesReceived', false)
      let url = '/life-backend/api/v3/prices/'
      let params = {}
      this.$axios.get(url, {
        params: params
      })
        .then(response => {
          // Обрезаем все лишнее что пришло в ответе
          response = response.data
          context.commit('togglePricesReceived', true)
          if (response.prices.length === 0) {
            context.commit('togglePricesEmpty', true)
          } else {
            context.commit('togglePricesEmpty', false)
            let prices = {
              type: 'all',
              value: response.prices
            }
            context.commit('updatePrices', prices)
          }
        })
        .catch(error => {
          console.log('error in promise', error)
        })
      // получаем полный набор актуальных
      context.dispatch('loadActualPriceByFeeType')
    }
  }
}
