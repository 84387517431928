<template>
  <div class="cell-wrapper">
    <b-button v-if="showDownloadButton" variant="outline-secondary"  v-on:click="loadReport(item)">Скачать</b-button>
    <div v-if="showError">{{item.error_message}}</div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  computed: {
    showDownloadButton(){
      if (this.item.status === 'success'){return true}
      return false
    },
    showError(){
      if (this.item.error_message === ''){return false}
      return true
    }
  },
  methods: {
    loadReport: function (item) {
      // Подгружаем нужный эксель
      this.$store.dispatch('reportsExport/downloadReport', item.id)
    }
  }
}
</script>