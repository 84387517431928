<template>
  <!--<div class="mandarin-icons">
    <a class="round-wrapper first" href="https://care.mandarin.io/hc/ru/requests/new" target="_blank">
      <div id="send-mail"><span class="material-symbols-outlined">mail</span></div>
    </a>
    <a class="round-wrapper second" href="https://t.me/SuppMandarinBot" target="_blank">
      <img id="send-t" class="t-sociallinks__svg" src="@/assets/images/send_tg.svg" alt="send_tg">
    </a>
  </div>-->
</template>

<script>

export default {
  components: {
  },
  mounted () {
    let attributes ={
        //name: '',
        email: '',
        //phone: '',
        client_id: '',
        legal_name: '',
        services: '',
        sla: '',
        account_manager: '',
      }
    if (Object.prototype.hasOwnProperty.call(this.$store.state.account, 'mandarin')){
      attributes.email = this.$store.state.account.mandarin.email
      attributes.client_id = this.$store.state.account.mandarin.client_id
    }
    // Остальные данные подгружаются в Account.js
    let newObject = {
      visitorAttributes: attributes,
      }
    console.log(newObject, 'newObject')
    Object.assign(window.flomniConfig.visitorAttributes, newObject.visitorAttributes);
  }
}
</script>

<style lang="scss" scoped>
.mandarin-icons {
  position: fixed;
  bottom: 20px;
  right: 16px;
  z-index: 999;
  a{
    display: block;
  }
}
.round-wrapper{
  width: 64px;
  height: 64px;
  border-radius: 40px;
  background-color: black;
  position: relative;
  &.first{
    margin-bottom: 14px;
  }
  #send-t{
    width: 30px;
    height: 30px;
    position: absolute;
    left: 15px;
    top: 18px;
  }
  #send-mail{
    color: white;
    position: absolute;
    left: 15px;
    top: 16px;
    .material-symbols-outlined{
      font-size: 34px;
    }
  }
}
@media (max-width: 953px) {
.mandarin-icons {
  bottom: 14px;
  right: 6px;
}
.round-wrapper{
  width: 52px;
  height: 52px;
  &.first{
    margin-bottom: 10px;
  }
  #send-t{
    width: 26px;
    height: 26px;
    left: 12px;
    top: 14px;
  }
  #send-mail{
    left: 10px;
    top: 11px;
    .material-symbols-outlined{
      font-size: 32px;
    }
  }
}
}
</style>