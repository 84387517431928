<template>
  <b-row>
    <b-col>

      <b-tabs class="alternative-nav-tabs-look">
        <b-tab @click.prevent="activeTab=0" :active="activeTab===0" title="Общие"></b-tab>
        <b-tab @click.prevent="activeTab=1" :active="activeTab===1" v-if="!error_message" title="Интеграция"></b-tab>
        <b-tab @click.prevent="activeTab=2" :active="activeTab===2"
               v-if="checkScopePresence('secure_settlements_sub_merchant.read') && !error_message"
               title="Роутинг"></b-tab>
        <b-tab @click.prevent="activeTab=3" :active="activeTab===3"
               v-if="checkScopePresence('antifraud:client_limits_self.read') && !error_message" title="Лимиты"></b-tab>
        <b-tab @click.prevent="activeTab=4" :active="activeTab===4"
               v-if="checkScopePresence('secure_app_payout_module.write') && !error_message"
               title="Форма для выплаты"></b-tab>
      </b-tabs>

      <b-card class="card-small with-nav-tabs">
        <div v-if="showForm">
          <b-row>
            <b-col>
              <h5 class="pt-2">{{ title }}</h5>
              <hr>
            </b-col>
          </b-row>
          <div v-if="activeTab === 0">
            <div v-if="error_message" class="bold d-flex justify-content-center align-items-center">
              {{ error_message }}
            </div>
            <div v-else>
              <b-row class="mt-4">
                <b-col cols="2">
                  <span class="label">Название проекта</span><span>*</span>
                </b-col>
                <b-col>
                  <b-form-input v-model="projectName" :state="projectNameValid"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="2">
                  <span class="label">Контактный телефон</span><span>*</span>
                </b-col>
                <b-col>
                  <b-form-input v-model="projectPhone" :state="projectPhoneValid"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="2">
                  <span class="label">Адрес отправки коллбэка</span><span>*</span>
                </b-col>
                <b-col>
                  <b-form-input v-model="callbackUrl" :state="callbackUrlValid"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="2">
                  <span class="label">Адрес возвращения пользователя</span><span>*</span>
                </b-col>
                <b-col>
                  <b-form-input v-model="returnUrl" :state="returnUrlValid"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="2">
                  <span class="label">Sandbox</span>
                </b-col>
                <b-col>
                  <b-form-checkbox v-model="projectSandbox" :disabled="true" name="project-sandbox"
                                   switch></b-form-checkbox>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="2"></b-col>
                <b-col>
                  <b-button v-on:click="saveMerchant" :disabled="!allowedSubmit()" variant="outline-primary">Сохранить
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
          <div v-if="activeTab === 1">
            <b-row v-if="checkScopePresence('secure_secrets.read')" class="mt-4">
              <b-col cols="2">
                <span class="label">Secret</span>
              </b-col>
              <b-col>
                <strong><a target="_blank" :href="vaultUrl">Перейти к управлению API Secrets</a></strong>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col cols="2">
                <span class="label">Белый список IP для использования api</span>
              </b-col>
              <b-col v-if="checkScopePresence('secure_merchants_v3.read')">
                <div v-if="allowed_ip_addresses.length === 0">
                  Не добавлен
                </div>
                <div v-for="(item, index) in allowed_ip_addresses" v-bind:key="index">
                  {{ item }}
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col cols="2">
                <span class="label">Белый список IP для выплат</span>
              </b-col>
              <b-col v-if="checkScopePresence('secure_merchants_v3.read')">
                <div v-if="allowed_payout_ip_addresses.length === 0">
                  Не добавлен
                </div>
                <div v-for="(item, index) in allowed_payout_ip_addresses" v-bind:key="index">
                  {{ item }}
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-if="activeTab === 2">
            <div>
              <div v-if="showSubmerchant">
                <div v-if="checkScopePresence('secure_settlements_sub_merchant.read')">
                  <b-row v-if="!registeredAsSubmerchant">
                    <b-col>
                      Вы не прирегистрированы как sub-merchant к какому-либо master-merchant.
                    </b-col>
                  </b-row>
                  <b-row v-if="registeredAsSubmerchant">
                    <b-col>
                      Ваш мерчант {{ mid }} зарегистрирован в качестве sub-merchant.

                      <table class="mt-2">
                        <tr>
                          <td style="padding-right: 20px;">sub_merchant_account_id:</td>
                          <td>{{ $store.state.projectMerchant.masterMerchant.subMerchantAccountId }}</td>
                        </tr>
                        <tr>
                          <td>Master merchant:</td>
                          <td>{{ $store.state.projectMerchant.masterMerchant.masterMerchantName }} (mid:
                            {{ $store.state.projectMerchant.masterMerchant.masterMerchantId }})
                          </td>
                        </tr>
                      </table>

                    </b-col>
                  </b-row>
                  <b-row v-if="checkScopePresence('secure_settlements_sub_merchant.write')" class="mt-4">
                    <b-col>
                      <b-button v-on:click="generateSubmerchantToken"
                                :disabled="$store.state.projectMerchant.generateSubmerchantTokenBusy"
                                variant="outline-primary">Получить sub-merchant token
                      </b-button>
                      <div v-if="$store.state.projectMerchant.submerchantToken" class="mt-3">
                        <b-input readonly :value="$store.state.projectMerchant.submerchantToken"></b-input>
                        <b-button class="mt-2" @click="clipboardHandler($store.state.projectMerchant.submerchantToken)"
                                  variant="outline-secondary">Скопировать sub-merchant token
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>

              </div>

              <div v-else style="min-height: 200px">
                <Loader :size="3"/>
              </div>

            </div>
          </div>
          <div v-if="activeTab === 3">
            <LimitsComponent :mid="mid"/>
          </div>
          <div v-if="activeTab === 4">
            <PayoutMerchantEdit :com_import="true" :mid="Number(mid)"/>
          </div>
        </div>
        <div v-else style="min-height: 200px">
          <Loader :size="3"/>
        </div>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import LimitsComponent from '@/components/ProjectMerchant/limits'
import PayoutMerchant from "@/components/Payout/PayoutMerchant.vue";

export default {
  components: {
    LimitsComponent,
    PayoutMerchantEdit: PayoutMerchant
  },
  data: function () {
    return {
      activeTab: 0,
      vaultUrl: VUE_APP_MERCHANT_VAULT,
      error_message: '',
    }
  },
  watch: {
    mid(newMid, oldMid) {
      if (newMid !== oldMid) {
        let mid = newMid
        if (mid === 0 || isNaN(mid)) {
          this.$toast.error("ID проекта невалидно; невозможно загрузить данные.");
          return false
        }
        this.handleMidChange(mid)
      }
    }
  },
  computed: {
    mid() {
      return this.$route.params.arg1
    },
    showForm() {
      if (this.busy) {
        return false
      }
      if (this.error_message) {
        return true
      }
      if (!Object.prototype.hasOwnProperty.call(this.$store.state.projectMerchant.merchant, 'id')) {
        return false
      }
      if (this.$store.state.projectMerchant.merchant.id == '') {
        return false
      }
      return true
    },
    showSubmerchant() {
      if (this.$store.state.projectMerchant.submerchantBusy) {
        return false
      }
      return true
    },
    registeredAsSubmerchant() {
      return this.$store.state.projectMerchant.registeredAsSubmerchant
    },
    allowed_ip_addresses() {
      return this.$store.state.projectMerchant.merchant.security.allowed_ip_addresses
    },
    allowed_payout_ip_addresses() {
      return this.$store.state.projectMerchant.merchant.security.allowed_payout_ip_addresses
    },
    title() {
      return "mid: " + this.mid
    },
    projectName: {
      get: function () {
        return this.$store.state.projectMerchant.merchant.general.name
      },
      set: function (newValue) {
        this.updateNewValue('general', 'name', newValue)
      }
    },
    projectNameValid() {
      if (this.projectName == '') {
        return false
      }
      return true
    },
    projectPhone: {
      get: function () {
        return this.$store.state.projectMerchant.merchant.general.phone
      },
      set: function (newValue) {
        this.updateNewValue('general', 'phone', newValue)
      }
    },
    projectPhoneValid() {
      if (this.projectPhone == '') {
        return false
      }
      // Длина российского телефона - 11 цифр и знак "+" для нормализованного телефона, то есть 12
      if (this.normalizePhone(this.projectPhone).length !== 12) {
        return false
      }
      return true
    },
    callbackUrl: {
      get: function () {
        return this.$store.state.projectMerchant.merchant.api.callback_url
      },
      set: function (newValue) {
        this.updateNewValue('api', 'callback_url', newValue)
      }
    },
    callbackUrlValid() {
      if (this.callbackUrl == '') {
        return false
      }
      if (!this.isValidHttpUrl(this.callbackUrl)) {
        return false
      }
      return true
    },
    returnUrl: {
      get: function () {
        return this.$store.state.projectMerchant.merchant.api.return_url
      },
      set: function (newValue) {
        this.updateNewValue('api', 'return_url', newValue)
      }
    },
    returnUrlValid() {
      if (this.returnUrl == '') {
        return false
      }
      if (!this.isValidHttpUrl(this.returnUrl)) {
        return false
      }
      return true
    },
    projectSandbox: {
      get: function () {
        return this.$store.state.projectMerchant.merchant.api.is_sandbox
      },
      set: function (newValue) {
        return false
        //console.log(newValue, 'new value for sandbox switch, but it shouldt be enabled')
      }
    },
    busy() {
      return this.$store.state.projectMerchant.busy
    }

  },
  methods: {
    async handleMidChange(mid) {
      try {
        const onboarding_status = await this.$store.dispatch('projectMerchant/loadClientsMerchant', mid);
        if (onboarding_status) {
          if (onboarding_status === 'approved') {
            await this.$store.dispatch('projectMerchant/loadProject', mid);
            if (this.checkScopePresence('secure_settlements_sub_merchant.read')) {
              await this.$store.dispatch('projectMerchant/loadMasterMerchant', mid);
            }
            await this.$store.dispatch('projectMerchant/resetSubmerchantData');
          } else if (onboarding_status === 'wait_approval') {
            this.error_message = 'Мы получили заявку на новый проект и рассматриваем ее. После одобрения проекта вы получите email с подтверждением одобрения проекта.';
          } else if (onboarding_status === 'rejected') {
            this.error_message = 'Данный проект был отклонен. Для уточнения причин свяжитесь с вашим менеджером или Службой поддержки.';
          }
        } else {
          console.log('Error loading merchant data');
          this.localVariable = 'Некоторое значение'; // Пример изменения локальной переменной
        }
      } catch (error) {
        console.error('Error handling mid change:', error);
        this.$toast.error('Произошла ошибка при загрузке данных о торговце.');
      }
    },
    updateNewValue(propParentName, propChildName, value) {
      let newObject = {}
      newObject['propParentName'] = propParentName
      newObject['propChildName'] = propChildName
      newObject['value'] = value
      this.$store.commit('projectMerchant/updateMerchantValue', newObject)
    },
    allowedSubmit() {
      let valid = true
      if (!this.projectNameValid || !this.projectPhoneValid || !this.callbackUrlValid || !this.returnUrlValid) {
        valid = false
      }
      if (this.busy) {
        valid = false
      }
      return valid
    },
    saveMerchant() {
      this.$store.dispatch('projectMerchant/editProject')
    },
    generateSubmerchantToken() {
      this.$store.dispatch('projectMerchant/generateSubmerchantToken')
    },
    clipboardHandler(value) {
      this.$clipboard(value)
      this.$toast.success("Скопировано: " + value);
    },

  },
  async mounted() {
    let mid = Number(this.$route.params.arg1)
    if (mid === 0 || isNaN(mid)) {
      this.$toast.error("ID проекта невалидно; невозможно загрузить данные.");
      return false
    }
    this.$store.commit('pager/updateActiveTableNamespace', 'payLender', {root: true})
    this.$store.commit('pager/updateActiveColumnRow', 'modulesAll', {root: true})

    await this.handleMidChange(mid)

    this.$nextTick(() => {
      if (this.$route.query.tab) this.activeTab = Number(this.$route.query.tab);
    })
  }
}
</script>
<style scoped>
::v-deep .card {
  box-shadow: none;
}
</style>
