<template>
  <b-form-checkbox variant="outline-secondary" v-model="is_active" name="check-button-is_active">
    <p>
      показывать неактивные
      <span v-if="inactiveCount > 0"> (есть неактивные - {{ inactiveCount }} мод.)</span>
    </p>
  </b-form-checkbox>
</template>
<style>
    .custom-checkbox {
        display: inline-block;
        margin-left: 10px;
    }
</style>

<script>
export default {
  props: {
    inactiveCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    is_active:{
        get: function() {
            return (this.$store.state.payLender.is_active)
        },
        set: function(v) {
            this.$store.commit('payLender/is_active', v)
            this.$emit('activeChanged')
        }
    }
  }
}
</script>

