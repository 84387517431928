const filters_config = {
  instrument_type: {
    show: true,
    options: [
      { value: '10', label: 'Apple Pay'},
      { value: '800', label: 'Заявка на кредитование'},
      { value: '1,4747', label: 'Банковская карта'},
      { value: '801', label: 'MandarinPOS' },
      { value: '20', label: 'СБП' }
    ]
  },
  status:{
    show: true,
    options: [
      { value: 'success', label: 'Успешно', selected: true},
      { value: 'fail,timeout', label: 'Ошибка', selected: true },
      { value: 'wait,wait_internal,wait_3ds,wait_external,revise_require', label: 'Обработка' }
    ]
  },
  opcodes: {
    show: true,
    options: [
      { value: '1', label: 'Оплата'},
      { value: '2', label: 'Возврат' },
      { value: '3', label: 'Оплата (рекуррент)' },
      { value: '4', label: 'Предавторизация' },
      { value: '5', label: 'Отмена авторизации' },
      { value: '6', label: 'Оплата (2х стадийная)' },
      { value: '22', label: 'Возврат' },
      { value: '200', label: 'Возврат рассрочки' },
      { value: '451', label: 'Ручной возврат' },
      { value: '453', label: 'Опротестование' },
      { value: '472', label: 'Выплата' }
    ]
  }
}

export default filters_config
