<template>
  <div>
    <div class="w-100 min-vh-300">
      <div v-if="true">
        <div class="container-form">
          <div class="form-payout">
            Ожидание...
          </div>
        </div>
      </div>
    </div>
    <a href="#" @click.prevent="nextStep" class="btn btn-primary w-100 mt-2">Следующая выплата</a>
  </div>
</template>

<script>
export default {
  methods: {
    nextStep() {
      this.$emit('changeStep', 'back');
    }
  }
}
</script>
