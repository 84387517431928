<template>
  <b-row>
    <b-col cols="9">
      <b-card class="card-small">
        <Filters/>
        <PagerComponent/>
        <Loader v-if="loadingData" :height="600" :size="3" />
        <div class="main-table-wrapper" v-else>
          <b-table striped hover selectable select-mode="single" class="text-nowrap" :fields="fields" :items="dataTable" @row-clicked="selectItem">
            <template v-slot:head(cs1)="data">
              <span v-html="data.field.label"></span>
            </template>
            <template v-slot:head()="data">
              <span v-html="data.field.label"></span>
            </template>
            <template v-slot:cell(cs1)="data">
              <clipboard :data="data.item.cs1"/>&nbsp;{{data.item.cs1}}<br>
              <clipboard :data="data.item.order_id"/>&nbsp;{{data.item.order_id}}
            </template>
            <template v-slot:cell(customer)="data">
              <div v-if="data.item.customer_info">
                <clipboard v-if="data.item.customer_info.phone" :data="data.item.customer_info.phone"/>&nbsp;{{data.item.customer_info.phone}}<br>
                <clipboard v-if="data.item.customer_info.email" :data="data.item.customer_info.email"/>&nbsp;{{data.item.customer_info.email}}
              </div>
            </template>
            <template v-slot:cell(rrn)="data">
              {{displayLabel('opcodes', data.item.opcode)}}:&nbsp;{{displayLabel('instrument_type', data.item.instrument_type_id)}}<br>
              <clipboard v-if="data.item.rrn" :data="data.item.rrn"/>&nbsp;{{data.item.rrn}}
              <span v-if="data.item.secure_mode">&nbsp;<d-badge outline class="theme3ds" theme="secondary">3DS</d-badge></span>
            </template>
            <template v-slot:cell(mid)="data">
              {{data.item.mw_merchant_id}}<br>
              {{properDT(data.item.updated)}}
            </template>
            <template v-slot:cell(amount)="data">
              <span :class="getAmountClass(data.item.opcode)">{{signFromOpcode(data.item.opcode)}} {{properM(data.item.amount)}} <span v-html="currency(data.item.currency_id)"></span></span>
              <br>
              <span v-html="getStatusBadge(data.item)"></span>
            </template>
            <template v-slot:cell(actions)="data">
              <b-dropdown text="Действия" variant="primary" right ref="dropdown">
                <b-dropdown-item >Нет активных действий</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div v-if="!haveResults" style="min-height: 400px; text-align: center; padding-top: 150px;">
            Данные за указанный период с учетом выбранных фильтров отсутствуют.
          </div>
        </div>

      </b-card>
    </b-col>
    <b-col cols="3" class="fixed-extend">
      <ExtendPos :item="extendItem"/>
    </b-col>
  </b-row>
</template>

<script>
import PagerComponent from '@/components/Pager/pagerComponent'
import Filters from './Filters/Filters'
import ExtendPos from './extendPos'
import { getLabel, getStatusBadgeWithCodeError } from '@/configs/filtersTables/labels'

export default {
  components: {
    PagerComponent,
    Filters,
    ExtendPos
  },
  data(){
    return{
      extendItem: false,
      fields: [
        {
          key: 'cs1',
          label: 'ID платежа<br>ID заказа в системе клиента',
        },
        {
          key: 'customer',
          label: 'Клиент',
        },
        {
          key: 'rrn',
          label: 'Метод платежа<br>RRN (ID способ оплаты)',
        },
        {
          key: 'mid',
          label: 'ID проекта<br>Дата и время',
        },
        {
          key: 'amount',
          label: 'Сумма<br>Статус',
        },
        {
          key: 'actions',
          label: '',
        },
      ]
    }
  },
  computed:{
    dataTable(){
      console.log(this.$store.state.pos.transactions, 'this.$store.state.pos.transactions')
      return this.$store.state.pos.transactions
    },
    loadingData(){
      return !this.$store.state.pagerStd.apiResponseReceived
    },
    haveResults(){
      if (Array.isArray(this.dataTable) && this.dataTable.length>0){
        return true
      }
      return false
    }
  },
  watch: {
    loadingData: function (newValue, oldValue) {
      if (newValue){
        // Если мы грузим данные - чистим extendItem
        this.extendItem = false

      }
    }
  },
  methods:{
    selectItem(item, index){
      this.$store.dispatch('transactionsAdditional/getTransactionById', {id: item.cs1, mid: item.mw_merchant_id})
      this.extendItem = item
    },
    displayLabel(filter_name, current_value){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getLabel(filters_config, filter_name, current_value)
    },
    getStatusBadge(item){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getStatusBadgeWithCodeError(this.$store.state.notificationsService.paygwErrorsMapping, filters_config, item)
    }
  },
  created(){
    // Чтобы остатки фильтров не оставались после предыдущих страниц, очищаем фильтры. Делать это надо до установки новых.
    this.$store.commit('filtersStd/clearFilters')
    // Устанавливаем, какой из конфигов фильтров из config/filtersTables используется в этой таблице.
    this.$store.commit('filtersStd/setActiveFilterConfig', 'filtersPos', { root: true })
  },
  mounted () {
    // Устанавливаем нейспейс для пейджера в этой таблице.
    this.$store.commit('pagerStd/updateActiveTableNamespace', 'pos', { root: true })
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
  },
}
</script>

<style scoped>

</style>
