<template>
    <b-row>
      <b-col>
        <nav-tabs />
        <b-card class="card-small with-nav-tabs">
          <div v-if="showForm">
            <b-row>
              <b-col>
                <label style="cursor: pointer;" class="mr-3" for="notifications_activated">Включить отправку реестров</label>
                <b-form-checkbox inline switch :disabled="isNotificationsCheckboxDisabled"
                v-model="notificationsActivated"
                name="notifications_activated"
                id="notifications_activated"
                style="position: relative; top: 8px;"
                ></b-form-checkbox>
                <div v-if="emailsLimitReached" class="float-right mb-3">Вы достигли максимального количества email для оповещений (10).<br>Для добавления нового адреса необходимо удалить текущие адреса.</div>
                <div v-if="!emailsLimitReached"><b-button :v-if="checkScopePresence('idp:clients:report_emails.write')" variant="outline-primary" class="float-right mb-3" v-b-modal.newemail>Добавить адрес</b-button></div>

                <b-modal no-close-on-backdrop id="newemail" title="Добавить адрес">
                  <template #modal-footer="{ cancel }">
                    <b-button size="sm" variant="outline-secondary" class="m-0 mr-auto" @click="cancel()">
                      Назад
                    </b-button>
                    <b-button size="sm" variant="outline-primary" class="m-0" @click="addNotificationEmail()" :disabled="!allowedNotificationEmailSubmit">Добавить адрес</b-button>
                  </template>
                  <b-input id="add_new_email" name="add_new_email" v-model="newEmail"/>
                </b-modal>

              </b-col>
            </b-row>
            <b-table striped class="text-nowrap" :fields="fields" :items="dataTable">
              <template v-slot:cell(email)="data">
                {{data.item.email}}
              </template>
              <template v-slot:cell(is_active)="data">
                <b-form-checkbox switch disabled
                  v-model="data.item.is_active"
                  :name="`checkboxactive-`+data.item.id"
                >
                </b-form-checkbox>
              </template>
              <template v-slot:cell(actions)="data">
                <b-dropdown :v-if="checkScopePresence('idp:clients:report_emails.write')" text="Действия" variant="primary" right ref="dropdown">
                  <b-dropdown-item v-if="!data.item.is_active" @click="editEmailStatus(data.item, true)">Активировать</b-dropdown-item>
                  <b-dropdown-item v-if="data.item.is_active" @click="editEmailStatus(data.item, false)">Деактивировать</b-dropdown-item>
                  <b-dropdown-item @click="$store.dispatch('notifications/deleteEmail', data.item)">Удалить</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <div v-else style="min-height: 200px">
            <Loader :size="3" />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </template>

  <script>
  import NavTabs from './navTabs'
  export default {
    components:{
      NavTabs
    },
    data(){
      return{
        newEmail: '',
        fields: [
          {
            key: 'email',
            label: 'Почтовый адрес',
          },
          {
            key: 'is_active',
            label: 'Отключен/Активен',
          },
          {
            key: 'actions',
            label: '',
          }
        ]
      }
    },
    computed: {
      notificationsActivated:{
        get(){ return this.$store.state.account.clientData.report_is_active },
        set( val ) {
          let data = {
            is_active: val
          }
          this.$store.dispatch('notifications/switchNotificationsActivated', data)
        }
      },
      emailsLimitReached(){
        if (this.dataTable.length >= 10){return true}
        return false
      },
      dataTable(){
        let emailArray = JSON.parse(JSON.stringify(this.$store.state.account.clientData.report_emails))
        emailArray.sort(this.compareById)
        return emailArray
      },
      isNotificationsCheckboxDisabled(){
        return this.$store.state.notifications.busy
      },
      showForm: function () {
        if (this.$store.state.account.clientDataReceived && !this.$store.state.account.clientDataEmpty) {
          return true
        }
        return false
      },
      allowedNotificationEmailSubmit(){
        return this.isValidEmail(this.newEmail)
      }
    },
    methods:{
      compareById(a, b){
        return b.id - a.id;
      },
      editEmailStatus(item, status){
        let data = {
          item: item,
          status: status
        }
        this.$store.dispatch('notifications/editEmailStatus', data)
      },
      addNotificationEmail(){
        let data = {
          email: this.newEmail
        }
        this.$store.dispatch('notifications/addNewEmail', data)
        this.newEmail = ''
        this.$bvModal.hide("newemail")
      }
    }

  }
  </script>
