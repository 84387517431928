export default {
  namespaced: true,
  state: {
    partnersDataReceived: false,
    partner_rewards:[],
  },
  mutations: {
    updatePartnerRewards (state, PRData) {
      state.partner_rewards = PRData;
    },
    togglePartnersDataReceived (state, value) {
      state.partnersDataReceived = value;
    },
  },
  actions: {
    prepareFilterByString (context){
      let filterByString = '';
      if (context.rootState.filtersStd.dateFrom){
        filterByString += '&reward_date>='+context.rootState.filtersStd.dateFrom;
      }
      if (context.rootState.filtersStd.dateTo){
        filterByString += '&reward_date<='+context.rootState.filtersStd.dateTo;
      }
      if (context.rootState.filtersStd.service){
        filterByString += '&mandarin_service in '+context.rootState.filtersStd.service
      }
      if (context.rootState.filtersStd.clientId){
        filterByString += '&referral_client_id='+context.rootState.filtersStd.clientId
      }

      //очищаем первый &
      if (filterByString && filterByString.charAt(0)==='&'){
        filterByString = filterByString.substring(1)
      }
      // filterByString = encodeURIComponent(filterByString)
      context.commit('filtersStd/updateFilterByString', filterByString, {root: true})
    },
    refreshTable (context, queryObject) {
      let url = '/partner_rewards/v1/partner_rewards';

      if (queryObject && queryObject.type === 'cursor'){
        url += '?cursor='+queryObject.params.cursor
      }
      else{
        let limit = 'limit_to='+ queryObject.params.limit_to
        url += '?' + limit

        context.dispatch('prepareFilterByString')

        let filterByString = context.rootState.filtersStd.filterByString
        if (filterByString){
          url += '&' + 'filter_by='+encodeURIComponent(filterByString)
        }
      }
      this.$axios.get(url)
        .then(response => {
          let cursor = response.data.cursor
          let partners = response.data.partner_rewards
          context.commit('pagerStd/updateCursor', cursor, { root: true })
          context.commit('pagerStd/updateApiResponseReceived', true, { root: true })
          //Если в запросе передаётся currentOffset, используем его, если нет - ставим 0.
          if (typeof queryObject === 'object' && queryObject !== null && queryObject.hasOwnProperty('currentOffset')){
            context.commit('pagerStd/updateCurrentOffset', queryObject.currentOffset, { root: true })
          }
          else{
            context.commit('pagerStd/updateCurrentOffset', 0, { root: true })
          }
          context.commit('updatePartnerRewards', partners)

        })
        .catch(error => {
          console.log('error in promise', error)
          context.commit('updatePartnerRewards', [])
        })
    },
  },
}
