const filters_config = {
    instrument_type: {
      show: true,
      options: [
        { value: '1', label: 'Банковская карта', selected: true }
      ]
    },
    mw_type: {
      show: false,
      options: [
        { value: 'binding'}
      ]
    },
    status:{
      show: true,
      options: [
        { value: 'success', label: 'Успешно', selected: true},
        { value: 'fail,timeout', label: 'Ошибка'},
        { value: 'wait,wait_internal,wait_3ds,wait_external,revise_require', label: 'Обработка' }
      ]
    },
    opcodes: {
      show: true,
      options: [
        { value: '1', label: 'Оплата' },
        { value: '2', label: 'Возврат' },
        { value: '4', label: 'Предавторизация' },
        { value: '5', label: 'Отмена авторизации' }
      ]
    }
  }

  export default filters_config
