<template>
  <b-row>
    <b-col cols="9">
      <NavTabs/>
      <b-card class="card-small with-nav-tabs">
      <Filters  v-if="!isUploadMode" />
      <div class="lifebroker" v-if="!isUploadMode">
        <PagerComponent/>
      </div>
      <Loader v-if="loadingData" :height="600" :size="3" />
      <div class="main-table-wrapper" v-else-if="!isUploadMode">
        <b-table striped hover selectable select-mode="single" class="text-nowrap" :fields="fields" :items="dataTable" @row-clicked="selectItem">
          <template v-slot:head()="data">
            <span v-html="data.field.label"></span>
          </template>
          <template v-slot:cell(application_id)="data">
            <clipboard v-if="data.item.customer.fio" :data="data.item.customer.fio"/>&nbsp;{{data.item.customer.fio | formatFIO}}<br>
            <clipboard :data="data.item.contract_number"/>&nbsp;{{data.item.contract_number}}
          </template>
          <template v-slot:cell(customer)="data">
            <clipboard v-if="data.item.customer.phone" :data="data.item.customer.phone"/>&nbsp;{{data.item.customer.phone}}<br>
            <clipboard v-if="data.item.customer.email" :data="data.item.customer.email"/>&nbsp;{{data.item.customer.email}}
          </template>
          <template v-slot:cell(purchase_amount)="data">
            <span>{{properM(data.item.purchase_amount)}} <span v-html="currency(data.item.currency_id)"></span></span>
            <br>
            <span>{{data.item.cr_ras}} на {{data.item.loan_term}} мес</span>
          </template>
          <template v-slot:cell(status)="data">
            <span>{{properDT_HL(data.item.created)}}</span>
            <br>
            <span v-html="getStatusBadge(data.item.status.status_code)"></span>
          </template>
          <template v-slot:cell(actions)="data">
            <b-dropdown  variant="primary" style="min-width: 100px;" no-caret>
              <template v-slot:button-content>
                <b-spinner v-if="loadingDownloads[data.item.application_id]" small type="border" />
                <span v-else>Действия</span>
              </template>
              <b-dropdown-item :disabled="checkStatus_send(data.item.status.status_code, 'open')" @click="downloadAppDocsByPdf(data.item.application_id)">Скачать документы</b-dropdown-item>
              <b-dropdown-item :disabled="checkStatus_send(data.item.status.status_code)" @click="triggerUploadMode(data.item)">Отправить сканы на проверку</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div v-if="!haveResults" style="min-height: 400px; text-align: center; padding-top: 150px;">
          Данные за указанный период с учетом выбранных фильтров отсутствуют.
        </div>
      </div>
      <div v-else>
        <FileUploader @closeUploadMode="closeUploadMode()" :item="select_item" />
      </div>
      
      </b-card>
    </b-col>
    <b-col cols="3" class="fixed-extend extend-with-nav-tabs">
      <Extend :item="extendItem"/>
    </b-col>
  </b-row>
</template>

<script>
import PagerComponent from '@/components/Pager/pagerComponent'
import Filters from './Filters/Filters'
import getStatusBadge from '@/configs/filtersTables/labels_lifeBroker'
import NavTabs from './../navTabs'
import Extend from './extendBroker'
import FileUploader from './FileUploader.vue';

export default {
  components: {
    PagerComponent,
    Filters,
    NavTabs,
    Extend,
    FileUploader
  },
  data(){
    return{
      select_item: {},
      isUploadMode: false,
      loadingDownloads: {},
      extendItem: false,
      filters_config: null,
      transaction: null,
      fields: [
        {
          key: 'application_id',
          label: 'ФИО клиента<br>Номер заявки'
        },
        {
          key: 'customer',
          label: 'Моб. телефон клиента<br>E-mail клиента'
        },
        {
          key: 'purchase_amount',
          label: 'Сумма заявки<br>Тип и срок заявки'
        },
        {
          key: 'status',
          label: 'Дата создания заявки<br>Текущий статус'
        },
        {
          key: 'actions',
          label: ''
        }
      ]
    }
  },
  computed:{
    dataTable: function () {
      let dataTable = this.$store.state.lifeBroker.transactions
      console.log(this.filters_config)
      dataTable = dataTable.map(item => {
          return {
            ...item,
            customer: (item.customer === null)?
            {fio: '', phone: '', email: ''}:
                    Object.assign(item.customer, {fio: item.customer.last_name + ' ' + item.customer.name + ' ' + item.customer.patronymic}),
            status: (item.status === null)?
            {status_code: 'A0', merch_status_description: null}:
                    item.status,
            merch_status_description: this.getMerchStatusDescription(item),
            currency_id: (item.hasOwnProperty('currency_id'))?item.currency_id:643,
            purchase_amount: Number(item.purchase_amount),
            cr_ras: (item.max_discount.calc_discount7Seconds)?'Рассрочка':'Кредит',
            offer: (item.offer === null)?
            {settlement_amount: null, lender_name: null, lender_agreement_number: null, maturity_in_months: null}:
                    item.offer,
            order: (item.order === null)?
            {settlement_order_id: null, order_description: null}:
                    item.order
          }
      })
      return dataTable
    },
    loadingData(){
      return !this.$store.state.pagerStd.apiResponseReceived
    },
    haveResults(){
      if (Array.isArray(this.dataTable) && this.dataTable.length>0){
        return true
      }
      return false
    }

  },
  watch: {
    loadingData: function (newValue, oldValue) {
      if (newValue){
        this.extendItem = false

      }
    }
  },
  methods:{
    getMerchStatusDescription: function(item) {
      if (!item || !item.status || !this.filters_config || !this.filters_config.status || !this.filters_config.status.codes) {
        return null;
      }
      const codeInfo = this.filters_config.status.codes[item.status.status_code];
      return codeInfo ? codeInfo[1] : null;
    },
    triggerUploadMode(item) {
      this.select_item = item;
      this.selectItem(item);
      this.isUploadMode = true;
    },
    checkStatus_send(statusCode, button = 'document') {
      let codes = ['L13', 'L7', 'L9', 'C3', 'L10'];
      if (button === 'open') codes = ['R5', 'C0', 'P0', 'L11', 'A16', 'A13', 'A10', 'A4', 'R8', 'C2', 'A11', 'L13', 'L7', 'L9', 'C3', 'L10', 'L4', 'V1', 'P2'];
      return !codes.includes(statusCode);
    },
    selectItem(item, index){
      item.extStatus = this.filters_config.status.codes[item.status.status_code]
      this.extendItem = item
    },
    getStatusBadge(status){
      return getStatusBadge(this.filters_config, status)
    },
    async OpenLoan(applicationId) {
      this.$set(this.loadingDownloads, applicationId, true)
      try {
        await this.$store.dispatch('lifeBroker/OpenLoan', applicationId)
        this.$toast.success('Ссуда открыта');
        this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
      } catch (error) {
        if (error.errors) {
          Object.values(error.errors).forEach(errMessage => {
            this.$toast.error(errMessage);
          });
        } else if (error.Errors) {
          error.Errors.forEach(errObj => {
            this.$toast.error(errObj.ErrorDescription);
          });
        } else if (error.Message) {
          this.$toast.error(error.Message);
        } else {
          this.$toast.error('Failed to open loan');
        }
      } finally {
        this.$set(this.loadingDownloads, applicationId, false)
      }
    },
    async downloadAppDocsByPdf(applicationId) {
      this.$set(this.loadingDownloads, applicationId, true)
      try {
        await this.$store.dispatch('lifeBroker/downloadAppDocsByPdf', applicationId)
      } catch (error) {
        this.$toast.error('Failed to download documents', error)
      } finally {
        this.$set(this.loadingDownloads, applicationId, false)
      }
    },
    closeUploadMode() {
      this.isUploadMode = false;
      this.$store.dispatch('pagerStd/updateCurrentTableState', 'default');
    }
  },
  created(){
    // Чтобы остатки фильтров не оставались после предыдущих страниц, очищаем фильтры. Делать это надо до установки новых.
    this.$store.commit('filtersStd/clearFilters')
    // Устанавливаем, какой из конфигов фильтров из config/filtersTables используется в этой таблице.
    this.$store.commit('filtersStd/setActiveFilterConfig', 'filtersLifeBroker', { root: true })
  },
  mounted () {
    this.filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
    // Устанавливаем нейспейс для пейджера в этой таблице.
    this.$store.commit('pagerStd/updateActiveTableNamespace', 'lifeBroker', { root: true })
    // после установки таблицы апдейтим ее нынешнее значение. К этому моменту все фильтры должны быть установлены.
    this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
  },
}
</script>

<style>
    div.lifebroker button#exportButton {
      display: none;
    }
</style>

<style scoped>
    table >>> .b-table-row-selected {
      background-color: rgba(189, 214, 320, 1) !important;
    }
</style>
