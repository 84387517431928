<template>
  <div>
    <multiselect v-if="showFilterMids" v-model="selectedValues" :options="mids" :multiple="true"
                 track-by="value" label="value" @input="selectChanged"
                 deselect-label="Удалить" select-label="Добавить" selected-label="Опция выбрана"
                 placeholder="ID Проекта">
      <template #option="props">
        <span>
          {{ props.option.label }}
        </span>
      </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      selectedValues: null,
    }
  },
  computed: {
    resetAllFilters() {
      return this.$store.state.filtersStd.resetAllFilters
    },
    showFilterMids() {
      return this.$store.state.account.clientData !== undefined && this.$store.state.account.clientData.merchants !== undefined;

    },
    mids() {
      const processedSites = this.$store.state.account.clientData.processedSites || [];
      let options = processedSites.map(site => ({
        value: site.merchant_id,
        label: `${site.merchant_id}${site.name ? ` - ${site.name}` : ''}`
      }));
      options.sort(this.compareByMid)
      return options
    }
  },
  watch: {
    resetAllFilters(newCount, oldCount) {
      this.selectedValues = null
      this.$nextTick(() => {
        this.$store.commit('filtersStd/resetAllFilters', 'ready')
      })
    }
  },
  created() {
  },
  methods: {
    compareByValue(a, b) {
      return a.value - b.value;
    },
    selectChanged() {
      let valueString = this.selectedValues.map(item => item.value).join(',')
      this.$store.commit('filtersStd/updateMids', valueString)
    }
  }
}
</script>
