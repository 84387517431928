import { render, staticRenderFns } from "./pager.vue?vue&type=template&id=8870627e&scoped=true"
import script from "./pager.vue?vue&type=script&lang=js"
export * from "./pager.vue?vue&type=script&lang=js"
import style0 from "./pager.vue?vue&type=style&index=0&id=8870627e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8870627e",
  null
  
)

export default component.exports