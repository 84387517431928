// Этот файлик определяет, при каких условиях в транзакциях надо показывать возможность повторной отправки коллбэка

export default {
  methods: {
    showRenotifyOption(item){
      if (item.instrument_type_id === 801){
        return false
      }
      return true
    },
  }
}