<template>
    <div class="">
      <b-form-input
        v-model="numberOrder"
        placeholder="Номер заказа"
      >
      </b-form-input>
    </div>
</template>

<script>
export default {
  computed: {
    numberOrder: {
      get: function () { return this.$store.state.filtersStd.numberOrder },
      set: function (newValue) {
        this.$store.commit('filtersStd/updateNumberOrder', newValue)
      }
    }
  },
  methods: {

  }
}
</script>
