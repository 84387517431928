<template>
  <div id="sandbox" :class="sandboxClasses" v-if="isSandbox">
    <div class="wrapper">
      <div class="badge-sandbox" v-b-tooltip.hover.html="tooltipSandbox">Тестовый режим
        <b-form-checkbox class="chb-sandbox" v-model="sandbox" disabled name="chb-sandbox" switch></b-form-checkbox>
        <a id="link-wrapper-other" :href="pathToOnboardingWithToken"></a>
      </div>
      <div class="check" v-b-tooltip.hover.html="tooltipSandbox">
        <span class="text">Режим тестирования</span>
        <b-form-checkbox class="chb-sandbox" v-model="sandbox" disabled name="chb-sandbox" switch></b-form-checkbox>
        <a id="link-wrapper" :href="pathToOnboardingWithToken"></a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return{
      sandbox: true
    }
  },
  computed: {
    isSandbox(){
      return this.$store.state.account.mandarin.is_sandbox
    },
    tooltipSandbox(){
      if (this.$store.state.account.mandarin.onboarding_status === 'wait_client_data'){
        return '<div class="ob-tooltip-wrapper"><span class="onboarding-tooltip">Этот аккаунт находится в тестовом режиме. <br>Пожалуйста, <a href=' + this.pathToOnboardingWithToken + '>активируйте свою учётную запись</a>.</span></div>'
      }
      if (this.$store.state.account.mandarin.onboarding_status === 'wait_approval'){
        return '<div class="ob-tooltip-wrapper"><span class="onboarding-tooltip">Ваша анкета на рассмотрении, ожидайте решения.</span></div>'
      }
      return "test"
    },
    pathToOnboardingWithToken() {
      if (this.$store.state.account.mandarin.onboarding_status === 'wait_approval'){return "#"}
      let anketa_token = this.$store.state.account.accessToken
      let client_id = this.$store.state.account.mandarin.client_id
      let user_id = this.$store.state.account.mandarin.id
      return this.removeTrailingSlash(VUE_APP_ONBOARDING_URL)+'/questionnaire?anketa_token=' + anketa_token 
      + '&client_id=' + client_id + '&user_id=' + user_id
    },
    sandboxClasses(){
      if (!this.$store.state.account.mandarin.onboarding_status){return ''}
      if (this.$store.state.account.mandarin.onboarding_status === 'wait_client_data'){return "wait-client-data"}
      if (this.$store.state.account.mandarin.onboarding_status === 'wait_approval'){return "wait-approval"}
      return this.$store.state.account.mandarin.onboarding_status
    }
  }
}
  
</script>

<style lang="scss" scoped>
  #sandbox{
    padding-left: 15px;
    position: relative;
    .wrapper{
      border-top: #FFA028 solid 1px;
    }
    .badge-sandbox{
      background-color: #FFA028;
      margin-left: auto;
      margin-right: auto;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      width: 180px;
      line-height: 28px;
      color: #ffffff;
      font-size: 13px;
      text-align: center;
      position: relative;
      left: -80px;
      &>div{
        display: inline-block;
        margin-left: 10px;
        margin-right: -10px;
      }
    } 
    .check{
      position: absolute;
      right: 10px;
      top: 0px;
      color: #FFA028;
      .text{
        margin-right: 10px;
      }
      .chb-sandbox{
        display: inline-block;
        
      }
      
    }
    &.wait-approval{
      .wrapper{
        border-color: #a1a1a1;
      }
      .badge-sandbox{
        background-color: #a1a1a1;
      }
      .check{
        color: #a1a1a1;
      }
    }
  }
  ::v-deep .check label.custom-control-label::before{
    background-color: #FFA028 !important;
  }
  ::v-deep .badge-sandbox label.custom-control-label::before{
    background-color: #FFFFFF !important;
  }
  ::v-deep .badge-sandbox label.custom-control-label::after{
    background-color: #FFA028 !important;
  }  
  ::v-deep .tooltip-inner .ob-tooltip-wrapper{
    text-align: left;
  }
  ::v-deep .onboarding-tooltip{
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.26px;
    a{
      font-weight: 500;
    }
  }
  #link-wrapper{
    position: absolute;
    width: 190px;
    height: 26px;
    left: 0px;
    top: 0px;
    z-index: 100;
  }
  #link-wrapper-other{
    position: absolute;
    width: 180px;
    height: 28px;
    left: 0px;
    top: 0px;
    z-index: 100;
  }
  .wait-approval{
    #link-wrapper{
      display: none;
    }
  }
</style>