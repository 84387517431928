<template>

  <div class="cell-wrapper">
    <!--<d-dropdown text="выбрать" variant="primary" right ref="dropdown" @toggle="toggle">
      <b-dropdown-item @click="refundHandler(item)">Выполнить возврат</b-dropdown-item>
    </d-dropdown>-->
  </div>

</template>

<script>
export default {
  props: ['item'],
  methods: {
    refundHandler(item){
      alert('К сожалению, возвраты в данный момент находятся в процессе разработки')
    },
    toggle(event){
  
    }
  }
}
</script>