<template>
  <div class="cell-wrapper">
    <div v-if="item.is_accepted">
      <b-form-checkbox class="chb-green" disabled v-model="item.is_accepted" name="check-button" switch>
        {{properDT(item.accepted_at)}}
      </b-form-checkbox>
    </div>
    <div v-else>
      <b-form-checkbox button-variant="outline-secondary" disabled v-model="item.is_accepted" name="check-button" switch></b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item']

}
</script>

<style>
.checkbox-docs .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #41b883;
}
.checkbox-docs .custom-control-input:disabled ~ .custom-control-label{
  color: #23282c;
}

</style>
