<template>
<b-container fluid>
  <b-row><b-col>
  <footer class="card main-footer d-flex py-2 bg-white">
    <b-container fluid>
      <b-row>
        <b-col>
          <ul class="links">
            <li class=""><a target="_blank" href="https://mandarin.io/conditions">Условия</a></li>
            <li class=""><a target="_blank" href="https://mandarin.io/security">Безопасность</a></li>
            <li class=""><a target="_blank" href="https://docs.mandarin.io">Интеграция</a></li>
            <li class=""><a target="_blank" href="https://mandarin.io/contacts">Контакты</a></li>
          </ul>
          <ul class="social-links">
            <li class=""><a target="_blank" href="https://t.me/mandarin_platform"><img src="@/assets/images/tg.svg" style="width: 24px;"></a></li>
            <li class=""><a target="_blank" href="https://vk.com/mandarin_pay"><img src="@/assets/images/vk.svg" style="width: 24px;"></a></li>
            <li class=""><a target="_blank" href="https://www.youtube.com/channel/UCiKCZXLdq_nO_sRX577NI6Q"><img src="@/assets/images/youtube.svg" style="width: 24px;"></a></li>
            <li class=""><a target="_blank" href="http://linkedin.com/company/mandarinpay/"><img src="@/assets/images/lnkdin.svg" style="width: 24px;"></a></li>
          </ul>

        </b-col>

      </b-row>
      <hr style="margin-top: 7px;">
      <b-row>
        <b-col md="4" class="">
          <div class="year" style="line-height: 32px;">© 2016-{{currentYear}} Mandarin</div>
        </b-col>
<!--        <b-spacer></b-spacer>-->
        <b-col>
          <PayLogos/>
        </b-col>
      </b-row>
    </b-container>
  </footer>
  </b-col></b-row>
  </b-container>
</template>

<script>
import PayLogos from '@/components/Layout/MainFooter/PayLogos.vue'

export default {
  name: 'main-footer',
  components: {
    PayLogos
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.links{
  padding: 0px;
  margin: 0px;
  display: inline-block;
}
.social-links{
  display: inline-block;
  padding-left: 0px;
  margin-bottom: 0px;
}
li{
  margin-right: 38px;
  display: inline-block;
  padding: 0;
  list-style: none;
}
.social-links li{
  margin-right: 20px;
}
a{
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  text-decoration: none;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
}
a:hover{
  text-decoration: underline;
}
.text-right{
  text-align: right;
}
.main-footer{
  height: auto;
}
.year{
  color: #000;

  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
}
</style>
