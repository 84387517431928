<template>
  <div id="client-info" v-if="!clientDataEmpty">
    <clipboard :data="'CID: '+$store.state.account.mandarin.client_id+' ИНН: ' + INN + ' ' + companyName"/>&nbsp;&nbsp;
    CID:&nbsp;<span style="cursor: pointer;" @click="clipboardHandler($store.state.account.mandarin.client_id)">{{ $store.state.account.mandarin.client_id }}</span>
    &nbsp;&nbsp;ИНН:&nbsp;<span style="cursor: pointer;" @click="clipboardHandler(INN)">{{ INN }}</span>
    &nbsp;&nbsp;<span style="cursor: pointer;" @click="clipboardHandler(companyName)">{{ companyNameShort }}</span>

  </div>
</template>

<script>
  export default{
    computed: {
      companyName(){
        if (!Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData, 'legal_info') ||
        !Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info, 'legal_name')){
          return ""
        }
        return this.$store.state.account.clientData.legal_info.legal_name
      },
      companyNameShort(){
        if (!this.companyName){return ''}
        const count = 38
        let result = this.companyName.slice(0, count) + (this.companyName.length > count ? "..." : "")
        return result
      },
      INN(){
        if (!Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData, 'legal_info') ||
        !Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info, 'legal') || 
        !Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info.legal, 'id')
        ){
          return ""
        }
        return this.$store.state.account.clientData.legal_info.legal.id
      },
      clientDataEmpty () { return this.$store.state.account.clientDataEmpty }
    },
    methods: {
      clipboardHandler (value) {
        this.$clipboard(value)
        this.$toast.success("Скопировано: "+value);
      }
    }
  }
</script>

<style scss>
#client-info{
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 38px;
  right: 0;
  font-size: 12px;
  z-index: 110;
  font-weight: 400;
}

</style>
