<template>

  <div class="cell-wrapper">
    <d-dropdown text="Действия" variant="primary" right ref="dropdown" @toggle="toggle">
      <b-dropdown-item v-if="hasPermission(item.action)"><router-link :to="item.pathTo">Изменить настройки</router-link></b-dropdown-item>
      <b-dropdown-item v-if="item.action === 'pay'" @click='open(item.link)'>Создать счёт</b-dropdown-item>
      <b-dropdown-item v-if="item.action === 'payout'" @click='registry_payment(item.merchant_id)'>Выплатить по реестру</b-dropdown-item>
      <b-dropdown-item v-if="hasPermission(item.action)" @click='toogle_is_active(item)'>{{ item.is_active?'Деактивировать':'Активировать' }}</b-dropdown-item>
    </d-dropdown>
  </div>

</template>

<script>
export default {
  props: ['item', 'registryPayment', 'toogleIsActive'],
  methods: {
    hasPermission(action) {
      if(action === 'pay') {
        return this.$store.state.account.scopesArray.includes('secure_app_pay_module.write');
      } else if(action === 'payout') {
        return this.$store.state.account.scopesArray.includes('secure_app_payout_module.write');
      } else if (action === 'life') {
        return this.$store.state.account.scopesArray.includes('secure_app_life_module.write')
      }
      return false;
    },
    edit(item){
      window.location=item;
    },
    toggle(event){
      console.log('toggle');
    },
    open(link){
      window.open(link + '&bill=true');
    },
    registry_payment(merchant_id) {
      this.$emit('registryPayment', merchant_id)
    },
    toogle_is_active(item) {
      this.$emit('toogleIsActive', item)
    }
  }
}
</script>

<script setup>
</script>
