<template>
  <div v-if="showFilter">
    <multiselect v-model="selectedValues" :options="opcodesOptions" :multiple="true"
     track-by="label" label="label" @input="selectChanged"
    deselect-label="Удалить" select-label="Добавить" selected-label="Опция выбрана" 
    placeholder="Тип (opcode)"></multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      selectedValues: null
    }
  },
  computed: {
    showFilter(){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      if (Object.prototype.hasOwnProperty.call(filters_config.opcodes, 'show')){
        return filters_config.opcodes.show
      }
      return false
    },
    resetAllFilters () {
      return this.$store.state.filtersStd.resetAllFilters
    },
    opcodesOptions(){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return filters_config.opcodes.options
    }
  },
  watch: {
    resetAllFilters (newCount, oldCount) {
      this.selectedValues = null
      this.$nextTick(() => {
        this.$store.commit('filtersStd/resetAllFilters', 'ready')
      })
    }
  },
  created(){
    // В этой точке мы забираем дефолтные значения фильтра из файла конфига. 
    let selected = []
    let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
    filters_config.opcodes.options.forEach(function(value){
      if (Object.prototype.hasOwnProperty.call(value, 'selected') && value.selected){
        selected.push(value)
      }
    })
    this.selectedValues = selected;
  },
  mounted(){
    this.selectChanged()
  },  
  methods:{
    selectChanged () {
      let opcodesString = ''
      this.selectedValues.forEach(item => {
        opcodesString += item.value+','
      })
      //убираем последнюю запятую из строки
      opcodesString = opcodesString.substring(0, opcodesString.length - 1)
      this.$store.commit('filtersStd/updateOpcodes', opcodesString)
    }
  }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>