<template>
  <b-row>
    <b-col cols="9">
      <b-card id="receipts" class="card-small">
      <Filters/>
      <PagerComponent/>
      <Loader v-if="loadingData" :height="600" :size="3" />
      <div class="main-table-wrapper" v-else>
        <b-table striped hover selectable select-mode="single" class="text-nowrap" :fields="fields" :items="dataTable" @row-clicked="selectItem">
          <template v-slot:head()="data">
            <span v-html="data.field.label"></span>
          </template>
          <template v-slot:cell(cs1)="data">
            <clipboard :data="data.item.mandarinId"/>&nbsp;{{data.item.mandarinId}}
            <div v-if="data.item.extraData.FiscalDocument">
              <clipboard :data="data.item.extraData.FiscalDocument"/>&nbsp;{{data.item.extraData.FiscalDocument}}
            </div>
          </template>
          <template v-slot:cell(customer)="data">
            <clipboard v-if="data.item.customerPhone" :data="data.item.customerPhone"/>&nbsp;{{data.item.customerPhone}}<br>
            <clipboard v-if="data.item.customerEmail" :data="data.item.customerEmail"/>&nbsp;{{data.item.customerEmail}}
          </template>
          <template v-slot:cell(rrn)="data">
            <div v-if="data.item.extraData.Request &&
              data.item.extraData.Request.items &&
              data.item.extraData.Request.items[0] &&
              data.item.extraData.Request.items[0].calculationMethod">
              {{getCalculationMethodName(data.item.extraData.Request.items[0].calculationMethod)}}
            </div>
            <br v-else>
            {{getActionType(data.item.actionType)}}
          </template>
          <template v-slot:cell(mid)="data">
            {{data.item.merchantId}}<br>
            {{properDT(data.item.createdAt)}}
          </template>
          <template v-slot:cell(amount)="data">
            {{data.item.extraData.FiscalAmount ? properM(data.item.extraData.FiscalAmount) : properM(data.item.amount)}}<br>
            <span v-html="getStatusBadge(data.item.mandarinStatus)"></span>
          </template>
          <template v-slot:cell(actions)="data">
            <b-dropdown text="Действия" variant="primary" right ref="dropdown">
              <b-dropdown-item
                @click="printReceipt({dk: (data.item.providerType === 'DigitalKassa' ? data.item.providerId : null ), other: (data.item.extraData.Response ? data.item.extraData.Response.Raw : null)})"
                :disabled="(!data.item.extraData.Response || !data.item.extraData.Response.Raw) && data.item.providerType !== 'DigitalKassa'"
              >
                Распечатать чек
              </b-dropdown-item>
<!--              <b-dropdown-item @click="linkOldAdminGeneratedBlank(data.item.cs1, '1')">Справка по транзакции (бланк)</b-dropdown-item>-->
            </b-dropdown>
          </template>
        </b-table>
        <div v-if="!haveResults" style="min-height: 400px; text-align: center; padding-top: 150px;">
          Данные за указанный период с учетом выбранных фильтров отсутствуют.
        </div>
      </div>

      </b-card>
    </b-col>
    <b-col cols="3" class="fixed-extend">
      <ExtendReceipts :item="extendItem"/>
    </b-col>
  </b-row>
</template>

<script>
import PagerComponent from '@/components/Pager/pagerComponent'
import Filters from './Filters/Filters'
import ExtendReceipts from './extendReceipts'
import { getStatusBadge } from '@/configs/filtersTables/labels'

export default {
  components: {
    PagerComponent,
    Filters,
    ExtendReceipts
  },
  data(){
    return{
      isLoading: false,
      extendItem: false,
      fields: [
        {
          key: 'cs1',
          label: 'ID платежа/заявки<br>ID фиск. док.',
        },
        {
          key: 'customer',
          label: 'Телефон клиента<br>Почта клиента',
        },
        {
          key: 'rrn',
          label: 'Метод расчета<br>Тип чека',
        },
        {
          key: 'mid',
          label: 'ID проекта<br>Дата и время',
        },
        {
          key: 'amount',
          label: 'Сумма<br>Статус',
        },
        {
          key: 'actions',
          label: '',
        },
      ]
    }
  },
  computed:{
    dataTable(){
      return this.$store.state.receipts.receipts
      // return []
    },
    loadingData(){
      return !this.$store.state.pagerStd.apiResponseReceived
      // return false
    },
    haveResults(){
      if (Array.isArray(this.dataTable) && this.dataTable.length>0){
        return true
      }
      return false
    }
  },
  watch: {
    loadingData: function (newValue, oldValue) {
      if (newValue){
        this.extendItem = false

      }
    }
  },
  methods:{
    convertToDate(timestamp) {
      return new Date(timestamp).toISOString();
    },
    printReceipt(content) {
      if(!content.dk) {
        if (!content.other) return;
      }

      if (content.dk) {
        window.open('https://crm.digitalkassa.ru/show/1/' + content.dk, '_blank');
        return;
      }

      const printWindow = window.open('', '_blank');

      printWindow.document.open();
      printWindow.document.write(`
      <html>
        <head>
          <title>Print Receipt</title>
          <style>
            @page { size: A4 portrait; margin: 20mm; }
            body { font-size: 12px; }
            pre { white-space: pre-wrap; }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          <pre>${content.other}</pre>
        </body>
      </html>
    `);
      printWindow.document.close();
    },
    selectItem(item, index){
      this.extendItem = item
    },
    displayLabel(filter_name, current_value){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getLabel(filters_config, filter_name, current_value)
    },
    getStatusBadge(status){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getStatusBadge(filters_config, status)
    },
    getCalculationMethodName(method) {
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig];
      if (filters_config && filters_config.replaceMethod && filters_config.replaceMethod[method]) {
        return filters_config.replaceMethod[method];
      }
      return method;
    },
    getActionType(method) {
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig];
      if (filters_config && filters_config.actionType && filters_config.actionType[method]) {
        return filters_config.actionType[method];
      }
      return method;
    }
  },
  created(){
    // Чтобы остатки фильтров не оставались после предыдущих страниц, очищаем фильтры. Делать это надо до установки новых.
    this.$store.commit('filtersStd/clearFilters')
    // Устанавливаем, какой из конфигов фильтров из config/filtersTables используется в этой таблице.
    this.$store.commit('filtersStd/setActiveFilterConfig', 'filtersReceipts', { root: true })
  },
  mounted () {
    // Устанавливаем нейспейс для пейджера в этой таблице.
    this.$store.commit('pagerStd/updateActiveTableNamespace', 'receipts', { root: true })
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
  },
}
</script>

<style scoped>

</style>

<style>

</style>
