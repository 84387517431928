<template>
  <b-row class="pager mb-3">
    <b-col col xl="2"><Pager v-if="$store.state.pagerStd.apiResponseReceived" /></b-col>
    <b-col col><PagerTotal v-if="$store.state.pagerStd.apiResponseReceived" /></b-col>
    <b-col col xl="1" style="min-width: 174px;"><WidgetExport /></b-col>
    <b-col col xl="1" style="min-width: 92px;" class="page-options"><PageOptions /></b-col>
  </b-row>
</template>



<script>

import Pager from '@/components/Pager/pager'
import PageOptions from '@/components/Pager/pageOptions'
import PagerTotal from '@/components/Pager/pagerTotal'

export default {
  components: {
    Pager,
    PageOptions,
    PagerTotal
  }
}


</script>