// Модуль описывает получение данных относящихся к вознаграждениям.

// /api/v3/lead_reward/recipients/
import router from '@/router'
export default {
  namespaced: true,
  state: {
    rewardsRecipients: {},
    rewardsRecipientsActive: {},
    rewardsRecipientsActiveReceived: false,
    rewardsRecipientsEmpty: true,
    rewardsRecipientsReceived: false,
    showRecipientDetailsModal: false,
    showRecipientDetailsModalState: 'display',
    currentRewardsRecipient: {},
    currentRewardsRecipientAdditionalFields: {
      bankname: '',
      bankcity: '',
      bankcorcount: '',
      opf: '',
      address: ''
    },
    urls: {
      recipients: '/life-backend/api/v3/lead_reward/recipients/'
    }
  },
  mutations: {
    toggleRewardsRecipientsEmpty (state, value) {
      state.rewardsRecipientsEmpty = value
    },
    toggleRewardsRecipientsReceived (state, value) {
      state.rewardsRecipientsReceived = value
    },
    toggleRewardsRecipientsActiveReceived (state, value) {
      state.rewardsRecipientsActiveReceived = value
    },
    toggleShowRecipientDetailsModal (state, value) {
      state.showRecipientDetailsModal = value
    },
    updateShowRecipientDetailsModalState (state, value) {
      state.showRecipientDetailsModalState = value
    },
    updateRewardsRecipients (state, value) {
      state.rewardsRecipients = value
    },
    updateRewardsRecipientsActive (state, value) {
      state.rewardsRecipientsActive = value
    },
    updateCurrentRewardsRecipient (state, value) {
      state.currentRewardsRecipient = value
    },
    updateCurrentRewardsRecipientAdditionalFields (state, data) {
      Object.keys(data).forEach(function (key) { state.currentRewardsRecipientAdditionalFields[key] = data[key] })
    },
    cleanCurrentRewardsRecipient (state) {
      state.currentRewardsRecipient = {}
      state.currentRewardsRecipientAdditionalFields = {
        bankname: '',
        bankcity: '',
        bankcorcount: '',
        opf: '',
        address: ''
      }
    }
  },
  actions: {
    loadRewardsRecipients (context) {
      context.commit('toggleRewardsRecipientsEmpty', true)
      context.commit('toggleRewardsRecipientsReceived', false)
      let url = context.state.urls.recipients
      let params = {}
      this.$axios.get(url, {
        params: params
      })
        .then(response => {
          // Обрезаем все лишнее что пришло в ответе
          response = response.data
          context.commit('toggleRewardsRecipientsReceived', true)
          if (response.lead_reward_recipients.length === 0) {
            context.commit('toggleRewardsRecipientsEmpty', true)
          } else {
            context.commit('toggleRewardsRecipientsEmpty', false)
            context.commit('updateRewardsRecipients', response.lead_reward_recipients)
          }
        })
        .catch(error => {
          console.log('error in promise', error)
        })
    },
    // Пожалуй, имеет смысл выделить эту штуку в отдельный экшн
    loadRewardsRecipientsActive (context) {
      context.commit('toggleRewardsRecipientsActiveReceived', false)
      let url = context.state.urls.recipients
      let filterByString = encodeURIComponent('is_active=true')
      let params = { filter_by: filterByString }
      this.$axios.get(url, {
        params: params
      })
        .then(response => {
          // Обрезаем все лишнее что пришло в ответе
          response = response.data
          context.commit('toggleRewardsRecipientsActiveReceived', true)
          context.commit('updateRewardsRecipientsActive', response.lead_reward_recipients)
        })
        .catch(error => {
          console.log('error in promise', error)
        })
    },

    saveEditedRewardsRecipient (context) {
      // первым делом берем ныне редактируемый объект-реципиент
      let recipientId = context.state.currentRewardsRecipient.id
      let url = context.state.urls.recipients + recipientId
      let recip = context.state.currentRewardsRecipient
      delete recip.id
      delete recip.updated_at
      delete recip.created_at
      delete recip.type
      let payload = { 'lead_reward_recipient': recip }
      this.$axios.patch(url, payload)
        .then(response => {
          router.go()
        })
        .catch(error => {
          console.log('error in promise', error)
          console.log('error.response', error.response)
          console.log('error.request', error.request)
          console.log('Error', error.message)
        })
    },
    saveAddedRewardsRecipient (context) {
      let url = context.state.urls.recipients
      let recip = context.state.currentRewardsRecipient
      let vettedRecipObject = {}

      // Удаляем ненужные нам объекты и переменные согласно типу
      if (recip.type === 'sole proprietor') {
        // Копируем в новый объект только разрешенные поля.
        vettedRecipObject.short_name = recip.short_name
        vettedRecipObject.merchant_client_id = recip.merchant_client_id
        vettedRecipObject.contact_person_for_finance = recip.contact_person_for_finance
        vettedRecipObject.merchant_site_id = recip.merchant_site_id
        vettedRecipObject.is_active = recip.is_active
        vettedRecipObject.bic = recip.bic
        vettedRecipObject.ogrn = recip.ogrn
        vettedRecipObject.agreement = recip.agreement
        vettedRecipObject.inn = recip.inn
        vettedRecipObject.full_name = recip.full_name
        vettedRecipObject.address = recip.address
        vettedRecipObject.bank_account = recip.bank_account
        vettedRecipObject.contact_person = recip.contact_person
        vettedRecipObject.type = recip.type
      }

      if (recip.type === 'company') {
        // Копируетм в новый объект только разрешенные поля.
        vettedRecipObject.short_name = recip.short_name
        vettedRecipObject.merchant_client_id = recip.merchant_client_id
        vettedRecipObject.contact_person_for_finance = recip.contact_person_for_finance
        vettedRecipObject.merchant_site_id = recip.merchant_site_id
        vettedRecipObject.is_active = recip.is_active
        vettedRecipObject.bic = recip.bic
        vettedRecipObject.ogrn = recip.ogrn
        vettedRecipObject.agreement = recip.agreement
        vettedRecipObject.inn = recip.inn
        vettedRecipObject.full_name = recip.full_name
        vettedRecipObject.address = recip.address
        vettedRecipObject.bank_account = recip.bank_account
        vettedRecipObject.kpp = recip.kpp
        vettedRecipObject.management = recip.management
        vettedRecipObject.contact_person = recip.contact_person
        vettedRecipObject.type = recip.type
      }
      // Удаляем ненужные нам объекты и переменные согласно типу
      if (recip.type === 'individual' || recip.type === 'self-employed') {
        // Копируем в новый объект только разрешенные поля.
        vettedRecipObject.short_name = recip.full_name
        vettedRecipObject.merchant_client_id = recip.merchant_client_id
        vettedRecipObject.contact_person_for_finance = recip.contact_person_for_finance
        vettedRecipObject.merchant_site_id = recip.merchant_site_id
        vettedRecipObject.is_active = recip.is_active
        vettedRecipObject.bic = recip.bic
        vettedRecipObject.agreement = recip.agreement
        vettedRecipObject.inn = recip.inn
        vettedRecipObject.snils = recip.snils
        vettedRecipObject.full_name = recip.full_name
        vettedRecipObject.address = recip.address
        vettedRecipObject.address.flat = ''
        vettedRecipObject.address.street = ''
        vettedRecipObject.address.city = ''
        vettedRecipObject.address.house = ''
        vettedRecipObject.address.region = ''
        vettedRecipObject.address.zip_code = ''
        vettedRecipObject.bank_account = recip.bank_account
        vettedRecipObject.birthdate = recip.birthdate
        vettedRecipObject.document = recip.document
        vettedRecipObject.document.type = 'passport'
        vettedRecipObject.contact_person = recip.contact_person
        vettedRecipObject.type = recip.type
      }

      let payload = { 'lead_reward_recipient': vettedRecipObject }
      this.$axios.post(url, payload)
        .then(response => {
          router.go()
        })
        .catch(error => {
          console.log('error in promise', error)
          console.log('error.response', error.response)
          console.log('error.request', error.request)
          console.log('Error', error.message)
        })
    },
    processSuggestions (context, suggestionData) {
      if (suggestionData.suggestionType === 'bank') {
        let data = {
          'bankname': suggestionData.suggestion.value,
          'bankcity': suggestionData.suggestion.data.payment_city,
          'bankcorcount': suggestionData.suggestion.data.correspondent_account
        }
        context.commit('updateCurrentRewardsRecipientAdditionalFields', data)
      } else {
        // Готовим объекты для апдейта - там, где в апи есть поля.
        let newObject = JSON.parse(JSON.stringify(context.state.currentRewardsRecipient))
        // Для ИП:
        if (suggestionData.suggestion.data.type === 'INDIVIDUAL') {
          newObject.ogrn = suggestionData.suggestion.data.ogrn
          newObject.short_name = suggestionData.suggestion.value
          // newObject.full_name = suggestionData.suggestion.data.name.full
          newObject.full_name = suggestionData.suggestion.data.name.full_with_opf

          context.commit('updateCurrentRewardsRecipient', newObject)
          context.dispatch('processUpdateContactPersons', suggestionData.suggestion.data.name.full)
        }
        if (suggestionData.suggestion.data.type === 'LEGAL') {
          let data = {
            'address': suggestionData.suggestion.data.address.value
          }
          if (suggestionData.suggestion.data.hasOwnProperty('opf') && suggestionData.suggestion.data.opf !== null) {
            data.opf = suggestionData.suggestion.data.opf.short
          }

          context.commit('updateCurrentRewardsRecipientAdditionalFields', data)

          newObject.ogrn = suggestionData.suggestion.data.ogrn
          newObject.short_name = suggestionData.suggestion.value
          newObject.kpp = suggestionData.suggestion.data.kpp
          newObject.full_name = suggestionData.suggestion.data.name.full_with_opf
          newObject.address.city = suggestionData.suggestion.data.address.data.city
          newObject.address.flat = suggestionData.suggestion.data.address.data.flat
          newObject.address.house = suggestionData.suggestion.data.address.data.house
          newObject.address.region = suggestionData.suggestion.data.address.data.region
          newObject.address.street = suggestionData.suggestion.data.address.data.street
          newObject.address.zip_code = suggestionData.suggestion.data.address.data.postal_code
          if (suggestionData.suggestion.data.hasOwnProperty('management') && suggestionData.suggestion.data.management !== null) {
            newObject.management.full_name = suggestionData.suggestion.data.management.name
            newObject.management.position = suggestionData.suggestion.data.management.post
          }

          context.commit('updateCurrentRewardsRecipient', newObject)
          // context.dispatch('processUpdateContactPersons', suggestionData.suggestion.data.name.full)
        }
      }
    },
    queryDadataApiInn (context, query) {
      // по дефолту - inn
      let url = VUE_APP_DADATA_API + 'party'
      let data = { 'query': query.value }

      if (query.type === 'bic') {
        url = VUE_APP_DADATA_API + 'bank'
      }

      let options = {
        url: url,
        method: 'POST',
        data: JSON.stringify(data)
      }
      this.$axiosDadata(options)
        .then((response) => {
          if (response.data.suggestions.hasOwnProperty('0') && response.data.suggestions[0]) {
            if (query.type === 'bic') {
              let suggestionData = {
                suggestion: response.data.suggestions[0],
                suggestionType: 'bank'
              }
              context.dispatch('processSuggestions', suggestionData)
            } else {
              let suggestionData = {
                suggestion: response.data.suggestions[0],
                suggestionType: 'party'
              }
              context.dispatch('processSuggestions', suggestionData)
            }
          }
        }).catch(error => {
          console.log('error in cath', error)
          console.log('error.response', error.response)
          console.log('error.request', error.request)
          console.log('Error', error.message)
        })
    },
    processUpdateContactPersons (context, fullName) {
      let recipientObject = JSON.parse(JSON.stringify(context.state.currentRewardsRecipient))
      let nameArray = fullName.split(' ')
      if (nameArray.hasOwnProperty('0')) {
        if (!recipientObject.contact_person.surname) {
          recipientObject.contact_person.surname = nameArray[0]
        }
        if (!recipientObject.contact_person_for_finance.surname) {
          recipientObject.contact_person_for_finance.surname = nameArray[0]
        }
      }
      if (nameArray.hasOwnProperty('1')) {
        if (!recipientObject.contact_person.name) {
          recipientObject.contact_person.name = nameArray[1]
        }
        if (!recipientObject.contact_person_for_finance.name) {
          recipientObject.contact_person_for_finance.name = nameArray[1]
        }
      }
      if (nameArray.hasOwnProperty('2')) {
        if (!recipientObject.contact_person.patronymic) {
          recipientObject.contact_person.patronymic = nameArray[2]
        }
        if (!recipientObject.contact_person_for_finance.patronymic) {
          recipientObject.contact_person_for_finance.patronymic = nameArray[2]
        }
      }
      context.commit('updateCurrentRewardsRecipient', recipientObject)
    },
    addNewRewardRecipient (context) {
      let currentRewardsRecipient = {}
      currentRewardsRecipient.type = 'company'
      currentRewardsRecipient.address = {}
      currentRewardsRecipient.contact_person = {}
      currentRewardsRecipient.contact_person_for_finance = {}
      currentRewardsRecipient.management = {}
      currentRewardsRecipient.document = {}
      context.commit('updateShowRecipientDetailsModalState', 'add')
      context.commit('updateCurrentRewardsRecipient', currentRewardsRecipient)
      context.commit('toggleShowRecipientDetailsModal', true)
    }
  }
}
