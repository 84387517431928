export default {
  namespaced: true,
  state: {
    projectsDataReceived: false,
    projects:[],
  },
  mutations: {
    updateProjects (state, PRData) {
      state.projects = PRData;
    },
    toggleProjectsDataReceived (state, value) {
      state.projectsDataReceived = value;
    },
  },
  actions: {
    prepareFilterByString (context){
      let filterByString = '';
      if (context.rootState.filtersStd.merchantId){
        filterByString += '&merchant_id in '+context.rootState.filtersStd.merchantId;
      }
      if (context.rootState.filtersStd.statusString){
        filterByString += '&onboarding_status in '+context.rootState.filtersStd.statusString;
      }
      if (context.rootState.filtersStd.isSandbox !== null){
        filterByString += '&is_sandbox='+context.rootState.filtersStd.isSandbox;
      }

      //очищаем первый &
      if (filterByString && filterByString.charAt(0)==='&'){
        filterByString = filterByString.substring(1)
      }
      // filterByString = encodeURIComponent(filterByString)
      context.commit('filtersStd/updateFilterByString', filterByString, {root: true})
    },
    refreshTable (context, queryObject) {
      let url = VUE_APP_API_CLIENTS + `/clients/v1/clients/${context.rootState.account.cid}/merchants`

      if (queryObject && queryObject.type === 'cursor'){
        url += '?cursor='+queryObject.params.cursor
      }
      else{
        let limit = 'limit_to='+ queryObject.params.limit_to
        url += '?' + limit

        context.dispatch('prepareFilterByString')

        let filterByString = context.rootState.filtersStd.filterByString
        if (filterByString){
          url += '&' + 'filter_by='+encodeURIComponent(filterByString)
        }
      }
      this.$axios.get(url)
        .then(response => {
          let cursor = response.data.cursor
          let projects = response.data.merchants
          context.commit('pagerStd/updateCursor', cursor, { root: true })
          context.commit('pagerStd/updateApiResponseReceived', true, { root: true })
          //Если в запросе передаётся currentOffset, используем его, если нет - ставим 0.
          if (typeof queryObject === 'object' && queryObject !== null && queryObject.hasOwnProperty('currentOffset')){
            context.commit('pagerStd/updateCurrentOffset', queryObject.currentOffset, { root: true })
          }
          else{
            context.commit('pagerStd/updateCurrentOffset', 0, { root: true })
          }
          context.commit('updateProjects', projects)

        })
        .catch(error => {
          console.log('error in promise', error)
          context.commit('updatePartnerRewards', [])
        })
    },
  },
}
