const filters_config = {
  service:{
    show: true,
    options: [
      { value: 'life', label_short: 'Кредиты', label: 'Кредитные сервисы', selected: true },
      { value: 'pay', label_short: 'Платежи', label: 'Платежные сервисы', selected: true },
    ],
  },
}

export default filters_config
