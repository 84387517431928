<template>
  <div>
    <p class="mb-0">{{ $t("filters.st1") }}</p>
    <multiselect v-model="selectedValues"
    :options="options" :multiple="true" track-by="label"
    label="label" @input="selectChanged"
    :deselect-label="$i18n.t('service.remove')" :select-label="$i18n.t('service.add')" :selected-label="$i18n.t('service.optionSelected')"
    :placeholder="multiselectPlaceholder()"></multiselect>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      selectedValues: [],
      settingProcess: true
    }
  },
  computed: {
    options: function () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let optionsObject = this.$store.state[activeTableNamespace].displayTable.tableFilters.status[activeColumnRow]
      let options = []
      Object.entries(optionsObject).forEach(entry => {
        let option = {
          label: this.$i18n.t(entry[1].label),
          default: entry[1].default,
          id: entry[0]
        }
        options.push(option)
      })
      return options
    }
  },
  methods: {
    selectChanged () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let optionsObject = this.$store.state[activeTableNamespace].displayTable.tableFilters.status[activeColumnRow]
      let values = []
      this.selectedValues.forEach(item => {
        values.push(optionsObject[item.id])
      })
      if (!this.settingProcess) {
        // нам не нужно запускать этот процесс если мы только устанавливаем значение при сетапе фильтра
        values.skipApiQuery = false
      } else {
        values.skipApiQuery = true
      }
      this.$store.commit('filters/updateStatusFilter', values)
      this.$store.dispatch('filters/filterChanged', values)
    }
  },
  mounted () {
    // При маунтинге населяем опции дефолтными значениями.
    // Это население не триггерит обновление фильтров
    // Поэтому обновляем руками.
    this.options.forEach(option => {
      if (option.default) {
        this.selectedValues.push(option)
      }
    })
    this.selectChanged()
    this.$nextTick(() => { this.settingProcess = false })
  }
}
</script>

<style>

</style>
