<template>
  <b-card title="Расширенная информация" class="card-small extended-information">
    <div v-if="item">
      <div>
        <div v-if="item.id" class="mb-2">
          <p class="font-weight-bold my-0">{{ $t('extend.transactionId') }}</p>
          <div class=" ">{{ item.id }} <clipboard :data="item.id"/></div>
        </div>
        <div class="mb-2" v-if="item.reference_id">
          <p class="font-weight-bold my-0">{{ $t('extend.initialTransactionId') }}</p>
          <div class=" ">{{ item.reference_id }}</div>
        </div>
        <div class="mb-2" v-if="item.authcode">
          <span class="font-weight-bold my-0">AuthCode:</span>
            {{ item.authcode }} <clipboard :data="item.authcode"/>

        </div>

        <div v-if="item.pan" class="mb-2">
          <span class="font-weight-bold my-0">Pan:</span>&nbsp;
          <span class=" ">{{ item.pan }} <clipboard :data="item.pan"/></span>
        </div>

        <div v-if="item.pan_id" class="mb-2">
          <p class="font-weight-bold my-0">{{ $t('extend.bankingCardId') }}</p>
          <div class=" ">{{ item.pan_id }} <clipboard :data="item.pan_id"/></div>
        </div>
        <div class="mb-1">
          <span class="font-weight-bold my-0">Response code:</span>&nbsp;
          <span class=" ">{{ item.response_code }}</span>
          <span v-if="item.status === 'fail'"> - {{ findErrorDescription(this.$store.state.notificationsService.paygwErrorsMapping, item.channel_id, item.response_code) }}</span>
        </div>
        <div class="mb-1">
          <span class="font-weight-bold my-0">{{ $t('extend.accountId') }}</span>&nbsp;
          <span class=" ">{{ item.account_id }}</span>
        </div>
        <div class="mb-1" v-if="item.price&&item.price.mandarin_total_price">
          <span class="font-weight-bold my-0">Комиссия Мандарин:</span>&nbsp;
          <span class="">{{ item.price.mandarin_total_price }}</span>
        </div>


        <div class="mb-2">
          <span class="font-weight-bold my-0">{{ $t('extend.channel') }}</span>&nbsp;
          <span class=" ">{{ getChannel(item.channel_id) }}</span>
        </div>
        <div class="mb-2" v-if="additionalDataLoaded">
          <p class="font-weight-bold my-0">Дополнительные поля:</p>
          <div v-for="field in additionalData" :key="field.id">
            <span>{{field.name}}:&nbsp;{{field.value}}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!item" class="text-center" style="line-height: 25em;">
      <p>Выберите транзакцию</p>
    </div>
  </b-card>
</template>

<script>
export default {
  props: ['item'],
  computed:{
    additionalDataLoaded(){
      if (!this.$store.state.transactionsAdditional.transactionAdditionalDataLoading){
        if (this.additionalData.length > 0){
          return true
        }
      }
      return false
    },
    additionalData(){
      return this.$store.state.transactionsAdditional.transactionAdditionalData
    }
  },
  methods:{
  }
}
</script>
