// Получение общего баланса пользователя
export default {
  namespaced: true,
  state: {
    balance_object: null,
    balance_total: false,
    balance_details: null,
    balance_loaded: false,
  },
  mutations: {
    clearBalance(state){
      state.balance_object = null
      state.balance_total = false
      state.balance_details = null
      state.balance_loaded = false
    },
    updateBalanceLoaded(state, value){
      state.balance_loaded = value
    },
    updateBalanceDetails (state, data) {
      state.balance_details = data
    },
    updateBalanceObject (state, data) {
      state.balance_object = data
    },
    updateBalanceTotal (state, value) {
      state.balance_total = value
    }
  },
  actions: {
    loadBalance(context){
      let url = VUE_APP_API_BALANCE + '/balance/v3/client/' + context.rootState.account.cid + '/balance'
      context.commit('clearBalance')
      this.$axios.get(url)
        .then(response => {
          if (!Object.prototype.hasOwnProperty.call(response, 'status')){
            console.log('we got no response from server loadBalance', response)
            this._vm.$toast.error('Сервер баланса не ответил на наш запрос')
            return false
          }
          if (response.status === 200){
            context.commit('updateBalanceLoaded', true)
            context.commit('updateBalanceTotal', response.data.client.total_balance)
            context.commit('updateBalanceObject', response.data.client.balances)
            let balance_details = []
            response.data.client.balances.forEach((item, i) => {
              let balanceItem = {}
              balanceItem.label = item.description
              balanceItem.amount = item.balance[0].virtual.amount
              balance_details.push(balanceItem)
            })
            context.commit('updateBalanceDetails', balance_details)
          } else {
            console.log('response loadBalance', response)
            this._vm.$toast.error('Данные, полученные с сервера баланса, не соответствуют ожидаемому формату');
          }
        })
        .catch(error => {
          console.log('Не работает баланс')
          //console.log('error in promise', error)
        })
    }
  }
}
