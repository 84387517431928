<template>
    <div class="small-paddings">
        <input 
            class="form-control" 
            style="display: inline-block !important;" 
            v-model="Search" 
            placeholder="Поиск по ФИО / телефону / email"
            v-on:keyup.enter="run_filters">
    </div>
</template>

<script>
export default {
  props: ['runFilters'],
  computed: {
    Search: {
      get: function () { '' },
      set: function (newValue) { 
        this.$store.commit('filtersStd/UpdateSearchClient', newValue) 
      }
    }
  },
  methods: {
      run_filters() {
          this.$emit('runFilters')
      }
  }
}
</script>
