<template>
  <div class="cell-wrapper">
    <div v-if="activeColumnRow === 'loansFinalized'">
      <span v-if="fio">{{fio}}&nbsp;<clipboard :data="fio"/> <br /></span>
      {{phone}}&nbsp;<clipboard :data="phone"/>
    </div>
    <div v-if="activeColumnRow === 'loansPending'">
      <span v-if="fio">{{fio}}&nbsp;<clipboard :data="fio"/> <br /></span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeColumnRow: function () { return this.$store.state.pager.activeColumnRow },
    fio: function () {
      let fio = ''
      let surname = ''
      let name = ''
      let patronymic = ''
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      if (activeColumnRow === 'loansFinalized') {
        if (this.item.customer.surname) { surname = this.item.customer.surname }
        if (this.item.customer.name) { name = this.item.customer.name }
        if (this.item.customer.patronymic) { patronymic = this.item.customer.patronymic }
      }
      if (activeColumnRow === 'loansPending') {
        if (this.item.lead.surname) { surname = this.item.lead.surname }
        if (this.item.lead.name) { name = this.item.lead.name }
        if (this.item.lead.patronymic) { patronymic = this.item.lead.patronymic }
      }
      fio = (surname + ' ' + name + ' ' + patronymic).trim()
      return fio
    },
    phone: function () {
      let phone = ''
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      if (activeColumnRow === 'loansFinalized') {
        phone = this.item.customer.phone
      }
      if (activeColumnRow === 'loansPending') {
        phone = this.item.lead.phone
      }
      return phone
    }
  },
  props: ['item']
}
</script>
