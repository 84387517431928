<template>
  <b-row>
    <b-col cols="12">
      <b-card class="card-small">
        <h5 class="desktop-layer">История счетов</h5>
        <Loader v-if="loadingData || isLoading" :height="600" :size="3" />
        <div class="main-table-wrapper text-size-12" v-else>
          <pager />
          <b-table striped hover selectable select-mode="single" class="text-nowrap w-100" :fields="fields" :items="dataTable" @row-clicked="selectItem">
            <template v-slot:head()="data">
              <span v-html="data.field.label"></span>
            </template>
            <template v-slot:cell(created_at)="data">
              <span>{{properDT(data.item.created_at)}}</span>
              <br>
              <span> {{ data.item.settings_data.orderNumber }}</span>
            </template>
            <template v-slot:cell(status)="data">
              <span>{{ properM(calculateTotalCost(data.item.cart)) }} ₽</span>
              <br>
              <span v-html="getStatusBadge(data.item.status)"></span>
            </template>
            <template v-slot:cell(email)="data">
              <span>{{ data.item.settings_data.customerPhone }}</span>
              <br>
              <span>{{ data.item.settings_data.customerEmail }}</span>
            </template>
            <template v-slot:cell(payment_method)="data">
              <span v-html="getPaymentMethodName(data.item.payment_method)"></span>
            </template>
          </b-table>
          <div v-if="!haveResults" style="min-height: 400px; text-align: center; padding-top: 150px;">
          Данные за указанный период с учетом выбранных фильтров отсутствуют.
        </div>
        </div>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import PagerComponent from '@/components/Pager/pagerComponent'
import Filters from './Filters/Filters'
import ExtendInvoice from './extendInvoice.vue'
import { getLabel } from '@/configs/filtersTables/labels'
import { getStatusBadge, getPaymentMethodName } from '@/configs/filtersTables/labels_invoices'
import pager from "./pager.vue";

export default {
  components: {
    PagerComponent,
    Filters,
    ExtendInvoice,
    pager,
  },
  data(){
    return{
      isLoading: false,
      extendItem: false,
      fields: [
        {
          key: 'created_at',
          label: 'Дата<br>Номер заказа',
        },
        {
          key: 'email',
          label: 'Телефон<br>Email',
        },
        {
          key: 'payment_method',
          label: 'Тип оплаты',
        },
        {
          key: 'status',
          label: 'Сумма<br>Статус',
        },
      ]
    }
  },
  computed:{
    dataTable(){
      return this.$store.state.invoices.invoices
    },
    loadingData(){
      return !this.$store.state.pagerStd.apiResponseReceived
    },
    haveResults(){
      if (Array.isArray(this.dataTable) && this.dataTable.length>0){
        return true
      }
      return false
    }
  },
  watch: {
    loadingData: function (newValue, oldValue) {
      if (newValue){
        // Если мы грузим данные - чистим extendItem
        this.extendItem = false

      }
    }
  },
  methods:{
    async createNewInvoice (invoice) {
      console.log(invoice);
      // await this.$store.dispatch('invoices/setInvoice', invoice);
      this.$router.push({ name: 'Invoices' });
      // this.$toast.success('Счет успешно создан');
    },
    calculateTotalCost(cart) {
      let totalCost = 0;
      if (cart) cart.forEach(item => {
        totalCost += item.cost;
      });
      return totalCost;
    },
    setLoadingState(isLoading) {
      this.isLoading = isLoading;
    },
    async selectItem(item, index){
      await this.$store.commit('invoices/setInvoiceCopy', item);
      await this.$store.dispatch('invoices/setInvoice', item);
    },
    displayLabel(filter_name, current_value){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getLabel(filters_config, filter_name, current_value)
    },
    getStatusBadge(status){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getStatusBadge(filters_config, status)
    },
    getPaymentMethodName(value){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getPaymentMethodName(filters_config, value)
    },
  },
  created(){
    // Чтобы остатки фильтров не оставались после предыдущих страниц, очищаем фильтры. Делать это надо до установки новых.
    this.$store.commit('filtersStd/clearFilters')
    // Устанавливаем, какой из конфигов фильтров из config/filtersTables используется в этой таблице.
    this.$store.commit('filtersStd/setActiveFilterConfig', 'filtersInvoices', { root: true })
  },
  mounted () {
    this.$store.commit('pagerStd/updateRange', 15, { root: true })
    // Устанавливаем нейспейс для пейджера в этой таблице.
    this.$store.commit('pagerStd/updateActiveTableNamespace', 'invoices', { root: true })
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
  },
}
</script>

<style scoped>

</style>
