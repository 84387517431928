<template>
  <div class="mb-3">
    <b-row class="mb-3">
      <b-col cols="12" lg="4" class="mb-2 mb-lg-0">
        <mid/>
      </b-col>
      <b-col cols="12" lg="4" class="mb-2 mb-lg-0">
        <sandbox/>
      </b-col>
      <b-col cols="12" lg="4">
        <statuses/>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12" lg="4">
        <b-button variant="outline-primary" block @click.prevent="getAddProject">Создать проект <mdicon name="plus" :width="20" :height="20" /></b-button>
      </b-col>
      <b-col cols="12" lg="4"></b-col>
      <b-col cols="12" lg="4">
        <b-button block @click="runFilters" class="search-button" variant="primary">Поиск с фильтрами</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import sandbox from './sandbox.vue'
import statuses from './statuses.vue'
import mid from './mid.vue'

export default {
  components: {
    sandbox,
    statuses,
    mid,
  },
  data() {
    return {}
  },
  methods: {
    getUrl() {
      return `${VUE_APP_PARTNER_REWARDS_URL}?pref_id=${this.$store.state.account.mandarin.client_id}`;
    },
    getAddProject(){
      this.$router.push({ name: 'ProjectAdd' })
    },
    goToInstruction(url=null) {
      window.open(url || VUE_APP_PARTNER_REWARDS_INSTRUCTION, '_blank');
    },
    runFilters() {
      this.$store.dispatch('pagerStd/updateCurrentTableState', null, {root: true})
    },
  }
}
</script>

<style scoped>
.search-button {
  line-height: 18px;
  font-size: 14px;
  border-radius: 5px;
}
.cursor-pointer.form-control {
  background-color: #ffffff;
  border-color: rgb(232, 232, 232);
  border-right: none;
  color: rgb(0, 123, 255);
  font-weight: 400;
  font-size: 14px;
}
.cursor-pointer.form-control:disabled:hover, .cursor-pointer.form-control[readonly]:hover {
  cursor: pointer;
}
.input-group .btn-outline-secondary {
  border-color: rgb(232, 232, 232);
  padding: 5px;
}
.input-group .btn-outline-secondary:hover {
  background-color: #ffffff;
  color: rgb(90, 97, 105);
}
div >>> .form-control {
  height: 38px;
}
</style>
