export default {
  namespaced: true,
  mutations: {
     
  },
  actions:{
    sendRenotifyQuery(context, transaction){
      console.log('sendRenotifyQuery', transaction)
      let ids = [transaction.cs1]
      let url = VUE_APP_SECURE + 'api/notifications/bulk/'
      const config = {
        headers:{
          mid: transaction.mw_merchant_id
        }
      };
      let payload = {
          "ids": ids,
      }

      this.$axios.post(url, payload, config)
        .then(response => {
          console.log(response, 'response renotify')
          if (response === undefined){
            this._vm.$toast.error("Нам не удалось связаться с сервисом повторной отсылки callback; ответа от сервера нет.");
          }else{
            this._vm.$toast.success("Повторная отсылка callback выполнена");
          // в этом месте response.data.id возвращает ид новой транзакции, но зачем это пользователю.
          }
        })
        .catch(error => {
          console.log('error in promise', error)
          this._vm.$toast.error("Нам не удалось связаться с сервисом повторной отсылки callback; ошибка: "+error.message);
          })
    }
  }
}
