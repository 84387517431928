<template>
  <div class="cell-wrapper">
   {{properDT(item.updated)}} <br />
   <d-badge :theme="getBadge(item.status)">{{getDetailedTransactionStatus(item)}}</d-badge>
   <span v-if="has3ds">&nbsp;&nbsp;<d-badge outline class="theme3ds" theme="secondary">3DS</d-badge></span>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    has3ds: function () {
      if (this.item.secure_mode === 1) { return true } else { return false }
    }
  },
  props: ['item']
}
</script>
