
<template>
  <d-row class="mb-4">
    <d-col lg="6">
      <p class="mb-0">{{ $t("filters.pm1") }}</p>
      <month-picker-input
        lang="ru"
        :no-default="true"
        @input="fromChanged"
        ></month-picker-input>
    </d-col>
    <d-col lg="6">
      <p class="mb-0">{{ $t("filters.pm2") }}</p>
      <month-picker-input
        lang="ru"
        :no-default="true"
        @input="toChanged"
        ></month-picker-input>
    </d-col>
  </d-row>
</template>

<script>
// import Multiselect from 'vue-multiselect'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import moment from 'moment'
// Переделать на датепикер
export default {
  components: {
    MonthPickerInput,
    MonthPicker
  },
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {
    fromChanged (date) {
      let values = {}
      values.fromPeriod = moment.utc(date.from).format()
      this.$store.commit('filters/updatePeriodsFilter', values)
      values = {}
      this.$store.dispatch('filters/filterChanged', values)
    },
    toChanged (date) {
      let values = {}
      values.toPeriod = moment.utc(date.to).format()
      this.$store.commit('filters/updatePeriodsFilter', values)
      values = {}
      this.$store.dispatch('filters/filterChanged', values)
    }
  },
  mounted () {
    // При маунтинге населяем опции дефолтными значениями.
    // Это население не триггерит обновление фильтров
    // Поэтому обновляем руками.

  }
}
</script>

<style>
.month-picker__container{
  z-index: 10000;
  background: white;
}
.month-picker-input-container .month-picker-input{
  padding: 0.5rem 1rem;
  font-size: 0.8125rem;
}

</style>
