import Vue from 'vue'
import Vuex from 'vuex'

import pager from '@/store/modules/Pager'
import pagerStd from '@/store/modules/PagerStd'
import filters from '@/store/modules/Filters'
import filtersStd from '@/store/modules/FiltersStd'

import payLender from '@/store/modules/PayLender'
import lifeMerchant from '@/store/modules/LifeMerchant'
import account from '@/store/modules/Account'
import rewards from '@/store/modules/Rewards'
import prices from '@/store/modules/Prices'
import extend from '@/store/modules/Extend'
import acts from '@/store/modules/Acts'
import reportsExport from '@/store/modules/Export'
import analytics from '@/store/modules/transactionsTables/Analytics'
import payments from '@/store/modules/transactionsTables/Payments'
import pos from '@/store/modules/transactionsTables/Pos'
import payouts from '@/store/modules/transactionsTables/Payouts'
import receipts from '@/store/modules/transactionsTables/Receipts'
import partners from '@/store/modules/PartnerRewards'
import projects from '@/store/modules/Projects'
import invoices from '@/store/modules/Invoices'
import bindings from '@/store/modules/transactionsTables/Bindings'
import projectMerchant from '@/store/modules/ProjectMerchant'
import projectLimits from '@/store/modules/ProjectLimits'
import renotify from '@/store/modules/Renotify'
import balance from '@/store/modules/Balance'
import search from '@/store/modules/transactionsTables/Search'
import lifeBroker from '@/store/modules/lifeTables/Broker'
import bankAccounts from '@/store/modules/BankAccounts'
import notifications from '@/store/modules/Notifications'
import notificationsService from '@/store/modules/NotificationsService'
import transactionPriceInfo from '@/store/modules/TransactionPriceInfo'
import transactionsAdditional from '@/store/modules/TransactionsAdditional'
Vue.use(Vuex)

const store = new Vuex.Store({
  state () {
  },
  modules: {
    pager: pager,
    pagerStd: pagerStd,
    filters: filters,
    filtersStd: filtersStd,
    payLender: payLender,
    lifeMerchant: lifeMerchant,
    account: account,
    rewards: rewards,
    acts: acts,
    prices: prices,
    extend: extend,
    reportsExport: reportsExport,
    analytics: analytics,
    payments: payments,
    receipts: receipts,
    partners: partners,
    projects: projects,
    invoices: invoices,
    pos: pos,
    payouts: payouts,
    bindings: bindings,
    projectMerchant: projectMerchant,
    projectLimits: projectLimits,
    renotify: renotify,
    search: search,
    lifeBroker: lifeBroker,
    balance: balance,
    bankAccounts: bankAccounts,
    notifications: notifications,
    notificationsService: notificationsService,
    transactionPriceInfo: transactionPriceInfo,
    transactionsAdditional: transactionsAdditional
  },
  mutations: {
  },
  actions: {
  }
})

export default store
