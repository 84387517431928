<template>
  <d-card class="card-small mb-4" style="border-radius: 0 0.625rem 0.625rem 0.625rem">
    <d-card-body>
      <d-row class="mb-4">
        <d-col lg="2">
          <Action/>
        </d-col>
      </d-row>
          <d-row class="mb-12">
            <d-col lg="6">
              <b-button v-if="addModuleLifeScope||addPayoutModules" type="submit" variant="primary"  @click.prevent='openSettings()'>Добавить модуль</b-button>
              <Is_Active :inactiveCount="inactiveCount" @activeChanged="handleActiveChanged"/>
            </d-col>
            <d-col lg="6">
                <div class="float-lg-right">
                    <a href="https://mndr.in/nocode" target="_blank">Инструкция по настройке и использованию</a>
                </div>
            </d-col>
          </d-row>
      <Loader v-if="!loadingData" :height="600" :size="3" />
      <div v-else style="min-height: 600px">
        <div class="" v-if="showTable">
            <b-table small class="text-nowrap" :fields="fields" :items="dataTableTR" responsive="sm">
                <template v-slot:cell(link)="data">
                  <CellLink :item="data.item.link" />
                </template>
                <template #cell(type)="data">
                  {{ $t('components.payout.'+data.item.type) }}
                </template>
                <template #cell(merchant_id)="data">
                  {{ (data.item.action === 'life')?data.item.customer_info.merchant_id:data.item.merchant_id }}
                </template>
                <template #cell(module_name)="data">
                  {{ data.item.module_name }}
                </template>
                <template #cell(is_active)="data">
                  {{ data.item.is_active?'активирован':'выключен' }}
                </template>
                <template #cell(actions)="data">
                  <CellAction :item="data.item" @registryPayment="registryPayment" @toogleIsActive="toogle_is_active"/>
                </template>
            </b-table>
        </div>
        <div class="text-center" style="line-height: 30em;">
          <p>{{ showError }}</p>
        </div>
      </div>
    </d-card-body>
    <b-modal v-model="step1" id="modal-center" no-close-on-backdrop hide-footer centered title="Загрузка реестра">
          <b-form @submit="onSubmit">
          <b-form-file
            id="input_file"
            v-model="file"
            class="mb-2"
            :state="Boolean(file)"
            ref="file"
            browse-text="Открыть"
            placeholder="Реестр"
            accept=".xlsx"
            @change="deleteErrors"
          ></b-form-file>
          <b-row class="mt-4">
            <b-col>
              <b-btn variant="success" :disabled="!(file && !showRowsError)" type="submit">Загрузить</b-btn>
            </b-col>
            <b-col class="mt-2 col-12">
                <span v-if="showRowsError">Загруженный реестр не прошел первичную валидацию. <a href="#" @click.prevent="downloadErrors">Скачайте файл</a> с описанием ошибок, исправьте их и повторите</span>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal v-model="step2" id="modal-payments-center" no-close-on-backdrop hide-footer centered title="Выплата по реестру">
          <b-form @submit="onSubmitPayment">
              <div class="body">
                  Реестр загружен на сервер:
                  <span class="bold">{{ rows }}</span>
                  транзакции, на сумму
                  <span class="bold">{{ properMRub(parseFloat(amount)) }}</span>
              </div>
          <b-row class="mt-4">
            <b-col>
              <b-btn variant="success" type="submit">Начать выплаты</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
  </d-card>
</template>

<script>

import Action from '@/components/Filters/action'
import Is_Active from '@/components/Filters/is_active'

function makeStep(stepName) {
  return {
    get() {
      return this.$store.state.payLender[stepName]
    },
    set(val) {
      this.$store.state.payLender[stepName] = val
    }
  }
}
export default {
  components:{
      Action,
      Is_Active
  },
  data() {
      return {
        file: null,
        merchant_id: null,
        id: null,
        inactiveCount: 0
      }
  },
  computed: {
    showRowsError: function() {
        return (this.$store.state.payLender.rows.length > 0);
    },
    step1: makeStep('step1'),
    step2: makeStep('step2'),
    amount() {
        return this.$store.state.payLender.registry_response.amount
    },
    rows() {
        return this.$store.state.payLender.registry_response.rows
    },
    addPayoutModules () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace;
      let activeColumnRow = this.$store.state.pager.activeColumnRow;
      let responseObject = this.$store.state[activeTableNamespace].displayTable.tableApiUrls[activeColumnRow].responseObject;
      let dataTable = this.$store.state[activeTableNamespace].apiData[responseObject];

      let merchants = this.$store.state.account.merchants;

      let existingPayMerchants = new Set();
      let existingPayoutMerchants = new Set();

      dataTable.forEach(m => {
        if (m.action === 'pay') {
          existingPayMerchants.add(m.merchant_id);
        } else if (m.action === 'payout') {
          existingPayoutMerchants.add(m.merchant_id);
        }
      })

      let madded = merchants.filter(merchantId => {
        return (!existingPayMerchants.has(merchantId) && this.addModulePayScope)
          || (!existingPayoutMerchants.has(merchantId) && this.addModulePayoutScope);
      });

      if (madded.length > 0) {
        // console.log(madded);
        return madded;
      } else {
        return false;
      }
    },
    addModulePayScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_pay_module.write');
    },
    addModulePayoutScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_payout_module.write');
    },
    addModuleLifeScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_life_module.write');
    },
    showError: function() {
        return this.$store.state[this.$store.state.pager.activeTableNamespace].apiData.error;
    },
    loadingData: function () {
      return this.$store.state.pager.apiResponseReceived;
    },
    showTable: function () {
      //if (this.$store.state.pager.apiResponseReceived && !this.$store.state.pager.apiDataEmpty) {
        return true;
      //}
      //return false
    },
    dataTableTR: function () {
      let dataTable = this.getDataTable()
      let module_name = ""
      dataTable = dataTable.filter(item => (this.$store.state.payLender.is_active || (!this.$store.state.payLender.is_active && item.is_active))).map((item, index) => {
        if (item.mandatory_info.site) {
            module_name = item.mandatory_info.site.module_name;
        } else {
            module_name = "";
        }
        let mid = (item.action === 'life')?item.id + '/':'?mid=' + item.merchant_id
        // let mid = (item.action === 'life')?'':'?mid=' + item.merchant_id
        let mid_path = (item.action === 'life')?item.id:item.merchant_id
        return {
            ...item,
            index: index,
            type: item.action,
            module_name: module_name,
            link: VUE_APP_SECURE_APP + item.action + '/' + mid,
            actions: 'none',
            pathTo: /pay_merchant/ + this.$route.params.arg1 + '/' +this.$route.params.arg2 + '/' + item.action +'/' + mid_path + '/'
        }
      })
      // Тут, похоже, array-like object - переделываем в массив.
      dataTable = Array.from(dataTable);
      this.updateInactiveCount();
      return dataTable;
    },
    fields () {return [
      {
        key: 'merchant_id',
        label: 'Merchant ID',
        templateName: 'CellText'
      },
      {
        key: 'type',
        label: 'Тип модуля',
        templateName: 'CellText'
      },
      {
        key: 'is_active',
        label: 'Состояние',
        templateName: 'CellText'
      },
      {
        key: 'module_name',
        label: 'Название',
        templateName: 'CellText'
      },
      {
        key: 'link',
        label: 'Ссылка',
        templateName: 'CellText'
      },
      {
        key: 'actions',
        label: '',
        templateName: 'CellText'
      }
    ]},
    templatedFields () {
      let templatedFields = []
      this.fields.forEach(field => {
        if (field.hasOwnProperty('templateName')) {
          let f = {}
          f.key = field.key
          f.templateName = field.templateName
          templatedFields.push(f)
        }
      })
      return templatedFields
    }
  },
  created () {

  },
  mounted () {
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.commit('filters/clearAllFilters');
    this.$store.dispatch('pager/updateCurrentTableState', 'default')
    this.$store.dispatch('extend/changedTable')
  },
  mutations () {

  },
  methods: {
    updateInactiveCount() {
      const data = this.getDataTable();
      this.inactiveCount = data.filter(item => !item.is_active).length;
    },
    getDataTable() {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace;
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let responseObject = this.$store.state[activeTableNamespace].
        displayTable.tableApiUrls[activeColumnRow].responseObject;
      return this.$store.state[activeTableNamespace].apiData[responseObject];
    },
    handleActiveChanged() {
      this.$store.dispatch('payLender/refreshTableData', 'default');
    },
    deleteErrors () {
        this.$store.dispatch('payLender/deleteErrors')
    },
    downloadErrors () {
        this.$store.dispatch('payLender/downloadErrors')
    },
    registryPayment(merchant_id) {
        this.merchant_id = merchant_id
        this.step1 = true
    },
    toogle_is_active (item) {
      const { id, action, client_id, merchant_id, is_active } = item;
      let IO = {
        id,
        action,
        client_id,
        merchant_id,
        is_active: !is_active
      }
      this.$store.dispatch('payLender/saveEditPayout', {item: IO, back: false, router: this.$router})
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let responseObject = this.$store.state[activeTableNamespace].displayTable.tableApiUrls[activeColumnRow].responseObject
      this.$store.state[activeTableNamespace].apiData[responseObject][item.index].is_active = !item.is_active
    },
    onSubmit (evt) {
      evt.preventDefault()
      this.$store.dispatch('payLender/downloadRegistry', this.merchant_id)
    },
    onSubmitPayment( evt ) {
        evt.preventDefault()
        this.$store.dispatch('payLender/paymentsStart', this.$router)
    },
    selectItem (item, index, event, type) {
      const target = type === 'get' ? event : event.currentTarget
      if (!target) {
        item = {}
        this.$store.dispatch('extend/tableRowSelected', item)
      }

      for (let i = 0; i < target.parentNode.children.length; i++) {
        if (target.parentNode.children[i].classList.contains('select')) {
          target.parentNode.children[i].classList.remove('select')
        }
      }

      target.classList.add('select')
      this.$store.dispatch('extend/tableRowSelected', item)
    },
    get_merchants() {
      let merchants = this.$store.state.account.merchants;
      let merchants_pay = [];
      let merchants_payout = [];
      this.getDataTable().forEach(m => {
        if (m.action === 'pay' && this.addModulePayScope) {
          merchants_pay.push(m.merchant_id);
        } else if (m.action === 'payout' && this.addModulePayoutScope) {
          merchants_payout.push(m.merchant_id);
        }
      })
      let add_merchants_pay = []
      let add_merchants_payout = []
      merchants.forEach(m =>{
        if (!merchants_pay.includes(m) && this.addModulePayScope) {
          add_merchants_pay.push(m);
        }
        if (!merchants_payout.includes(m) && this.addModulePayoutScope) {
          add_merchants_payout.push(m);
        }
      });
      // console.log(add_merchants_pay, ' ', add_merchants_payout)
      return {
        add_merchants_pay: add_merchants_pay,
        add_merchants_payout: add_merchants_payout,
      }
    },
    openSettings() {
      if (this.addPayoutModules) {
        const { add_merchants_pay, add_merchants_payout } = this.get_merchants()
        let mid = 'new'
        let payout = 'payout'
        if (add_merchants_payout.length > 0) {
          mid = add_merchants_payout[0]
          payout = 'payout'
        } else {
          mid = add_merchants_pay[0]
          payout = 'pay'
        }
          this.$router.push('/pay_merchant/'
          + this.$route.params.arg1
          + '/' + this.$route.params.arg2
          + '/' + payout
          + '/' + mid + '/');
        return
      }
      this.$router.push('/pay_merchant/'
        + this.$route.params.arg1
        + '/' + this.$route.params.arg2
        + '/' + 'life'
        + '/' + 'new' + '/')
    }
  }
}
</script>
<style scoped>
.table > tbody > tr > td {
  vertical-align: middle !important;
}
</style>
