<template>
  <d-card class="card-small mb-4" style="border-radius: 0 0.625rem 0.625rem 0.625rem">
    <d-card-body>
      <d-row class="mb-4">
        <d-col lg="2">
          <Action/>
        </d-col>
        <d-col lg="2">
          <DatePicker/>
        </d-col>
        <d-col lg="5">
          <Search/>
        </d-col>
      </d-row>
      <Loader v-if="!loadingData" :height="600" :size="3" />
      <div v-else style="min-height: 600px">

        <d-row class="mt-4">
          <d-col>
            <Pager/>
          </d-col>
          <d-col lg="1" style="min-width: 126px">
            <pageOptions/>
          </d-col>
        </d-row>
        <div class="table-overflow-y-hidden" v-if="showTable">
            <b-table small :fields="fields" :items="dataTable" responsive @row-clicked="selectItem">
                <template #cell(action)="data">
                  {{ $t('components.payout.'+data.item.action) }}
                </template>
                <template #cell(state)="data">
                  <CellState :item="data.item" />
                </template>
                <template #cell(created_at)="data">
                    <p class="text-nowrap">{{ properD(data.item.created_at) }}</p>
                </template>
                <template #cell(amount)="data">
                    <p class="text-nowrap">{{ properMRub(parseFloat(data.item.amount)) }}</p>
                </template>
                <template #cell(secure_id)="data">
                  {{ (data.item.state==='error')?data.item.error_desc:data.item.secure_id }}
                </template>
              <template #cell(client_merchant)="data">
                <p class="text-nowrap">
                  {{ (data.item.action === 'life') ? data.item.attributes.merchant_id : data.item.client_merchant }}
                </p>
              </template>
              <template #cell(order_id)="data">
                <p class="text-nowrap">
                  {{ (data.item.action === 'life') ? data.item.id : data.item.order_id }}
                </p>
              </template>
            </b-table>
        </div>
        <div class="text-center" style="line-height: 30em;">
          <p>{{ showError }}</p>
        </div>
      </div>
    </d-card-body>
  </d-card>
</template>
<style>
    .form-control, .search_transactions {
        height: calc(2.425rem + 2px);
    }
</style>
<script>

import DatePicker from '@/components/Filters/datePicker_old'
import Pager from '@/components/Filters/pager'
import pageOptions from '@/components/Filters/pageOptions'
import Search from '@/components/Filters/search'
import Action from '@/components/Filters/action'

export default {
  components:{
      DatePicker,
      Pager,
      pageOptions,
      Search,
      Action
  },
  data() {
      return {

      }
  },
  computed: {
    showError: function() {
        return this.$store.state[this.$store.state.pager.activeTableNamespace].apiData.error
    },
    loadingData: function () {
      return this.$store.state.pager.apiResponseReceived
    },
    showTable: function () {
      //if (this.$store.state.pager.apiResponseReceived && !this.$store.state.pager.apiDataEmpty) {
        return true
      //}
      //return false
    },
    dataTable: function () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let responseObject = this.$store.state[activeTableNamespace].displayTable.tableApiUrls[activeColumnRow].responseObject
      let dataTable = this.$store.state[activeTableNamespace].apiData[responseObject]
      let url = this.$store.state[activeTableNamespace].displayTable.tableApiUrls[activeColumnRow].url
      dataTable = dataTable.map(item => {
          let cv = {"success": "success","failed": "danger","error": "secondary","processing": "info","timeout": "primary"}
          let tp = ['timeout','processing']
          return {
            ...item,
            variant: (cv[item.state])?cv[item.state]:'outline-secondary',
            bord: (tp.includes(item.state))?true:false,
            link: (tp.includes(item.state))?VUE_APP_SECURE_APP + url + '/' + item.id + '/':false,
            pay_link: (item.action === 'pay')?VUE_APP_SECURE_APP + 'pay/?mid=' + item.client_merchant + '&transaction_id=' + item.secure_id:false
          }
      })
      dataTable = Array.from(dataTable)
      return dataTable
    },
    fields () {return [
      {
        key: 'created_at',
        label: 'Дата',
        templateName: 'CellText'
      },
      {
        key: 'action',
        label: 'Тип модуля',
        templateName: 'CellText'
      },
      {
        key: 'client_merchant',
        label: 'Merchant ID',
        templateName: 'CellText'
      },
      {
        key: 'order_id',
        label: 'Номер заказа',
        templateName: 'CellText'
      },
      {
        key: 'n_card',
        label: 'Номер карты',
        templateName: 'CellText'
      },
      {
        key: 'email',
        label: 'User',
        templateName: 'CellText'
      },
      {
        key: 'amount',
        label: 'Сумма',
        templateName: 'CellText'
      },
      {
        key: 'secure_id',
        label: 'transaction_id',
        templateName: 'CellText'
      },
      {
        key: 'state',
        label: 'Статус',
        templateName: 'CellText'
      }
    ]},
    templatedFields () {
      let templatedFields = []
      this.fields.forEach(field => {
        if (field.hasOwnProperty('templateName')) {
          let f = {}
          f.key = field.key
          f.templateName = field.templateName
          templatedFields.push(f)
        }
      })
      return templatedFields
    }
  },
  created () {

  },
  mounted () {
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pager/updateCurrentTableState', 'default')
    this.$store.dispatch('extend/changedTable')
  },
  methods: {
    selectItem (item, index, event, type) {
      const target = type === 'get' ? event : event.currentTarget
      if (!target) {
        item = {}
        this.$store.dispatch('extend/tableRowSelected', item)
      }

      for (let i = 0; i < target.parentNode.children.length; i++) {
        if (target.parentNode.children[i].classList.contains('select')) {
          target.parentNode.children[i].classList.remove('select')
        }
      }

      target.classList.add('select')
      this.$store.dispatch('extend/tableRowSelected', item)
    },
    open(mid) {
        this.$router.push('/pay_merchant/' + this.$route.params.arg1 + '/' + 'payout/' + mid + '/')
    }
  }
}
</script>
