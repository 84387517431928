<template>
  <div class="small-paddings">
    <input
      class="form-control"
      v-model="providerId"
      placeholder="ID чека"
      >
  </div>
</template>

<script>
export default {
  props: ['runFilters'],
  computed: {
    providerId: {
      get: function () {
        return '';
      },
      set: function (newValue) {
        this.$store.commit('filtersStd/updateProviderId', newValue);
      }
    }
  },
  methods: {
    // run_filters() {
    //   this.$emit('runFilters')
    // }
  }
}
</script>
