import jsonwebtoken from 'jsonwebtoken'
import {Userpilot} from 'userpilot'
import Mixins from '@/plugins/mixins'
// Этот модуль описывает структуру получения данных по аккаунту
// Все, связанное с работой с пользовательским аккаунтом, будет сложено здесь,
// структурировано и максимально упрощено.
// Пока следует помнить, что account пользователя и дата по client - разные вещи
// у одного client может быть множество accounts


export default {
  state: {
    accessToken: "",
    scopesArray: [],
    rolesArray: [],
    mandarin: {
      client_id: 0,
      email: "",
      id: 0,
      is_sandbox: false,
      onboarding_status: "",
      serviceOptions: [],
    },
    clientDataReceived: false,
    clientDataEmpty: true,
    clientData: {},
    hl_merchants: {},
    merchants: [],
    merchants_name: [],
    passwordChangedModalShow: false
  },
  mutations: {
    updateHLMerchants(state, merchantData) {
      state.hl_merchants = merchantData;
    },
    updateClientData(state, clientData) {
      state.clientData = clientData;
      let merchants_processed = [];
      let merchants_name = [];
      let merchants = clientData.merchants;
      merchants.forEach(field => {
        if (field.hasOwnProperty('merchant_id')) {
          if (Number(field.merchant_id) > 0) {
            merchants_processed.push(field.merchant_id);
            let name = '';
            if (field.hasOwnProperty('name')) name = field.name;
            merchants_name.push(name);
          }
        }
      })
      state.merchants = merchants_processed;
      state.merchants_name = merchants_name;
    },
    toggleClientDataEmpty(state, value) {
      state.clientDataEmpty = value;
    },
    toggleClientDataReceived(state, value) {
      state.clientDataReceived = value;
    },
    togglePasswordChangedModalShow(state, value) {
      // Не нужно? Меняется в рамках myaccount
      state.passwordChangedModalShow = value;
    },
    updateAccessToken(state, accessToken) {
      state.accessToken = accessToken
    },
    updateCid(state, cid) {
      state.cid = cid
    },
    updateMandarin(state, mandarin) {
      state.mandarin.client_id = mandarin.client_id
      state.mandarin.email = mandarin.email
      state.mandarin.id = mandarin.id
    },
    updateScopesArray(state, scopesArray) {
      state.scopesArray = scopesArray
    },
    updateRolesArray(state, rolesArray) {
      state.rolesArray = rolesArray
    },
    updateAuthObject(state, authObject) {
      state.auth = authObject
    },
    updateClientSandbox(state, value) {
      state.mandarin.is_sandbox = value
    },
    updateClientOnboardingStatus(state, value) {
      state.mandarin.onboarding_status = value
    },
    updateServiceOptions(state, value) {
      state.mandarin.serviceOptions = value
    },
  },
  actions: {
    authorizeAndRedirectWithQualificationToken(context, data) {
      context.commit('updateAccessToken', data.qualification_token)
      let url = '/api/v1/users/' + data.user_id
      this.$axiosClient.get(url)
        .then(response => {
          console.log(response, 'response for authorizeAndRedirectWithQualificationToken')
          if (response.status === 200) {
            let mandarinObject = {
              client_id: response.data.client_id,
              email: response.data.email,
              id: response.data.id,
            }
            context.commit('updateMandarin', mandarinObject)
            context.commit('updateCid', response.data.client_id)
            context.commit('updateRolesArray', response.data.groups)
            context.commit('updateScopesArray', response.data.permissions)
            context.dispatch('loadV1ClientObject', response.data.client_id)
            context.dispatch('userpilotSendData')
          } else {
            console.log(response, 'error getting user data from authorizeAndRedirectWithQualificationToken')
          }
        })
        .catch(console.error);
    },

    /* No useful info, улучшить ответ бэка.
    loadUserData(context){
      let url = '/api/v1/users/' + context.state.mandarin.id
      this.$axiosClient.get(url)
        .then(response => {
          console.log(response, 'response for loadUserData')
          if (response.status === 200) {
            let mandarinObject = {
              client_id: response.data.client_id,
              email: response.data.email,
              id: response.data.id,
            }
          } else {
            console.log(response, 'error getting user data from loadUserData')
          }
        })
        .catch(console.error);
    },*/
    // Получаем хэш, который нам выдает идентификатор, декодим его и прогружаем в объект auth
    authorizeAndRedirect(context, data) {
      let hash = data.hash
      let routerObject = data.router
      // Если хэш есть то обновляем объект auth. А если его нет - направляем на логин.

      if (!hash || hash.substring(0, 14) !== '#access_token=') {
        context.dispatch('authorizeUser')
        return false
      }
      let authObject = {}
      // убираем символ # и добавляем каждую пару значений в объект
      hash.substring(1).split('&').forEach(function (item) {
        let element = item.split('=')
        if (element[0] === 'state') {
          authObject[element[0]] = JSON.parse(decodeURIComponent(element[1]))
        } else {
          authObject[element[0]] = element[1]
        }
      })
      context.commit('updateAccessToken', authObject.access_token)
      const scopesArray = authObject.scope.split('+')
      context.commit('updateScopesArray', scopesArray)
      const account_data = jsonwebtoken.decode(authObject.id_token)
      if (account_data.hasOwnProperty('mandarin')) {
        context.commit('updateCid', account_data.mandarin.client_id)
        context.commit('updateMandarin', account_data.mandarin)
        // context.dispatch('loadUserData') TODO no useful info, change it.
      }
      context.dispatch('userpilotSendData')

      const rolesArray = account_data.roles.split(' ')
      context.commit('updateRolesArray', rolesArray)

      // Проверяем на особые случаи, как-то - возвращение после смены пароля
      // убрать после проверки.
      if (authObject.state.redirect !== undefined && authObject.state.redirect != '') {
        let redirectHash = authObject.state.redirect.substring(authObject.state.redirect.indexOf('#') + 1)
        if (redirectHash === 'passwordchanged') {
          context.commit('togglePasswordChangedModalShow', true)
          let routePath = '/settings#security'
          routerObject.push(routePath)
          return
        }
      }

      // Проведя все необходимые действия, редиректим.
      routerObject.push(authObject.state.redirect)
    },
    async loadingHLMerchants(context) {
      let url = VUE_APP_API_LIFE_API + '/life-api/api/v1/arm/merchants_short/'
      await this.$axios.get(url)
        .then(response => {
          if (Object.prototype.hasOwnProperty.call(response, 'data')) {
            context.commit('updateHLMerchants', response.data)
          } else
            console.log(response)
        })
        .catch(console.error)
    },

    userpilotSendData(context){
      let userpilot_token;
      if (typeof VUE_APP_USERPILOT_TOKEN !== 'undefined') {
        userpilot_token = VUE_APP_USERPILOT_TOKEN;
      } else {
        userpilot_token = null;
      }

      if (userpilot_token) {
        Userpilot.initialize(userpilot_token);
        let userId = context.state.mandarin.id;
        let userPromise = this.$axios.get(VUE_APP_IDENTITY + '/api/v1/users/' + userId);
        let clientId = context.state.mandarin.client_id;
        let clientDataPromise = context.dispatch('loadV1ClientObject', clientId);

        Promise.all([userPromise, clientDataPromise])
          .then(results => {
            let idp_user = results[0].data;
            let sendData = {
              name: idp_user.first_name + ' ' + idp_user.last_name,
              email: idp_user.email,
              created_at: idp_user.date_joined.split('T')[0],
              company: {
                id: clientId,
                created_at: context.state.clientData.created.split('T')[0],
                sla: context.state.clientData.sla,
                is_sandbox: context.state.clientData.is_sandbox,
                onboarding_status: context.state.clientData.onboarding_status,
              }
            }
            if (typeof(context.state.clientData.industry) === 'object' && context.state.clientData.industry !== null
              && Object.prototype.hasOwnProperty.call(context.state.clientData.industry, 'codename')){
              sendData.company.industry = context.state.clientData.industry.codename
            }
            if (typeof(context.state.clientData.legal_info) === 'object' && context.state.clientData.legal_info !== null
              && Object.prototype.hasOwnProperty.call(context.state.clientData.legal_info, 'legal_name')){
              sendData.company.legal_name = context.state.clientData.legal_info.legal_name
            }
            if (typeof(context.state.clientData.legal_info) === 'object' && context.state.clientData.legal_info !== null
              && Object.prototype.hasOwnProperty.call(context.state.clientData.legal_info, 'legal_type')){
              sendData.company.legal_type = context.state.clientData.legal_info.legal_type
            }
            if (typeof(context.state.clientData.account_manager) === 'object' && context.state.clientData.account_manager !== null
              && Object.prototype.hasOwnProperty.call(context.state.clientData.account_manager, 'id')){
              sendData.company.account_manager = context.state.clientData.account_manager.id
            }
            if (typeof(context.state.clientData.country) === 'object' && context.state.clientData.country !== null
              && Object.prototype.hasOwnProperty.call(context.state.clientData.country, 'iso_country_name_a3')){
              sendData.company.country = context.state.clientData.country.iso_country_name_a3
            }
            Userpilot.identify(userId, sendData);
          })
          .catch(error => {
            console.error("Ошибка при получении данных:", error);
          });
      }
    },

    populateFlomniDataStructure(context){
      let attributes ={
        //name: '',
        email: '',
        //phone: '',
        client_id: '',
        legal_name: '',
        services: '',
        sla: '',
        account_manager: '',
      }
      if (Object.prototype.hasOwnProperty.call(context.state, 'mandarin')){
        attributes.email = context.state.mandarin.email
        attributes.client_id = context.state.mandarin.client_id
      }
      if (Object.prototype.hasOwnProperty.call(context.state, 'clientData')){
        attributes.sla = context.state.clientData.sla
        //attributes.services = context.state.clientData.services
        if (Object.prototype.hasOwnProperty.call(context.state.clientData, 'account_manager')){
          attributes.account_manager = context.state.clientData.account_manager.name
        }
        if (Object.prototype.hasOwnProperty.call(context.state.clientData, 'legal_info')){
          attributes.legal_name = context.state.clientData.legal_info.legal_name
        }
      }
      let newObject = {
        visitorAttributes: attributes,
        }
      Object.assign(window.flomniConfig.visitorAttributes, newObject.visitorAttributes)
    },

    loadV1ClientObject(context, clientId) {
      return new Promise((resolve, reject) => {
        let url = VUE_APP_API_CLIENTS + '/clients/v1/clients/' + clientId
        this.$axios.get(url)
          .then(response => {
            if (response.status === 200) {
              context.commit('toggleClientDataReceived', true);

              // Особая ситуация когда клиент не прошел до конца квалификацию.
              // в этом случае у него и is_sandbox true и onboarding_status = wait_client_data
              // и questions и services пустые.

              if (response.data.client.is_sandbox && response.data.client.onboarding_status === 'wait_client_data'
                && (response.data.client.questions.length === 0 || response.data.client.services.length === 0)
              ) {
                let user_id = context.state.mandarin.id;
                let client_id = context.state.mandarin.client_id;
                let anketa_token = context.state.accessToken
                let path = Mixins.methods.removeTrailingSlash(VUE_APP_ONBOARDING_URL) + '/qualifications?anketa_token=' + anketa_token 
                + '&client_id=' + client_id + '&user_id=' + user_id
                window.location = path
              }
              // Конец особого случая, дальше идет стандартная обработка без редиректа.
              context.dispatch('populateFlomniDataStructure') // Flomni - загружаем данные в структуру чата
              if (response.data.client.is_sandbox) {
                context.commit('updateClientSandbox', response.data.client.is_sandbox);
              }
              if (Object.prototype.hasOwnProperty.call(response.data.client, 'onboarding_status')) {
                context.commit('updateClientOnboardingStatus', response.data.client.onboarding_status);
              }
              if (Object.prototype.hasOwnProperty.call(response.data.client, 'merchants')) {
                let sitesObject = JSON.parse(JSON.stringify(response.data.client.merchants))
                let processedSites = [];
                sitesObject.forEach(site => {
                  if (site.is_active && site.merchant_id !== null) {
                    processedSites.push(site);
                  }
                })
                processedSites.sort((a, b) => (a.merchant_id > b.merchant_id) ? 1 : ((b.merchant_id > a.merchant_id) ? -1 : 0));
                response.data.client['processedSites'] = processedSites;
                context.commit('updateClientData', response.data.client);
                context.commit('toggleClientDataEmpty', false);
                resolve(context.state.clientData);
              }
            } else {
              context.commit('toggleClientDataEmpty', true);
              console.log(response, 'loadV1ClientObject error')
              reject(new Error('Ошибка загрузки данных клиента'));
            }

          })
          .catch(error => {
            console.error("Ошибка при запросе данных клиента:", error);
            reject(error);
          });
      });

    },
    loadServiceOptions(context) {
      let url = VUE_APP_API_CLIENTS + '/clients/v1/clients/' + context.state.mandarin.client_id + '/services';
      this.$axios.get(url)
        .then(response => {
          console.log(response, 'response service options')
          if (response.status === 200) {
            context.commit('updateServiceOptions', response.data.services)
          } else {
            console.log(response, 'loadServiceOptions error')
          }
        })
        .catch(console.error);
    },

    authorizeUser(context) {
      // Вариант когда мы получаем ошибку в урле - перекидываем на страницу ошибки.
      // пока обрабатываем только ?error=invalid_scope
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('error')) {
        let error = urlParams.get('error');
        if (error === 'invalid_scope') {
          context.commit('updateAccessToken', '');
          return window.location = `${VUE_APP_IDENTITY}/accounts/logout`;
        }
      }

      const loco = window.location;
      const antiCache = Date.now()
      const redirectUrl = `${loco.protocol}//${loco.host}/login?nocache=` + antiCache;
      const scope =
        'openid ' +
        'transactions.read  ' + // доступ к транзакциям paygw
        'clients:client.read ' + // доступ к инфе по собственному клиенту, основное право для шапки, списка мерчей и т.д.
        'loans.read ' + //
        'loans.write ' + //
        'purchase_requests.read ' +
        'loan_requests.read ' +
        'purchases.read ' +
        'report.read ' +
        'report.write ' +
        'life-api:core.applications.read ' +
        'pay_bank_account_balance.read ' +
        'secure_merchants_v3.read ' +
        'secure_secrets.read ' +
        'hl_app.read ' +
        'secure_settlements_sub_merchant.read ' +
        'secure_settlements_sub_merchant.write ' +
        'hl_broker:applications.read ' +
        'hl_broker:applications.write ' +
        'secure_app_life_transactions.write ' +
        'secure_app_pay_transactions.write ' +
        'directory:directories.merchant_notifications_service.read ' +
        'directory:directories.read ' +
        'secure_app_life_module.write ' +
        'secure_app_pay_module.write ' +
        'secure_app_payout_module.write ' +
        'secure_app_binding_transactions.write ' +
        'life_api:merchants.read ' +
        'secure_app_transactions.read_all ' +
        'secure_app_transactions.read ' +
        'secure_app_module.read ' +
        'paygw:transactions_billing_info.read ' +
        'paygw:transactions_billing_info.write ' +
        'receipts:public_receipts.read ' +
        'idp:users.read ' +
        'antifraud:client_limits_self.read ' +
        'antifraud:client_limits_self.write ' +
        'secure_card_bindings.write ' +
        'clients:client_report_emails.write ' +
        'clients:client_bank_accounts.write ' +
        'clients:client_services.read ' +
        'clients:clients.read ' +
        'clients:client_merchants.read ' +
        'clients:client_merchants.write ' +
        'clients:client_documents.write ' +
        'clients:client_legal_info.write ' +
        'clients:client_report_emails.write ' +
        'clients:client_send_for_approval.write ' +
        'clients:client_services.read ' +
        'clients:client_services.write ' +
        'clients:reference_data.read ' +
        'clients:client_qualification_questions clients:client_metadata ' +
        'idp:users_self.read ' +
        'idp:users_self.write ' +
        'self_introspection ' +
        'clients.self.read ' +
        'clients:client_documents.read ' +
        'directory:directories.paygwErrorsMapping.read directory:directories.read ' +
        // limits to refunds
        'secure_merchants_v3.read ' +
        'secure:transactions.read secure:transactions_all.read ' +
        '';

      let redirectPath = loco.pathname + loco.hash;
      let stateObject = {redirect: redirectPath};
      let stateJSON = JSON.stringify(stateObject);
      let nonce = Date.now()
      const state = encodeURIComponent(stateJSON);
      /* eslint operator-linebreak: off */
      let oauth = VUE_APP_IDENTITY
        + '/oauth/authorize?'
        + 'client_id=' + VUE_APP_CLIENT_ID
        + '&redirect_uri=' + redirectUrl
        + '&scope=' + scope
        + '&response_type=id_token%20token'
        + '&response_mode=fragment'
        + '&nonce=' + nonce
        + '&state=' + state;
      return loco.href = oauth;
    }
  }
}
