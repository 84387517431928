<template>
  <div class="cell-wrapper">
      <span v-if="firstvalue">{{firstvalue}}&nbsp;<clipboard :data="firstvalue"/><br/></span>
      <span v-if="secondvalue">{{secondvalue}}&nbsp;<clipboard :data="secondvalue"/></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  computed: {
    firstvalue () {
      if (this.item.instrument_type_id === 801) {
        return false
      }
      return this.item.cs1
    },
    secondvalue () {
      if (this.item.instrument_type_id === 1 && this.item.cs2 !== null) {
        return this.item.order_id
      }
      if (this.item.instrument_type_id === 1 && this.item.cs2 === null) {
        return this.item.pan
      }
      if (this.item.instrument_type_id === 801) {
        return this.item.cs1
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
