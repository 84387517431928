<template>
  <b-nav tabs class="tables-top-nav">
    <b-nav-item class="desktop-layer" :class="{'visible-desktop': !isActiveFormTab}" exact to="/payouts">Все операции</b-nav-item>
    <b-nav-item class="desktop-layer" :class="{'visible-desktop': !isActiveFormTab}" :to="{ name: 'PayoutsForm' }" :active="isActiveFormTab">По форме</b-nav-item>
    <b-nav-item class="desktop-layer" :class="{'visible-desktop': !isActiveFormTab}" exact :href="merchant_vue3_registry_url">Реестры</b-nav-item>

    <b-nav-item class="mobile-layer" :class="{'visible-mobile': isActiveFormTab}" :active="isActiveFormTabMobile('payout')" @click="setMobileTab('payout')">Форма</b-nav-item>
    <b-nav-item class="mobile-layer" :class="{'visible-mobile': isActiveFormTab}" :active="isActiveFormTabMobile('history')" @click="setMobileTab('history')">История</b-nav-item>
    <b-nav-item class="mobile-layer" :class="{'visible-mobile': isActiveFormTab}" exact
                :href="merchant_vue3_registry_url">Реестры
    </b-nav-item>
  </b-nav>
</template>

<script>
export default {
  props: ['mobileTab'],
  computed: {
    merchant_vue3_registry_url() {
      return `${VUE_APP_MERCHANT_VUE3_URL}payouts/registries`
    },
    isActiveFormTab() {
      return this.$route.name === 'PayoutsForm' || this.$route.path.startsWith('/payouts/form/');
    },
  },
  methods: {
    isActiveFormTabMobile(tab) {
      return this.mobileTab === tab;
    },
    setMobileTab(tab) {
      this.$emit('setMobileTab', tab);
    },
  },
}
</script>
<style scoped>
.mobile-layer {
  display: none;
}
@media (max-width: 953px) {
  .desktop-layer {
    display: none;
  }
  .desktop-layer.visible-desktop {
    display: block;
  }
  .mobile-layer.visible-mobile {
    display: block;
  }
}
</style>
