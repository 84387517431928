<template>
  <div></div>
</template>

<script>

import NotificationHTML from './notificationHTML'
export default {
  computed:{
    notificationsAreLoaded(){
      return this.$store.state.notificationsService.notificationsAreLoaded
    }
  },
  watch: {
    notificationsAreLoaded: function (newValue, oldValue) {
      if (newValue){
        // Данные прогрузились. Теперь нам надо их выложить.
        this.showNotificationToast()
      }
    }
  },
  methods:{
      // or "success", "error", "default", "info" and "warning"
    showNotificationToast(){
      let options = {
        position: "top-right",
        timeout: false,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      }


      let notificationsDisplay = this.$store.state.notificationsService.notificationsDisplay
      if (notificationsDisplay.length > 0){
        notificationsDisplay.forEach(item => {
          options.type = item.type
          this.$toast({
              component: NotificationHTML,
              props: {
                message: item.notification,
              },
            }, options);
        })
      }
    }
  },
  mounted(){
    this.$store.dispatch('notificationsService/loadNotifications')
    this.$store.dispatch('notificationsService/loadPaygwErrorsMapping')
  }
}
</script>

<style>
.Vue-Toastification__toast a{
  color: white;
  text-decoration: underline;
}
</style>
