// Модуль, отвечающий за работу вкладки Аналитика и всего с ней связанного.
import { defaultFilterString } from '@/configs/filtersTables/defaultFilters'

export default {
  namespaced: true,
  state: {
    transactions: [],
    urls: {
      transactions: '/payment-gateway/v3/transactions'
    }
  },
  mutations: {
    updateTransactions(state, value){
      state.transactions = value
    }
  },
  actions: {
    prepareFilterByString (context){
      let filters_config = context.rootState.filtersStd.filtersConfigs[context.rootState.filtersStd.activeFilterConfig]
      //добавляем filterByString
      let filterByString = '';
      // Сперва устанавливаем обязательный фильтр на client_id. Он на самом деле не нужен,
      // потому, что по правам, пользователь не может получить не свои транзакции, 
      // но на всякий случай установим.
      filterByString += '&client_id='+context.rootState.account.cid
      //тут мы опрашиваем фильтры и строим логику для них
      if (context.rootState.filtersStd.dateFrom){
        filterByString += '&updated>='+context.rootState.filtersStd.dateFrom
      }
      if (context.rootState.filtersStd.dateTo){
        filterByString += '&updated<'+context.rootState.filtersStd.dateTo
      }
      if (context.rootState.filtersStd.statusString){
        filterByString += '&status in '+context.rootState.filtersStd.statusString
      } else {
        let string = defaultFilterString(filters_config, 'status')
        if (string != ''){filterByString += '&status in '+string}
      }
      if (context.rootState.filtersStd.instrumentTypesString){
        filterByString += '&instrument_type_id in '+context.rootState.filtersStd.instrumentTypesString
      } else {
        let string = defaultFilterString(filters_config, 'instrument_type')
        if (string != ''){filterByString += '&instrument_type_id in '+string}
      }
      if (context.rootState.filtersStd.mids){
        filterByString += '&mw_merchant_id in '+context.rootState.filtersStd.mids
      }
      if (context.rootState.filtersStd.opcodesString){
        filterByString += '&opcode in '+context.rootState.filtersStd.opcodesString
      } else {
        let string = defaultFilterString(filters_config, 'opcodes')
        if (string != ''){filterByString += '&opcode in '+string}
      }
      // для mw_type, невидимого фильтра.
      let string = defaultFilterString(filters_config, 'mw_type')
      if (string != ''){filterByString += '&mw_type in '+string}
      /*
      if (context.rootState.filtersStd.transactionId){
        filterByString += '&id='+context.rootState.filtersStd.transactionId
      }          
      if (context.rootState.filtersStd.opcodesString){
        filterByString += '&opcode in '+context.rootState.filtersStd.opcodesString
      }

*/

      //очищаем первый &
      if (filterByString && filterByString.charAt(0)==='&'){
        filterByString = filterByString.substring(1)
      }
      filterByString = encodeURIComponent(filterByString)
      context.commit('filtersStd/updateFilterByString', filterByString, {root: true})
    },
    refreshTable (context, queryObject) {
      let url = context.state.urls.transactions

      //делаем ветку логики на запросы по типам.
      if (queryObject && queryObject.type === 'cursor'){
          url += '?cursor='+queryObject.params.cursor
      }
      else{
        //сперва делаем ограничение по limit_to
        let limit = 'limit_to='+ queryObject.params.limit_to
        url += '?' + limit

        // Создаем строку FilterBy
        context.dispatch('prepareFilterByString')
        
        //Сортировки тут нет, вроде, поэтому пока пропускаем.

        //Добавляем фильтры
        let filterByString = context.rootState.filtersStd.filterByString
        if (filterByString){
          url += '&' + 'filter_by='+filterByString
        }
      }
      this.$axios.get(url)
      .then(response => {
        let cursor = response.data.cursor
        let transactions = response.data.transactions
        context.commit('pagerStd/updateCursor', cursor, { root: true })
        context.commit('pagerStd/updateApiResponseReceived', true, { root: true })
        //Если в запросе передаётся currentOffset, используем его, если нет - ставим 0.
        if (typeof queryObject === 'object' && queryObject !== null && queryObject.hasOwnProperty('currentOffset')){
          context.commit('pagerStd/updateCurrentOffset', queryObject.currentOffset, { root: true })
        }
        else{
          context.commit('pagerStd/updateCurrentOffset', 0, { root: true })
        }
        context.commit('updateTransactions', transactions)
       
      })
      .catch(error => {
        console.log('error in promise', error)
      })
    }
  }
}