<template>
  <div>
    <multiselect v-model="selectedValues" :options="serviceOptions" :multiple="true"
                 track-by="label" label="label" @input="selectChanged"
                 deselect-label="Удалить" select-label="Добавить" selected-label="Опция выбрана"
                 placeholder="Сервис"></multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      selectedValues: null
    }
  },
  computed: {
    resetAllFilters () {
      return this.$store.state.filtersStd.resetAllFilters
    },
    serviceOptions(){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return filters_config.is_sandbox.options
    }
  },
  watch: {
    resetAllFilters (newCount, oldCount) {
      this.selectedValues = null
      this.$nextTick(() => {
        this.$store.commit('filtersStd/resetAllFilters', 'ready')
      })
    }
  },
  created(){
    let selected = []
    let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
    filters_config.is_sandbox.options.forEach(function(value){
      if (Object.prototype.hasOwnProperty.call(value, 'selected') && value.selected){
        selected.push(value)
      }
    })
    this.selectedValues = selected;
  },
  mounted(){
    this.selectChanged()
  },
  methods: {
    selectChanged() {
      let isSandbox = null
      let isSandboxTrue = false
      let isSandboxFalse = false
      this.selectedValues.forEach(item => {
        if (item.value === true) isSandboxTrue = true
        if (item.value === false) isSandboxFalse = true
      })
      if (isSandboxTrue && isSandboxFalse) {
        isSandbox = null
      } else if (isSandboxTrue) {
        isSandbox = true
      } else if (isSandboxFalse) {
        isSandbox = false
      }
      this.$store.commit('filtersStd/updateIsSandbox', isSandbox)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
