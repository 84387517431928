<template>
  <b-container fluid class="main-container mt-3 mb-3">
    <b-row>
      <b-col>
        <NotificationsService />
        <div class="component-element">
          <component v-bind:is="currentComponent"></component>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import PayoutsForm from '@/components/Tabs/Payouts/PayoutsForm.vue'
import Invoices from '@/components/Tabs/Invoices/invoicesWrapper.vue'
import Projects from '@/components/Tabs/Projects/tableProjects.vue'
import NotificationsService from "@/components/NotificationsService/notificationsService.vue";
export default {
  components: {
    NotificationsService,
    PayoutsForm,
    Invoices,
    Projects,
  },
  data () {
    return {
    }
  },
  computed: {
    currentComponent () {
      if (Object.prototype.hasOwnProperty.call(this.$route, 'name')){
        return this.$route.name
      }
      return 'PayoutsForm'
    },
  },
  created () {
    this.$store.dispatch('loadV1ClientObject', this.$store.state.account.cid)
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>

