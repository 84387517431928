export default {
  namespaced: true,
  state: {
    busy: false,
    report: null,
  },
  mutations: {
    updateBusy(state, value){
      state.busy = value
    },
  },
  actions: {
    switchNotificationsActivated(context, data){
      let payload = {
        is_active: data.is_active
      }
      let url = VUE_APP_API_CLIENTS + '/clients/v1/clients/' + context.rootState.account.cid + '/report_is_active'
      context.commit('updateBusy', true)
      this.$axios.patch(url, payload)
        .then(response => {
          context.commit('updateBusy', false)
          if (response.status === 200){
            console.log(response, 'response')
            if(payload.is_active){this._vm.$toast.success('Отправка реестров включена')}
            else{this._vm.$toast.success('Отправка реестров отключена')}
            context.dispatch('loadV1ClientObject', context.rootState.account.cid, { root: true })
            return false
          }
          this._vm.$toast.error('К сожалению, нам не удалось изменить состояние отправки реестров ')
          console.log(response, 'response in switchNotificationsActivated')
        })
        .catch(error => {
          console.log('error in promise', error)
        })
    },
    addNewEmail(context, data){
      let payload = {
        email: data.email,
        is_active: true
      }
      let url = VUE_APP_API_CLIENTS + '/clients/v1/clients/' + context.rootState.account.cid + '/report_emails'
      context.commit('updateBusy', true)
      this.$axios.post(url, payload)
        .then(response => {
          context.commit('updateBusy', false)
          if (response.status === 201){
            this._vm.$toast.success('Добавлен адрес ' + data.email)
            context.dispatch('loadV1ClientObject', context.rootState.account.cid, { root: true })
            return false
          }
          this._vm.$toast.error('К сожалению, нам не удалось добавить адрес ' + data.email)
          console.log(response, 'response in addNewEmail')
        })
        .catch(error => {
          console.log('error in promise', error)
        })
    },
    editEmailStatus(context, data){
      let payload = {
        is_active: data.status
      }
      let url = VUE_APP_API_CLIENTS + '/clients/v1/clients/' + context.rootState.account.cid + '/report_emails/' + data.item.id
      context.commit('updateBusy', true)
      this.$axios.patch(url, payload)
        .then(response => {
          context.commit('updateBusy', false)
          if (response.status === 200){
            if (data.status){ this._vm.$toast.success('Адрес ' + data.item.email + ' активирован') }
            else{ this._vm.$toast.success('Адрес ' + data.item.email + ' деактивирован') }
            context.dispatch('loadV1ClientObject', context.rootState.account.cid, { root: true })
            return false
          }
          this._vm.$toast.error('К сожалению, нам не удалось поменять статус вашего адреса ' + data.item.email)
          console.log(response, 'response in editEmailStatus')
        })
        .catch(error => {
          console.log('error in promise', error)
        })
    },
    deleteEmail(context, item){
      let url = VUE_APP_API_CLIENTS + '/clients/v1/clients/' + context.rootState.account.cid + '/report_emails/' + item.id
      context.commit('updateBusy', true)
      this.$axios.delete(url)
        .then(response => {
          context.commit('updateBusy', false)
          if (response.status === 200){
            this._vm.$toast.success('Адрес ' + item.email + ' удален')
            context.dispatch('loadV1ClientObject', context.rootState.account.cid, { root: true })
            return false
          }
          this._vm.$toast.error('К сожалению, нам не удалось удалить ваш адрес ' + item.email)
          console.log(response, 'response in deleteEmail')
        })
        .catch(error => {
          console.log('error in promise', error)
        })
    }
  }
}