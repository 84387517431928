<template>
  <div class="wrapper-global">
    <div class="wrapper-content">
      <component :is="sidebarComponent" class="main-sidebar" :class="{active: activeSidebar}" @toggleSidebar="toggleSidebar" />
      <div class="custom-main">
        <component :is="navbarComponent" class="main-navbar" @toggleSidebar="toggleSidebar"/>
        <slot />

      </div>
    </div>
    <div class="wrapper-footer">
      <main-footer />
    </div>
    <SupportIcons />
  </div>
</template>

<script>
import MainNavbar from '@/components/Layout/MainNavbar/MainNavbar.vue'
import MainSidebar from '@/components/Layout/StdSidebar.vue'
import MainNavbarMobile from '@/components/Layout/MainNavbar/MobileNavbar.vue'
import MainSidebarMobile from '@/components/Layout/MobileSidebar.vue'
import MainFooter from '@/components/Layout/MainFooter/MainFooter.vue'
import SupportIcons from '../components/Layout/SupportIcons.vue'

export default {
  name: 'merchantsMobilePlus',
  components: {
    MainNavbar,
    MainSidebar,
    MainFooter,
    MainNavbarMobile,
    MainSidebarMobile,
    SupportIcons
  },
  data() {
    return {
      activeSidebar: false,
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    toggleSidebar() {
      this.activeSidebar = !this.activeSidebar;
    }
  },
  computed: {
    sidebarComponent() {
      return this.windowWidth > 1440 ? 'MainSidebar' : 'MainSidebarMobile';
    },
    navbarComponent() {
      return this.windowWidth > 1440 ? 'MainNavbar' : 'MainNavbarMobile';
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }
}
</script>

<style scoped>
.wrapper-global{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper-footer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  padding-left: 140px;
}

.wrapper-content {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}
.custom-main {
  padding-left: 145px;
  padding-right: 10px;
  min-width: 1320px;
}
.main-sidebar {
  background: none;
  box-shadow: none;
}
@media (max-width: 1440px) {
  .wrapper-footer {
    display: none;
  }
  .custom-main {
    padding-left: 0;
    padding-right: 0;
    min-width: 360px;
  }
  .main-sidebar {
    display: none;
  }
  .main-sidebar.active {
    transform: translateY(0) !important;
    display: block;
    background: #FFF;
    box-shadow: #F5F6F8;
    border-right: 1px solid #D2D2D2;
  }
}
</style>
