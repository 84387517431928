// Модуль, отвечающий за работу Поиска и всего с ней связанного.
export default {
  namespaced: true,
  state: {
    query: '',
    transactions: [],
    urls: {
      transactions: '/payment-gateway/v3/transactions/search',
      transactionsCursor: '/payment-gateway/v3/transactions'
    }
  },
  mutations: {
    updateTransactions(state, value){
      state.transactions = value
    },
    updateQuery(state, value){
      state.query = value
    }
  },
  actions: {
    refreshTable (context, queryObject) {
      let url = context.state.urls.transactions
      // У нас эндпойнт search в payment-gateway не обрабатывает запросы по cursor, хотя и передает их. Вместо этого все запросы с cursor 
      // пересылаются на другой эндпойнт, просто транзакций.
      let urlCursor = context.state.urls.transactionsCursor
      //делаем ветку логики на запросы по типам.
      if (queryObject && queryObject.type === 'cursor'){
        url = urlCursor + '?cursor='+queryObject.params.cursor
      }
      else{
        if (context.state.query) {
          url += '?q=' + context.state.query
        }else{
          context.commit('pagerStd/updateApiResponseReceived', true, { root: true })
          return false
        }
        //сперва делаем ограничение по limit_to 

        /*Меня уверяют, что limit_to d поиске работает неправильно, я не могу повторить, но меня уговорили отменить его.
        let limit = 'limit_to='+ queryObject.params.limit_to
        url += '&' + limit
        */
      }
      this.$axios.get(url)
      .then(response => {
        let cursor = response.data.cursor
        let transactions = response.data.transactions
        context.commit('pagerStd/updateCursor', cursor, { root: true })
        context.commit('pagerStd/updateApiResponseReceived', true, { root: true })
        //Если в запросе передаётся currentOffset, используем его, если нет - ставим 0.
        if (typeof queryObject === 'object' && queryObject !== null && queryObject.hasOwnProperty('currentOffset')){
          context.commit('pagerStd/updateCurrentOffset', queryObject.currentOffset, { root: true })
        }
        else{
          context.commit('pagerStd/updateCurrentOffset', 0, { root: true })
        }
        context.commit('updateTransactions', transactions)
       
      })
      .catch(error => {
        console.log('error in promise', error)
      })
    }
  }
}