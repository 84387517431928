<template>
  <div class="small-paddings">
    
    <v-date-picker
    mode="range"
    v-model="selectedDate"
    @input="dateChanged"
    is-double-paned
    :show-day-popover="false"
    popoverVisibility="focus">
    <template slot-scope="props">
        <d-input
        type="text"
        icon="calendar"
        :value="props.inputValue"
        :placeholder="inputState.message"
        @change.native="props.updateValue($event.target.value)"
        expanded>
        </d-input>
    </template>
    </v-date-picker>
  </div>
</template>

<script>
import moment from 'moment'
import { setupCalendar, DatePicker } from 'v-calendar'
setupCalendar({
  firstDayOfWeek: 2
})
export default {
  components: {
    'v-date-picker': DatePicker
  },
  data () {
    return {
      selectedDate: {
        start: moment().subtract(1, 'days').toDate(),
        end: moment().toDate()
      },
      settingProcess: true
    }
  },
  computed: {
    rangeStart: function () {
      return moment(this.selectedDate.start).format('YYYY-MM-DD')
    },
    rangeEnd: function () { return moment(this.selectedDate.end).format('YYYY-MM-DD') },
    inputState () {
      if (!this.selectedValue) {
        let message = this.$i18n.t('filters.dp2')
        return {
          type: 'is-danger',
          message: message
        }
      }
      return {
        type: 'is-primary',
        message: ''
      }
    }
  },
  methods: {
    dateChanged () {
      let values = {}
      values.start = moment.utc(this.dateIsMoscow(this.rangeStart)).format()
      values.end = moment.utc(this.dateIsMoscow(this.rangeEnd)).add(1, 'day').format()
      if (!this.settingProcess) {
        // нам не нужно запускать этот процесс если мы только устанавливаем значение при сетапе фильтра
        values.skipApiQuery = false
      } else {
        values.skipApiQuery = true
      }
      this.$store.commit('filtersStd/setDates', values)
    }
  },
  created () {
    this.dateChanged()
    this.$nextTick(() => { this.settingProcess = false })
  }
}
</script>
<style scoped>
.small-paddings >>> input{
  padding-left: 10px;
  padding-right: 10px;
}
.form-control{
  height: 38px;
  border: 1px solid #e8e8e8;
}

</style>
