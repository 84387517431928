<template>
  <span>
    <i class="pointer far fa-copy" v-b-tooltip.hover="tooltipText" @click="clipboardHandler(dataToClip)"></i>
  </span>
</template>

<script>
export default {
  props: ['id', 'data', 'text'],
  computed: {
    dataToClip () {
      if (this.data) {
        return this.data;
      } else {
        return this.id;
      }
    }
  },
  methods: {
    tooltipText () {
      let text = (this.text)?this.$i18n.t('service.copy2'):this.$i18n.t('service.copy') + ' ' + this.dataToClip;
      return text;
    },
    clipboardHandler (value) {
      this.$clipboard(value)
      this.$toast.success("Скопировано: "+value);
    }
  }
}
</script>
