<template>
  <input
    class="form-control"
    :value="displayValue"
    @keydown="onKeyDownHandler"
    @input="onInput"
    @blur="onBlur"
    @focus="onFocus"
    @click="setCursorToEnd"
    @cut="setCursorToEnd"
    @paste="setCursorToEnd"
    type="text"
    inputmode="decimal"
  >
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    separator: {
      type: String,
      default: ' ',
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      displayValue: '',
      isFocused: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.updateDisplayValue(newValue);
      },
    },
  },
  methods: {
    onKeyDownHandler(event) {
      if (['ArrowLeft', 'ArrowUp', 'Home'].includes(event.key)) {
        event.preventDefault();
        this.setCursorToEnd(event);
        return;
      }

      const key = event.key;
      const input = event.target;
      const { selectionStart, selectionEnd } = input;
      const value = input.value;
      let cursorPositionAdjustment = 0;
      let newValue

      // Обработка нажатия Delete перед сепаратором
      if (key === 'Delete' && selectionStart === selectionEnd) {
        const nextChar = value[selectionStart];
        if (nextChar === this.separator) {
          event.preventDefault(); // Предотвратить стандартное удаление
          // Удалить символ после сепаратора
          newValue = value.substring(0, selectionStart) + value.substring(selectionStart + 2);
          input.value = newValue;
          this.onInput(event); // Пересчитать и отправить новое значение
          input.setSelectionRange(selectionStart, selectionStart); // Восстановить позицию курсора
        }
      }

      // Обработка нажатия Backspace после сепаратора
      if (key === 'Backspace' && selectionStart === selectionEnd && selectionStart > 0) {
        const prevChar = value[selectionStart - 1];
        if (prevChar === this.separator) {
          event.preventDefault(); // Предотвратить стандартное удаление
          // Удалить символ перед сепаратором
          newValue = value.substring(0, selectionStart - 2) + value.substring(selectionStart - 1);
          input.value = newValue;
          this.onInput(event); // Пересчитать и отправить новое значение
          input.setSelectionRange(selectionStart - 1, selectionStart - 1); // Восстановить позицию курсора
        }
      }

      this.$nextTick(() => {
        // Рассчитываем новую позицию курсора с учетом cursorPositionAdjustment
        const newCursorPosition = selectionStart + cursorPositionAdjustment;
        input.setSelectionRange(newCursorPosition, newCursorPosition);
      });
    },
    onInput(event) {
      let value = event.target.value;
      const cursorPositionBefore = event.target.selectionStart;

      // Заменяем запятые на точки для единообразия и разрешаем точку как десятичный разделитель
      value = value.replace(/,/g, '.');
      const originalValue = value;

      // Удаляем все кроме цифр и точки
      value = value.replace(/[^0-9.]/g, '');

      // Ограничиваем количество точек до одной
      const parts = value.split('.');
      value = parts.shift() + (parts.length > 0 ? '.' + parts.join('') : '');

      // Применяем форматирование к целой части
      let [integerPart, decimalPart = ''] = value.split('.');
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, this.separator);

      // Ограничиваем дробную часть двумя цифрами, если она есть
      decimalPart = decimalPart.substring(0, 2);

      // Объединяем целую и дробную части. Если значение заканчивалось на точку, сохраняем этот факт.
      value = integerPart + ((decimalPart || originalValue.endsWith('.')) ? '.' + decimalPart : '');

      // Устанавливаем отформатированное значение обратно в инпут
      event.target.value = value;

      const lengthAfter = event.target.value.length;
      let newCursorPosition = cursorPositionBefore + (lengthAfter - originalValue.length);

      // Корректировка позиции курсора для особых случаев, например, когда курсор стоит перед сепаратором
      if (event.inputType === 'deleteContentBackward' && originalValue[cursorPositionBefore - 1] === this.separator) {
        newCursorPosition -= 1;
      } else if (event.inputType === 'deleteContentForward' && originalValue[cursorPositionBefore] === this.separator) {
        newCursorPosition += 1;
      }

      // Обновляем позицию курсора
      this.$nextTick(() => {
        event.target.setSelectionRange(newCursorPosition, newCursorPosition);
      });

      // Отправляем числовое значение, если возможно
      let numericValue = parseFloat(value.replace(new RegExp(`\\${this.separator}`, 'g'), ''));
      numericValue = isNaN(numericValue) ? '' : numericValue;
      this.$emit('input', numericValue);
    },
    onBlur() {
      this.isFocused = false;
      if (this.value || this.value === 0) {
        this.updateDisplayValue(this.value);
      } else {
        this.displayValue = '';
      }
    },
    onFocus(event) {
      this.isFocused = true;
      // Форматируем значение без префикса и суффикса, но сохраним сепараторы
      this.displayValue = this.formatNumber(this.value);
      this.setCursorToEnd(event);
    },
    formatNumber(value) {
      // Преобразуем значение в строку для форматирования
      let stringValue = value.toString();
      let [integerPart, decimalPart] = stringValue.split('.');
      // Добавляем сепараторы в целую часть
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, this.separator);
      // Соединяем целую и дробную часть
      return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    },
    updateDisplayValue(value) {
      if (!value && value !== 0) {
        this.displayValue = '';
        return;
      }

      let formattedValue = this.formatNumber(value);
      // Добавляем префикс и суффикс только если инпут не в фокусе
      if (!this.isFocused) {
        formattedValue = `${this.prefix}${formattedValue}${this.suffix}`;
      }
      this.displayValue = formattedValue;
    },
    setCursorToEnd(event) {
      this.$nextTick(() => {
        setTimeout(() => {
          const len = event.target.value.length;
          event.target.setSelectionRange(len, len);
        }, 0);
      });
    },
  }
};
</script>
