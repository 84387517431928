<template>
  <div class="test mb-3">
    <b-row class="mb-md-3 mb-0">
      <b-col cols="12" md="4" class="mb-1 mb-md-0">
        <DatePicker/>
      </b-col>
      <b-col cols="12" md="4" class="mb-1 mb-md-0">
        <NumberOrder />
      </b-col>
      <b-col cols="12" md="4" class="mb-1 mb-md-0">
        <PaymentMethod />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="9" class="mb-2 mb-md-0">
        <Status/>
      </b-col>
      <b-col cols="12" md="3">
        <b-button block @click="runFilters" class="search-button" variant="primary">Поиск с фильтрами</b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import DatePicker from './datePicker'
import Status from './status'
import NumberOrder from "./numberOrder.vue";
import PaymentMethod from "./paymentMethod.vue";

export default {
  components: {
    DatePicker,
    Status,
    NumberOrder,
    PaymentMethod,
  },
  data() {
    return {}
  },
  methods: {
    runFilters() {
      this.$store.dispatch('pagerStd/updateCurrentTableState', null, {root: true})
    }
  }
}
</script>

<style scoped>
.search-button {
  line-height: 18px;
  font-size: 14px;
  border-radius: 5px;
}

div >>> .form-control {
  height: 38px;
}
</style>
