<template>
  <div class="row">
    <div class="col min-w-355">
      <NavTabs :mobileTab="mobileTab" @setMobileTab="setMobileTab" />
      <b-card class="card-small with-nav-tabs desktop-layer" :class="{'mobile-active': mobileTab === 'payout'}">
        <Loader v-if="isLoading" :height="600" :size="3" />
        <div v-else class="main-table-wrapper">
          <component :mobileTab="mobileTab" :is="currentStepComponent" @updateHistory="updateHistory" @updateTransaction="updateTransaction"/>
        </div>
      </b-card>
    </div>
    <div class="col fixed-width desktop-layer" :class="{'mobile-active': mobileTab === 'history'}">
      <ExtendPayouts :mobileTab="mobileTab" :isLoading="loadingData" @goTransaction="goTransaction"/>
    </div>
  </div>
</template>

<script>
import ExtendPayouts from './extendPayoutsForm.vue'
import NavTabs from './navTabs'
import Step1 from './Steps/Step1.vue'
import Step2 from './Steps/Step2.vue'
import Step3 from './Steps/Step3.vue'

export default {
  components: {
    NavTabs,
    ExtendPayouts,
    Step1,
    Step2,
    Step3
  },
  data() {
    return {
      isLoading: false,
      currentStep: 1,
      mobileTab: 'payout',
    }
  },
  computed:{
    currentStepComponent() {
      switch (this.currentStep) {
        case 1: return Step1;
        case 2: return Step2;
        case 3: return Step3;
      }
    },
    loadingData(){
      return !this.$store.state.pager.apiResponseReceived;
    }
  },
  methods: {
    setMobileTab (tab) {
      this.mobileTab = tab;
    },
    updateTransaction(tid) {

    },
    goTransaction(data) {
      this.$router.push({ name: 'PayoutsForm', params: { mid: data.mid, tid: data.tid, step: data.step } });
    },
    changeStep(step) {
      if (step === 'back') {
        this.$router.push({ name: 'PayoutsForm', params: { mid: this.$route.params.mid } });
      } else {
        this.currentStep = step;
        this.$router.push({ name: 'PayoutsForm', params: { mid: this.$route.params.mid, tid: this.$route.params.tid, step: step } });
      }
    },
    async updateHistory() {
      await this.$store.dispatch('pager/updateCurrentTableState', 'default');
    },
  },
  async created(){
    this.$store.commit('filtersStd/clearFilters');
    this.$store.commit('filtersStd/setActiveFilterConfig', 'filtersPayoutsForm', { root: true });
    this.$store.commit('filters/clearAllFilters');
    this.$store.commit('filters/updateActionFilter', 'payout');

    this.defineCurrentRowColumn('modulesAll');
    await this.$store.dispatch('payLender/refreshTableData', 'default');

    const stepFromUrl = this.$route.params.step;
    // if (stepFromUrl) {
    //   this.currentStep = parseInt(stepFromUrl, 10);
    // } else {
      this.currentStep = 1;
    // }
  },
  async mounted () {
    await this.defineCurrentRowColumn('modulestransactionsHistory');
    this.$store.commit('pager/updateActiveTableNamespace', 'payLender', { root: true });
    this.$store.commit('pager/updateRange', 15, { root: true });
    await this.$store.dispatch('pager/updateCurrentTableState', 'default');
  },
  watch: {
    '$route'(to, from) {
      // if (to.params.step) {
      //   this.currentStep = parseInt(to.params.step, 10);
      // } else {
        this.currentStep = 1;
      // }
    }
  },
}

</script>

<style scoped>
.fixed-width {
  margin-top: 43px;
}
.min-w-355 {
  min-width: 355px;
}

@media (max-width: 953px) {
  .row {
    display: flex;
    flex-direction: column;
  }
  .desktop-layer {
    display: none;
  }
  .desktop-layer.mobile-active {
    display: block;
  }
  .fixed-width {
    margin-top: 0;
    border-top-left-radius: 0;
  }
}

@media (min-width: 954px) and (max-width: 1679px) {
  .fixed-width {
    width: 690px;
    min-width: 690px;
  }
}

@media (min-width: 1680px) and (max-width: 1919px) {
  .fixed-width {
    width: 962px;
    min-width: 962px;
  }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .fixed-width {
    width: 1152px;
    min-width: 1152px;
  }
}

@media (min-width: 2561px) {
  .fixed-width {
    width: 1312px;
    min-width: 1312px;
  }
}
</style>
