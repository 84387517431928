<template>
  <b-nav tabs class="tables-top-nav">
    <b-nav-item to="/analytics">Все транзакции</b-nav-item>
  </b-nav>
</template>

<script>
export default {
  
}
</script>