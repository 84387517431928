/* eslint-disable */
import Vue from 'vue'
import VueAxios from 'vue-axios'
import ShardsVue from 'shards-vue'
import Clipboard from 'v-clipboard'
import BootstrapVue from 'bootstrap-vue'
import FlagIcon from 'vue-flag-icon'
import VueI18n from 'vue-i18n'
import UUID from "vue-uuid";

// outside click detection
import VueClosable from 'vue-closable'

// Styles
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'v-calendar/lib/v-calendar.min.css'
import '@/scss/shards-dashboards.scss'

// Core
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import instance from '@/plugins/axios'
import { money } from '@/plugins/utils'
import Mixins from '@/plugins/mixins'
import Renotify from '@/configs/renotify'
import { translations, locale } from '@/plugins/translator'
// Components
import Loader from '@/components/Loader'
import ClipboardComponent from '@/components/Clipboard'
// Table cell components
import CellUpdated from '@/components/tableCells/cellUpdated'
import CellLoanDate from '@/components/tableCells/cellLoanDate'
import CellLoanAmount from '@/components/tableCells/cellLoanAmount'
import CellLoanPerson from '@/components/tableCells/cellLoanPerson'
import CellLoanPersonContact from '@/components/tableCells/cellLoanPersonContact'
import CellLoanAgreement from '@/components/tableCells/сellLoanAgreement'
import CellAmount from '@/components/tableCells/cellAmount'

import CellActsDate from '@/components/tableCells/cellActsDate'
import CellActsPeriod from '@/components/tableCells/cellActsPeriod'
import CellActsRecipient from '@/components/tableCells/cellActsRecipient'
import CellActsAccount from '@/components/tableCells/cellActsAccount'
import CellActsAccepted from '@/components/tableCells/cellActsAccepted'
import CellActsActions from '@/components/tableCells/cellActsActions'

import CellContract from '@/components/tableCells/cellContract'
import CellCustomer from '@/components/tableCells/cellCustomer'
import CellAction from '@/components/tableCells/cellAction'
import CellTransactionsActions from '@/components/tableCells/cellTransactionsActions'
import CellRewardsAction from '@/components/tableCells/cellRewardsAction'
import CellRewardsIsActive from '@/components/tableCells/cellRewardsIsActive'
import CellText from '@/components/tableCells/cellText'
import CellLink from '@/components/tableCells/cellLink'
import CellState from '@/components/tableCells/cellState'

import CellReportsDateTime from '@/components/tableCells/cellReportsDateTime'
import CellReportsActions from '@/components/tableCells/cellReportsActions'

import WidgetExport from '@/components/Export/widgetExport'
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'




Vue.mixin(Mixins)
Vue.mixin(Renotify)
Vue.filter('money', money)
ShardsVue.install(Vue)

Vue.use(VueAxios, instance)
Vue.use(BootstrapVue)
Vue.use(Clipboard)
Vue.use(VueClosable)
Vue.use(FlagIcon)
Vue.use(VueI18n)
Vue.use(UUID)
Vue.use(mdiVue, {
  icons: mdijs
})

import Toast from "vue-toastification";
// standard css for notification popup
import "vue-toastification/dist/index.css";
const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  closeOnClick: false,
  draggable: false,
  position: "top-right",
  timeout: 6000,
  pauseOnHover: true,
};
Vue.use(Toast, options);

Vue.component('Loader', Loader)
Vue.component('Clipboard', ClipboardComponent)


// Table cell components
Vue.component('CellUpdated', CellUpdated)
Vue.component('CellLoanDate', CellLoanDate)
Vue.component('CellLoanAmount', CellLoanAmount)
Vue.component('CellLoanPerson', CellLoanPerson)
Vue.component('CellLoanPersonContact', CellLoanPersonContact)
Vue.component('CellLoanAgreement', CellLoanAgreement)
Vue.component('CellAmount', CellAmount)
Vue.component('CellContract', CellContract)
Vue.component('CellActsDate', CellActsDate)
Vue.component('CellActsPeriod', CellActsPeriod)
Vue.component('CellActsRecipient', CellActsRecipient)
Vue.component('CellActsAccount', CellActsAccount)
Vue.component('CellActsAccepted', CellActsAccepted)
Vue.component('CellActsActions', CellActsActions)
Vue.component('CellReportsDateTime', CellReportsDateTime)
Vue.component('CellReportsActions', CellReportsActions)


Vue.component('CellCustomer', CellCustomer)
Vue.component('CellAction', CellAction)
Vue.component('CellTransactionsActions', CellTransactionsActions)
Vue.component('CellRewardsAction', CellRewardsAction)
Vue.component('CellRewardsIsActive', CellRewardsIsActive)

Vue.component('CellText', CellText)
Vue.component('CellLink', CellLink)
Vue.component('CellState', CellState)

Vue.component('WidgetExport', WidgetExport)


const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'ru',
  messages: translations
})

Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue()

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
