<template>
    <div class="">
      <multiselect v-model="selectedValues" :options="options" :multiple="true"
                   track-by="label" label="label" @input="selectChanged"
                   deselect-label="Удалить" select-label="Добавить" selected-label="Опция выбрана"
                   placeholder="Метод"></multiselect>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: {Multiselect},
  data() {
    return {
      selectedValues: [],
      options: [
        {label: 'Карта', value: 'card'},
        {label: 'Кредит', value: 'credit'},
        // Example selected: {label: 'Кредит', value: 'credit', selected: true},
      ],
    }
  },
  created() {
    // В этой точке мы забираем дефолтные значения фильтра из файла конфига.
    let selected = []
    this.options.forEach(function (value) {
      if (Object.prototype.hasOwnProperty.call(value, 'selected') && value.selected) {
        selected.push(value)
      }
    })
    this.selectedValues = selected;
  },
  mounted() {
    this.selectChanged()
  },
  methods: {
    selectChanged() {
      let statusString = ''
      this.selectedValues.forEach(item => {
        statusString += item.value + ','
      })
      //убираем последнюю запятую из строки
      statusString = statusString.substring(0, statusString.length - 1)
      this.$store.commit('filtersStd/updatePaymentMethod', statusString)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
