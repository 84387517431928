import Mixins from '@/plugins/mixins'

export default {
    namespaced: true,
    state:{
      busy: false,
      notificationsAreLoaded: false,
      notifications: [],
      notificationsDisplay: [],
      paygwErrorsMapping: []
    },
    mutations: {
      updateBusy(state, value){
        state.busy = value
      },
      updateNotifications(state, value){
        state.notifications = value
      },
      updateNotificationsDisplay(state, value){
        state.notificationsDisplay = value
      },
      updateNotificationsAreLoaded(state, value){
        state.notificationsAreLoaded = value
      },
      updatePaygwErrorsMapping(state, value){
        state.paygwErrorsMapping = value
      },
    },
    actions:{
      loadPaygwErrorsMapping(context){
        let url = Mixins.methods.removeTrailingSlash(VUE_APP_DIRECTORIES) + '/directories/paygwErrorsMapping/'
        context.commit('updateBusy', true)
        this.$axios.get(url)
        .then(response => {
          if (response.status === 200){
            context.commit('updatePaygwErrorsMapping', response.data)
          }else{
            console.log(response, 'error response in loadPaygwErrorsMapping')
          }
        })
        .catch(error => {
          console.log('error in promise', error)
        })
      },
      loadNotifications(context){
        let url = Mixins.methods.removeTrailingSlash(VUE_APP_DIRECTORIES) + '/directories/merchant_notifications_service/'
        context.commit('updateBusy', true)
        // We hardcode the name of the dictionaly
        // merchant_notifications_service
        /*
        {
          "name": "merchant_notifications_service",
          "json_data": [{
            "notification":"ВАЖНАЯ ИНФОРМАЦИЯ ДЛЯ КЛИЕНТОВ СЕРВИСА «МАССОВЫЕ ВЫПЛАТЫ»: до 31.01.2023 необходимо направить список IP адресов, с которых поступают запросы на выплаты. Подробности <a href=\"google.com\">тут</a>",
            "show_start":"2024-09-29T09:38:41.341Z",
            "show_end":"2024-10-29T09:38:41.341Z",
            "show_groups":[
                "all",
                "visual_pay_merchant",
                "visual_life_merchant"
            ],
            "show_scopes":[
                "all",
                "some scope here",
                "some_more_scopes"
            ]
          }]
        }
        */
        this.$axiosClient.get(url)
        .then(response => {
          if (response.status === 200){
            context.commit('updateNotifications', response.data)
            context.dispatch('processNotifications')
          }else{
            console.log(response, 'error response in loadNotifications')
          }
        })
        .catch(error => {
          console.log('error in promise', error)
        })
      },
      processNotifications(context){
        let items = []
        let timenow = new Date()
        context.state.notifications.forEach(element => {
          let addNotification = true
          let haveRole = false
          let item = {}
          let timeStart = new Date(element.show_start)
          let timeEnd = new Date(element.show_end)
          if (timeStart > timenow){addNotification = false}
          if (timeEnd < timenow){addNotification = false}
          let groups = element.show_groups
          if (groups.includes("all")){
            haveRole = true
          }
          if (Mixins.methods.checkRolePresenceStore(groups, context.rootState.account.rolesArray)) {
            haveRole = true
          }
          if (addNotification && haveRole){
            item.notification = element.notification
            item.type = element.type
            items.push(item)
          }
        })
        context.commit('updateNotificationsDisplay', items)
        context.commit('updateNotificationsAreLoaded', true)
      }
    }
  }
