<template>
  <b-row>
    <b-col>
      <nav-tabs />
      <b-card class="card-small with-nav-tabs">
        <div class="text-right">
          <b-button v-if="checkScopePresence('clients:client_bank_accounts.write')" class="mb-3" variant="outline-primary" to="/settings/requisites/addbankaccount">Добавить банковский счет</b-button>
        </div>
        <div v-if="showForm">

          <b-table striped class="text-nowrap" :fields="fields" :items="dataTable">
            <template v-slot:cell(bic)="data">
              {{data.item.bank_name}}<br>
              БИК:&nbsp;{{data.item.bic}}
            </template>
            <template v-slot:cell(account_number)="data">
              р/с:&nbsp;{{data.item.account_number}}<br>
              к/с:&nbsp;{{data.item.correspondent_account}}
            </template>
            <template v-slot:cell(is_active)="data">
              <b-form-checkbox switch :disabled="isActiveDisabled(data.item.id)"
                v-model="checkboxesValues[data.item.id].is_active"
                :name="`checkboxactive-`+data.item.id"
                @change="checkboxChanged($event, data.item.id, 'is_active')"
              >
                Активный
              </b-form-checkbox>
              <b-form-checkbox switch :disabled="isDefaultDisabled(data.item.id)"
                v-model="checkboxesValues[data.item.id].is_default"
                :name="`checkboxdefault-`+data.item.id"
                @change="checkboxChanged($event, data.item.id, 'is_default')"
              >
                Основной
              </b-form-checkbox>
            </template>
          </b-table>
        </div>
        <div v-else style="min-height: 200px">
          <Loader :size="3" />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import NavTabs from './navTabs'
export default {
  components:{
    NavTabs
  },
  data(){
    return{
      fields: [
        {
          key: 'bic',
          label: 'Банк',
        },
        {
          key: 'account_number',
          label: 'Номер счёта',
        },
        {
          key: 'is_active',
          label: 'Статус счёта',
        }
      ]
    }
  },
  computed: {
    dataTable: function(){
      let reqArray = []
      this.$store.state.account.clientData.bank_accounts.forEach((item, index) => {
        reqArray.push(item)
      })
      reqArray.sort(this.compareById)
      return reqArray
    },
    checkboxesValues: function(){
      let obj = {}
      this.$store.state.account.clientData.bank_accounts.forEach((item, index) => {
        obj[item.id] = {}
        obj[item.id]['is_active'] = item.is_active
        obj[item.id]['is_default'] = item.is_default

      })
      return obj;
    },
    showForm: function () {
      if (this.$store.state.account.clientDataReceived && !this.$store.state.account.clientDataEmpty) {
        return true
      }
      return false
    }
  },
  methods:{
    compareById(a, b){
      return b.id - a.id;
    },
    isActiveDisabled(id){
      if (this.checkboxesValues[id]["is_default"]){
        return true
      }
      if (this.$store.state.bankAccounts.busy){ return true }
      if (!this.checkScopePresence('clients:client_bank_accounts.write')){
        return true
      }
      return false
    },
    isDefaultDisabled(id){
      if (this.checkboxesValues[id]["is_default"]){
        return true
      }
      if (this.$store.state.bankAccounts.busy){ return true}
      if (!this.checkScopePresence('clients:client_bank_accounts.write')){ return true }
      return false
    },
    checkboxChanged(checked, id, property){
      let data = {
        id: id,
        payload:{
        }
      }
      data.payload[property] = checked
      this.$store.dispatch('bankAccounts/patchProperties', data)
    }
  }

}
</script>
