<template>
  <d-card class="card-div mb-4">
    <d-card-body style="min-height: 500px">
      <h5 class="font-weight-bold" style="font-size: 1.1rem;">{{ $t('extend.extInfo') }}</h5>
      <hr>
      <Loader v-if="!apiResponseReceived" :size="3" />
      <div v-else>
        <div v-if="item">
          <div>
            <div v-if="item.id" class="mb-2">
              <p class="font-weight-bold my-0">Request ID</p>
              <div class=" ">{{ item.id }} <clipboard :id="item.id"/></div>
            </div>
            <div v-if="item.phone" class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.phone') }}</p>
              <div class=" ">{{ item.phone}} <clipboard :id="item.phone"/></div>
            </div>
            <div v-if="item.attributes" class="mb-2">
              <h6 class="font-weight-bold my-0">{{ $t('extend.optionalInfo') }}:</h6>
              <p class="mb-2" v-for="(value, name) in item.attributes" :key="name">
                  {{ name }}: "{{ value }}"
              </p>
            </div>
            <div v-if="item.fiscal_information" class="mb-2">
              <h6 class="font-weight-bold my-0">{{ $t('extend.fiscalInformation') }}:</h6>
              <p class="mb-2">
                  taxationSystem: "{{ item.fiscal_information.taxationSystem }}"
              </p>
              <p class="mb-2" v-for="(value, name) in item.fiscal_information.items[0]" :key="name">
                  {{ name }}: "{{ value }}"
              </p>
            </div>
            <div v-if="item.pay_link" class="mb2">
              <p class="font-weight-bold my-0">{{ $t('extend.pay_link') }} <clipboard :id="item.pay_link"/></p>
              <div class=" ">{{ item.pay_link}}</div>
            </div>
          </div>
        </div>
        <div v-else-if="!item" class="text-center" style="line-height: 25em;">
          <p>{{ $t('extend.chooseTransaction') }}</p>
        </div>
      </div>
    </d-card-body>
  </d-card>
</template>

<script>
export default {
  computed: {
    apiResponseReceived () {
      return this.$store.state.pager.apiResponseReceived
    },
    item () {
      let item = {}
      item = this.$store.state.extend.itemForExtend
      /*if (item.hasOwnProperty('cs1')) {

      } else {
        item = false
      }*/
      return item
    }
  }
}
</script>
