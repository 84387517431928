<template>
  <d-modal v-if="showRecipientModal" size="lg"
  @close="handleRecipientModalClose">
    <d-modal-header>
      <d-modal-title>Информация</d-modal-title>
    </d-modal-header>
    <d-modal-body>
<div class="form-block-header">Общие сведения</div>
      <d-form-row class="mx-4">

        <d-col :md="4">
          <label for="recipient-type">Получатель вознаграждения</label>
          <d-form-select
            v-model="type"
            id="recipient-type"
            name="recipient-type"
            :options="optionsRecipientType"
            :disabled="ifControlDisabled('type')"
          />
        </d-col>
        <d-col :md="2">
          <label for="inn">ИНН</label>
          <d-input v-if="type == 'individual' || type == 'self-employed'" id="inn" name="inn" v-model="inn" placeholder="Введите ИНН" required :disabled="ifControlDisabled('inn')" />

    <VueInnSuggestions v-if="type == 'company' || type == 'sole proprietor'"
      v-bind:inn="inn"
      v-on:update:inn="inn = $event"
      :placeholder="'Введите ИНН'"
      class="form-control"
      :disabled="ifControlDisabled('inn')"
      >
    </VueInnSuggestions>

        </d-col>
        <d-col :md="3" v-if="type == 'company'">
          <label for="ogrn">ОГРН</label>
          <d-input id="ogrn" name="ogrn" v-model="ogrn" disabled />
        </d-col>

<d-col :md="3" v-if="type == 'sole proprietor'">
          <label for="ogrnip">ОГРНИП</label>
          <d-input id="ogrnip" name="ogrnip" v-model="ogrn" disabled />
</d-col>

<d-col :md="3" v-if="type == 'individual' || type == 'self-employed'">
          <label for="document_number">Серия и номер паспорта</label>
          <d-input id="document_number" name="document_number" v-model="document_number" placeholder="Введите серию и номер" required :disabled="ifControlDisabled('document_number')" />
</d-col>
<d-col :md="3" v-if="type == 'individual' || type == 'self-employed'">
          <label for="issue_date">Дата выдачи паспорта</label>
          <v-date-picker v-if="!ifControlDisabled('issue_date')" :max-date='new Date()'
          class="birthdate-container" v-model="issue_date" popoverVisibility="focus" />
          <d-input v-if="ifControlDisabled('issue_date')" id="issue_date" name="issue_date" :value="issue_date_string" required disabled/>

</d-col>

        <d-col :md="3" v-if="type == 'company'">
          <label for="kpp">КПП</label>
          <d-input id="kpp" name="kpp" v-model="kpp" disabled />
        </d-col>
      </d-form-row>

      <d-form-row class="mx-4">
         <d-col :md="4" v-if="type == 'company' || type == 'sole proprietor'">
          <label for="short_name">Краткое наименование</label>
          <d-input id="short_name" name="short_name" v-model="short_name" disabled/>
        </d-col>
        <d-col :md="4" v-if="type == 'company' || type == 'sole proprietor'">
          <label for="full_name">Полное наименование</label>
          <d-input id="full_name" name="full_name" v-model="full_name" disabled />
        </d-col>
        <d-col :md="4" v-if="type == 'company'">
          <label for="opf">Правовая форма</label>
          <d-input id="opf" name="opf" :value="opf" disabled />
        </d-col>

        <d-col :md="4" v-if="type == 'individual' || type == 'self-employed'">
          <label for="full_name">Фамилия Имя Отчество</label>
          <d-input id="full_name" name="full_name" v-model="full_name" placeholder="Введите ФИО полностью" :disabled="ifControlDisabled('full_name')"/>
        </d-col>
        <d-col :md="4" v-if="type == 'individual' || type == 'self-employed'">
          <label for="ogrn">Номер мобильного телефона</label>
          <d-input id="ogrn" name="ogrn" type="tel" v-model="contact_person_phone" placeholder="Введите номер мобильного телефона" :disabled="ifControlDisabled('ogrn')"/>
        </d-col>
        <d-col :md="4" v-if="type == 'individual' || type == 'self-employed'">
          <label for="kpp">Email</label>
          <d-input id="kpp" name="kpp" type="email" v-model="contact_person_email" placeholder="Введите email" :disabled="ifControlDisabled('kpp')"/>
        </d-col>

      </d-form-row>

      <d-form-row class="mx-4" v-if="type == 'individual' || type == 'self-employed'">
        <d-col :md="3" v-if="type == 'individual' || type == 'self-employed'">
          <label for="birthdate">Дата рождения</label>
          <v-date-picker v-if="!ifControlDisabled('birthdate')" :max-date='this.removeYearsFromDate(new Date(), 18)'
          class="birthdate-container" v-model="birthdate" popoverVisibility='focus'
          />
          <d-input v-if="ifControlDisabled('birthdate')" id="birthdate" name="birthdate" :value="birthdate_string" required disabled/>

        </d-col>

        <d-col :md="3" v-if="type == 'individual' || type == 'self-employed'">
          <label for="snils">СНИЛС</label>
          <d-input id="snils" name="snils" v-model="snils" placeholder="Введите СНИЛС" required :disabled="ifControlDisabled('snils')"/>
        </d-col>
      </d-form-row>

      <d-form-row class="mx-4">
         <!--<d-col :md="4" v-if="type == 'company'">
          <label for="inn">Телефон</label>
          <d-input id="inn" name="inn" v-model="warning" disabled/>
        </d-col>-->
        <d-col :md="12" v-if="type == 'company'">
          <label for="address">Юридический адрес</label>
          <d-input id="address" name="address" :value="address" disabled />
        </d-col>

        <!--<d-col :md="12" v-if="type == 'individual' || type == 'self-employed'">
          <label for="ogrn">Адрес регистрации</label>
          <d-input id="ogrn" name="ogrn" v-model="warning" placeholder="Введите адрес регистрации как в паспорте" />
        </d-col>-->

      </d-form-row>

<div v-if="type == 'company'">
<div class="form-block-header">Сведения о руководителе</div>
      <d-form-row class="mx-4">

        <d-col :md="6">
          <label for="management_full_name">ФИО</label>
          <d-input id="management_full_name" name="management_full_name" v-model="management_full_name" placeholder :disabled="ifControlDisabled('management_full_name')"/>
        </d-col>
        <d-col :md="6">
          <label for="management_position">Должность руководителя</label>
          <d-input id="management_position" name="management_position" v-model="management_position" placeholder :disabled="ifControlDisabled('management_position')"/>
        </d-col>
      </d-form-row>
</div>

<div class="form-block-header">Банковские реквизиты</div>
      <d-form-row class="mx-4">
        <d-col :md="6">
          <label for="bank_account">Расчетный счет</label>
          <d-input id="bank_account" name="bank_account" v-model="bank_account" :disabled="ifControlDisabled('bank_account')" placeholder="Введите номер банковского счета" />
        </d-col>
        <d-col :md="6">
          <label for="bic">БИК банка получателя</label>
          <!--<d-input id="bic" name="bic" v-model="bic" placeholder="Введите БИК" />-->

    <VueBicSuggestions
      v-bind:bic="bic"
      v-on:update:bic="bic = $event"
      :placeholder="'Введите БИК'"
      class="form-control"
      :disabled="ifControlDisabled('bic')"
      >
    </VueBicSuggestions>

        </d-col>
      </d-form-row>
      <d-form-row class="mx-4">
        <d-col :md="4">
          <label for="bankname">Наименование банка</label>
          <d-input id="bankname" name="bankname" :value="bankname" disabled/>
        </d-col>
        <d-col :md="4">
          <label for="bankcity">Город банка получателя</label>
          <d-input id="bankcity" name="bankcity" :value="bankcity" disabled/>
        </d-col>
        <d-col :md="4">
          <label for="bankcorcount">К/с банка</label>
          <d-input id="bankcorcount" name="bankcorcount" :value="bankcorcount" disabled/>
        </d-col>
      </d-form-row>

<div class="form-block-header">Контактные данные для связи по общим вопросам</div>
      <d-form-row class="mx-4">
        <d-col :md="4">
          <label for="contact_person_surname">Фамилия</label>
          <d-input id="contact_person_surname" name="contact_person_surname" v-model="contact_person_surname" placeholder="Фамилия" :disabled="ifControlDisabled('contact_person_surname')" />
        </d-col>
        <d-col :md="4">
          <label for="contact_person_name">Имя</label>
          <d-input id="contact_person_name" name="contact_person_name" v-model="contact_person_name" placeholder="Имя" :disabled="ifControlDisabled('contact_person_name')" />
        </d-col>
        <d-col :md="4">
          <label for="contact_person_patronymic">Отчество</label>
          <d-input id="contact_person_patronymic" name="contact_person_patronymic" v-model="contact_person_patronymic" placeholder="Отчество" :disabled="ifControlDisabled('contact_person_patronymic')" />
        </d-col>
      </d-form-row>
      <d-form-row class="mx-4">
        <d-col :md="6">
          <label for="contact_person_email">Email</label>
          <d-input id="contact_person_email" name="contact_person_email" v-model="contact_person_email" placeholder="Введите email" :disabled="ifControlDisabled('contact_person_email')" />
        </d-col>
        <d-col :md="6">
          <label for="contact_person_phone">Номер телефона</label>
          <d-input id="contact_person_phone" name="contact_person_phone" v-model="contact_person_phone" placeholder="Введите номер телефона" :disabled="ifControlDisabled('contact_person_phone')" />
        </d-col>
      </d-form-row>

<div class="form-block-header person-for-finance">Контактные данные для связи по финансовым вопросам
  <b-form-checkbox style="display: inline; margin-left: 50px;" id="same-contacts" v-model="same_contacts" name="same-contacts" @change="sameContactsCheck($event)" :disabled="ifControlDisabled('same_contacts')">Данные сопадают</b-form-checkbox>
</div>
      <d-form-row class="mx-4">
        <d-col :md="4">
          <label for="contact_person_for_finance_surname">Фамилия</label>
          <d-input id="contact_person_for_finance_surname" name="contact_person_for_finance_surname" v-model="contact_person_for_finance_surname" placeholder="Фамилия" :disabled="ifControlDisabled('contact_person_for_finance_surname')" />
        </d-col>
        <d-col :md="4">
          <label for="contact_person_for_finance_name">Имя</label>
          <d-input id="contact_person_for_finance_name" name="contact_person_for_finance_name" v-model="contact_person_for_finance_name" placeholder="Имя" :disabled="ifControlDisabled('contact_person_for_finance_name')" />
        </d-col>
        <d-col :md="4">
          <label for="contact_person_for_finance_patronymic">Отчество</label>
          <d-input id="contact_person_for_finance_patronymic" name="contact_person_for_finance_patronymic" v-model="contact_person_for_finance_patronymic" placeholder="Отчество" :disabled="ifControlDisabled('contact_person_for_finance_patronymic')" />
        </d-col>
      </d-form-row>
      <d-form-row class="mx-4">
        <d-col :md="6">
          <label for="contact_person_for_finance_email">Email</label>
          <d-input id="contact_person_for_finance_email" name="contact_person_for_finance_email" v-model="contact_person_for_finance_email" placeholder="Введите email" :disabled="ifControlDisabled('contact_person_for_finance_email')" />
        </d-col>
        <d-col :md="6">
          <label for="contact_person_for_finance_phone">Номер телефона</label>
          <d-input id="contact_person_for_finance_phone" name="contact_person_for_finance_phone" v-model="contact_person_for_finance_phone" placeholder="Введите номер телефона" :disabled="ifControlDisabled('contact_person_for_finance_phone')" />
        </d-col>
      </d-form-row>

    </d-modal-body>

    <d-modal-footer v-if="ifEditModal()">

      <d-button v-if="validatedShowSaveButton()" outline v-on:click="saveRecipient" theme="secondary">Сохранить</d-button>
      <d-button v-else outline theme="secondary" disabled>Пожалуйста, заполните все поля</d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import VueInnSuggestions from '@/components/Rewards/rewardsInnSuggestions'
import VueBicSuggestions from '@/components/Rewards/rewardsBicSuggestions'

import moment from 'moment'
import { setupCalendar, DatePicker } from 'v-calendar'
setupCalendar({
  firstDayOfWeek: 2
})

export default {
  components: {
    VueInnSuggestions,
    VueBicSuggestions,
    'v-date-picker': DatePicker
  },
  data () {
    return {
      formsomething: '',
      same_contacts: false,
      optionsRecipientType: [
        { value: 'company', text: 'Юридическое лицо' },
        { value: 'sole proprietor', text: 'Индивидуальный предприниматель' },
        // { value: 'individual', text: 'Физическое лицо' },
        { value: 'self-employed', text: 'Самозанятый' }
      ],
      warning: 'не сделано'
    }
  },
  computed: {
    bankname () { return this.$store.state.rewards.currentRewardsRecipientAdditionalFields.bankname },
    bankcity () { return this.$store.state.rewards.currentRewardsRecipientAdditionalFields.bankcity },
    bankcorcount () { return this.$store.state.rewards.currentRewardsRecipientAdditionalFields.bankcorcount },
    opf () { return this.$store.state.rewards.currentRewardsRecipientAdditionalFields.opf },
    address () { return this.$store.state.rewards.currentRewardsRecipientAdditionalFields.address },
    inn: {
      get: function () { return this.$store.state.rewards.currentRewardsRecipient.inn },
      set: function (newValue) { this.updateNewValue('inn', newValue) }
    },
    ogrn: {
      get: function () { return this.$store.state.rewards.currentRewardsRecipient.ogrn },
      set: function (newValue) { this.updateNewValue('ogrn', newValue) }
    },
    kpp: {
      get: function () { return this.$store.state.rewards.currentRewardsRecipient.kpp },
      set: function (newValue) { this.updateNewValue('kpp', newValue) }
    },
    short_name: {
      get: function () { return this.getObjectValue('short_name') },
      set: function (newValue) { this.updateNewValue('short_name', newValue) }
    },
    type: {
      get: function () { return this.$store.state.rewards.currentRewardsRecipient.type },
      set: function (newValue) { this.updateNewValue('type', newValue) }
    },
    snils: {
      get: function () { return this.getObjectValue('snils') },
      set: function (newValue) { this.updateNewValue('snils', newValue) }
    },
    birthdate: {
      get: function () {
        if (this.getObjectValue('birthdate') === '') { return null }
        let retdate = new Date(moment(this.getObjectValue('birthdate')).format())
        return retdate
      },
      set: function (newValue) {
        let dateApiFormat = moment(newValue).format('YYYY-MM-DD')
        this.updateNewValue('birthdate', dateApiFormat)
      }
    },
    birthdate_string () { return moment(this.birthdate).format('DD.MM.YYYY') },
    full_name: {
      get: function () { return this.$store.state.rewards.currentRewardsRecipient.full_name },
      set: function (newValue) { this.updateNewValue('full_name', newValue) }
    },
    document_number: {
      get: function () { return this.getChildObjectValue('document', 'number') },
      set: function (newValue) { this.updateChildObjectValue('document', 'number', newValue) }
    },
    issue_date: {
      get: function () {
        if (this.getChildObjectValue('document', 'issue_date') === '') { return null }
        let retdate = new Date(moment(this.getChildObjectValue('document', 'issue_date')).format())
        return retdate
      },
      set: function (newValue) {
        let dateApiFormat = moment(newValue).format('YYYY-MM-DD')
        this.updateChildObjectValue('document', 'issue_date', dateApiFormat)
      }
    },
    issue_date_string () { return moment(this.issue_date).format('DD.MM.YYYY') },
    management_full_name: {
      get: function () { return this.getChildObjectValue('management', 'full_name') },
      set: function (newValue) { this.updateChildObjectValue('management', 'full_name', newValue) }
    },
    management_position: {
      get: function () { return this.getChildObjectValue('management', 'position') },
      set: function (newValue) { this.updateChildObjectValue('management', 'position', newValue) }
    },
    bank_account: {
      get: function () { return this.getObjectValue('bank_account') },
      set: function (newValue) { this.updateNewValue('bank_account', newValue) }
    },
    bic: {
      get: function () { return this.getObjectValue('bic') },
      set: function (newValue) { this.updateNewValue('bic', newValue) }
    },
    contact_person_phone: {
      get: function () { return this.getChildObjectValue('contact_person', 'phone') },
      set: function (newValue) { this.updateChildObjectValue('contact_person', 'phone', newValue) }
    },
    contact_person_email: {
      get: function () { return this.getChildObjectValue('contact_person', 'email') },
      set: function (newValue) { this.updateChildObjectValue('contact_person', 'email', newValue) }
    },
    contact_person_surname: {
      get: function () { return this.getChildObjectValue('contact_person', 'surname') },
      set: function (newValue) { this.updateChildObjectValue('contact_person', 'surname', newValue) }
    },
    contact_person_name: {
      get: function () { return this.getChildObjectValue('contact_person', 'name') },
      set: function (newValue) { this.updateChildObjectValue('contact_person', 'name', newValue) }
    },
    contact_person_patronymic: {
      get: function () { return this.getChildObjectValue('contact_person', 'patronymic') },
      set: function (newValue) { this.updateChildObjectValue('contact_person', 'patronymic', newValue) }
    },
    contact_person_for_finance_phone: {
      get: function () { return this.getChildObjectValue('contact_person_for_finance', 'phone') },
      set: function (newValue) { this.updateChildObjectValue('contact_person_for_finance', 'phone', newValue) }
    },
    contact_person_for_finance_email: {
      get: function () { return this.getChildObjectValue('contact_person_for_finance', 'email') },
      set: function (newValue) { this.updateChildObjectValue('contact_person_for_finance', 'email', newValue) }
    },
    contact_person_for_finance_surname: {
      get: function () { return this.getChildObjectValue('contact_person_for_finance', 'surname') },
      set: function (newValue) { this.updateChildObjectValue('contact_person_for_finance', 'surname', newValue) }
    },
    contact_person_for_finance_name: {
      get: function () { return this.getChildObjectValue('contact_person_for_finance', 'name') },
      set: function (newValue) { this.updateChildObjectValue('contact_person_for_finance', 'name', newValue) }
    },
    contact_person_for_finance_patronymic: {
      get: function () { return this.getChildObjectValue('contact_person_for_finance', 'patronymic') },
      set: function (newValue) { this.updateChildObjectValue('contact_person_for_finance', 'patronymic', newValue) }
    },

    showRecipientModal: {
      get: function () { return this.$store.state.rewards.showRecipientDetailsModal },
      set: function (newValue) { this.$store.commit('rewards/toggleShowRecipientDetailsModal', newValue) }
    }
  },
  watch: {
    contact_person_surname: function (val) { if (this.same_contacts) { this.contact_person_for_finance_surname = val } },
    contact_person_name: function (val) { if (this.same_contacts) { this.contact_person_for_finance_name = val } },
    contact_person_patronymic: function (val) { if (this.same_contacts) { this.contact_person_for_finance_patronymic = val } },
    contact_person_email: function (val) { if (this.same_contacts) { this.contact_person_for_finance_email = val } },
    contact_person_phone: function (val) { if (this.same_contacts) { this.contact_person_for_finance_phone = val } }
  },
  methods: {
    removeYearsFromDate (dt, years) {
      return new Date(dt.setFullYear(dt.getFullYear() - years))
    },
    ifEditModal () {
      if (this.$store.state.rewards.showRecipientDetailsModalState === 'display') {
        return false
      }
      return true
    },
    ifControlDisabled (control) {
      if (this.$store.state.rewards.showRecipientDetailsModalState === 'display') {
        return true
      }
      if (this.$store.state.rewards.showRecipientDetailsModalState === 'edit') {
        if (control === 'type') {
          return true
        }
        // Если установлена галка "Данные совпадают, возвращаем true"
        if (control === 'contact_person_for_finance_surname' ||
        control === 'contact_person_for_finance_name' ||
        control === 'contact_person_for_finance_patronymic' ||
        control === 'contact_person_for_finance_email' ||
        control === 'contact_person_for_finance_phone'
        ) {
          if (this.same_contacts) { return true }
        }
      }
      if (this.$store.state.rewards.showRecipientDetailsModalState === 'add') {
        // Если установлена галка "Данные совпадают, возвращаем true"
        if (control === 'contact_person_for_finance_surname' ||
        control === 'contact_person_for_finance_name' ||
        control === 'contact_person_for_finance_patronymic' ||
        control === 'contact_person_for_finance_email' ||
        control === 'contact_person_for_finance_phone'
        ) {
          if (this.same_contacts) { return true }
        }
        return false
      }
      return false
    },
    handleRecipientModalClose () {
      this.showRecipientModal = false
      // Очищаем от мусора
      this.$store.commit('rewards/cleanCurrentRewardsRecipient')
      this.same_contacts = false
    },
    updateNewValue (propertyName, newValue) {
      let newObject = JSON.parse(JSON.stringify(this.$store.state.rewards.currentRewardsRecipient))
      newObject[propertyName] = newValue
      this.$store.commit('rewards/updateCurrentRewardsRecipient', newObject)
    },
    getObjectValue (propertyName) {
      if (this.$store.state.rewards.currentRewardsRecipient.hasOwnProperty(propertyName)) {
        return this.$store.state.rewards.currentRewardsRecipient[propertyName]
      }
      return ''
    },
    getChildObjectValue (childEntityName, propertyName) {
      if (this.$store.state.rewards.currentRewardsRecipient.hasOwnProperty(childEntityName)) {
        if (this.$store.state.rewards.currentRewardsRecipient[childEntityName].hasOwnProperty(propertyName)) {
          return this.$store.state.rewards.currentRewardsRecipient[childEntityName][propertyName]
        }
      }
      return ''
    },
    updateChildObjectValue (childEntityName, propertyName, newValue) {
      let newObject = JSON.parse(JSON.stringify(this.$store.state.rewards.currentRewardsRecipient))
      newObject[childEntityName][propertyName] = newValue
      this.$store.commit('rewards/updateCurrentRewardsRecipient', newObject)
    },
    validatedShowSaveButton () {
      let validated = true
      if (this.type === 'company') {
        if (!this.management_full_name || !this.management_position) {
          validated = false
        }
      }
      if (this.type === 'individual' || this.type === 'self-employed') {
        if (!this.document_number || !this.snils || !this.birthdate || !this.issue_date) {
          validated = false
        }
      }
      // Общие

      if (!this.inn || !this.bank_account || !this.bic || !this.full_name) {
        validated = false
      }
      if (!this.contact_person_surname || !this.contact_person_name || !this.contact_person_patronymic || !this.contact_person_email || !this.contact_person_phone) {
        validated = false
      }
      if (!this.contact_person_for_finance_surname || !this.contact_person_for_finance_name || !this.contact_person_for_finance_patronymic || !this.contact_person_for_finance_email || !this.contact_person_for_finance_phone) {
        validated = false
      }
      return validated
    },
    saveRecipient () {
      if (this.$store.state.rewards.showRecipientDetailsModalState === 'edit') {
        this.$store.dispatch('rewards/saveEditedRewardsRecipient')
      }
      if (this.$store.state.rewards.showRecipientDetailsModalState === 'add') {
        this.$store.dispatch('rewards/saveAddedRewardsRecipient')
      }
    },
    sameContactsCheck (checkboxValue) {
      if (checkboxValue) {
        this.contact_person_for_finance_surname = this.contact_person_surname
        this.contact_person_for_finance_name = this.contact_person_name
        this.contact_person_for_finance_patronymic = this.contact_person_patronymic
        this.contact_person_for_finance_email = this.contact_person_email
        this.contact_person_for_finance_phone = this.contact_person_phone
      }
    }
  }
}
</script>

<style scoped>
.modal-body {
  padding-left: 0;
  padding-right: 0;
}
#recipient-type {
  height: 2.35rem;
}
.modal-body >>> .suggestions-suggestions{
  width: 350px !important;
}
.form-block-header{
  margin-left: 1.5rem;
  padding-left: 5px;
  margin-top: 30px;
  font-weight: bold;
  color: #868e96;
}
.person-for-finance /deep/ label{
  font-weight: bold;
}
/deep/ .birthdate-container input{
  padding: 0.45rem 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  height: auto;
  width:  100%;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #becad6;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.form-control{
  padding: 0.45rem 1rem;
  font-size: 0.95rem;
  font-weight: 500;
}
</style>
