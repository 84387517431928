<template>
  <div class="small-paddings">
    <p class="mb-0 text-nowrap">Фильтры: Тип модуля</p>
    <d-form-select v-model="Action" :options="Options"></d-form-select>
  </div>
</template>

<script>
export default {
  computed: {
    Action: {
      get: function () {
        let action = this.$store.state.pager.cursor.action;
        return (action) ? action : '';
      },
      set: function (newValue) {
        this.$store.commit('filters/updateActionFilter', newValue)
        this.$store.dispatch('filters/filterChanged', newValue)
      }
    },
    Options() {
      return [
        {value: '', text: 'Все'},
        {value: 'payout', text: 'Выплаты'},
        {value: 'pay', text: 'Приём платежей'},
        {value: 'life', text: 'Рассрочки/Выплаты'}
      ]
    }
  }
}
</script>
