import Mixins from "@/plugins/mixins";

export default {
  namespaced: true,
  state: {
    transactionAdditionalDataLoading: false,
    transactionAdditionalData: [],
  },
  mutations: {
    updateTransactionAdditionalData(state, value) {
      state.transactionAdditionalData = value;
    },
    updateTransactionAdditionalDataLoading(state, value) {
      state.transactionAdditionalDataLoading = value;
    },
  },
  actions: {
    getTransactionById(context, item) {
      context.commit('updateTransactionAdditionalDataLoading', true)
      context.commit('updateTransactionAdditionalData', [])
      // нужен мид в заголовках
      let options = { headers: { Mid: item.mid } };
      let url = Mixins.methods.removeTrailingSlash(VUE_APP_SECURE) + "/api/public/transactions/" + item.id
      this.$axios
        .get(url, options)
        .then((response) => {
          if (!Object.prototype.hasOwnProperty.call(response, "status")) {
            console.log("we got no response from server loadProject", response);
            this._vm.$toast.error("Сервер не ответил на наш запрос");
          }
          if (response.status === 200) {
            context.commit('updateTransactionAdditionalData', response.data.custom_values) 
          }
          context.commit('updateTransactionAdditionalDataLoading', false)
        })
        .catch((error) => {
          console.log("error in promise", error);
        });
    },
  },
};
