<template>
  <d-card class="card-div mb-4">
    <d-card-body style="min-height: 500px">
      <h5 class="font-weight-bold" style="font-size: 1.1rem;">{{ $t('extend.extInfo') }}</h5>
      <hr>
      <Loader v-if="!apiResponseReceived" :size="3" />
      <div v-else>
        <div v-if="item">
          <div>
            <div v-if="item.order_id" class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.orderId') }}</p>
              <div class=" ">{{ item.order_id }} <clipboard :id="item.order_id"/></div>
            </div>
            <div class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.summ') }}</p>
              <div class=" ">{{ item.amount | money }}</div>
            </div>
            <div v-if="item.id" class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.transactionId') }}</p>
              <div class=" ">{{ item.id }} <clipboard :id="item.id"/></div>
            </div>
            <div class="mb-2" v-if="item.reference_id">
              <p class="font-weight-bold my-0">{{ $t('extend.initialTransactionId') }}</p>
              <div class=" ">{{ item.reference_id }}</div>
            </div>
            <div class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.rrnAuth') }}</p>
              <span class=" " v-if="item.rrn">
                {{ item.rrn }} <clipboard :id="item.rrn"/>
              </span>
              /
              <span class=" " v-if="item.authcode">
                {{ item.authcode }} <clipboard :id="item.authcode"/>
              </span>
            </div>
            <div v-if="item.pan_id" class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.bankingCardId') }}</p>
              <div class=" ">{{ item.pan_id }} <clipboard :id="item.pan_id"/></div>
            </div>
            <div class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.rc') }}</p>
              <div class=" ">{{ item.response_code }}</div>
            </div>
            <div class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.accountId') }}</p>
              <div class=" ">{{ item.account_id }}</div>
            </div>
            <div class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.type') }}</p>
              <div class=" ">{{ getOpcode(item.opcode) }}</div>
            </div>
            <div class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.instrumentTypeId') }}</p>
              <div class=" ">{{ getInstrument(item.instrument_type_id) }}</div>
            </div>
            <div class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.channel') }}</p>
              <div class=" ">{{ getChannel(item.channel_id) }}</div>
            </div>
            <div class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.status') }}</p>
              <div class=" ">{{ getStatus(item.status) }}</div>
            </div>
            <div class="mb-2">
              <p class="font-weight-bold my-0">{{ $t('extend.merchantId') }}</p>
              <div class=" ">{{ item.mw_merchant_id }}</div>
            </div>
          </div>
        </div>
        <div v-else-if="!item" class="text-center" style="line-height: 25em;">
          <p>{{ $t('extend.chooseTransaction') }}</p>
        </div>
      </div>
    </d-card-body>
  </d-card>
</template>

<script>
export default {
  computed: {
    apiResponseReceived () {
      return this.$store.state.pager.apiResponseReceived
    },
    item () {
      let item = {}
      item = this.$store.state.extend.itemForExtend
      if (item.hasOwnProperty('cs1')) {
        return item
      } else {
        item = false
        return item
      }
    }
  }
}
</script>
