<template>
  <div class="cell-wrapper">
    {{properMRub(item.total_current_settlement_amount)}}
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>
