
function defaultFilterString(filters_config, filter_name){
  let filterByString = ''
  // У нас сейчас есть фильры, которые, несмотря на то, что их не надо показывать,
  // надо использовать в дефолтной строке. Поэтому убираем проверку. 
  /*
  if (!filters_config[filter_name].show){
    return filterByString
  }*/
  if (!Object.prototype.hasOwnProperty.call(filters_config, filter_name)){
    // Такого фильтра нет в конфиге.
    return filterByString
  }
  if (!Object.prototype.hasOwnProperty.call(filters_config[filter_name], 'options')){
    // Фильтр в конфиге есть, но у него нет опций.
    return filterByString
  }  
  let valuesArray = filters_config[filter_name].options
  valuesArray.forEach(element => {
    filterByString += ','+element.value
  })
  // очищаем первую ','
  if (filterByString && filterByString.charAt(0)===','){
    filterByString = filterByString.substring(1)
  }
  return filterByString
}

export { defaultFilterString }

