const filters_config = {
  instrument_type: {
    show: true,
    options: [
      { value: '800', label: 'Заявка на кредитование' },
      { value: '801', label: 'MandarinPOS' },
    ]
  }, 
  status:{ 
    show: true,
    options: [
      { value: 'success', label: 'Успешно'},
      { value: 'fail,timeout', label: 'Ошибка'},
      { value: 'wait,wait_internal,wait_3ds,wait_external,revise_require', label: 'Обработка' }
    ]
  },
  opcodes: {
    show: true,
    options: [
      { value: '1', label: 'Оплата' }, 
      { value: '200', label: 'Возврат рассрочки' },
      { value: '451', label: 'Ручной возврат' },
      { value: '472', label: 'Выплата' }
    ]
  }
}

export default filters_config