<template>
  <div class="cell-wrapper">
    {{item.accounting_period}}
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>
