<template>
  <d-card class="card-small mb-4" style="border-radius: 0 0.625rem 0.625rem 0.625rem">
    <d-card-body v-if="checkScopePresence('report.read')">
      <d-row class="mb-2">
        <d-col lg="2">
          <d-button outline class="" v-on:click="reloadReports" theme="secondary" :disabled="loadingData">Обновить список</d-button>
        </d-col>
      </d-row>
      <d-row>
        <d-col>
          <Pager/>
        </d-col>
        <d-col lg="1" style="min-width: 126px">
          <pageOptions/>
        </d-col>
      </d-row>

      <Loader v-if="loadingData" :height="600" :size="3" />
      <div v-else style="min-height: 600px">

        <div class="table-overflow-y-hidden" v-if="showTable">
          <b-table striped ref="tableLoan" class="text-nowrap" :fields="fields" :items="dataTable" @row-clicked="selectItem">
            <template v-for='(templatedField, index) in templatedFields' v-slot:[goToCell(templatedField.key)]='row'>
              <component v-bind:is="templatedField.templateName" v-bind="row" :key="index"></component>
            </template>
          </b-table>
        </div>
        <div v-else class="text-center" style="line-height: 30em;">
          <p>У вас нет запросов на экспорт данных.</p>
        </div>
      </div>
    </d-card-body>
    <d-card-body v-else>
      <p>У вас нет прав на доступ к странице экспорта.</p>
    </d-card-body>
  </d-card>
</template>

<script>
import Pager from '@/components/Filters/pager'
import pageOptions from '@/components/Filters/pageOptions'
import exportCellStatus from './tableCells/cellStatus'

export default {
  components: {
    Pager,
    pageOptions,
    exportCellStatus
  },
  computed: {
    loadingData: function () {
      return this.$store.state.reportsExport.reportsListUpdating
    },
    showTable: function () {
      if (!this.$store.state.reportsExport.reportsListUpdating && this.$store.state.reportsExport.reportsList.length > 0) {
        return true
      }
      return false
    },
    fields: function () {
      let fieldsArray = [
          {
            key: 'created_at',
            label: 'Дата создания',
            sortable: false,
            templateName: 'CellReportsDateTime'
          },
          {
            key: 'id',
            label: 'Идентификатор',
            sortable: false
          },
          {
            key: 'status',
            label: 'Состояние',
            templateName: 'exportCellStatus'
          },
          {
            key: 'actions',
            label: '',
            templateName: 'CellReportsActions'
          }
        ]
      return fieldsArray
    },
    templatedFields () {
      let templatedFields = []
      this.fields.forEach(field => {
        if (field.hasOwnProperty('templateName')) {
          let f = {}
          f.key = field.key
          f.templateName = field.templateName
          templatedFields.push(f)
        }
      })
      return templatedFields
    },
    dataTable: function () {
      let dataTable = this.$store.state.reportsExport.reportsList
      // Тут, похоже, array-like object - переделываем в массив.
      dataTable = Array.from(dataTable)
      return dataTable
    }
  },
  created () {
  },
  mounted () {
    this.defineCurrentActiveModule()
    // Чтобы не вылетал бэк с 500 ошибкой, надо обязательно зачищать фильтры, прежде чем отправлять запрос на пейджер.
    this.$store.commit('filters/clearAllFilters')
    this.$store.dispatch('pager/updateCurrentTableState', 'default')
  },
  methods: {
    reloadReports () {
      this.$store.dispatch('pager/updateCurrentTableState', 'default')
    },
    selectItem (item, index, event, type) {
      const target = type === 'get' ? event : event.currentTarget
      if (!target) {
        item = {}
        this.$store.dispatch('extend/tableRowSelected', item)
      }

      for (let i = 0; i < target.parentNode.children.length; i++) {
        if (target.parentNode.children[i].classList.contains('select')) {
          target.parentNode.children[i].classList.remove('select')
        }
      }

      target.classList.add('select')
      // this.$store.dispatch('extend/tableRowSelected', item)
    }
  }
}
</script>

<style scoped>
 table >>> .btn-secondary{
     background-color: white;
     color: #5A6169;
 }
</style>
