<template>
  <d-card class="card-div mb-4 life_merchants">
    <d-card-body style="min-height: 500px">
      <Loader v-if="!apiResponseReceived" :size="3" />
      <b-form v-else @submit="onSubmit">
        <div class="back">
          <router-link :to="back">
            <img src="@/assets/images/apple-keyboard-control.png" alt="Go back" width="24" height="24">
            <span class="back-link">Вернуться</span>
          </router-link>
          <div class="float-right ml-auto">
            <a href="https://wiki.mandarin.io/pages/viewpage.action?pageId=17400832" target="_blank">Инструкция по настройке и использованию</a>
          </div>
        </div>
        <div class="settings-block">

          <b-form-checkbox class="mt-0 mb-4" v-model="item.is_active" switch>Модуль активен
            <i v-b-tooltip.hover title="Активируйте модуль, чтобык лиенты могли подавать через него заявки.
Деактивируйте модуль, чтобы приостановить по нему продажи, сохранив все настройки.">
              <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
            </i>
          </b-form-checkbox>

          <b-form-group class="payment-input">
            <legend>Название модуля
              <i v-b-tooltip.hover title="Выводится в общем списке модулей в Личном кабинете, клиенты не увидят этого названия">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <b-form-input v-model="item.mandatory_info.site.module_name" placeholder="Новый модуль (до 100 символов)"></b-form-input>
          </b-form-group>

          <b-form-group class="payment-input file-group-container">
            <legend>
              Логотип мерчанта
              <i v-b-tooltip.hover title="Загрузите ваш логотип в формает PNG, если хотите, чтобы он выводился на модуле">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <div class="align-center" v-if="!item.mandatory_info.site.logo">
              <div class="file-drop-area">
                <span class="file-message">Загрузите логотип</span>
                <input class='w-100 file-input' type="file" accept="image/jpeg, image/gif, image/png" id="logoimage" @change="onLogoChange"/>
              </div>
            </div>
            <div v-else>
              <div class="align-center" style="max-height: 100px;">
                <img class='' :src="item.mandatory_info.site.logo"  alt="image"/><br/>
                <a class="cursor-pointer mt-2" @click='deleteImageLogo'>
                  <img src="@/assets/images/icon_delete.png" alt="Удалить">
                </a>
              </div>
            </div>
          </b-form-group>

          <b-form-group class="payment-input">
            <legend>Кредитный продукт
              <i v-b-tooltip.hover title="Выберите из выпадающего списка кредитный продукт - его условия будут применяться к заявке клиента">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <b-form-select :options="hl_merchants_options" v-model="item.customer_info.merchant_id" @change="handleMerchantIdChange"></b-form-select>
          </b-form-group>

          <b-form-group class="payment-input">
            <legend>Заголовок модуля
              <i v-b-tooltip.hover title="Укажите названите модуля, который клиент увидет в заголовке при начале оформления.">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <b-form-input v-model="item.mandatory_info.site.module_title"></b-form-input>
          </b-form-group>

          <b-form-group class="payment-input">
            <legend>Название тарифа в кредитном договоре клиента
              <i v-b-tooltip.hover title="Укажите название тарифа, которое будет отображаться в кредитном договоре клиента.">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <b-form-input v-model="item.mandatory_info.site.tariff_name" placeholder="до 70 символов"></b-form-input>
          </b-form-group>

          <b-form-group class="payment-input">
            <legend>Описание оффера
              <i v-b-tooltip.hover title="Укажите описание услуги, которое увидит клиент на модуле. Ограничение: 200 символов">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <b-form-input v-model="item.mandatory_info.site.offer_description" placeholder="до 200 символов"></b-form-input>
          </b-form-group>

          <b-form-group class="payment-input">
            <legend>Стоимость товара/услуги, руб.
              <i v-b-tooltip.hover title="Укажите стоимость приобритаемого товара/услуги без скидки.">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <b-form-input v-model="item.mandatory_info.site.cost"></b-form-input>
          </b-form-group>

          <b-form-group class="payment-input">
            <legend>Срок выплаты, в месяцах
              <i v-b-tooltip.hover title="Укажите до трех вариантов срока, которые клиент увидит на модуле. По умолчанию выбирается наибольший срок.">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <div class="d-flex justify-content-between">
              <multiselect  id="some" v-model="term_in_months"
              :options="term_in_months_options_ms"
              :multiple="true"
              :searchable="false"
              track-by="value"
              label="text"
              :max="3"
              :deselect-label="$i18n.t('service.remove')"
              :select-label="$i18n.t('service.add')"
              :selected-label="$i18n.t('service.optionSelected')"
              placeholder="Выберите сроки для показа в модуле"></multiselect>
            </div>
          </b-form-group>

          <b-form-group class="payment-input">
            <legend>Ссылка на оферту
              <i v-b-tooltip.hover title="Добавьте ссылку на вашу оферту с клиентом, если хотите, чтобы она отображалась на модуле.">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <b-form-input v-model="item.mandatory_info.site.offer_url" placeholder="https://mandarin.io/conditions"></b-form-input>
          </b-form-group>

          <b-form-group class="payment-input">
            <legend>Ссылка для редиректа клиента при одобрении заявки
              <i v-b-tooltip.hover title="Укажите URL страницы, на которую клиент будет перенаправлен после окончания одобрения, если заявка одобрена.">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <b-form-input v-model="item.mandatory_info.site.approval_url"></b-form-input>
          </b-form-group>

          <b-form-group class="payment-input">
            <legend>Ссылка для редиректа клиента при отказе по заявке
              <i v-b-tooltip.hover title="Укажите URL страницы, на которую клиент будет перенаправлен после окончания одобрения, если по заявке отказ.">
                <img src="@/assets/images/Info_outline.png" alt="info" width="24" height="24">
              </i>
            </legend>
            <b-form-input v-model="item.mandatory_info.site.failure_url"></b-form-input>
          </b-form-group>

        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <b-button type="submit" variant="primary" block>Сохранить</b-button>
          </div>
        </div>
      </b-form>
    </d-card-body>
  </d-card>
</template>


<script>
import Multiselect from 'vue-multiselect'
function validURL(str) {
  let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}
function isNumeric(n) {
  return /^-?\d*[.,]?\d*$/.test(n);
}

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      logoImage: false,
      term_in_months: [],
      settingUpTerms: true,
      options: [
        {value: 'life', text: 'Кредит/Рассрочка'},
        {value: 'payout', text: 'Выплаты'},
        {value: 'pay', text: 'Приём платежей'}
      ],
      themas: [
        {value: 'white', text: 'Светлая тема'},
        {value: 'black', text: 'Темная тема'}
      ],
      selected_products: 'none',
      selected_products1: 'none',
      selected_products2: 'none',
      selected_products3: 'none',
      products: [
        {value: 'none', text: '- не выбрано -'},
        {value: 'one', text: 'Кредит'},
        {value: 'two', text: 'Рассрочка'}
      ],
      hl_merchants_options: [
        {value: 'none', text: '- не выбрано -'},
      ],
      term_in_months_options: [
        {value: 'none', text: '- не выбрано -'},
      ],
      min_order_amount: 1000,
      max_order_amount: 500000
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let moduleName = this.item.mandatory_info.site.module_name;
      if (!moduleName || moduleName.length > 100) {
        this.$toast.error('Текст в поле "Название модуля" не может превышать 100 символов.');
        return;
      }
      if (this.item.customer_info.merchant_id === 'none') {
        this.$toast.error('Выберите значение поля "Кредитный продукт" из выпадающего списка.');
        return;
      }
      let moduleTitle = this.item.mandatory_info.site.module_title;
      if (!moduleTitle) {
        this.$toast.error('Поле "Заголовок модуля" не может быть пустым');
        return;
      }
      let tariffName = this.item.mandatory_info.site.tariff_name;
      if (!tariffName || tariffName.length > 70) {
        this.$toast.error('Текст в поле "Название тарифа" не может превышать 70 символов.');
        return;
      }
      let offerDescription = this.item.mandatory_info.site.offer_description;
      if (!offerDescription || offerDescription.length > 200) {
        this.$toast.error('Текст в поле "Описание оффера" не может превышать 200 символов.');
        return;
      }
      let cost = this.item.mandatory_info.site.cost;
      if (!(/^[0-9]+[.,]?[0-9]*$/).test(cost)) {
        this.$toast.error('В поле "Стоимость товара/услуги, руб." необходимо указать числовое значение');
        return;
      } else {
        cost = parseFloat(cost.replace(',', '.'));
        if (!(cost > this.min_order_amount && cost < this.max_order_amount)) {
          this.$toast.error(`Стоимость должна быть больше ${this.min_order_amount} и меньше ${this.max_order_amount}, для настройки этих параметров обратитесь в службу технической поддержки`);
          return;
        }
      }

      // Проверяем, выбран ли у нас хоть один срок.
      let terms_array = this.term_in_months
      if (terms_array.length === 0 || terms_array[0].value === 'none'){
        this.$toast.error('Выберите хотя бы одно значение поля "Срок выплаты в месяцах" из выпадающего списка.');
        return;
      }
      // Выбран. Сейчас нам надо раскидать сроки по термам.
      let itemCopy = JSON.parse(JSON.stringify(this.item));
      itemCopy.mandatory_info.site.term1_in_months = terms_array[0].value
      if (terms_array[1] !== undefined){itemCopy.mandatory_info.site.term2_in_months = terms_array[1].value}
      else{itemCopy.mandatory_info.site.term2_in_months = 'none'}
      if (terms_array[2] !== undefined){itemCopy.mandatory_info.site.term3_in_months = terms_array[2].value}
      else{itemCopy.mandatory_info.site.term3_in_months = 'none'}

      if (!validURL(this.item.mandatory_info.site.approval_url)) {
        this.$toast.error('В поле "Ссылка для редиректа клиента при одобрении заявки" необходимо указать ссылку.');
        return;
      }
      if (!validURL(this.item.mandatory_info.site.failure_url)) {
        this.$toast.error('В поле "Ссылка для редиректа клиента при отказе по заявке" необходимо указать ссылку.');
        return;
      }
      if (!validURL(this.item.mandatory_info.site.offer_url)) {
        this.$toast.error("Указана невалидная Ссылка на оферту");
        return;
      }

      this.$store.dispatch('payLender/saveEditPayout', {item: itemCopy, back: this.back, router: this.$router});

    },
    handleMerchantIdChange(newVal) {
      let options = [{value: 'none', text: '- не выбрано -'}]
      let api_key = ""
      let credit = false
      let gp = false
      let segment = ''
      if (newVal !== 'none') {
        let merchants = this.$store.state.account.hl_merchants
        let merchant = merchants.find(obj => obj.merchant_id === newVal);
        if (merchant) {
          if (merchant.min_order_amount !== undefined &&  isNumeric(merchant.min_order_amount)) this.min_order_amount = parseFloat(merchant.min_order_amount.replace(',', '.'));
          if (merchant.max_order_amount !== undefined && isNumeric(merchant.max_order_amount)) this.max_order_amount = parseFloat(merchant.max_order_amount.replace(',', '.'));
          api_key = merchant.api_key
          if (merchant.hl_product === "Гарантированный платеж") {
            gp = true
            let stringArray = merchant.term_setting_steps.split(";");
            options = stringArray.map(item => {
              return {value: Number(item), text: item};
            });
            options.unshift({value: 'none', text: '- не выбрано -'});
          } else {
            if (merchant.settings && merchant.settings.length > 0) {
              options = merchant.settings.map(item => {
                return {value: item.Term, text: item.Term.toString()};
              });
            } else {
              let stringArray = merchant.origin_term_setting_steps.split(";");
              options = stringArray.map(item => {
                return {value: Number(item), text: item};
              });
            }
            options.unshift({value: 'none', text: '- не выбрано -'});
          }

          let creditProductType = merchant.calc_discount7Seconds ? 'Рассрочка' : 'Кредит';
          credit = !merchant.calc_discount7Seconds;
          segment = merchant.segment ? merchant.segment : '';
          this.item.mandatory_info.site.module_title = `${creditProductType} на ${segment}`;
        }
      }
      this.term_in_months_options = options
      this.item.customer_info.api_key = api_key
      this.item.mandatory_info.site.credit = credit
      this.item.mandatory_info.site.gp = gp
      this.item.mandatory_info.site.segment = segment
    },
    deleteImageLogo() {
      this.item.mandatory_info.site.logo = "";
    },
    onLogoChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0], true);
    },
    createImage: function(file, lg) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = function(e) {
        if (lg) {
          vm.item.mandatory_info.site.logo = e.target.result;
        } else {
          vm.item.mandatory_info.site.bg_image = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    getDataTable() {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace;
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let responseObject = this.$store.state[activeTableNamespace].
        displayTable.tableApiUrls[activeColumnRow].responseObject;
      return this.$store.state[activeTableNamespace].apiData[responseObject];
    },
    add() {
      this.item.optional_info.push({
        'key': '',
        'value': ''
      });
    },
    del(index) {
      this.item.optional_info.splice(index, 1);
    },
  },
  watch: {
    '$store.state.account.hl_merchants': {
      immediate: true,
      handler() {
        let merchants = this.$store.state.account.hl_merchants
        let options = [{value: 'none', text: '- не выбрано -'},]
        let options_term = [{value: 'none', text: '- не выбрано -'},]
        if(Array.isArray(merchants)) {
          merchants.forEach( merchant => {
            if (merchant.is_active) {
              options.push({value: merchant.merchant_id, text: merchant.full_name})
              if (merchant.merchant_id === this.item.customer_info.merchant_id) {
                if (merchant.min_order_amount !== undefined && isNumeric(merchant.min_order_amount)) this.min_order_amount = parseFloat(merchant.min_order_amount.replace(',', '.'));
                if (merchant.max_order_amount !== undefined && isNumeric(merchant.max_order_amount)) this.max_order_amount = parseFloat(merchant.max_order_amount.replace(',', '.'));
                if (merchant.hl_product === "Гарантированный платеж") {
                  let stringArray = merchant.term_setting_steps.split(";");
                  options_term = stringArray.map(item => {
                    return {value: Number(item), text: item};
                  });
                  options_term.unshift({value: 'none', text: '- не выбрано -'});
                } else {
                  if (merchant.settings && merchant.settings.length > 0) {
                    options_term = merchant.settings.map(item => {
                      return {value: item.Term, text: item.Term.toString()};
                    });
                  } else {
                    let stringArray = merchant.origin_term_setting_steps.split(";");
                    options_term = stringArray.map(item => {
                      return {value: Number(item), text: item};
                    });
                  }
                  options_term.unshift({value: 'none', text: '- не выбрано -'});
                }
                this.term_in_months_options = options_term;
              }
            }
          })
        }
        this.hl_merchants_options = options
      }
    },
  },
  computed: {
    term_in_months_options_ms(){
      let options = this.term_in_months_options
      if (options.length > 1 && options[0].value === 'none'){
        options.shift()
      }
      return options
    },
    apiResponseReceived () {
      return this.$store.state.pager.apiResponseReceived;
    },
    item () {
      let item = {
        new: true,
        is_active: true,
        action: 'life',
        merchant_id: 0,
        id: null,
        mandatory_info: {
          site: {
            price: "0",
            module_name: '',
            module_title: "",
            logo: "",
            failure_url: "https://mandarin.io/",
            approval_url: "https://mandarin.io/",
            offer_description: "",
            tariff_name: "",
            term1_in_months: "none",
            term2_in_months: "none",
            term3_in_months: "none",
            cost: "",
            offer_url: "https://mandarin.io/conditions",
            credit: false,
            gp: false,
            segment: '',
          }
        },
        customer_info: {
          api_key: "",
          merchant_id: "none"
        },
        optional_info: [
          {
            key: '',
            value: ''
          }
        ]
      }
      let dataTable = this.getDataTable()
      dataTable.forEach( (field, j) => {
        if (field.hasOwnProperty('id')) {
          if (field.id === Number(this.$route.params.mid) && field.action === this.$route.params.arg3) item.new = j
          if (field.id === Number(this.$route.params.mid) && field.action === 'life') {
            if (field.mandatory_info === null) dataTable[j].mandatory_info = item.mandatory_info
            if (field.optional_info === null || field.optional_info.length < 1 || field.optional_info[0] === null) dataTable[j].optional_info = item.optional_info
            if (field.customer_info === null) dataTable[j].customer_info = item.customer_info
            let i = field.mandatory_info.site
            if (i.logo && !i.logo.includes('base64') && !i.logo.includes('://')) i.logo = VUE_APP_SECURE_APP+'images/'+i.logo;
            dataTable[j].mandatory_info.site = i
            if (field.is_active === null)
              dataTable[j].is_active = item.is_active
          }
        }
      })
      // setting data points

      if (item.new === true) {
        let len = dataTable.push(item)
        return dataTable[len-1]
      } else {
        // Добавляем существующие выборы при редактировании в наш мультиселект.
        // Делаем это один раз и изолируем после этого поле выборов от непредсказуемой реактивности item.
        if (this.settingUpTerms){
          this.settingUpTerms = false
          let siteObj = JSON.parse(JSON.stringify(dataTable[item.new].mandatory_info.site));
          if (siteObj.term1_in_months !== "none"){
            this.term_in_months.push({value: siteObj.term1_in_months, text: siteObj.term1_in_months.toString()})
          }
          if (siteObj.term2_in_months !== "none"){
            this.term_in_months.push({value: siteObj.term2_in_months, text: siteObj.term2_in_months.toString()})
          }
          if (siteObj.term3_in_months !== "none"){
            this.term_in_months.push({value: siteObj.term3_in_months, text: siteObj.term3_in_months.toString()})
          }
        }
        return dataTable[item.new]
      }
    },
    back () {
      return '/pay_merchant/' + this.$route.params.arg1 + '/' + 'modules/';
    },
    addModuleLifeScope: function () {
      return this.$store.state.account.scopesArray.includes('secure_app_life_module.write');
    }
  },
  mounted () {
    if (!this.addModuleLifeScope) {
      this.$router.push('/pay_merchant/'
        + this.$route.params.arg1
        + '/' + this.$route.params.arg2
        + '/' + this.$route.params.arg3
        + '/'
      )
    }
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pager/updateCurrentTableState', 'default')
    this.$store.dispatch('extend/changedTable')
    this.$store.dispatch('loadingHLMerchants')
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

