<template>
  <b-row>
    <b-col>
      <nav-tabs />
        <d-card class="card-small">
          <d-card-body class="pl-0 pr-0">
            <RewardsRate/>
            <RewardsSalesRepresentatives/>
            <RewardsRecipientModal class="recip-modal"/>
          </d-card-body>
        </d-card>
      </b-col>
  </b-row>
</template>

<script>
import RewardsSalesRepresentatives from '@/components/Rewards/rewardsSalesRepresentatives'
import RewardsRate from '@/components/Rewards/rewardsRate'
import RewardsRecipientModal from '@/components/Rewards/rewardsRecipientModal'
import NavTabs from '@/components/Settings/navTabs.vue'
export default {
  components: {
    RewardsSalesRepresentatives,
    RewardsRate,
    RewardsRecipientModal,
    NavTabs
  },
  data () {
    return {

    }
  }
}
</script>

<style>
.recip-modal .modal-dialog{
  max-width: 1000px;
}
</style>
