<template>
  <div class="cell-wrapper">

    <div class="checkbox-docs">
    <b-form-checkbox button-variant="outline-secondary" disabled v-model="originalDocumentsReceived" name="check-button" switch>
      <span v-if="originalDocumentsReceived === false">{{$t('service.documentsNotReceived')}}</span>
      <span v-if="originalDocumentsReceived">{{originalDocumentsReceivedAt}}</span>
    </b-form-checkbox>
    </div>
    {{contractId}}&nbsp;<clipboard :data="contractId"/>
  </div>
</template>

<script>
export default {
  computed: {
    originalDocumentsReceived: function () {
      let originalDocumentsReceived = false
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      if (activeColumnRow === 'loansFinalized') {
        return this.item.original_documents_received
      }
      return originalDocumentsReceived
    },
    originalDocumentsReceivedAt: function () {
      let originalDocumentsReceivedAt = ''
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      if (activeColumnRow === 'loansFinalized') {
        if (this.item.original_documents_received_at) {
          originalDocumentsReceivedAt = 'Документы получены ' + this.properD(this.item.original_documents_received_at)
        }
      }
      return originalDocumentsReceivedAt
    },
    contractId: function () {
      let contractId = ''
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      if (activeColumnRow === 'loansFinalized') {
        contractId = this.item.contract_id
      }
      return contractId
    }
  },
  props: ['item']
}
</script>

<style>
.checkbox-docs .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #41b883;
}
.checkbox-docs .custom-control-input:disabled ~ .custom-control-label{
  color: #23282c;
}

</style>
