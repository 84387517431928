<template>
  <b-row>
    <b-col cols="12">
      <b-card class="card-small">
      <Filters/>
      <PagerComponent/>
      <Loader v-if="loadingData" :height="600" :size="3" />
      <div class="main-table-wrapper" v-else>
        <b-table striped hover selectable select-mode="single" class="text-nowrap" :fields="fields" :items="dataTable">
          <template v-slot:head()="data">
            <span v-html="data.field.label"></span>
          </template>
          <template v-slot:cell(reward_date)="data">
            {{convertToDate(data.item.reward_date)}}
          </template>
          <template v-slot:cell(mandarin_service)="data">
            {{getService(data.item.mandarin_service)}}
          </template>
          <template v-slot:cell(turnover)="data">
            {{properMRub(data.item.turnover)}}
          </template>
          <template v-slot:cell(reward_amount)="data">
            {{properMRub(data.item.reward_amount)}}
          </template>
        </b-table>
        <div v-if="!haveResults" style="min-height: 400px; text-align: center; padding-top: 150px;">
          Данные за указанный период с учетом выбранных фильтров отсутствуют.
        </div>
      </div>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import PagerComponent from '@/components/Pager/pagerComponent'
import Filters from './Filters/Filters'
import moment from 'moment'

export default {
  name: 'Partners',
  components: {
    PagerComponent,
    Filters,
    moment
  },
  data(){
    return{
      isLoading: false,
      fields: [
        {
          key: 'referral_client_id',
          label: 'CID',
        },
        {
          key: 'referral_client_name',
          label: 'Название',
        },
        {
          key: 'reward_date',
          label: 'Дата',
        },
        {
          key: 'mandarin_service',
          label: 'Сервис',
        },
        {
          key: 'turnover',
          label: 'Оборот',
        },
        {
          key: 'reward_amount',
          label: 'Вознаграждение',
        },
      ]
    }
  },
  computed:{
    dataTable(){
      return this.$store.state.partners.partner_rewards
      // return []
    },
    loadingData(){
      return !this.$store.state.pagerStd.apiResponseReceived
      // return false
    },
    haveResults(){
      if (Array.isArray(this.dataTable) && this.dataTable.length>0){
        return true
      }
      return false
    }
  },
  watch: {

  },
  methods:{
    convertToDate(sourceDate) {
      let momentDate = moment(sourceDate, 'YYYY-MM-DD');
      return momentDate.format('DD.MM.YYYY');
    },
    getService (item) {
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      let option = filters_config.service.options.find(opt => opt.value === item);
      return option ? option.label : item;
    },
  },
  created(){
    // Чтобы остатки фильтров не оставались после предыдущих страниц, очищаем фильтры. Делать это надо до установки новых.
    this.$store.commit('filtersStd/clearFilters')
    // Устанавливаем, какой из конфигов фильтров из config/filtersTables используется в этой таблице.
    this.$store.commit('filtersStd/setActiveFilterConfig', 'filtersPartners', { root: true })
  },
  mounted () {
    // Устанавливаем нейспейс для пейджера в этой таблице.
    this.$store.commit('pagerStd/updateActiveTableNamespace', 'partners', { root: true })
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
  },
}
</script>

<style scoped>

</style>
