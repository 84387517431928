<template>
  <d-card class="card-small mb-4"
          style="border-radius: 0 0.625rem 0.625rem 0.625rem">
    <d-card-body>
      <d-row class="mb-4">
        <d-col lg="2">
          <DatePicker/>
        </d-col>
        <d-col lg="2">
          <p class="mb-0"></p>
          <button type="button" class="btn btn-light btn-sm mx-3 search_registries" v-on:click="getSearch('search')">{{ $t('extend.search') }}</button>
        </d-col>
        <d-col lg="8">
        </d-col>
      </d-row>
      <Loader v-if="!loadingData" :height="600" :size="3" />
      <div v-else style="min-height: 600px">

        <d-row class="mt-4">
          <d-col>
            <Pager/>
          </d-col>
          <d-col lg="1" style="min-width: 126px">
            <pageOptions/>
          </d-col>
        </d-row>
        <div class="table-overflow-y-hidden" v-if="showTable">
            <b-table small :fields="fields" :items="dataTable" responsive>
                <template #cell(state)="data">
                    <div class="cell-wrapper">
                        <d-dropdown class="w-100" :text="data.item.state | to_state(data.item.errors, 0)" :theme="data.item.state | to_state(data.item.errors, 1)" split right>
                            <b-dropdown-item v-if="data.item.state == 'processing' || data.item.state == 'download'" @click='action_payouts(data.item, true)'>{{ (data.item.state == 'download')?'Начать выплаты':'Продолжить выплаты' }}</b-dropdown-item>
                            <b-dropdown-item v-if="data.item.state == 'processing' || data.item.state == 'processed'" @click='action_payouts(data.item)'>Скачать отчет</b-dropdown-item>
                        </d-dropdown>
                    </div>
                </template>
                <template #cell(created)="data">
                    <p class="text-nowrap">{{ properD(data.item.created) }}</p>
                </template>
                <template #cell(amount)="data">
                    <p class="text-nowrap">{{ properMRub(parseFloat(data.
                                item.amount)) }}</p>
                </template>
                <template #cell(action)="data">
                  {{ $t('components.payout.'+data.item.action) }}
                </template>
            </b-table>
        </div>
        <div class="text-center" style="line-height: 30em;">
          <p>{{ showError }}</p>
        </div>
      </div>
    </d-card-body>
  </d-card>
</template>
<style>
    .form-control {
        height: calc(2.425rem + 2px);
    }
</style>
<script>

import DatePicker from '@/components/Filters/datePicker_old'
import Pager from '@/components/Filters/pager'
import pageOptions from '@/components/Filters/pageOptions'
import Action from '@/components/Filters/action'

export default {
  components:{
      DatePicker,
      Pager,
      pageOptions,
      Action
  },
  data() {
      return {

      }
  },
  filters:{
    to_state: function(s, e, num) {
        let c = {
            download: ['Загружен', 'secondary'],
            processed_e: ['Обработан с ошибками', 'info'],
            processed: ['Успешно обработан', 'success'],
            processing: ['Прерван', 'danger']
        }
        if (s === 'processed' && e !== null) {
            s = 'processed_e'
        }
        return c.hasOwnProperty(s)?c[s][num]:'danger'
    }
  },
  computed: {
    showError: function() {
        return this.$store.state[this.$store.state.pager.activeTableNamespace].
                apiData.error
    },
    loadingData: function () {
      return this.$store.state.pager.apiResponseReceived
    },
    showTable: function () {
      if (this.$store.state.pager.apiResponseReceived && !this.$store.state.
              pager.apiDataEmpty) {
        return true
      }
      return false
    },
    dataTable: function () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let responseObject = this.$store.state[activeTableNamespace].displayTable.
              tableApiUrls[activeColumnRow].responseObject
      return this.$store.state[activeTableNamespace].apiData[responseObject]
    },
    fields () {return [
      {
        key: 'id',
        label: 'ID',
        templateName: 'CellText'
      },
      {
        key: 'created',
        label: 'Дата',
        templateName: 'CellText'
      },
      {
        key: 'action',
        label: 'Тип модуля',
        templateName: 'CellText'
      },
      {
        key: 'merchant_id',
        label: 'Merchant ID',
        templateName: 'CellText'
      },
      {
        key: 'amount',
        label: 'Сумма',
        templateName: 'CellText'
      },
      {
        key: 'rows',
        label: 'Строк',
        templateName: 'CellText'
      },
      {
        key: 'state',
        label: 'Статус',
        templateName: 'CellText',
        class: 'col-3'
      }
    ]},
    templatedFields () {
      let templatedFields = []
      this.fields.forEach(field => {
        if (field.hasOwnProperty('templateName')) {
          let f = {}
          f.key = field.key
          f.templateName = field.templateName
          templatedFields.push(f)
        }
      })
      return templatedFields
    }
  },
  created () {
    //this.$store.commit('filters/clearAllFilters')
    //this.defineActiveRowColumnAndModule()
  },
  mounted () {
    this.$store.dispatch('pager/updateCurrentTableState', 'default')
  },
  methods: {
    getSearch (value) {
      this.$store.dispatch('pager/updateCurrentTableState', value, { root: true })
    },
    action_payouts(item, update = false){
        this.$store.state.payLender.registry_response.id = item.id
        //console.log('Start')
        if (update) {
            if (item.state === 'download') {
                console.log('Start payments')
            } else {
                console.log('Continue payments')
            }
            this.$store.dispatch('payLender/paymentsStart', this.$router)
        } else {
            console.log('Download report')
            this.$store.dispatch('payLender/downloadRegistryReport',
                                item.merchant_id)
        }
    },
    open(mid) {
        this.$router.push('/pay_merchant/' + this.$store.state.user.cid + '/' +
        this.$route.params.arg1 + '/' + 'payout/' + mid + '/')
    }
  }
}
</script>
<style>
.dropdown-toggle-split {
  max-width: 32px;
}
.search_registries {
  margin-top: 21px;
  height: 41px;
}
</style>
