<template>
  <ul class="pagination">
    <li v-if="showPrev" @click="switchData('prev')" class="page-link pointer pager-previous">«</li>
    <li class="page-link pager-numbers">{{numberFrom}} - {{numberTo}}</li>
    <li v-if="showNext" @click="switchData('next')" class="page-link pointer pager-next">»</li>
  </ul>
</template>

<script>
export default {
  computed: {
    showPrev () { if (this.$store.state.pager.cursor.prev === null) { return false } else { return true } },
    showNext () { if (this.$store.state.pager.cursor.next === null) { return false } else { return true } },
    numberFrom () { return this.$store.state.pager.cursor.range * this.$store.state.pager.cursor.currentOffset },
    numberTo () { return this.numberFrom + this.$store.state.pager.cursor.count }
  },
  methods: {
    switchData (value) {
      this.$store.dispatch('pager/updateCurrentTableState', value, { root: true })
    }
  }
}
</script>
