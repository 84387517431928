const currencies = {
    643: {
      num: 643,
      code: 'RUB',
      unicode: '&#x20BD;',
      short: 'руб',
      long: 'рублей',
      default: '&#x20BD;'
    }, 
    978: {
      num: 978,
      code: 'EUR',
      unicode: '&#x20AC;',
      short: 'EUR',
      long: 'euro',
      default: '&#x20AC;'
    },     
    840: {
      num: 840,
      code: 'USD',
      unicode: '&#36;',
      short: 'USD',
      long: 'dollar',
      default: '&#36;'
    },      
  }
  
  export default currencies