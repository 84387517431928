import filtersConfigs from '@/configs/filtersTables/importFilters'

export default {
  namespaced: true,
  state: {
    filtersConfigs: filtersConfigs, // Для настройки фильтеров по конфигам
    activeFilterConfig: '', // Для настройки фильтеров по конфигам
    resetDateFilters: 'ready',
    setDateFrom: false,
    resetAllFilters: 'ready',
    periodFrom: false,
    periodTo: false,
    dateFrom: false,
    dateTo: false,
    clientId: false,
    showInactive: false,
    transactionId: false,
    contractId: false,
    loanRequestId: false,
    merchantId: false,
    lenderId: false,
    accepted: [],
    customFilterSet: [],
    opcodesString: '',
    stagesString: '',
    statusString: '',
    instrumentTypesString: '',
    sortByField: false,
    test: null,
    docsOriginal: null,
    docsScan: null,
    mids: '',
    searchApp: '',
    searchClient: '',
    filterByString: '',
    mandarinId: null,
    providerId: null,
    service: null,
    numberOrder: null,
    paymentMethod: null,
    isSandbox: null,
  },
  mutations:{
    setActiveFilterConfig(state, value){
      state.activeFilterConfig = value
    },
    clearDateFilters(state){
      state.dateFrom = false
      state.dateTo = false
      state.resetDateFilters = 'reset'
    },
    setDates(state, value){
      state.dateFrom = value.start
      state.dateTo = value.end
    },
    setDateFrom(state, value){
      state.setDateFrom = value
    },
    resetDateFilters(state, value){
      state.resetDateFilters = value
    },
    resetAllFilters(state, value){
      state.resetAllFilters = value
    },
    clearFilters(state){
      state.periodFrom = false
      state.periodTo = false
      state.dateFrom = false
      state.dateTo = false
      state.clientId = false
      state.showInactive = false
      state.transactionId = false
      state.contractId = false
      state.loanRequestId = false
      state.merchantId = false
      state.lenderId = false
      state.accepted = []
      state.customFilterSet = []
      state.opcodesString = ''
      state.stagesString = ''
      state.statusString = ''
      state.instrumentTypesString = ''
      state.sortByField = false
      state.test = null
      state.docsOriginal = null
      state.docsScan = null
      state.mids = ''
      state.filterByString = ''
      state.searchApp = ''
      state.searchClient = ''
      state.mandarinId = null
      state.providerId = null
      state.service = null
      state.paymentMethod = null
      state.numberOrder = null
      state.isSandbox = null
    },
    updateIsSandbox (state, value) {
      state.isSandbox = value
    },
    updateTest (state, value) {
      state.test = value
    },
    updateNumberOrder (state, value) {
      state.numberOrder = value
    },
    updatePaymentMethod (state, value) {
      state.paymentMethod = value
    },
    updateMids (state, value){
      state.mids = value
    },
    UpdateSearchApp (state, value){
      state.searchApp = value
    },
    UpdateSearchClient (state, value){
      state.searchClient = value
    },
    updateInstrumentTypesString (state, value){
      state.instrumentTypesString = value
    },
    updateDocsOriginal (state, value) {
      state.docsOriginal = value
    },
    updateDocsScan (state, value) {
      state.docsScan = value
    },
    updateSortByField (state, values) {
      state.sortByField = values
    },
    updateCustomFilterSet (state, values) {
      state.customFilterSet = values
    },
    updateOpcodes (state, values) {
      state.opcodesString = values
    },
    updateStages (state, values) {
      state.stagesString = values
    },
    updateStatus (state, values) {
      state.statusString = values
    },
    updateDateFrom (state, values) {
      state.dateFrom = values
    },
    updateDateTo (state, values) {
      state.dateTo = values
    },
    updatePeriodsFilter (state, values) {
      if (!values) { return false }
      if (values.hasOwnProperty('fromPeriod')) {
        state.periodFrom = values.fromPeriod
      }
      if (values.hasOwnProperty('toPeriod')) {
        state.periodTo = values.toPeriod
      }
    },
    updateLoanRequestId(state, value){
      state.loanRequestId = value
    },
    updateMerchantId(state, value){
      state.merchantId = value
    },
    updateLenderId(state, value){
      state.lenderId = value
    },
    updateClientId(state, value){
      state.clientId = value
    },
    updateShowInactive(state, value){
      state.showInactive = value
    },
    updateTransactionId(state, value){
      state.transactionId = value
    },
    updateContractId(state, value){
      state.contractId = value
    },
    updateAccepted(state, value){
      state.accepted = value
    },
    updateFilterByString(state, value){
      state.filterByString = value
    },
    updateMandarinId(state, value){
      state.mandarinId = value
    },
    updateProviderId(state, value){
      state.providerId = value
    },
    updateService (state, values) {
      state.service = values
    },

  },
  actions:{
    prepareFilterByParameter(context){
      context.dispatch(context.rootState.pagerStd.activeTableNamespace+'/prepareFilterByString', '', { root: true })
    }
  }
}
