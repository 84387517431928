<template>
  <b-row>
    <b-col>
      <b-card class="card-small">
        <b-row>
          <b-col>
            <h5 class="pt-2">Добавление проекта</h5>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <span class="label">Название проекта</span><span>*</span>
          </b-col>
          <b-col>
            <b-form-input v-model="projectName"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="2">
            <span class="label">Выбор сервиса</span>
          </b-col>
          <b-col>

            <div>
              <multiselect v-model="projectType"
              :options="projectTypeOptions"
              :multiple="true"
              :searchable="false"
              track-by="tid"
              label="name"
              class="project-type-select"
              placeholder="Выберите одну или несколько категорий"></multiselect>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="2">
            <span class="label">Тестовый проект (sandbox mode)</span>
          </b-col>
          <b-col>
            <div v-if="clientIsSandbox" v-b-tooltip.hover="tooltipSandbox" style="width: 36px;">
              <b-form-checkbox  v-model="projectSandbox" :disabled="clientIsSandbox" name="project-sandbox" switch></b-form-checkbox>
            </div>
            <div v-else>
              <b-form-checkbox v-model="projectSandbox" :disabled="clientIsSandbox" name="project-sandbox" switch></b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="2"></b-col>
          <b-col>
            <b-button v-on:click="createNewProject" :disabled="!allowedSubmit()" variant="outline-primary">Добавить проект</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data: function () {
    return {
      projectName: '',
      projectType: [],
      projectSandbox: true,
      busy: false,
      // pojectTypeOptions: [
      //   { name: 'Прием платежей', tid: 32 },
      //   { name: 'Выплаты', tid: 33 },
      //   { name: 'Упрощенная идентификация', tid: 45 },
      //   { name: 'Рассрочка', tid: 44 },
      //   { name: 'Роутинг', tid: 46 }
      // ]
    }
  },

  computed:{
    clientIsSandbox() {
      this.projectSandbox = this.$store.state.account.mandarin.is_sandbox
      return this.$store.state.account.mandarin.is_sandbox
    },
    tooltipSandbox(){
        return 'Создание боевого проекта будет доступно после одобдрения вашей анкеты. В случае наличия вопросов, свяжитесь с вашим менеджером.'
    },
    projectTypeOptions() {
      // Получаем данные сервисов из Vuex
      const services = this.$store.state.account.mandarin.serviceOptions;
      // Преобразуем данные в нужный формат для Multiselect
      return services.reduce((acc, service) => {
        service.options.forEach(option => {
          acc.push({
            tid: `${service.codename}:${option.codename}`, // Уникальный идентификатор для track-by
            name: `${service.name}: ${option.name}` // Название для отображения
          });
        });
        return acc;
      }, []);
    },
  },
  methods: {
    allowedSubmit(){
      let valid = true
      if (this.projectType.length === 0){
        valid = false
      }
      if (this.busy){ valid = false }
      if (!this.projectNameValid()){ valid = false}
      return valid
    },
    projectNameValid(){
      return this.projectName !== '';
    },
    getSelectedServices() {
      // Преобразуем выбранные опции в требуемый формат
      const groupedOptions = this.projectType.reduce((acc, option) => {
        const [serviceCodename, optionCodename] = option.tid.split(':');
        if (!acc[serviceCodename]) {
          acc[serviceCodename] = { codename: serviceCodename, options: [] };
        }
        acc[serviceCodename].options.push(optionCodename);
        return acc;
      }, {});

      // Преобразуем объект в массив
      return Object.values(groupedOptions);
    },
    createNewProject() {
      let url = VUE_APP_API_CLIENTS + `/clients/v1/clients/${this.$store.state.account.mandarin.client_id}/merchants`
      let payload = {
        name: this.projectName,
        is_sandbox: this.projectSandbox,
        services: this.getSelectedServices()
      }
      this.busy = true
      this.axios.post(url, payload)
        .then(response => {
          this.busy = false
          if (!Object.prototype.hasOwnProperty.call(response, 'status')){
            console.log('error in promise createNewProject', error)
            this.$toast.error("Нам не удалось связаться с cервером проектов")
          }
          console.log(response, 'response createNewProject')
          if (response.status === 201 && response.data.merchant_id){
            this.$router.push({ name: 'ProjectEdit', params: { arg1: response.data.merchant_id }})
            this.$toast.success("Новый проект был создан и отправлен на проверку и одобрение.");
          }else{
            console.log(response, 'response createNewProject - some error in response!')
            this.$toast.error("Нам не удалось создать новый проект; ошибка: "+response.data.message)
          }
        })
        .catch(error => {
          console.log('error in promise createNewProject', error)
          this.$toast.error("Нам не удалось связаться с сервисом добавления проектов: "+error.message);
        })
    },
  },
  async mounted(){
    await this.$store.dispatch('loadServiceOptions')
  },
}
</script>
