<template>
  <div class="cell-wrapper">
    {{properD(item.created_at)}}
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>
