const filters_config = {
    status:{
      show: true,
      options: [
        { value: 'Success', label: 'Успешно', selected: true},
        { value: 'Fail', label: 'Ошибка', selected: true},
        { value: 'New', label: 'Обработка', selected: true }
      ]
    },
    replaceMethod: {
      PREPAY_FULL: '- 100% предоплата',
      PREPAY_PARTIAL: 'Частичная предоплата',
      AVANS: 'Аванс',
      FULL_PAY: 'Полная оплата',
      PARTIAL_SETTLEMENT_AND_CREDIT: 'Частичный расчет и кредит',
      TRANSFER_ON_CREDIT: 'Оплата в кредит',
      CREDIT_PAYMENT: 'Оплата кредита'
    },
    actionType: {
     Receipt: "Оплата",
     Refund: "Возврат оплаты",
     Purchase: "Выплата",
     PurchaseRefund: "Возврат выплаты",
    }
  }

  export default filters_config
