<template>
  <div class="w-100">
    <div v-closable="{handler: 'clickedOutsideSearchElement'}" class="main-navbar__search py-3" style="min-width: 550px; max-width: 550px;">
      <d-input-group seamless>
        <d-input-group-text slot="prepend">
          <i class="material-icons">search</i>
        </d-input-group-text>
        <d-input
          ref="search"
          v-model="search"
          :class="`${result ? 'navbar-search-radius' : ''} navbar-search`"
          placeholder="Поиск ..."
          @input.native="inputHandler($event.target.value)"
          style=" box-shadow: 0 0 10px rgba(0,0,0,0.1)" />
          <d-input-group-addon append class="pr-2" style="top: 17%">
            <i v-if="isSearch" class="fa fa-spinner fa-spin" style="font-size: 1.5em; width: 22px; height: 22px;"></i>
            <small v-else-if="result && result.total" style="color: #afafaf; padding-top: 2px;">{{result.total}} транз.</small>
        </d-input-group-addon>
      </d-input-group>
      <div v-if="focus" class="focus">
        Введите значение, по которому необходимо осуществить поиск
      </div>
      <div v-else-if="result && result.data && result.data.length" class="search-result">
        <div class="pt-2 text-nowrap">
          <div v-for="item in result.data.slice(0, 5)" :key="item.id" @click="selectItem(item)" class="pointer search-result-item">
            <div>{{properDT(item.updated)}}</div>
            <div>{{item.cs1}}</div>
            <div :style="`color: ${getAmountStyle(item.opcode)}; width: 100%; text-align: right;`">
              {{getAmount(item.opcode, item.amount) | money}}
              <i class="fas fa-check-circle"></i>
            </div>
          </div>
        </div>
        <div class="show-all pt-1">
          <d-row>
            <d-col>
              <d-button @click.prevent="showAll" block-level>Показать детально все результаты</d-button>
            </d-col>
          </d-row>
        </div>
      </div>
      <div v-else-if="result && result.data && !result.data.length">
        <d-row align-v="center" class="search-result text-center">
          <d-col>
            <p>Транзакции отсутствуют. Пожалуйста, измените запрос.</p>
          </d-col>
        </d-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      focus: false,
      search: null,
      total: null,
      result: null,
      isSearch: false
    }
  },
  mounted () {
    const search = this.$refs.search
    search.$el.addEventListener('focus', this.focusHandler)
    search.$el.addEventListener('blur', this.blurHandler)
  },
  methods: {
    clickedOutsideSearchElement () {
      // триггерим event blur на ref search
      let event = new Event('blur')
      this.$refs.search.$el.dispatchEvent(event)
    },
    focusHandler () {
      if (this.search) return this.inputHandler()
      this.focus = true
    },
    blurHandler (event) {
      this.focus = false
      this.isSearch = false
      setTimeout(() => this.result = null, 200)
    },
    async inputHandler (value) {
      this.focus = false
      if (this.search && this.search.length) {
        this.isSearch = true

        try {
          const url = VUE_APP_API
          const query = `${url}/payment-gateway/v3/transactions/search?q=${encodeURIComponent(this.search)}`
          const result = await axios.get(query, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.account.accessToken
            }
          })
          const searchQuery = result.config.url.split('?q=')[1]
          if (searchQuery && searchQuery === encodeURIComponent(this.search)) {
            this.isSearch = false
            this.result = {
              total: result.data.cursor.total,
              data: result.data.transactions
            }
          }
        } catch (e) {
          console.log(e, 'some kind of error indeed')
        }
      }

      if (!this.search) {
        this.focus = false
        this.isSearch = false
        this.result.data = null
        this.result.total = null
      }
    },
    selectItem (item) {
      this.result = null
      this.$router.push('/')
      return this.$nextTick(() => this.$router.push(`/search?id=${item.id}`))
    },
    showAll () {
      this.result = null
      this.$router.push('/')
      return this.$nextTick(() => this.$router.push(`/search?query=${this.search}`))
    }
  }
}
</script>

<style lang="scss" scoped>
  .navbar-search-radius {
    border-radius: 0.25rem 0.25rem 0 0 !important;
  }
  .focus {
    height: 25px;
    width: 100%;
    z-index: 1000;
    border: 1px solid orange;
    color: #000;
    padding-left: 5px;
    margin-left: 11px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }
  .search-result {
    height: 230px;
    font-size: 14px;
    width: 100%;
    z-index: 1000;
    border-top: 1px solid #e5e5e5;
    margin-left: 11px;
    background: #fff;
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    .search-result-item {
      display: flex;
      align-items: center;
      height: 35px;
      border-right: 2px solid transparent;
      border-left: 2px solid transparent;
      div {
        margin-left: 10px;
        margin-right: 10px;
      }
      &:hover {
        border-right: 2px solid #fbb040;
        border-left: 2px solid #fbb040;
      }
    }
    .show-all {
      bottom: -212px;
      position: absolute;
      margin-left: 10px;
      margin-right: 10px;
      width: 530px;
    }
  }
</style>
