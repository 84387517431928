<template>
  <div class="cell-wrapper">
    <d-badge :theme="badgeTheme(item.is_active)">{{badgeText(item.is_active)}}</d-badge>
  </div>
</template>

<script>
export default {
  props: ['item'],
  methods: {
    badgeTheme (is_active) {
      if (is_active) {
        return 'success'
      }
      return 'danger'
    },
    badgeText (is_active) {
      if (is_active) {
        return 'активен'
      }
      return 'неактивен'
    }
  }
}
</script>
