// Этот модуль работает с созданием и редактированием проектов (они же магазины, они же мерчанты)
// Отправка проектов на одобрение, редактирование и так далее.
import Mixins from '@/plugins/mixins'

export default {
  namespaced: true,
  state: {
    merchant:{
      id: '',
      general: {
        currency_name: '',
        name: '',
        phone: ''
      },
      api: {
        callback_url: '',
        return_url: '',
        is_sandbox: false,
      },
      contactPerson: {
        name:'',
        email: '',
        phone: '',
      },
    },
    urls: {
      /*secureApi: 'admin/merchants/v3/',
      secureApiPatch: 'admin/merchants/v3/',
      masterMerchant: 'api/merchants/',
      generateSubmerchantToken: 'api/admin/merchants/v2/'*/
      clientsApi: '/clients/v1/clients/',
      secureApi: 'api/public/merchants/v3/',
      secureApiPatch: 'api/public/merchants/v3/',
      masterMerchant: 'api/merchants/',
      generateSubmerchantToken: 'api/public/merchants/v2/'
    },
    masterMerchant: {

    },
    submerchantToken: '',
    registeredAsSubmerchant: false,
    submerchantBusy: false,
    generateSubmerchantTokenBusy: false,
    busy: false
  },
  mutations: {
    updateProject (state, value) {
      state.merchant = value
    },
    updateMerchantValue (state, data) {
      state.merchant[data.propParentName][data.propChildName] = data.value
    },
    updateBusy(state, value){
      state.busy = value
    },
    updateSubmerchantBusy(state, value){
      state.submerchantBusy = value
    },
    updateMasterMerchant(state, value){
      state.masterMerchant = value
    },
    updateRegisteredAsSubmerchant(state, value){
      state.registeredAsSubmerchant = value
    },
    updateSubmerchantToken(state, value){
      state.submerchantToken = value
    },
    updateGenerateSubmerchantTokenBusy(state, value){
      state.generateSubmerchantTokenBusy = value
    },
    resetMerchantValues(state){
      state.merchant.id = ''
      state.merchant.general.name = ''
      state.merchant.general.phone = ''
      state.merchant.api.callback_url = ''
      state.merchant.api.return_url = ''
      state.merchant.api.is_sandbox = false
    }
  },
  actions: {
    resetSubmerchantData(context){
      context.commit('updateMasterMerchant', {})
      context.commit('updateRegisteredAsSubmerchant', false)
      context.commit('updateSubmerchantToken', '')
    },
    loadProject(context, mid){
      context.commit('updateBusy', true)
      context.commit('resetMerchantValues')
      let url = VUE_APP_SECURE + context.state.urls.secureApi + mid
      this.$axios.get(url)
        .then(response => {
          context.commit('updateBusy', false)
          if (!Object.prototype.hasOwnProperty.call(response, 'status')){
            console.log('we got no response from server loadProject', response)
            this._vm.$toast.error('Сервер не ответил на наш запрос');
          }
          if (response.status === 200){
            if (Object.prototype.hasOwnProperty.call(response.data, 'merchant')){
              context.commit('updateProject', response.data.merchant)
              // this._vm.$toast.success('Получили данные');
            }else{
              console.log('response LoadProject', response)
              this._vm.$toast.error('Данные, полученные с сервера, не соответствуют ожидаемому формату');
            }

          }
        })
        .catch(error => {
          console.log('error in promise', error)
        })
    },
    async loadClientsMerchant(context, mid) {
      context.commit('updateBusy', true);
      let cid = context.rootState.account.mandarin.client_id;
      let url = `${VUE_APP_API_CLIENTS}${context.state.urls.clientsApi}${cid}/merchants/${mid}`;

      try {
        const response = await this.$axios.get(url);
        if (response.status === 200 && Object.prototype.hasOwnProperty.call(response.data, 'onboarding_status')) {
          return response.data.onboarding_status;
        } else {
          console.log('response loadClientsMerchant', response);
          this._vm.$toast.error('Данные, полученные с сервера, не соответствуют ожидаемому формату');
          return null; // Возвращаем null, указывая на отсутствие данных
        }
      } catch (error) {
        console.log('error in promise', error);
        return null;
      } finally {
        context.commit('updateBusy', false);
      }
    },
    editProject(context){
      let url = VUE_APP_SECURE + context.state.urls.secureApiPatch + context.state.merchant.id

      let payload = {
        'name': context.state.merchant.general.name,
        'phone': Mixins.methods.normalizePhone(context.state.merchant.general.phone),
        'callback_url': context.state.merchant.api.callback_url,
        'return_url': context.state.merchant.api.return_url,
        'is_sandbox': context.state.merchant.api.is_sandbox,

      }
      context.commit('updateBusy', true)
      this.$axios.patch(url, payload)
      .then(response => {
        context.commit('updateBusy', false)
        if (response.status == 200){
          this._vm.$toast.success('Данные по проекту сохранены.')
          context.dispatch('loadProject', context.state.merchant.id)
        }else{
          console.log('Error in response for editProject', response)
          this._vm.$toast.error('Нам не удалось сохранить данные по проекту.')
        }

      })
      .catch(error => {
        context.commit('updateBusy', false)
        console.log('error in promise', error)
        console.log('error.response', error.response)
        console.log('error.request', error.request)
        console.log('Error', error.message)
        this._vm.$toast.error('Нам не удалось сохранить данные по проекту. ')

      })
    },
    createNewProject(context){

    },
    loadMasterMerchant(context, mid){
      context.commit('updateRegisteredAsSubmerchant', false)
      context.commit('updateSubmerchantBusy', true)
      context.commit('updateMasterMerchant', {})
      let url = VUE_APP_SECURE + context.state.urls.masterMerchant + mid + '/master-merchant'
      this.$axios.get(url)
        .then(response => {

          context.commit('updateSubmerchantBusy', false)
          if (!Object.prototype.hasOwnProperty.call(response, 'status')){
            console.log('we got no response from server loadProject', response)
            this._vm.$toast.error('Сервер не ответил на наш запрос');
          }
          if (response.status === 404){
            if (Object.prototype.hasOwnProperty.call(response.data, 'error')){
              if (response.data.error.includes('is not registered as a submerchant')){
                context.commit('updateRegisteredAsSubmerchant', false)
              }
            }else{
              console.log('response loadMasterMerchant', response)
              this._vm.$toast.error('Данные, полученные с сервера, не соответствуют ожидаемому формату');
            }
          }
          if (response.status === 200){
            context.commit('updateRegisteredAsSubmerchant', true)
            if (Object.prototype.hasOwnProperty.call(response.data, 'masterMerchantId')){
              context.commit('updateMasterMerchant', response.data)
            }else{
              console.log('response LoadProject', response)
              this._vm.$toast.error('Данные, полученные с сервера, не соответствуют ожидаемому формату');
            }
          }
        })
        .catch(error => {
          console.log('error in promise loadMasterMerchant', error)
        })
    },
    generateSubmerchantToken(context){
      context.commit('updateGenerateSubmerchantTokenBusy', true)
      context.commit('updateSubmerchantToken', '')
      let url = VUE_APP_SECURE + context.state.urls.generateSubmerchantToken + context.state.merchant.id + '/generate-token'
      this.$axios.post(url)
      .then(response => {
        context.commit('updateGenerateSubmerchantTokenBusy', false)
        if (response.status === 200){
          if (Object.prototype.hasOwnProperty.call(response.data, 'token')){
            context.commit('updateSubmerchantToken', response.data.token)
            return false
          }
        }
        console.log('response generateSubmerchantToken', response)
        this._vm.$toast.error('Данные, полученные с сервера, не соответствуют ожидаемому формату');
      })
      .catch(error => {
        console.log('error in promise generateSubmerchantToken', error)
      })
	  }
  }
}
