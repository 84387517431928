import { render, staticRenderFns } from "./rewardsBicSuggestions.vue?vue&type=template&id=134570fa&scoped=true"
import script from "./rewardsBicSuggestions.vue?vue&type=script&lang=js"
export * from "./rewardsBicSuggestions.vue?vue&type=script&lang=js"
import style0 from "suggestions-jquery/dist/css/suggestions.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./rewardsBicSuggestions.vue?vue&type=style&index=1&id=134570fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134570fa",
  null
  
)

export default component.exports