// GET /life-backend/api/v3/lead_reward/certificates/ea1357c3-6ef0-4439-b301-dfdecdf6dfe2

export default {
  namespaced: true,
  state: {
    actEmpty: true,
    actReceived: false,
    errorResponse: false,
    actCertificate: {},
    urls: {
      certificates: '/life-backend/api/v3/lead_reward/certificates/'
    }
  },
  mutations: {
    toggleActEmpty (state, value) {
      state.actEmpty = value
    },
    toggleActReceived (state, value) {
      state.actReceived = value
    },
    toggleErrorResponse (state, value) {
      state.errorResponse = value
    },
    updateActCertificate (state, value) {
      state.actCertificate = value
    }

  },
  actions: {
    loadCertificateById (context, id) {
      context.commit('toggleActReceived', false)
      context.commit('toggleActEmpty', true)
      context.commit('toggleErrorResponse', false)
      let url = context.state.urls.certificates + id
      this.$axios.get(url)
        .then(response => {
          // Обрезаем все лишнее что пришло в ответе
          response = response.data
          context.commit('toggleActReceived', true)
          if (!response.certificate) {
            context.commit('toggleActEmpty', true)
          } else {
            context.commit('toggleActEmpty', false)
            context.commit('updateActCertificate', response.certificate)
          }
        })
        .catch(error => {
          context.commit('toggleErrorResponse', true)
          console.log('error in promise', error)
        })
    },
    loadActByExcel (context, certId) {
      let url = '/report-generator/api/v3/certificates/export'
      let payload = {
        'certificate': {
          'id': certId
        }
      }
      this.$axios.post(url, payload, { responseType: 'blob' })
        .then(response => {
          let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          let url = window.URL.createObjectURL(blob)
          window.location.href = url
        })
        .catch(error => {
          console.log('error in promise', error)
          alert('К сожалению, мы не смогли получить экспорт данных в формате Excel. Пожалуйста, обратитесь в техподдержку.')
        })
    },

    acceptCertificate (context, data) {
      context.commit('toggleActReceived', false)
      context.commit('toggleActEmpty', true)
      context.commit('toggleErrorResponse', false)

      let url = context.state.urls.certificates + data.certificateId + '/accept'
      let payload = {
        'lead_reward_certificate': {
          'recipient_id': data.recipientId
        }
      }
      // payload = JSON.stringify(payload)
      this.$axios.post(url, payload)
        .then(response => {
          // Эту часть надо проверить когда научусь назад откатывать
          // console.log(response.headers.location)
          // Status: 201 Created
          // location: /api/v3/lead_reward/certificates/ea1357c3-6ef0-4439-b301-dfdecdf6dfe2
          // Обрезаем все лишнее что пришло в ответе
          response = response.data
          context.commit('toggleActReceived', true)
          if (!response.certificate) {
            context.commit('toggleActEmpty', true)
          } else {
            context.commit('toggleActEmpty', false)
            context.commit('updateActCertificate', response.certificate)
          }
        })
        .catch(error => {
          console.log('error in promise', error)
          context.commit('toggleErrorResponse', true)
          alert('К сожалению, при попытке принять сертификат произошла ошибка. Пожалуйста, обратитесь в техподдержку.')
        })
    }
  }
}
