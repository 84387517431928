const filters_config = {
    instrument_type: {
      show: true,
      options: [
        { value: '10', label: 'Apple Pay', selected: true},
        { value: '1', label: 'Банковская карта', selected: true }
      ]
    }, 
    status:{ 
      show: true,
      options: [
        { value: 'success', label: 'Успешно', selected: true},
        { value: 'fail,timeout', label: 'Ошибка'},
        { value: 'wait,wait_internal,wait_3ds,wait_external,revise_require', label: 'Обработка' }
      ]
    },
    opcodes: {
      show: true,
      options: [
        { value: '453', label: 'Опротестование' }
      ]
    }
  }
  
  export default filters_config


  // Оплаты payments  