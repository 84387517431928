
import en from '@/locales/en'
import ru from '@/locales/ru'

export const translations = {
  en: en,
  ru: ru
}
function getCookie (name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}
// let locale = 'en'
// Ставим локалу русской, потому что выключили переключатель.
let locale = 'ru'
// Проверяем, не сохранили ли мы locale в cookies.
/*
let setLocale = getCookie('merchant_locale')
if (setLocale !== undefined) {
  locale = setLocale
} else {
  // Если cookies у нас не стоит, проверяем локальный язык браузера - если есть хоть один русский, ставим русский
  navigator.languages.forEach((lang) => {
    if (lang.startsWith('ru')) {
      locale = 'ru'
    }
  })
}
*/
export { locale }
