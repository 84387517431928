<template>
  <b-row>
    <b-col>
      <nav-tabs />
      <b-card class="card-small with-nav-tabs">   
        <h5 class="pt-2">Общие сведения</h5>
        <hr />
        <div v-if="showForm">
          <b-row class="mt-4">
            <b-col sm="3"><label>ИНН</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.inn"></d-form-input></b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3"><label>Полное наименование</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.name"></d-form-input></b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3"><label>Краткое наименование</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.short_name"></d-form-input></b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3"><label>ОГРН</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.ogrn"></d-form-input></b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3"><label>КПП</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.kpp"></d-form-input></b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3"><label>ОКВЭД (основной)</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.okved"></d-form-input></b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3"><label>Юридический адрес</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.address_legal"></d-form-input></b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3"><label>Фактический адрес</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.address_factual"></d-form-input></b-col>
          </b-row>
          <h5 class="pt-5">Сведения о руководителе предприятия</h5>
          <hr />
          <b-row class="mt-4">
            <b-col sm="3"><label>ФИО</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.head_name"></d-form-input></b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col sm="3"><label>Должность</label></b-col>
            <b-col sm="9"><d-form-input readonly :value="legal_data.head_position"></d-form-input></b-col>
          </b-row>
          <b-row class="my-4">
            <b-col sm="3"><label></label></b-col>
            <b-col sm="9">
              <d-btn v-if="approval_status" disabled theme="success">Анкета одобрена <i class="fas fa-check-circle"></i></d-btn>
              <d-btn v-else disabled theme="warning">Анкета ожидает одобрения <i class="fas fa-minus-circle"></i></d-btn>
            </b-col>
          </b-row>
        </div>
        <div v-else style="min-height: 200px">
          <Loader :size="3" />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import NavTabs from './navTabs'
export default {
  components:{
    NavTabs
  }, 
  computed: {
    legal_data: function(){
      let legal_data_object = {
        inn: '',
        name: '',
        short_name: '',
        ogrn: '',
        kpp: '',
        okved: '',
        address_legal: '',
        address_factual: '',
        head_name: '',
        head_position: '',
      }
      if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData, 'legal_info')
        && Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info, 'legal_data')
        && this.$store.state.account.clientData.legal_info.legal_data !== null
      ){
        if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info.legal_data, 'inn')){
          legal_data_object.inn = this.$store.state.account.clientData.legal_info.legal_data.inn
        }
        if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info.legal_data, 'name')){
          legal_data_object.name = this.$store.state.account.clientData.legal_info.legal_data.name.full_with_opf
        } 
        if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info, 'legal_name')){
          legal_data_object.short_name = this.$store.state.account.clientData.legal_info.legal_name
        }     
        if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info.legal_data, 'ogrn')){
          legal_data_object.ogrn = this.$store.state.account.clientData.legal_info.legal_data.ogrn
        }
        if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info.legal_data, 'kpp')){
          legal_data_object.kpp = this.$store.state.account.clientData.legal_info.legal_data.kpp
        }
        if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info.legal_data, 'okved')){
          legal_data_object.okved = this.$store.state.account.clientData.legal_info.legal_data.okved
        }
        if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info.legal_data, 'address')){
          legal_data_object.address_legal = this.$store.state.account.clientData.legal_info.legal_data.address.value
        }
        if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info.legal_data, 'ogrn')){
          legal_data_object.ogrn = this.$store.state.account.clientData.legal_info.legal_data.ogrn
        }        
        if (Object.prototype.hasOwnProperty.call(this.$store.state.account.clientData.legal_info.legal_data, 'management')){
          legal_data_object.head_name = this.$store.state.account.clientData.legal_info.legal_data.management.name
          legal_data_object.head_position = this.$store.state.account.clientData.legal_info.legal_data.management.post
        }                
        
      }
      return legal_data_object
    },
    //inn: function () { return this.$store.state.account.clientData.legal_info.inn },
    //company_name: function () { return this.$store.state.account.clientData.legal_info.company_name },
    //company_small_name: function () { return this.$store.state.account.clientData.legal_info.company_small_name },
    //ogrn: function () { return this.$store.state.account.clientData.legal_info.ogrn },
    //kpp: function () { return this.$store.state.account.clientData.legal_info.kpp },
    //okved: function () { return this.$store.state.account.clientData.legal_info.okved },
    //address_legal: function () { return this.$store.state.account.clientData.legal_info.address_legal },
    //address_factual: function () { return this.$store.state.account.clientData.legal_info.address_factual },
    approval_status: function () {
      if (this.$store.state.account.clientData.onboarding_status === 'approved') { return true } else { return false }
    },
    showForm: function () {
      if (this.$store.state.account.clientDataReceived && !this.$store.state.account.clientDataEmpty) {
        return true
      }
      return false
    }
  }
}
</script>
