import Mixins from '@/plugins/mixins'
export default {
  namespaced: true,
  state: {
    mid: 0,
    limits:{
      current:{
        daily: null,
        monthly: null,
        transaction: null
      },
      daily:{
        byMid: {},
        byId: {}
      },
      monthly:{
        byMid: {},
        byId: {}
      },
      transaction:{
        byMid: {},
        byId: {}
      }
    },
    busy: false,
    limitsEmpty: true,
    url: VUE_APP_API_ANTIFRAUD + '/antifraud/v1/limits',
  },
  mutations: {
    updateUrl(state){
      state.url = Mixins.methods.removeTrailingSlash(VUE_APP_API_ANTIFRAUD) + '/antifraud/v1/limits'
    },
    updateBusy(state, value){
      state.busy = value
    },
    clearLimits(state){
      state.limits.daily.byMid = {}
      state.limits.daily.byId = {}
      state.limits.monthly.byMid = {}
      state.limits.monthly.byId = {}
      state.limits.transaction.byMid = {}
      state.limits.transaction.byId = {}
      state.limits.current.daily = null
      state.limits.current.monthly = null
      state.limits.current.transaction = null
    },
    updateCurrentLimitValues(state, values){
      if (Object.prototype.hasOwnProperty.call(values, 'daily')){
        state.limits.current.daily = values.daily
      }
      if (Object.prototype.hasOwnProperty.call(values, 'monthly')){
        state.limits.current.monthly = values.monthly
      }
      if (Object.prototype.hasOwnProperty.call(values, 'transaction')){
        state.limits.current.transaction = values.transaction
      }
    },
    updateLimits(state, limitsFromApi){
      limitsFromApi.forEach(element => {
        let mid = 'all'
        if (element.mw_merchant_id !== null){ mid = element.mw_merchant_id }
        if (element.limit_type === 'd'){
          state.limits.daily.byId[element.id] = element
          state.limits.daily.byMid[mid] = element
          if(parseInt(state.mid) === parseInt(mid)){
            state.limits.current.daily = element.amount
          }
        }
        if (element.limit_type === 'm'){
          state.limits.monthly.byId[element.id] = element
          state.limits.monthly.byMid[mid] = element
          if(parseInt(state.mid) === parseInt(mid)){
            state.limits.current.monthly = element.amount
          }
        }
        if (element.limit_type === 't'){
          state.limits.transaction.byId[element.id] = element
          state.limits.transaction.byMid[mid] = element
          if(parseInt(state.mid) === parseInt(mid)){
            state.limits.current.transaction = element.amount
          }
        }
        // Добавляем действующие лимиты
      });
    },
    setCurrentMid(state, mid){
      state.mid = mid
    }
  },
  actions:{
    async loadLimits(context){
      context.commit('updateBusy', true)
      context.commit('clearLimits')
      context.commit('updateUrl')
      await this.$axios.get(context.state.url)
        .then(response => {
          context.commit('updateBusy', false)
          if (!Object.prototype.hasOwnProperty.call(response, 'status')){
            console.log('we got no response from server loadLimits', response)
            this._vm.$toast.error('Сервер не ответил на наш запрос');
          }
          if (response.status === 200){
            if (Object.prototype.hasOwnProperty.call(response.data, 'limits')){
              context.commit('updateLimits', response.data.limits)
              // this._vm.$toast.success('Получили данные');
            }else{
              console.log('response LoadProject', response)
              this._vm.$toast.error('Данные, полученные с сервера, не соответствуют ожидаемому формату');
            }

          }
        })
        .catch(error => {
          console.log('error in promise loadLimits', error)
          this._vm.$toast.error('Нам не удалось загрузить лимиты с сервера')
        })
    },
    createLimit(context, payload){
      context.commit('updateBusy', true)
      context.commit('updateUrl')
      this.$axios.post(context.state.url, payload)
        .then(response => {
          context.commit('updateBusy', false)
          if (!Object.prototype.hasOwnProperty.call(response, 'status')){
            console.log('we got no response from server createLimit', response)
            this._vm.$toast.error('Сервер не ответил на наш запрос');
          }
          if (response.status === 201){
            this._vm.$toast.success("Новый лимит был добавлен");
            context.dispatch('loadLimits')
          }else{
            console.log('response createLimit', response)
            this._vm.$toast.error('Нам не удалось создать новый лимит');
          }
        })
        .catch(error => {
          console.log('error in promise createLimit', error)
          this._vm.$toast.error('Нам не удалось сохранить лимит на сервере')
        })
    },
    changeLimit(context, data){
      context.commit('updateBusy', true)
      context.commit('updateUrl')
      let url = context.state.url + '/' + data.id
      this.$axios.patch(url, data.payload)
        .then(response => {
          context.commit('updateBusy', false)
          if (!Object.prototype.hasOwnProperty.call(response, 'status')){
            console.log('we got no response from server createLimit', response)
            this._vm.$toast.error('Сервер не ответил на наш запрос');
          }
          if (response.status === 200){
            this._vm.$toast.success("Лимит был изменен");
            context.dispatch('loadLimits')
          }else{
            console.log('response changeLimit', response)
            this._vm.$toast.error('Нам не удалось изменить лимит');
          }
        })
        .catch(error => {
          console.log('error in promise changeLimit', error)
          this._vm.$toast.error('Нам не удалось изменить лимит')
        })
    },
    deleteLimit(context, data){
      context.commit('updateBusy', true)
      context.commit('updateUrl')
      let url = context.state.url + '/' + data.id
      this.$axios.delete(url)
        .then(response => {
          context.commit('updateBusy', false)
          if (!Object.prototype.hasOwnProperty.call(response, 'status')){
            console.log('we got no response from server deleteLimit', response)
            this._vm.$toast.error('Сервер не ответил на наш запрос');
          }
          if (response.status === 204){
            this._vm.$toast.success("Лимит был удалён");
            context.dispatch('loadLimits')
          }else{
            console.log('response deleteLimit', response)
            this._vm.$toast.error('Нам не удалось удалить лимит');
          }
        })
        .catch(error => {
          console.log('error in promise deleteLimit', error)
          this._vm.$toast.error('Нам не удалось удалить лимит')
        })
    }
  }
}
