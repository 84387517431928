<template>
  <div class="mb-3">
    <b-row class="mb-2">
      <b-col cols="9">
        <b-row>
          <b-col cols="4">
            <b-form-group label="Партнерская ссылка:">
              <b-input-group>
                <b-form-input class="cursor-pointer" :value="getUrl()" readonly @click.prevent="goToInstruction(getUrl())"></b-form-input>
                <b-input-group-append>
                  <b-button @click="copyUrl" variant="outline-secondary">
                    <mdicon name="file-multiple-outline" height="20"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="8">
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3" class="text-right">
        <b-form-group label="Инструкция:" class="text-white pt-2">
          <a href="#" @click.prevent="goToInstruction()">Узнать подробнее о партнерской программе</a>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="3">
        <DatePicker/>
      </b-col>
      <b-col cols="5">
        <Service/>
      </b-col>
      <b-col cols="1">
        <CId/>
      </b-col>
      <b-col cols="3">
        <b-button block @click="runFilters" class="search-button" variant="primary">Поиск с фильтрами</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import DatePicker from './datePicker'
import Service from './service.vue'
import CId from './cid.vue'

export default {
  components: {
    DatePicker,
    Service,
    CId,
  },
  data() {
    return {}
  },
  methods: {
    getUrl() {
      return `${VUE_APP_PARTNER_REWARDS_URL}?pref_id=${this.$store.state.account.mandarin.client_id}`;
    },
    copyUrl() {
      const urlToCopy = this.getUrl();
      navigator.clipboard.writeText(urlToCopy).then(() => {
        this.$toast.success('URL успешно скопирован в буфер обмена');
      }).catch(err => {
        this.$toast.error('Не удалось скопировать URL: ', err);
      });
    },
    goToInstruction(url=null) {
      window.open(url || VUE_APP_PARTNER_REWARDS_INSTRUCTION, '_blank');
    },
    runFilters() {
      this.$store.dispatch('pagerStd/updateCurrentTableState', null, {root: true})
    },
  }
}
</script>

<style scoped>
.search-button {
  line-height: 18px;
  font-size: 14px;
  border-radius: 5px;
}
.cursor-pointer.form-control {
  background-color: #ffffff;
  border-color: rgb(232, 232, 232);
  border-right: none;
  color: rgb(0, 123, 255);
  font-weight: 400;
  font-size: 14px;
}
.cursor-pointer.form-control:disabled:hover, .cursor-pointer.form-control[readonly]:hover {
  cursor: pointer;
}
.input-group .btn-outline-secondary {
  border-color: rgb(232, 232, 232);
  padding: 5px;
}
.input-group .btn-outline-secondary:hover {
  background-color: #ffffff;
  color: rgb(90, 97, 105);
}
div >>> .form-control {
  height: 38px;
}
</style>
