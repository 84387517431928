const filters_config = {
    instrument_type: {
      show: true,
      options: [
        { value: '4747', label: 'Банковская карта' }
      ]
    },
    status:{
      show: true,
      options: [
        { value: 'success', label: 'Успешно', selected: true},
        { value: 'bindings', label: 'Привязка'},
        { value: 'fail,timeout,error,failed', label: 'Ошибка'},
        { value: 'null', label: 'Новый'},
        { value: 'wait,wait_internal,wait_3ds,wait_external,revise_require,processing', label: 'Обработка' }
      ]
    },
    opcodes: {
      show: true,
      options: [
        { value: '472', label: 'Выплата' }
      ]
    }
  }

  export default filters_config
