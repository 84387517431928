export default {
  namespaced: true,
  state: {
    itemForExtend: {
    }
  },
  mutations: {
    updateItemForExtend (state, item) {
      state.itemForExtend = item
    }
  },
  actions: {
    changedTable (context) {
      let item = {}
      context.commit('updateItemForExtend', item)
    },
    tableRowSelected (context, item) {
      context.commit('updateItemForExtend', item)
    }
  }
}
