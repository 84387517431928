<template>
  <div class="cell-wrapper">
    <span v-if="item.phone">{{item.phone}}&nbsp;<clipboard :data="item.phone"/></span><span v-if="item.email"><br/>{{item.email}}&nbsp;<clipboard :data="item.email"/></span>
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>
