const filters_config = {
  is_sandbox:{
    show: true,
    options: [
      { value: true, label_short: 'Тестовый', label: 'Тестовый', badge: 'warning', selected: true },
      { value: false, label_short: 'Боевой', label: 'Боевой', badge: 'success', selected: true },
    ],
  },
  onboarding_status:{
    show: true,
    options: [
      { value: 'approved', label_short: 'Одобрен', label: 'Одобрен', badge: 'success', selected: true },
      { value: 'wait_approval', label_short: 'Ожидает', label: 'Ожидает', badge: 'primary', selected: true },
      { value: 'rejected', label_short: 'Отклонен', label: 'Отклонен', badge: 'danger', selected: true },
    ],
  },
}

export default filters_config
