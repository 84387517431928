/* eslint-disable */
import moment from 'moment-timezone'
import currencies from '@/configs/currencies'
import axios from "@/plugins/axios"

export default {
  filters: {
    formatFIO: function (fio) {
      return fio
        .split(' ')
        .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join(' ');
    }
  },
  methods: {
    isValidHttpUrl(string) {
      try {
        const newUrl = new URL(string);
        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
      } catch (err) {
        return false;
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    },
    normalizePhone(phone) {
      phone = phone.replace(/\D/g, '')
      if (phone.charAt(0) === '8') {
        phone = '7' + phone.substring(1)
      }
      phone = '+' + phone
      return phone
    },
    phoneProperDisplay(phone) {
      phone = this.normalizePhone(phone)
      let phoneString = phone.slice(0, 2) + '\u00A0' + phone.slice(2, 5) + '\u00A0' + phone.slice(5, 8) + '-' + phone.slice(8, 10) + '-' + phone.slice(10, 12)
      return phoneString
    },
    async linkOldAdminGeneratedBlank(id, type) {
      this.setLoadingState(true);
      let sign_type = type === '0' ? 'sign1' : 'no_sign';

      try {
        let response = await axios.post(`${VUE_APP_API}/report-generator-nt/v1/report/payments/transactions_billing_info/statements`, {
          id: String(id),
          sign_type: sign_type
        }, {
          responseType: 'blob'
        });

        if (response.status !== 200) {
          throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
        }

        let blob = new Blob([response.data], {type: 'application/pdf'});
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.click();

      } catch (error) {
        let error_text = 'Произошла неизвестная ошибка при генерации отчета';
        if (error.response && error.response.status) {
          if (error.response.status === 500) {
            error_text = 'Для получения справки по данной транзакции обратитесь в Службу поддержки'
          }
          if (error.response.status === 403) {
            error_text = 'У вас не хватает прав для доступа к данному функционалу. Обратитесь к вашему руковуодителю для корректной настройки прав'
          }
        }
        this.$toast.error(error_text);
        this.setLoadingState(false);
      }
      this.setLoadingState(false);
    },
    currency: function (num) {
      return currencies[num].default
    },
    multiselectPlaceholder: function () {
      return this.$i18n.t("service.placeholder")
    },
    properM: function (rawNumber) {
      return Number(rawNumber.toFixed(2)).toLocaleString('ru-RU').replace(/\u00a0/g, '\u2009')
    },
    properMRub: function (rawNumber) {
      return this.properM(rawNumber) + ' ' + '₽'
    },
    properDT_HL: function (sourceDate) {
      let momentDate = moment(sourceDate)
      if (momentDate._isValid) return momentDate.tz('UTC').format('DD.MM.YYYY HH:mm'); else return sourceDate;
    },
    properD_HL: function (sourceDate) {
      let momentDate = moment(sourceDate)
      if (momentDate._isValid) return momentDate.tz('UTC').format('DD.MM.YYYY'); else return sourceDate;
    },
    properDT: function (sourceDate) {
      let momentDate = moment(sourceDate)
      return momentDate.tz('Europe/Moscow').format('DD.MM.YYYY HH:mm')
    },
    properD: function (sourceDate) {
      let momentDate = moment(sourceDate)
      return momentDate.tz('Europe/Moscow').format('DD.MM.YYYY')
    },
    checkScopePresence(scope) {
      let scopesArray = this.$store.state.account.scopesArray
      if (scopesArray.includes(scope)) {
        return true
      }
      // Не забываем, что скоуп может быть закодирован для передачи в урле
      if (scopesArray.includes(encodeURIComponent(scope))) {
        return true
      }
      return false
    },
    dateYYYYMMDD: function (sourceDate) {
      let momentDate = moment(sourceDate)
      return momentDate.tz('Europe/Moscow').format('YYYY-MM-DD')
    },
    dateIsMoscow: function (sourceDate) {
      return moment.tz(sourceDate, "Europe/Moscow");
    },
    checkRolePresence(roles) {
      // checkRolePresence может проверять как массив ролей - если хоть одна роль из массива присутствует
      // у пользователя, то выдается true - так и роль как строку.
      let present = false;
      if (typeof roles === 'undefined' || roles === null || !roles) {
        return present
      }

      let rolesArray = this.$store.state.account.rolesArray
      // Если пришла одна роль в строке, в принципе лучше так не делать и слать сразу массив
      if (typeof roles === 'string') {
        if (rolesArray.includes(roles)) {
          present = true
        }
      }

      // Если пришел массив, проверяется для каждой роли.
      if (Array.isArray(roles)) {
        roles.forEach((role) => {
          if (rolesArray.includes(role)) {
            present = true
          }
        })
      }
      return present
    },
    checkRolePresenceStore(roles, accountRoles) {
      // checkRolePresence может проверять как массив ролей - если хоть одна роль из массива присутствует
      // у пользователя, то выдается true - так и роль как строку.
      let present = false;
      if (typeof roles === 'undefined' || roles === null || !roles) {
        return present
      }

      let rolesArray = accountRoles
      // Если пришла одна роль в строке, в принципе лучше так не делать и слать сразу массив
      if (typeof roles === 'string') {
        if (rolesArray.includes(roles)) {
          present = true
        }
      }

      // Если пришел массив, проверяется для каждой роли.
      if (Array.isArray(roles)) {
        roles.forEach((role) => {
          if (rolesArray.includes(role)) {
            present = true
          }
        })
      }
      return present
    },
    signFromOpcode(opcode) {
      let sign = ''
      switch (opcode) {
        case 4:
          break
        case 1:
        case 3:
        case 6:
          sign = '+'
          break
        default:
          sign = '-'
          break
      }
      return sign
    },
    getAmount(opcode, amount) {
      return opcode === 4 ? amount
        : opcode === 1 || opcode === 3 || opcode === 6 ? `+ ${amount}`
          : `- ${amount}`
    },
    get3ds(val) {
      return val === 1 ? 'Да' : 'Нет'
    },
    getAmountStyle(opcode) {
      return opcode === 4 ? ''
        : opcode === 1 || opcode === 3 || opcode === 6 ? '#4dbd74'
          : '#f86c6b'
    },
    getAmountClass(opcode) {
      return opcode === 4 ? ''
        : opcode === 1 || opcode === 3 || opcode === 6 ? 'positive'
          : 'negative'
    },
    getBadge(status) {
      if (!status) return 'primary'

      return status === 'success' ? 'success'
        : status.indexOf('wait') !== -1 ? 'warning'
          : status === 'fail' ? 'danger' : 'primary'
    },
    getStatusTheme(status) {
      let statusObject = this.calculateStatusData()
      if (statusObject.hasOwnProperty(status)) {
        return statusObject[status].theme
      }
      return 'primary'
    },
    getStatusLabel(status) {
      let statusObject = this.calculateStatusData()
      if (statusObject.hasOwnProperty(status)) {
        return this.$i18n.t(statusObject[status].label)
      }
      return status
    },
    calculateStatusData() {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let statusFilters = []
      Object.entries(this.$store.state[activeTableNamespace].displayTable.tableFilters.status[activeColumnRow]).forEach(entry => {
        statusFilters.push(entry[1])
      })
      let statusObject = {}

      statusFilters.forEach((item, i) => {
        item.filter.forEach((values) => {
          values.value.forEach((status) => {
            statusObject[status] = {}
            statusObject[status].label = item.label
            statusObject[status].theme = item.theme
          })
        })

      })
      return statusObject
    },
    //TODO постепенно удалить?
    getStatus(status) {
      if (!status) return ''

      return status === 'success' ? this.$i18n.t('status.success')
        : status.indexOf('wait') !== -1 ? this.$i18n.t('status.wait')
          : status === 'wait_3ds' ? this.$i18n.t('status.wait_3ds')
            : status === 'timeout' ? this.$i18n.t('status.timeout')
              : status === 'fail' ? this.$i18n.t('status.fail') : status
    },
    getDetailedTransactionStatus(item) {
      if (!item.status) {
        return ''
      }
      let statusLabel = ''
      switch (item.status) {
        case 'success':
          statusLabel = this.$i18n.t('status.success')
          break
        case 'wait_3ds':
          statusLabel = this.$i18n.t('status.wait_3ds')
          break
        case 'wait':
          statusLabel = this.$i18n.t('status.wait')
          break
        case 'timeout':
          statusLabel = this.$i18n.t('status.timeout')
          break
        case 'fail':
          statusLabel = this.$i18n.t('status.fail')
          if (item.response_code) {
            statusLabel += ' (' + item.response_code + ')'
          }
          break
        default:
          statusLabel = item.status
      }
      return statusLabel
    },
    removeTrailingSlash(url) {
      if (url.endsWith('/')) {
        return url.slice(0, -1)
      }
      return url
    },
    getCurrentDomain() {
      let hostnameArray = window.location.hostname.split('.')
      let domainArray = []
      domainArray.push(hostnameArray.pop())
      if (hostnameArray.length > 0) {
        domainArray.push(hostnameArray.pop())
      }
      domainArray.reverse()
      return domainArray.join('.')
    },
    getOpcode(opcode) {
      return opcode === 1 || opcode === 6 ? `Оплата (${opcode})`
        : opcode === 2 || opcode === 22 || opcode === 451 ? `Возврат (${opcode})`
          : opcode === 3 ? `Автоплатеж (${opcode})`
            : opcode === 4 ? `Блокировка`
              : opcode === 5 ? `Оплата (блок)(${opcode})`
                : opcode === 200 ? `Возврат займа (${opcode})`
                  : opcode === 453 ? `Возвратный платеж (${opcode})`
                    : opcode === 472 ? `Выплата (${opcode})` : opcode
    },
    getChannel(channel) {
      return channel === 29 ? `ПСБ+РИБ (${channel})`
        : channel === 34 ? `DOL (${channel})`
          : channel === 36 ? `MandarinPOS (${channel})`
            : channel === 52 ? `ПСБ+РИБ (N)(${channel})`
              : channel === 41 ? `Открытие+РИБ (${channel})`
                : channel === 60 ? `Открытие (${channel})`
                  : channel === 101 ? `Appex (${channel})`
                    : channel
    },
    getInstrument(instrument) {
      return instrument === 407 ? `ПС БЭСТ (${instrument})`
        : instrument === 1 || instrument === 474 || instrument === 482 || instrument === 483 || instrument === 484 || instrument === 4747 ? `БК (${instrument})`
          : instrument === 477 ? `Mobile phone (${instrument})`
            : instrument === 800 ? `Запрос займа (${instrument})`
              : instrument === 15 || instrument === 486 ? `WebmoneyR (${instrument})`
                : instrument === 481 ? `WebmoneyZ (${instrument})`
                  : instrument === 16 ? `Yandex.Money (${instrument})`
                    : instrument === 801 ? `MandarinPOS (${instrument})`
                      : instrument === 478 ? `Банк. счет (${instrument})`
                        : instrument === 485 ? `QIWI (${instrument})`
                          : instrument
    },
    defineActiveRowColumnAndModule() {
      this.defineCurrentActiveModule()

      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeModule = this.$store.state[activeTableNamespace]
      let currentRowColumn = this.getCurrentRowColumn(activeModule)
      this.defineCurrentRowColumn(currentRowColumn.argTopName)
    },
    properApiDateFormat(sourceDate) {
      let properDate = moment.utc(sourceDate).format()
      return properDate
    },
    defineCurrentActiveModule() {
      //Вообще получается что надо определять активный модуль по ряду параметров. Например
      //на странице вознаграждений свой собственный активный модуль получается. Вопрос только в том, надо
      //ли его перерисовывать там или вычислять логику тут. Наверное попробую все-таки там. Разумнее.

      // Для случаев, когда активный модуль не имеет значения - например, на общей странице экспорта.
      // Страницу мы определяем по пути.
      if (this.$route.name === 'ExportPage') {
        // Мы на странице экспорта
        this.$store.commit('pager/updateActiveTableNamespace', 'reportsExport')
        return false
      }
      // активный модуль в данный момент вычисляется из пути и пишется в стор
      if (this.$route.path.includes('/life_merchant/')) {
        this.$store.commit('pager/updateActiveTableNamespace', 'lifeMerchant', {root: true})
      }
      if (this.$route.path.includes('/pay_merchant/')) {
        this.$store.commit('pager/updateActiveTableNamespace', 'payLender', {root: true})
      }
    },
    defineCurrentRowColumn(rowColumn) {
      //Записываем активный rowColumn в стор
      this.$store.commit('pager/updateActiveColumnRow', rowColumn, {root: true})
    },
    getCurrentRowColumn(argumentsArray) {
      let returnObject = {}
      if (argumentsArray === undefined) {
        return returnObject
      }
      argumentsArray.argLeft.forEach((item, i) => {
        if (item.arg === this.$route.params.arg1)
          returnObject.argLeftKey = i
      })
      argumentsArray.argTop[returnObject.argLeftKey].forEach((item, i) => {
        if (item.arg === this.$route.params.arg2) {
          returnObject.argTopKey = i
          returnObject.argTopName = item.name
        }
      })
      return returnObject
    },
    getKeyForComponents() {
      let key = this.$route.params.arg1 + this.$route.params.arg2
      return key
    },
    moneyExport(str) {
      str = String(str)
      if (str === '0') {
        return '0.00'
      }
      let newvalue = ''
      let roubles = str.split('.')
      if (roubles.length > 1) {
        str = roubles[0]
      }
      let i = 0
      if (str.length > 3) {
        while (str.length > 3) {
          if (i === 0) {
            newvalue = str.substring(str.length - 3, str.length)
          } else {
            newvalue = str.substring(str.length - 3, str.length)
          }
          i++
          str = str.substring(0, str.length - 3)
        }
        newvalue = str + '' + newvalue
      } else {
        newvalue = str + '' + newvalue
      }
      if (roubles.length > 1) {
        newvalue = newvalue + ',' + roubles[1]
      }

      return `"${newvalue}"`
    },
    goToCell(key) {
      return `cell(${key})`; // simple string interpolation
    },

    findErrorDescription,
  }
}

export function findErrorDescription(errorsMapping, channelId, responseCode, locale='ru') {
  // console.log(errorsMapping, channelId, responseCode, locale);
  let description = null;

  if (responseCode === null || responseCode === undefined || channelId === null || channelId === undefined) {
    return description;
  }
  channelId = channelId.toString();
  responseCode = responseCode.toString();

  // Поиск с полным совпадением
  let error = errorsMapping.find(e => e.channel_id === channelId && e.response_code === responseCode);

  // Поиск с channel_id = '*'
  if (!error) {
    error = errorsMapping.find(e => e.response_code === responseCode && e.channel_id === '*');
  }

  // Поиск с response_code = '*' и channel_id = '*'
  if (!error) {
    error = errorsMapping.find(e => e.response_code === '*' && e.channel_id === '*');
  }

  if (error) {
    description = error.description.find(d => d[locale]);
    if (description) {
      description = description[locale];
    }
  }

  return description;
}
