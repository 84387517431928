<template>
  <div class="cell-wrapper">
      <a target="_blank" :href="`${item}`" >{{ item }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>
