<template>
  <b-card title="Расширенная информация" class="card-small extended-information">
    <div v-if="item">
      <div class="container-form" style="min-height: 400px;">
        <div class="form-payout">
          <div class="limits-info">
            <div v-if="!isLoadingLimits">
              <p v-if="daily_limit.amountLeft">
                <strong>Лимиты на выплаты:</strong>
                <span class="d-block"> {{ properMRub(daily_limit.amountLeft) }}/день </span>
                <span class="d-block" v-if="transaction_limit">
                  {{ properMRub(transaction_limit) }}/операция
                </span>
              </p>
              <p v-else-if="transaction_limit">
                <strong>Лимиты на выплаты:</strong>
                <span class="d-block">{{ properMRub(transaction_limit) }}/операция</span>
              </p>
              <p v-else>
                <strong>Лимиты на выплаты:</strong>
                <span class="d-block">Лимиты не установлены</span>
              </p>
            </div>
            <div v-else class="text-center w-100">
              <b-spinner class=""></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center" style="line-height: 25em;">
      <!--<mdicon name="information-outline" height="100"/>-->
      <p>Выберите проект для просмотра</p>
    </div>
    
  </b-card>
</template>

<script>
export default {
  props: ['item'],
  components: {},
  data() {
    return {
      daily_limit: null,
      transaction_limit: null,
      balance: null,
    }
  },
  computed: {
    isLoadingLimits() {
      return this.$store.state.projectLimits.busy;
    },
    isLoadingBalance() {
      return this.$store.state.balance.balance_loaded;
    },
    balance_total() {
      return this.$store.state.balance.balance_total;
    },
  },
  methods: {},
  async mounted() {

  },
  watch: {
    item: async function (newVal) {
      if (newVal) {
        let mid = newVal.merchant_id;
        this.$store.commit('projectLimits/setCurrentMid', mid);
        await this.$store.dispatch('projectLimits/loadLimits');

        const daily = this.$store.state.projectLimits.limits.daily.byMid[mid];

        if (!daily) this.daily_limit = {amount: 0, amountLeft: 0};
        else this.daily_limit = {amount: daily.amount, amountLeft: daily.balance.amount_left};

        const transaction = this.$store.state.projectLimits.limits.transaction.byMid[mid];

        this.transaction_limit = transaction ? transaction.amount : 0;
      }
    },
  },
}
</script>

<style scoped>
.form-control[readonly]:hover {
  cursor: pointer;
}
</style>
