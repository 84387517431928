<template>
  <ul class="pagination">
    <li v-if="showPrev" @click="switchData('prev')" class="page-link pointer pager-previous">«</li>
    <li v-if="numberTo" class="page-link pager-numbers">{{numberFrom}}&nbsp;-&nbsp;{{numberTo}}</li>
    <li v-if="showNext" @click="switchData('next')" class="page-link pointer pager-next">»</li>
  </ul>
</template>

<script>
export default {
  computed: {
    showPrev () { if (this.$store.state.pagerStd.cursor.prev === null) { return false } else { return true } },
    showNext () { if (this.$store.state.pagerStd.cursor.next === null) { return false } else { return true } },
    numberFrom () { return this.$store.state.pagerStd.cursor.range * this.$store.state.pagerStd.cursor.currentOffset + 1},
    numberTo () { return this.$store.state.pagerStd.cursor.range * this.$store.state.pagerStd.cursor.currentOffset + this.$store.state.pagerStd.cursor.count }
  },
  methods: {
    switchData (value) {
      this.$store.dispatch('pagerStd/updateCurrentTableState', value, { root: true })
    }
  }
}
</script>

<style scoped>
  .pagination{
    margin-bottom: 0;
    padding-top: 4px;
  }
</style>