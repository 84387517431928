<template>
  <d-card class="card-small mb-4" style="border-radius: 0 0.625rem 0.625rem 0.625rem">
    <d-card-body>
      <d-row class="mb-4">
        <d-col lg="2">
          <DatePicker/>
        </d-col>
        <d-col lg="5">
          <Status/>
        </d-col>
        <d-col lg="5">
          <OperationTypes/>
        </d-col>
      </d-row>
      <Loader v-if="!loadingData" :height="600" :size="3" />
      <div v-else style="min-height: 600px">

        <d-row class="mt-4">
          <d-col>
            <Pager/>
          </d-col>
          <d-col lg="1" style="min-width: 174px">
            <WidgetExport/>
          </d-col>
          <d-col lg="1" style="min-width: 126px">
            <pageOptions/>
          </d-col>
        </d-row>
        <div class="table-overflow-y-hidden" v-if="showTable">

          <b-table striped ref="tableLoan" class="text-nowrap" :fields="fields" :items="dataTable"  @row-clicked="selectItem">
            <template v-for='(templatedField, index) in templatedFields' v-slot:[goToCell(templatedField.key)]='row'>
              <component v-bind:is="templatedField.templateName" v-bind="row" :key="index"></component>
            </template>
          </b-table>
        </div>
        <div v-else class="text-center" style="line-height: 30em;">
          <p>Транзакции с заданными настройками фильтрации отсутствуют. Пожалуйста, измените настройки.</p>
        </div>
      </div>
    </d-card-body>
  </d-card>
</template>

<script>
import vSelect from 'vue-select'
import OperationTypes from '@/components/Filters/operationTypes'
import Status from '@/components/Filters/status'
import DatePicker from '@/components/Filters/datePicker'
import Pager from '@/components/Filters/pager'
import pageOptions from '@/components/Filters/pageOptions'

export default {
  components: {
    vSelect,
    DatePicker,
    OperationTypes,
    Status,
    Pager,
    pageOptions
  },
  data () {
    return {
    }
  },
  computed: {
    loadingData: function () {
      return this.$store.state.pager.apiResponseReceived
    },
    showTable: function () {
      if (this.$store.state.pager.apiResponseReceived && !this.$store.state.pager.apiDataEmpty) {
        return true
      }
      return false
    },
    fields: function () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let fieldsData = this.$store.state[activeTableNamespace].displayTable.columnsControl[activeColumnRow].fields
      let translatedFieldsData = fieldsData.map((obj) => {
        let objNew = Object.assign({}, obj, { // we need 3 objects to not overwrite obj
          label: this.$i18n.t(obj.label)
        })
        return objNew
      })
      return translatedFieldsData
    },
    templatedFields () {
      let templatedFields = []
      this.fields.forEach(field => {
        if (field.hasOwnProperty('templateName')) {
          let f = {}
          f.key = field.key
          f.templateName = field.templateName
          templatedFields.push(f)
        }
      })
      return templatedFields
    },
    dataTable: function () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let responseObject = this.$store.state[activeTableNamespace].displayTable.tableApiUrls[activeColumnRow].responseObject
      let dataTable = this.$store.state[activeTableNamespace].apiData[responseObject]
      // Тут, похоже, array-like object - переделываем в массив.
      dataTable = Array.from(dataTable)
      return dataTable
    }
  },
  created () {
    // Каждое создание требует очистки фильтров и новой подгрузки фильтров
    this.$store.commit('filters/clearAllFilters')
    // Уже при создании нам надо понимать какие активные модули у нас используются
    // И что у нас за собственно раздел в этом активном модуле.
    this.defineActiveRowColumnAndModule()
  },
  mounted () {
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pager/updateCurrentTableState', 'default')
    this.$store.dispatch('extend/changedTable')
  },
  methods: {
    selectItem (item, index, event, type) {
      const target = type === 'get' ? event : event.currentTarget
      if (!target) {
        item = {}
        this.$store.dispatch('extend/tableRowSelected', item)
      }

      for (let i = 0; i < target.parentNode.children.length; i++) {
        if (target.parentNode.children[i].classList.contains('select')) {
          target.parentNode.children[i].classList.remove('select')
        }
      }

      target.classList.add('select')
      this.$store.dispatch('extend/tableRowSelected', item)
    }
  }
}
</script>
