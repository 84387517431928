<template>
  <div class="cell-wrapper">

  <div class="cell-wrapper text-right">
    <d-dropdown :text="$t('service.actions')" :ref="'dropdown' + item.id" right theme="secondary" size="sm">
        <b-dropdown-item @click="loadAct(item)">{{$t('service.seeDetails')}}</b-dropdown-item>
        <b-dropdown-item @click="loadExcel(item)">{{$t('service.saveExcel')}}</b-dropdown-item>
    </d-dropdown>
  </div>

  </div>
</template>

<script>
export default {
  props: ['item'],
  methods: {
    loadExcel: function (item) {
      // Подгружаем нужный эксель
      this.$store.dispatch('acts/loadActByExcel', item.id)
    },
    loadAct (item) {
      let pathTo = '/life_merchant/' + 'rewards/act/' + item.id
      this.$router.push(pathTo)
    }
  }
}
</script>
