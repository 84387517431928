<template>
  <b-nav tabs class="tables-top-nav">
    <b-nav-item to="/loan_requests">Заявки</b-nav-item>
    <b-nav-item to="/loans">Выдачи</b-nav-item>
  </b-nav>
</template>

<script>
export default {
  
}
</script>