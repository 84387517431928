<template>
  <div>
    <h3>Шаг 3</h3>
    <div v-if="status === 'processing'">Обработка...</div>
    <div v-else-if="status === 'completed'">Завершено!</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: 'processing'
    };
  },
  created() {
    this.checkStatus();
  },
  methods: {
    checkStatus() {
      console.log('Check status')
    }
  }
}
</script>
