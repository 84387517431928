import {findErrorDescription} from "@/plugins/mixins.js";
function getLabel(filters_config, filter_name, current_value) {
  let output = current_value
  let valuesArray = filters_config[filter_name].options
  valuesArray.forEach(element => {
    // В этой точке к нам может прийти как одиночное значение,
    // так и набор значений, разделенных запятыми. При этом, поскольку
    // ввод все-таки пользовательский, могут наверное быть и пробелы.
    // На всякий случай обрабатываем любое значение.
    const particlesArray = element.value.split(",");
    particlesArray.forEach(particle => {
      if (particle.trim() == current_value.toString().trim()) {
        output = element.label
      }
    })
  })
  return output
}

function getStatusBadge(filters_config, current_value) {
  let statusOptions = filters_config.status.options
  let output = '<span class="badge badge-secondary">' + current_value + '</span>'
  statusOptions.forEach(element => {
    if (element.value.indexOf(current_value) !== -1) {
      if (element.label === 'Успешно') {
        output = '<span class="badge badge-success">' + element.label + '</span>'
      }
      if (element.label === 'Ошибка') {

        output = '<span class="badge badge-danger">' + element.label + '</span>'
      }
      if (element.label === 'Обработка') {
        output = '<span class="badge badge-info">' + element.label + '</span>'
      }
      if (element.label === 'Новый') {
        output = '<span class="badge badge-light">' + element.label + '</span>'
      }
      if (element.label === 'Привязка') {
        output = '<span class="badge badge-secondary">' + element.label + '</span>'
      }

    }
  })
  return output
}

function getStatusBadgeWithCodeError(errorsMapping, filters_config, item) {
  let statusOptions = filters_config.status.options
  let current_value = item.status
  let output = '<span class="badge badge-secondary">' + current_value + '</span>'
  statusOptions.forEach(element => {
    if (element.value.indexOf(current_value) !== -1) {
      if (element.label === 'Успешно') {
        output = '<span class="badge badge-success">' + element.label + '</span>'
      }
      if (element.label === 'Ошибка') {
        output = '<span class="badge badge-danger">' + element.label + '</span>'
        if (item.status === 'fail') {
          const errorDescription = findErrorDescription(errorsMapping, item.channel_id, item.response_code);
          if (errorDescription) {
            output = `<span v-b-tooltip.hover title="${errorDescription}"><span class="badge badge-danger">Ошибка (${item.response_code})</span></span>`;
          }
        }
      }
      if (element.label === 'Обработка') {
        output = '<span class="badge badge-info">' + element.label + '</span>'
      }
      if (element.label === 'Новый') {
        output = '<span class="badge badge-light">' + element.label + '</span>'
      }
      if (element.label === 'Привязка') {
        output = '<span class="badge badge-secondary">' + element.label + '</span>'
      }

    }
  })
  return output
}

export {getLabel, getStatusBadge, getStatusBadgeWithCodeError}

