<template>
  <div>
    <multiselect v-model="selectedValues" :options="statusOptions" :multiple="true"
     track-by="label" label="label" @input="selectChanged"
    deselect-label="Удалить" select-label="Добавить" selected-label="Опция выбрана" 
    placeholder="Статус"></multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      selectedValues: null
    }
  },
  computed: {
    resetAllFilters () {
      return this.$store.state.filtersStd.resetAllFilters
    },
    statusOptions(){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return filters_config.status.options
    }
  },
  watch: {
    resetAllFilters (newCount, oldCount) {
      this.selectedValues = null
      this.$nextTick(() => {
        this.$store.commit('filtersStd/resetAllFilters', 'ready')
      })
    }
  },
  created(){
    // В этой точке мы забираем дефолтные значения фильтра из файла конфига. 
    let selected = []
    let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
    filters_config.status.options.forEach(function(value){
      if (Object.prototype.hasOwnProperty.call(value, 'selected') && value.selected){
        selected.push(value)
      }
    })
    this.selectedValues = selected;
  },
  mounted(){
    this.selectChanged()
  }, 
  methods:{
    selectChanged () {
      let statusString = ''
      this.selectedValues.forEach(item => {
        statusString += item.value+','
      })
      //убираем последнюю запятую из строки
      statusString = statusString.substring(0, statusString.length - 1)
      this.$store.commit('filtersStd/updateStatus', statusString)
    }
  }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>