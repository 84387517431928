<template>

  <div class="cell-wrapper">
    <d-dropdown v-if="item.bord" class="w-100" :text="(item.state)?item.state:'null'" :theme="item.variant" split right>
        <b-dropdown-item @click='update(item)'>{{ (item.state==='timeout')?'Повторить запрос':'Обновить статус' }}</b-dropdown-item>
    </d-dropdown>
    <b-button v-else block :variant="item.variant">{{ (item.state)?item.state:'null' }}</b-button>
  </div>

</template>

<script>
    

export default {
  props: ['item'],
  methods: {
      update(item){
          if (item.state === 'timeout') {
              this.$store.dispatch('payLender/reopenTransaction', item)
          } else {
              this.$store.dispatch('payLender/updateTransaction', item)
          }
          
      }
  }
}
</script>