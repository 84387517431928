<template>
  <div class="cell-link">
    <Clipboard :id="`${item}`" :text="`${hint}`" />
    <i class="pointer fa fa-external-link-alt mr-1 ml-1" 
        v-b-tooltip.hover="tooltipTextLink" 
        @click="linkHandler(item)"></i>
    <a target="_blank" :href="`${item}`" >{{ item }}</a>
  </div>
</template>
<style>
    .cell-link {
        text-overflow: ellipsis;
        width: 450px;
        overflow: hidden;
    }
</style>
<script>
export default {
  props: ['item'],
  methods: {
    hint () {
        return true;
    },
    tooltipTextLink () {
      return "Открыть в новом окне";
    },
    linkHandler (value) {
      window.open(value);
    }
  }
}
</script>
