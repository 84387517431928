<template>
  <d-card class="card-small mb-4" style="border-radius: 0 0.625rem 0.625rem 0.625rem">
    <d-card-body>
      <Loader v-if="loadingData" :height="600" :size="3" />
      <div v-if="loadedData">
        <d-row class="mb-3">
          <d-col lg="2"><span class="label">Отчетный период</span></d-col>
          <d-col lg="10"><span class="value">{{cert.accounting_period}}</span></d-col>
        </d-row>
        <d-row class="mb-3">
          <d-col lg="2"><span class="label">Получатель вознаграждения</span></d-col>
          <d-col lg="5">
            <div v-if="showSelect">
              <multiselect v-model="selectedRecipientId"
              :options="rewRec"
              :multiple="false"
              :searchable="false"
              :disabled="cert.is_accepted"
              track-by="id"
              label="short_name"
              class="reward-receiver-select"
              :placeholder="selectOptPlaceholder"></multiselect>
            </div>
            <Loader v-else />
          </d-col>
          <d-col lg="5">
            <!-- Обработка добавления пользователя с соответствующими модалами. -->
            <d-button outline class="float-lg-right" v-b-modal.add-recip-mod theme="secondary">Добавить ещё одного получателя</d-button>
            <b-modal no-close-on-backdrop id="add-recip-mod" title="" ok-title="Добавить нового"
            cancel-variant="outline-secondary" ok-variant="outline-primary"
            cancel-title="Выбрать из списка" @ok="addRecipientThroughModal">
              <p class="m-0">Вы хотите добавить НОВОГО получателя. Обращаем внимание, что вы можете выбрать его из списка, если он ранее уже получал вознаграждение.</p>
            </b-modal>
            <RewardsRecipientModal class="recip-modal"/>
            <!-- Обработка добавления пользователя с соответствующими модалами. -->
          </d-col>
        </d-row>
        <d-row class="mb-3">
          <d-col lg="2"><span class="label">Итоговая сумма</span></d-col>
          <d-col lg="10"><span class="value">{{properMRub(cert.total_current_settlement_amount)}}</span></d-col>
        </d-row>

        <d-row v-if="cert.is_accepted" class="mb-3">
            <d-col lg="2">Согласован клиентом</d-col>
            <d-col lg="1"><b-form-checkbox class="chb-green" disabled v-model="cert.is_accepted" name="check-button" switch></b-form-checkbox></d-col>
            <d-col lg="3">{{properDT(cert.accepted_at)}}</d-col>
            <d-col lg="4"></d-col>
            <d-col lg="2"><d-button outline class="float-lg-right" v-on:click="loadExcel" theme="secondary">Выгрузить в Excel</d-button></d-col>
        </d-row>
        <d-row v-else class="mb-3">
            <d-col lg="2">Согласован клиентом</d-col>
            <d-col lg="1"><b-form-checkbox class="chb-green" disabled v-model="cert.is_accepted" name="check-button" switch></b-form-checkbox></d-col>
            <d-col lg="7"><d-button outline class="float-lg-right" v-on:click="loadExcel" theme="secondary">Выгрузить в Excel</d-button></d-col>
            <d-col lg="2"><d-button outline class="float-lg-right" v-on:click="acceptAct" theme="secondary" :disabled="acceptButtonDisabled">Согласовать акт</d-button></d-col>
        </d-row>
        <div class="detailed-information">
          <d-row class="expandable reward_to_be_paid" v-b-toggle.collapse-rtbp>
            <d-col lg="6">
              <i class="material-icons right">chevron_right</i>
              <i class="material-icons down">expand_more</i>
              Вознаграждение к перечислению</d-col>
            <d-col lg="3">Сумма покупок ({{this.$store.state.acts.actCertificate.reward_to_be_paid.loans.length}}): {{properMRub(cert.reward_to_be_paid.purchase_amount)}}</d-col>
            <d-col lg="3" class="text-success">К перечислению: {{properMRub(cert.reward_to_be_paid.total_current_settlement_amount)}}</d-col>
          </d-row>
          <b-collapse id="collapse-rtbp" accordion="single-act-exp" class="mt-2">
            <d-row><d-col>
              <SingleActAccTable data_property_name = "reward_to_be_paid"/>
            </d-col></d-row>
          </b-collapse>
          <d-row class="expandable reward_to_be_refunded" v-b-toggle.collapse-rtbr>
            <d-col lg="6">
              <i class="material-icons right">chevron_right</i>
              <i class="material-icons down">expand_more</i>
               Вознаграждение к удержанию</d-col>
            <d-col lg="3">Сумма покупок ({{this.$store.state.acts.actCertificate.reward_to_be_refunded.loans.length}}): {{properMRub(cert.reward_to_be_refunded.purchase_amount)}}</d-col>
            <d-col lg="3" class="text-danger">К удержанию: {{properMRub(cert.reward_to_be_refunded.total_current_settlement_amount)}}</d-col>
          </d-row>
          <b-collapse id="collapse-rtbr" accordion="single-act-exp" class="mt-2">
            <d-row><d-col>
              <SingleActAccTable data_property_name = "reward_to_be_refunded"/>
            </d-col></d-row>
          </b-collapse>
          <d-row class="expandable no_documents" v-b-toggle.collapse-nd>
            <d-col lg="6">
              <i class="material-icons right">chevron_right</i>
              <i class="material-icons down">expand_more</i>
              Займы, по которым не предоставлены документы</d-col>
            <d-col lg="3">Сумма покупок ({{this.$store.state.acts.actCertificate.no_documents.loans.length}}): {{properMRub(cert.no_documents.purchase_amount)}}</d-col>
            <d-col lg="3" class="">Вознаграждение: {{properMRub(cert.no_documents.reward_calculation_amount)}}</d-col>
          </d-row>
          <b-collapse id="collapse-nd" accordion="single-act-exp" class="mt-2">
            <d-row><d-col>
              <SingleActAccTable data_property_name = "no_documents"/>
            </d-col></d-row>
          </b-collapse>
        </div>
      </div>
      <div v-if="showErrorScreen">
        <br><br>
          К сожалению, при обращении к API произошла ошибка. Пожалуйста, обратитесь в техническую поддержку.
        <br><br>
      </div>

    </d-card-body>
  </d-card>
</template>

<script>
import Multiselect from 'vue-multiselect'
import SingleActAccTable from '@/components/Acts/singleActAccTable'
import RewardsRecipientModal from '@/components/Rewards/rewardsRecipientModal'

export default {
  components: {
    Multiselect,
    SingleActAccTable,
    RewardsRecipientModal
  },
  data: function () {
    return {
      selectedRecipientId: null
    }
  },
  computed: {
    cert: function () { return this.$store.state.acts.actCertificate },
    loadingData: function () {
      if (!this.$store.state.acts.errorResponse && !this.$store.state.acts.actReceived) {
        return true
      }
      return false
    },
    loadedData: function () {
      if (this.$store.state.acts.actReceived && !this.$store.state.acts.errorResponse) {
        return true
      }
      return false
    },
    showErrorScreen: function () { return this.$store.state.acts.errorResponse },
    rewRec: function () {
      return this.$store.state.rewards.rewardsRecipientsActive
    },
    showSelect: function () {
      this.calcSelectValue()
      return this.$store.state.rewards.rewardsRecipientsActiveReceived
    },
    selectOptPlaceholder: function () {
      if (this.rewRec.length) { return 'Выберите получателя вознаграждения' }
      return 'У вас нет активных получателей'
    },
    acceptButtonDisabled: function () {
      if (this.selectedRecipientId === null) {
        return true
      }
      return false
    }
  },
  methods: {
    acceptAct: function () {
      // Готовим данные для отправки в апи.
      let data = {}
      data.certificateId = this.cert.id
      data.recipientId = this.selectedRecipientId.id
      this.$store.dispatch('acts/acceptCertificate', data)
    },
    loadExcel: function () {
      // Подгружаем нужный эксель
      this.$store.dispatch('acts/loadActByExcel', this.cert.id)
    },
    calcSelectValue: function () {
      if (this.cert.is_accepted &&
      this.cert.recipient_info &&
      typeof this.cert.recipient_info === 'object') {
        this.selectedRecipientId = this.cert.recipient_info
      }
    },
    addRecipientThroughModal: function () {
      this.$store.dispatch('rewards/addNewRewardRecipient')
    }
  },
  mounted () {
    this.selectedRecipientId = null
    this.$store.dispatch('rewards/loadRewardsRecipientsActive')
    this.$store.dispatch('acts/loadCertificateById', this.$route.params.actid, { root: true })
  }
}
</script>
<style>
  .reward-receiver-select .multiselect__placeholder{
    color: #c4183c !important;
  }
</style>
<style lang="scss" scoped>

  .detailed-information .row.expandable{

    &>div{
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      border-top: 1px solid #dee2e6;
    }
  }
  .expandable{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    font-weight: bold;
    .material-icons.right{
      display: none;
    }
    &.collapsed{
      background-color: transparent;
      .material-icons.down{
        display: none;
      }
      .material-icons.right{
        display: inline-block;
      }
    }
    &:hover{
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

</style>
