import filtersAnalytics from './analytics'
import filtersPayments from './payments'
import filtersBindings from './bindings'
import filtersPaymentsAll from './paymentsAll'
import filtersPaymentsChargebacks from './paymentsChargebacks'
import filtersPayouts from './payouts'
import filtersInvoices from './invoices'
import filtersPayoutsForm from './payoutsForm'
import filtersPos from './pos'
import filtersLifeBroker from './lifeBroker'
import filtersReceipts from './receipts'
import filtersPartners from './filtersPartners'
import filtersProjects from './filtersProjects'

const filters_config = {
  filtersAnalytics: filtersAnalytics,
  filtersPayments: filtersPayments,
  filtersBindings: filtersBindings,
  filtersPaymentsAll: filtersPaymentsAll,
  filtersPaymentsChargebacks: filtersPaymentsChargebacks,
  filtersPayouts: filtersPayouts,
  filtersPayoutsForm: filtersPayoutsForm,
  filtersPos: filtersPos,
  filtersLifeBroker: filtersLifeBroker,
  filtersReceipts: filtersReceipts,
  filtersPartners: filtersPartners,
  filtersProjects: filtersProjects,
  filtersInvoices: filtersInvoices,
}

export default filters_config
