<template>
  <b-row>
    <b-col cols="12" xl="8">
      <div class="with-nav-tabs">
        <Filters/>
        <PagerComponent class="desktop-layer" />
        <div class="w-100 mobile-layer">
          <Pager />
        </div>
        <Loader v-if="loadingData || isLoading" :height="600" :size="3" />
        <div class="main-table-wrapper text-size-12" v-else>
          <b-table striped hover selectable select-mode="single" class="text-nowrap w-100" :fields="fields" :items="dataTable" @row-clicked="selectItem">
            <template v-slot:head()="data">
              <span v-html="data.field.label"></span>
            </template>
            <template v-slot:cell(created_at)="data">
              <span>{{properDT(data.item.created_at)}}</span>
              <br>
              <span> {{ data.item.settings_data.orderNumber }}</span>
            </template>
            <template v-slot:cell(status)="data">
              <span>{{ properM(calculateTotalCost(data.item.cart)) }} ₽</span>
              <br>
              <span v-html="getStatusBadge(data.item.status)"></span>
            </template>
            <template v-slot:cell(email)="data">
              <span>{{ data.item.settings_data.customerPhone }}</span>
              <br>
              <span>{{ data.item.settings_data.customerEmail }}</span>
            </template>
            <template v-slot:cell(payment_method)="data">
              <span v-html="getPaymentMethodName(data.item.payment_method)"></span>
            </template>
            <template v-slot:cell(actions)="data">
              <div class="text-right">
                <b-button class="btn-outline-primary" @click="createNewInvoice(data.item)">Повторить</b-button>
<!--                <b-dropdown text="Действия" variant="primary" right ref="dropdown">-->
<!--                  <b-dropdown-item @click="createNewInvoice(data.item)">Создать новый счет</b-dropdown-item>-->
  <!--                <b-dropdown-item @click="linkOldAdminGeneratedBlank(data.item.id, '1')">Справка по транзакции (бланк)</b-dropdown-item>-->
<!--                </b-dropdown>-->
              </div>
            </template>
          </b-table>

        </div>
      </div>
    </b-col>
    <b-col cols="0" xl="4" class="fixed-extend desktop-layer">
      <ExtendInvoice :item="extendItem"/>
    </b-col>
  </b-row>
</template>

<script>
import PagerComponent from '@/components/Pager/pagerComponent'
import Filters from './Filters/Filters'
import ExtendInvoice from './extendInvoice.vue'
import { getLabel } from '@/configs/filtersTables/labels'
import { getStatusBadge, getPaymentMethodName } from '@/configs/filtersTables/labels_invoices'
import Pager from "./pager.vue";

export default {
  components: {
    Pager,
    PagerComponent,
    Filters,
    ExtendInvoice
  },
  data(){
    return{
      isLoading: false,
      extendItem: false,
      fields: [
        {
          key: 'created_at',
          label: 'Дата<br>Номер заказа',
        },
        {
          key: 'email',
          label: 'Телефон<br>Email',
        },
        {
          key: 'payment_method',
          label: 'Тип оплаты',
        },
        {
          key: 'status',
          label: 'Сумма<br>Статус',
        },
        {
          key: 'actions',
          label: '',
        },
      ]
    }
  },
  computed:{
    dataTable(){
      return this.$store.state.invoices.invoices
    },
    loadingData(){
      return !this.$store.state.pagerStd.apiResponseReceived
    }
  },
  watch: {
    loadingData: function (newValue, oldValue) {
      if (newValue){
        // Если мы грузим данные - чистим extendItem
        this.extendItem = false

      }
    }
  },
  methods:{
    async createNewInvoice (invoice) {
      console.log(invoice);
      await this.$store.commit('invoices/setInvoiceCopy', invoice);
      this.$router.push({ name: 'Invoices' });
      // this.$toast.success('Счет успешно создан');
    },
    calculateTotalCost(cart) {
      let totalCost = 0;
      if (cart) cart.forEach(item => {
        totalCost += item.cost;
      });
      return totalCost;
    },
    setLoadingState(isLoading) {
      this.isLoading = isLoading;
    },
    selectItem(item, index){
      this.extendItem = item
    },
    displayLabel(filter_name, current_value){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getLabel(filters_config, filter_name, current_value)
    },
    getStatusBadge(status){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getStatusBadge(filters_config, status)
    },
    getPaymentMethodName(value){
      let filters_config = this.$store.state.filtersStd.filtersConfigs[this.$store.state.filtersStd.activeFilterConfig]
      return getPaymentMethodName(filters_config, value)
    },
  },
  created(){
    // Чтобы остатки фильтров не оставались после предыдущих страниц, очищаем фильтры. Делать это надо до установки новых.
    this.$store.commit('filtersStd/clearFilters')
    // Устанавливаем, какой из конфигов фильтров из config/filtersTables используется в этой таблице.
    this.$store.commit('filtersStd/setActiveFilterConfig', 'filtersInvoices', { root: true })
  },
  mounted () {
    this.$store.commit('pagerStd/updateRange', 25, { root: true })
    // Устанавливаем нейспейс для пейджера в этой таблице.
    this.$store.commit('pagerStd/updateActiveTableNamespace', 'invoices', { root: true })
    // после установки таблицы апдейтим ее нынешнее значение.
    this.$store.dispatch('pagerStd/updateCurrentTableState', 'default')
  },
}
</script>

<style scoped>

</style>
