<template>
  <b-card title="Расширенная информация" class="card-small extended-information">
    <div v-if="item">
      <div>
        <!-- Отображение чека в текстовом формате для Бифит -->
        <pre class="text-center" v-if="item.extraData.Response && item.providerType !== 'DigitalKassa'">{{ item.extraData.Response.Raw }}</pre>

        <!-- Отображение чека в iframe для DigitalKassa -->
        <iframe class="receipts-frame" v-if="item.providerType === 'DigitalKassa'" :src="'https://crm.digitalkassa.ru/show/1/' + item.providerId" width="100%"></iframe>
      </div>
    </div>
    <div v-else class="text-center" style="line-height: 25em;">
      <p>Выберите чек</p>
    </div>
  </b-card>
</template>


<script>
export default {
  props: ['item'],
  computed:{
  },
  methods:{
  }
}
</script>

<style scoped>

.receipts-frame {
  height: calc(100vh - 175px);
  border: none;
}

</style>
