<template>

  <div class="cell-wrapper text-right">
    <b-dropdown :text="$t('service.actions')" outline class="outline" :ref="'dropdown' + item.id" right theme="secondary" size="sm">
        <b-dropdown-item @click="detailsRead(item)">{{$t('service.seeDetails')}}</b-dropdown-item>
        <!--<b-dropdown-item @click="detailsWrite(item)">Изменить данные</b-dropdown-item>-->
        <b-dropdown-item v-if="item.is_active"  @click="deactivateRecipient(item)">{{$t('service.deactivateBuyer')}}</b-dropdown-item>
        <b-dropdown-item v-if="!item.is_active" @click="activateRecipient(item)">{{$t('service.activateBuyer')}}</b-dropdown-item>
    </b-dropdown>
  </div>

</template>

<script>
export default {
  props: ['item'],
  methods: {
    detailsRead (item) {
      // deep copy
      this.$store.commit('rewards/updateShowRecipientDetailsModalState', 'display')
      let currentRewardsRecipient = JSON.parse(JSON.stringify(item))
      this.$store.commit('rewards/updateCurrentRewardsRecipient', currentRewardsRecipient)
      this.$store.commit('rewards/toggleShowRecipientDetailsModal', true)
    },
    detailsWrite (item) {
      this.$store.commit('rewards/updateShowRecipientDetailsModalState', 'edit')
      let currentRewardsRecipient = JSON.parse(JSON.stringify(item))
      this.$store.commit('rewards/updateCurrentRewardsRecipient', currentRewardsRecipient)
      this.$store.commit('rewards/toggleShowRecipientDetailsModal', true)
    },
    deactivateRecipient (item) {
      let currentRewardsRecipient = JSON.parse(JSON.stringify(item))
      currentRewardsRecipient.is_active = false
      this.$store.commit('rewards/updateCurrentRewardsRecipient', currentRewardsRecipient)
      this.$store.dispatch('rewards/saveEditedRewardsRecipient')
    },
    activateRecipient (item) {
      let currentRewardsRecipient = JSON.parse(JSON.stringify(item))
      currentRewardsRecipient.is_active = true
      this.$store.commit('rewards/updateCurrentRewardsRecipient', currentRewardsRecipient)
      this.$store.dispatch('rewards/saveEditedRewardsRecipient')
    },
    outsideClick () {
      // console.log('outsideClick')
    }
  }
}
</script>
