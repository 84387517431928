<template>
  <input type="text" v-model="value">
</template>

<script>
import $ from 'jquery'
import 'suggestions-jquery'
export default {
  props: {
    inn: ''
  },
  data () {
    return {
      value: '',
      options: {
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454318
        token: VUE_APP_DADATA_TOKEN,
        type: 'PARTY'
        // scrollOnFocus: false,
        // triggerSelectOnBlur: false,
        // triggerSelectOnEnter: false,
        // addon: 'none',
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454320
      }
    }
  },
  watch: {
    value () {
      // Апдейтим у родительского элемента inn.
      this.$emit('update:inn', this.value)
    }
  },
  mounted () {
    this.callbacks = $.Callbacks()
    this.value = this.inn
    this.initSuggestion()
    let query = {
      'type': 'inn',
      'value': this.inn
    }
    this.$store.dispatch('rewards/queryDadataApiInn', query)
  },
  destroyed () {
    this.destroySuggestion()
  },
  methods: {
    initSuggestion () {
      this.callbacks.add(this.onSelect)
      const options = Object.assign({}, this.options, {
        onSelect: suggestion => this.callbacks.fire(suggestion)
      })
      $(this.$el).suggestions(options)
    },
    destroySuggestion () {
      const plugin = $(this.$el).suggestions()
      plugin.dispose()
    },
    onSelect (suggestion) {
      this.value = suggestion.data.inn
      let suggestionData = {
        suggestion: suggestion,
        suggestionType: 'party'
      }
      this.$store.dispatch('rewards/processSuggestions', suggestionData)
    }
  }
}
</script>

<style src="suggestions-jquery/dist/css/suggestions.min.css"></style>

<style lang="scss" scoped>
  .modal-content >>> .suggestions-suggestions{
    width: 500px;
  }
</style>
