<template>
  <div class="container" v-html="message"></div>
</template>

<script>
export default {
  props: ['message'],
}
</script>

<style>

</style>