// Работа с пейджером
export default {
    namespaced: true,
    state: {
      cursor: {
        range: 25,
        currentOffset: 0,
        count: 0,
        next: null,
        prev: null,
        total: null
      },

      activeTableNamespace: false,

      pageOptions: [10, 15, 25, 50, 100, 500, 2000],

      apiResponseReceived: false,
      apiDataEmpty: true

    },
    mutations: {
      resetCursor (state){
        state.cursor.range = 25
        state.cursor.currentOffset = 0
        state.cursor.count = 0
        state.cursor.next = null
        state.cursor.prev = null
        state.cursor.total = null
      },
      updateCursor (state, cursor) {
        state.cursor.count = cursor.count
        state.cursor.next = cursor.hasOwnProperty('next') ? cursor.next : null;
        state.cursor.prev = cursor.hasOwnProperty('prev') ? cursor.prev : null;
        state.cursor.total = cursor.total
      },
      updateCurrentOffset (state, currentOffset) {
        state.cursor.currentOffset = currentOffset
      },
      updateRange (state, range) {
        state.cursor.range = range
      },
      updateActiveTableNamespace (state, namespace) {
        state.activeTableNamespace = namespace
      },
      updateApiResponseReceived (state, value) {
        state.apiResponseReceived = value
      },
      updateApiDataEmpty (state, value) {
        state.apiDataEmpty = value
      }
    },
    actions: {
      perPageCountChanged (context, value) {
        context.commit('updateRange', value)
        context.dispatch('updateCurrentTableState', 'default')
      },
      updateCurrentTableState (context, value) {
        // Нам надо получить неймспейс активного тейбла и стриггерить обновление апи в этом модуле.
        // В зависимости от того что послужило источником запроса - генерим массив запроса - параметры
        let queryObject = {
          type: '',
          params: {},
          currentOffset: 0
        }
        // Поскольку мы отправляем пейджер на загрузку, нам надо сбросить значения пейджера
        // и установить знак того, что идет запрос в апи.

        context.commit('updateApiResponseReceived', false)

        let action = context.state.activeTableNamespace+'/refreshTable'
        switch (value) {
          case 'prev':
            queryObject.type = 'cursor'
            queryObject.params.cursor = context.state.cursor.prev
            queryObject.currentOffset = context.state.cursor.currentOffset - 1
            context.dispatch(action, queryObject, { root: true })
            break
          case 'next':
            queryObject.type = 'cursor'
            queryObject.params.cursor = context.state.cursor.next
            queryObject.currentOffset = context.state.cursor.currentOffset + 1
            context.dispatch(action, queryObject, { root: true })
            break
          default:
            // Для ситуации когда триггером запроса послужил не курсор - мы просто отправляем queryObject
            queryObject.type = 'filters'
            queryObject.params.limit_to = context.state.cursor.range
            queryObject.currentOffset = 0 // currentOffset обнуляем, так как весь подсчет начинаем с начала
            context.dispatch(action, queryObject, { root: true })
            break
        }
      }
    }
  }
