<template>
  <div v-if="checkScopePresence('report.write')">
    <b-button variant="outline-primary" id="exportButton" v-on:click="exportCurrentState()">Скачать&nbsp;реестр</b-button>
  </div>
</template>

<script>
export default {
  methods: {
    exportCurrentState () {
      
      this.$store.dispatch('reportsExport/generateReport')
      
    }
  },
}
</script>

<style scoped>
  .btn{
    font-size: 14px;
    padding: 11px 1rem;
  }
</style>