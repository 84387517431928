<template>
  <b-nav tabs class="tables-top-nav">
    <b-nav-item exact to="/payments">Оплаты</b-nav-item>
    <b-nav-item exact to="/payments/chargebacks">Опротестования</b-nav-item>
    <b-nav-item exact to="/payments/all">Все операции</b-nav-item>
  </b-nav>
</template>

<script>
export default {

}
</script>
