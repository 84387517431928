<template>
  <div>
    <multiselect
      v-model="selectedMerchantIds"
      :options="merchantOptions"
      :multiple="true"
      track-by="value"
      label="value"
      @input="selectChanged"
      deselect-label="Удалить"
      select-label="Добавить"
      selected-label="Опция выбрана"
      placeholder="Merchant ID"
    >
      <template #option="props">
        <span>
          {{ props.option.label }}
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      selectedMerchantIds: []
    }
  },
  computed: {
    merchantOptions() {
      const processedSites = this.$store.state.account.clientData.processedSites || [];
      let options = processedSites.map(site => ({
        value: site.merchant_id,
        label: `${site.merchant_id}${site.name ? ` - ${site.name}` : ''}`
      }));
      options.sort(this.compareByMid)
      return options
    }
  },
  methods: {
    selectChanged() {
      const selectedIds = this.selectedMerchantIds.map(item => item.value).join(',');
      this.$store.commit('filtersStd/updateMerchantId', selectedIds);
    },
    compareByMid(a, b) {
      return b.value - a.value;
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
