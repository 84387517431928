<template>
    <div class="small-paddings">
        <p class="mb-0">Поиск</p>
        <input class="form-control" style="width: 250px; display: inline-block !important;" v-model="Search" placeholder="номер заказа, номер карты" v-on:keyup.enter="switchData('search')">
        <button type="button" class="btn btn-light btn-sm mx-3 search_transactions" v-on:click="switchData('search')">{{ $t('extend.search') }}</button>
    </div>
</template>

<script>
export default {
  computed: {
    Search: {
      get: function () { return this.$store.state.pager.cursor.search },
      set: function (newValue) {
        this.$store.commit('filters/updateSearchFilter', newValue)
      }
    }
  },
  methods: {
    switchData (value) {
      this.$store.dispatch('pager/updateCurrentTableState', value, { root: true })
    }
  }
}
</script>
