<template>
  <div class="cell-wrapper">
    {{properDT(currentDateField)}} <br />
    <d-badge :theme="getStatusTheme(item.stage)">{{getStatusLabel(item.stage)}}</d-badge>
  </div>
</template>

<script>
export default {
  computed: {
    currentDateField: function () {
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      let activeColumnRow = this.$store.state.pager.activeColumnRow
      let fieldName = this.$store.state[activeTableNamespace].displayTable.tableFilters.datePicker[activeColumnRow].field
      return this.item[fieldName]
    }
  },
  props: ['item']
}
</script>
