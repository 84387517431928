<template>
    <div class="total">Всего: {{total}}</div>
</template>

<script>
export default {
  computed: {
    total: {
      get: function () { return this.$store.state.pagerStd.cursor.total },
    }
  }
}
</script>
<style lang="scss" scoped>
.total{
    padding-top: 10px;
}
</style>