<template>
  <div>
    <b-modal no-close-on-backdrop centered id="refund-modal" @shown="fetchLimits">
      <div class="mb-2">Возврат по транзакции ID: {{currentId}}</div>
      <div class="text-black-50 mb-2">Дневной лимит на возвраты {{ dailyLimitAmount || '-' }} рублей / {{ dailyLimitCount || '-' }} штук</div>
      <b-form-input class="mb-3" v-model="refundAmount" type="number" placeholder="Сумма возврата"></b-form-input>
      <div class="text-black-50 mb-2">
        Для изменения лимита обратитесь в службу поддержки:
        <a href="https://t.me/SuppMandarinBot" target="_blank">ссылка</a>
      </div>
      <b-row>
        <b-col>
          <b-button variant="outline-secondary" @click="$bvModal.hide('refund-modal')">Назад</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button variant="outline-primary" @click="refund">Выполнить возврат</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['transaction'],
  data: function () {
    return {
      refundAmount: null,
      dailyLimitAmount: null,
      dailyLimitCount: null
    }
  },
  computed: {
    currentId() {
      if (this.transaction != null && Object.prototype.hasOwnProperty.call(this.transaction, 'cs1')) {
        return this.transaction.cs1
      }
      return ''
    }
  },
  methods: {
    fetchLimits() {
      this.dailyLimitAmount = null;
      this.dailyLimitCount = null;
      if (this.transaction && this.transaction.mw_merchant_id) {
        const merchantId = this.transaction.mw_merchant_id;
        const url = `${VUE_APP_SECURE}api/public/merchants/v3/limits/${merchantId}`;
        this.axios.get(url)
          .then(response => {
            if (response.data) {
              this.dailyLimitAmount = response.data.dailyReversalSumLimit;
              this.dailyLimitCount = response.data.dailyReversalCountLimit;
            }
          })
          .catch(error => {
            console.error('Error fetching limits:', error);
            this.$toast.error("Не удалось загрузить лимиты.");
          });
      }
    },
    refund() {
      let url = VUE_APP_SECURE_APP + 'reversal/';
      const config = {
        headers: {
          mid: this.transaction.mw_merchant_id
        }
      };
      let payload = {
        "payment": {
          "action": "reversal",
          "orderId": this.$uuid.v4(),
          "price": this.refundAmount
        },
        "target": {
          "transaction": this.transaction.cs1
        }
      }

      this.axios.post(url, payload, config)
        .then(response => {
          console.log(response, 'response refund');
          if (response === undefined) {
            this.$toast.error("Нам не удалось связаться с сервисом возвратов; ответа от сервера нет.");
          } else {
            this.$toast.success("Возврат по транзакции " + payload.target.transaction + " выполнен");
          }
        })
        .catch(error => {
          console.log('error in promise', error);
          this.$toast.error("Нам не удалось связаться с сервисом возвратов; ошибка: " + error.message);
        });
      this.$bvModal.hide('refund-modal');
    }
  },
  mounted() {
    // this.fetchLimits();
  }
}
</script>

<style>
  #refund-modal .modal-footer,
  #refund-modal .modal-header{
    display: none;
  }
</style>
