<template>
  <ul class="list-unstyled navigation-tabs d-flex flex-nowrap mb-0">
    <li v-for="(item, index) in itemsMenu" v-bind:key="index">
        <router-link :class="item.classes" :to="item.pathTo">{{ $t(item.label) }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    itemsMenu: function () {
      let menuArray = []
      let urlPrefix = ''
      let classRole = ''
      let activeTableNamespace = this.$store.state.pager.activeTableNamespace
      
      if (activeTableNamespace === 'lifeMerchant'){
        urlPrefix = '/life_merchant/'
        classRole = 'life-merchant'
      }
      
      if (activeTableNamespace === 'payLender'){
        urlPrefix = '/pay_merchant/'
        classRole = 'pay-merchant'
      }

      let activeModule = this.$store.state[activeTableNamespace]
      let currentRowColumn = this.getCurrentRowColumn(activeModule)

      activeModule.argTop[currentRowColumn.argLeftKey].forEach((item, i) => {
        let menuItem = {}
        menuItem.label = item.label
        menuItem.classes = 'pay ' + classRole + ' ' + item.classes + ' row-' + i
        // Путь мы вычисляем из известного нам this.$route.params.arg1
        menuItem.pathTo = urlPrefix + this.$route.params.arg1 + '/' + item.arg
        menuArray.push(menuItem)
      })

      return menuArray
    }
  }
}
</script>
