<template>
  <d-container fluid class="">
    <d-row>
      <d-col :md="6">
        <h5 class="pt-2">Представитель продавца</h5>
      </d-col>
      <d-col :md="6" class="text-right">
        <d-button outline v-on:click="addRepresentative" theme="secondary" :disabled="disableAddButton">Добавить представителя</d-button>
      </d-col>
    </d-row>
    <b-table striped hover :items="tableItems" :fields="fields">
      <template #cell(action)="row">
        <component v-bind:is="CRAComponentName" v-bind="row"></component>
      </template>
      <template #cell(is_active)="row">
        <component v-bind:is="IAComponentName" v-bind="row"></component>
      </template>
    </b-table>
    <div v-if="showLoader" style="min-height: 200px">
      <Loader :size="3" />
    </div>
    <div v-if="showNoResults" class="text-center" style="line-height: 10em;">
          <p>Вы не добавили ни одного представителя</p>
        </div>
  </d-container>
</template>

<script>
export default {
  data () {
    return {
      IAComponentName: 'CellRewardsIsActive',
      fields: [
        {
          key: 'short_name',
          label: 'Наименование',
          sortable: false
        },
        {
          key: 'bank_account',
          label: 'Номер счета',
          sortable: false
        },
        {
          key: 'bic',
          label: 'БИК банка',
          sortable: false
        },
        {
          key: 'is_active',
          label: '',
          sortable: false
        },
        {
          key: 'action',
          label: '',
          sortable: false
        }
      ]
    }
  },
  computed: {
    showSRTable () {
      if (this.$store.state.rewards.rewardsRecipientsReceived && !this.$store.state.rewards.rewardsRecipientsEmpty) {
        return true
      }
      return false
    },
    showLoader () {
      if (this.$store.state.rewards.rewardsRecipientsReceived) {
        return false
      }
      return true
    },
    showNoResults () {
      if (this.$store.state.rewards.rewardsRecipientsReceived && this.$store.state.rewards.rewardsRecipientsEmpty) {
        return true
      }
      return false
    },
    disableAddButton () {
      // всегда показываем так как не нужно больше правило добавления реципиентов.
      return false
      /*
      if (!this.$store.state.rewards.rewardsRecipientsReceived) {
        // Результат не пришел
        return true
      }
      if (this.$store.state.rewards.rewardsRecipientsEmpty) {
        // результат пришел и он пустой
        return false
      }
      // У нас есть результаты и они не пусты
      // По умолчанию кнопку мы показываем
      let hideButton = false
      this.$store.state.rewards.rewardsRecipients.forEach((recipient, index) => {
        if (recipient.is_active) { hideButton = true }
      })
      return hideButton
      */
    },
    tableItems () {
      // не массив - переделываем в массив.
      let tableItems = Array.from(this.$store.state.rewards.rewardsRecipients)
      /* let tableItems = []
      Array.from(this.$store.state.rewards.rewardsRecipients).forEach(element => {
        let item = {}
        item['short_name'] = element['short_name']
        item['bank_account'] = element['bank_account']
        item['bic'] = element['bic']
        item['is_active'] = element['is_active']
        item['id'] = element['id']
        tableItems.push(item)
      }); */
      return tableItems
    },
    CRAComponentName () {
      return 'CellRewardsAction'
    }
  },
  methods: {
    addRepresentative () {
      let currentRewardsRecipient = {}

      currentRewardsRecipient.type = 'company'
      currentRewardsRecipient.address = {}
      currentRewardsRecipient.contact_person = {}
      currentRewardsRecipient.contact_person_for_finance = {}
      currentRewardsRecipient.management = {}
      currentRewardsRecipient.document = {}
      this.$store.commit('rewards/updateShowRecipientDetailsModalState', 'add')
      this.$store.commit('rewards/updateCurrentRewardsRecipient', currentRewardsRecipient)
      this.$store.commit('rewards/toggleShowRecipientDetailsModal', true)
    }
  },
  mounted () {
    this.$store.dispatch('rewards/loadRewardsRecipients')
  }
}
</script>

<style scoped>
 table >>> .btn-secondary{
     background-color: white;
     color: #5A6169;
 }
 .table >>> tr > td {
     vertical-align: middle;
}
</style>
